import ExpandingInput from 'components/ExpInput';
import PartnerCard from 'components/PartnerCard';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useI18nStore } from 'stores/i18nStore';
import { usePartnerStore } from 'stores/partnerStore';
import { convertColorToHex } from 'utils/functions';

function EditPartnerModal(props) {
    /* <div>
   STATE
 </div>
  */
    const editPartner = usePartnerStore((state) => state.editPartner);
    const createPartner = usePartnerStore((state) => state.createPartner);
    const partner = props.partner;
    const isCreating = props.isCreating ?? false;
    const [bannerImage, setBannerImage] = useState(null);
    const [previewPartner, setPreviewPartner] = useState(partner ?? {
        partnerDetail: {
            title: {
                it: "Title",
                en: "Title",
            },
            description: {
                it: "Description",
                en: "Description",
            },
            url: "",
            button: "0xFFFFFFFF",
            callToActionColor: "0xFF000000",
            textColor1: "0xFFFFFFFF",
            textColor2: "0xFFFFFFFF",
            backGroundColor1: "0xFF000000",
            backGroundColor2: "0xFF000000",
            startDate: new Date().toISOString(),
            endDate: (() => {
                const endDate = new Date();
                endDate.setMonth(endDate.getMonth() + 1);
                return endDate.toISOString();
            })(),
        }
    });
    const color1 = convertColorToHex(previewPartner?.partnerDetail.backGroundColor1);
    const color2 = convertColorToHex(previewPartner?.partnerDetail.backGroundColor2);
    const textColor1 = convertColorToHex(previewPartner?.partnerDetail.textColor1);
    const textColor2 = convertColorToHex(previewPartner?.partnerDetail.textColor2);
    const callToActionColor = convertColorToHex(previewPartner?.partnerDetail.callToActionColor);
    //const buttonColor = convertColorToHex(previewPartner?.partnerDetail.button);

    /* CALLBACK */

    // Callback for form submit
    async function onSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const data = {
            backGroundColor1: formData.get('color1'),
            backGroundColor2: formData.get('color2'),
            bannerImage,
            title: {
                it: formData.get('title_it'),
                en: formData.get('title_en'),
            },
            description: {
                it: formData.get('description_it'),
                en: formData.get('description_en'),
            },
            partner: formData.get('partner'),
            url: formData.get('url'),
            callToActionColor: formData.get('callToActionColor'),
            textColor1: formData.get('textColor1'),
            textColor2: formData.get('textColor2'),
            // FIXME: button color picker
            button: formData.get('textColor1'),
            startDate: new Date(formData.get('startDate').toString()).toISOString(),
            endDate: new Date(formData.get('endDate').toString()).toISOString(),
        };
        if (isCreating) {
            toast.promise(createPartner(data), {
                loading: 'Saving...',
                success: <div className="d-flex flex-column">
                    <b>Partner created!</b>
                </div>,
                error: <b>Could not save.</b>,
            }).then(() => {
                setPreviewPartner({
                    partnerId: partner?.partnerId,
                    partnerDetail: {
                        ...previewPartner.partnerDetail,
                        ...data,
                    }
                });
                setBannerImage(null);
                form.reset();
            });;
        } else {
            toast.promise(editPartner(partner, data), {
                loading: 'Saving...',
                success: <b>Partner saved!</b>,
                error: <b>Could not save.</b>,
            }).then(() => {
                setPreviewPartner({
                    partnerId: partner?.partnerId,
                    partnerDetail: {
                        ...previewPartner.partnerDetail,
                        ...data,
                    }
                });
                setBannerImage(null);
                form.reset();
            });
        }
    }
    // Callback for form change
    const onChange = async (e) => {
        const form = e.target.form;
        const formData = new FormData(form);
        // put data into an object
        const details = {};
        for (const [key, value] of formData.entries()) {
            details[key] = value;
        }
        // color from olor picker to data
        details.backGroundColor1 = formData.get('color1') ?? previewPartner.partnerDetail.backGroundColor1;
        details.backGroundColor2 = formData.get('color2') ?? previewPartner.partnerDetail.backGroundColor2;
        details.textColor1 = formData.get('textColor1') ?? previewPartner.partnerDetail.textColor1;
        details.textColor2 = formData.get('textColor2') ?? previewPartner.partnerDetail.textColor2;
        details.callToActionColor = formData.get('callToActionColor') ?? previewPartner.partnerDetail.callToActionColor;
        //FIXME: button color picker
        details.button = formData.get('textColor1') ?? previewPartner.partnerDetail.button;
        details.title = {
            it: formData.get('title_it'),
            en: formData.get('title_en'),
        };
        details.description = {
            it: formData.get('description_it'),
            en: formData.get('description_en'),
        };
        details.startDate = new Date(formData.get('startDate').toString()).toISOString();
        details.endDate = new Date(formData.get('endDate').toString()).toISOString();
        details.bannerId = previewPartner.partnerDetail.bannerId;
        details.bannerImage = previewPartner.partnerDetail.bannerImage;
        setPreviewPartner({
            partnerId: partner?.partnerId,
            partnerDetail: {
                ...previewPartner.partnerDetail,
                ...details,
            }
        });
    };
    // Check if form is valid
    const isFormValid = () => {
        if (!previewPartner.partnerDetail.title.it || previewPartner.partnerDetail.title.it === '') {
            return false;
        }
        if (!previewPartner.partnerDetail.description.it || previewPartner.partnerDetail.description.it === '') {
            return false;
        }
        if (!previewPartner.partnerDetail.partner || previewPartner.partnerDetail.partner === '') {
            return false;
        }
        if (!bannerImage && !partner?.partnerDetail?.bannerId) {
            return false;
        }
        if (!previewPartner.partnerDetail.startDate || !previewPartner.partnerDetail.endDate) {
            return false;
        }
        return true;
    }
    // Callback for banner image change
    const handleBannerImageChange = (event) => {
        setBannerImage(event.target.files[0]);
        let bannerId;
        // add base64 of bannerImage file
        if (event.target.files[0]) {
            bannerId = URL.createObjectURL(event.target.files[0]);
        }
        let partnerPreview = {
            partnerId: partner?.partnerId,
            partnerDetail: {
                ...previewPartner.partnerDetail,
            }
        };
        partnerPreview.partnerDetail.bannerId = bannerId;
        partnerPreview.partnerDetail.bannerImage = null;
        setPreviewPartner(partnerPreview);
        //Merge the data with the preview partner
    };
    const { t } = useTranslation();
    const quizLanguages = useI18nStore((state) => state.quizLanguages);
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);
    const [activeLanguage, setActiveLanguage] = useState(quizLanguages[0]);

    // Return the modal
    return (
        <div className="modal modal-xl fade" id={isCreating ? "createPartnerModal" : "editPartnerModal" + partner.partnerId} aria-labelledby="editPartnerModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="d-flex flex-column">
                            <h1 className="modal-title fs-5" id="editPartnerModalLabel">{isCreating ? "Create" : "Edit"} Partner</h1>
                            {!isCreating && <small className='text-secondary d-flex'>
                                <span>
                                    ID:
                                </span>&nbsp; <div className='text-secondary' style={{ cursor: 'copy' }}
                                    onClick={() => {
                                        toast('Copied to clipboard!', {
                                            icon: '📋',
                                        });
                                        navigator.clipboard.writeText(partner?.partnerId);
                                    }}>
                                    {partner?.partnerId}
                                </div>
                            </small>}
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={onSubmit} onChange={onChange}>
                            <div className="row">
                                <div className="col-6">
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            {quizLanguages.map((language) =>
                                                <button key={language} className={"nav-link text-secondary " + (language === activeLanguage ? "active" : "")} onClick={() => setActiveLanguage(language)} type="button">
                                                    {language.toUpperCase()}
                                                </button>
                                            )}
                                        </div>
                                    </nav>
                                    <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                                        {quizLanguages.map((language) =>
                                            <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
                                                <div className="d-flex flex-column h-100">
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">{t('forms:title')}</label>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={t('forms:title')} name={"title_" + language} defaultValue={getTranslationWithLanguage(partner?.partnerDetail?.title, language)} />
                                                    </div>

                                                    <span className="form-label">Description</span>
                                                    <div className="input-group mb-3">
                                                        <ExpandingInput required className="form-control" aria-label="Description" placeholder="Description" name={"description_" + language} defaultValue={getTranslationWithLanguage(partner?.partnerDetail.description, language)} />
                                                    </div>
                                                </div>
                                            </div>)}
                                    </div>

                                    {/* CTA INPUT */}
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput2" className="form-label">URL</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput2" placeholder="Partner website link" name="url" defaultValue={partner?.partnerDetail.url} />
                                        {/* help text */}
                                        <div id="emailHelp" className="form-text">Optional URL to the partner's website. Will cause a "Discover" button to appear.</div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput3" className="form-label">Partner name</label>
                                        <input type="text" required className="form-control" id="exampleFormControlInput3" placeholder="Partner Title" name="partner" defaultValue={partner?.partnerDetail.partner} />
                                        <div id="partnerHelp" className="form-text">The name of the partner. Not visualized on the card.</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="colorInput" className="form-label">Background Color #1</label>
                                                <input type="color" className="form-control form-control-color" id="colorInput" name="color1" defaultValue={color1} title="Choose your color" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="colorInput" className="form-label">Background Color #2</label>
                                                <input type="color" className="form-control form-control-color" id="colorInput" name="color2" defaultValue={color2} title="Choose your color" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="colorInput" className="form-label">Call to Action Color</label>
                                                <input type="color" className="form-control form-control-color" id="colorInput" name="callToActionColor" defaultValue={callToActionColor} title="Choose your color" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="colorInput" className="form-label">Text Color #1 - Title</label>
                                                <input type="color" className="form-control form-control-color" id="colorInput" name="textColor1" defaultValue={textColor1} title="Choose your color" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="colorInput" className="form-label">Text Color #2 - Description</label>
                                                <input type="color" className="form-control form-control-color" id="colorInput" name="textColor2" defaultValue={textColor2} title="Choose your color" />
                                            </div>
                                        </div>
                                        {/* // button color PICKER
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="colorInput" className="form-label">Text Color #3 - Button</label>
                                                <input type="color" className="form-control form-control-color" id="colorInput" name="button" defaultValue={buttonColor ?? '0xFF000000'} title="Choose your color" />
                                            </div>
                                        </div> */}
                                        {/* START date datepicker */}
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="startDate" className="form-label">Start Date </label>
                                                <input type="date" required className="form-control" id="startDate" name="startDate" defaultValue={partner?.partnerDetail.startDate.substr(0, 10) || previewPartner.partnerDetail.startDate?.substr(0, 10) || new Date()} />
                                            </div>
                                        </div>
                                        {/* END date datepicker */}
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label htmlFor="endDate" className="form-label">End Date</label>
                                                <input type="date" required className="form-control" id="endDate" name="endDate" defaultValue={partner?.partnerDetail.endDate.substr(0, 10) || previewPartner.partnerDetail.endDate?.substr(0, 10) || new Date()} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Banner PICKER */}
                                    <h6>Banner</h6>
                                    <div id="nav-argument-banner-image" className="d-flex flex-column mb-3">
                                        {!(bannerImage || partner?.partnerDetail?.bannerId) && <h6>No image selected</h6>}
                                        {(bannerImage || partner?.partnerDetail?.bannerId) && <img src={bannerImage != null ? URL.createObjectURL(bannerImage) : (partner?.partnerDetail?.bannerId ?? "")} className="img-fluid object-fit-contain border rounded mb-3 w-75" style={{
                                            height: "220px",
                                            objectFit: "cover"
                                        }} alt="Preview of banner to upload" />}

                                        <input onChange={handleBannerImageChange} required={(bannerImage || partner?.partnerDetail?.bannerId) ? false : true}
                                            className="form-control" type="file" id="formFile" name="bannerImage" />
                                    </div>
                                    {/* PREVIEW */}

                                </div>
                                <div className="col-6">
                                    <h6>Preview</h6>
                                    <PartnerCard partner={previewPartner} />

                                </div>
                                <div className='col-12'>
                                    <div className="d-flex flex-row-reverse mt-3">
                                        <button type="submit" className="btn btn-primary ms-2" data-bs-dismiss={isFormValid() ? "modal" : ""} >Save changes</button>
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>);
}

export default EditPartnerModal