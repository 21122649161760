import React from "react";
import { useTranslation } from "react-i18next";

export function StepControl({ step, handlePreviousStep, handleNextStep }: { step: number, handlePreviousStep: () => void, handleNextStep: () => void }) {
    const { t } = useTranslation();
    return (
        <div className="d-flex flex-row align-items-center w-100">
            <div className="progress flex-fill me-3">
                <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${(step) * (100 / 10)}%`, height: "2rem" }} aria-valuenow={(step) * (100 / 10)} aria-valuemin={0} aria-valuemax={100}></div>
            </div>
            <div className="d-flex align-items-center">
                <button className={"btn btn-primary me-3 " + (step === 1 ? "disabled" : "")} onClick={handlePreviousStep}>{t('common:back')}</button>
                {step < 10 ? (
                    <button className="btn btn-primary" onClick={handleNextStep}>{t('common:next')}</button>
                ) : (
                    <button className="btn btn-primary" onClick={handleNextStep}>{t('common:create')}</button>
                )}
            </div>
        </div>
    );
}