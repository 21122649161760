import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function ShopErrorModal({ error, dismiss }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className='position-fixed w-100 h-100 d-flex align-items-end align-items-md-end align-items-lg-center bg-primary bg-opacity-50' style={{
            top: 0,
            left: 0,
            zIndex: 9999
        }}>
            <div className='container px-0'>
                <div className='row'>
                    <div className='col-12 col-md-6 mx-auto'>
                        <div className='card'>
                            <div className='card-body p-4'>
                                <div className='d-flex align-items-center mb-3'>
                                    <i className='fa-duotone fa-shop-slash text-danger fa-3x me-3' />
                                    <h3 className='text-center mb-0'>
                                        {t('shop:error')}
                                    </h3>
                                </div>
                                <p className='mb-3'>
                                    {t('shop:' + error)}
                                </p>
                                <div className='d-flex align-items-center'>
                                    <button className='ms-auto btn btn-danger rounded-pill px-5' onClick={dismiss}>
                                        {t('common:exit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
