import xp from "assets/images/xp.svg";
import React from 'react'
import { useExperiencePointsStore } from "stores/experiencePointsStore";

function XpCounter() {
  const currentExperiencePointData = useExperiencePointsStore(state => state.currentExperiencePointData);
  // init xp if currentExperiencePointData is null
  React.useEffect(() => {
    if (currentExperiencePointData === null) {
      useExperiencePointsStore.getState().getUserExperiencePoints();
    }
  }, [currentExperiencePointData]);

  return (
    <div className="XpCounter mx-2 d-flex">
      <img src={xp} className="me-1" alt="xp" style={{ height: "1.5rem" }}></img>
      <h5 className="mb-0">{currentExperiencePointData?.experiencePointDetail.currentExperiencePoints ?? "   "}</h5>
    </div>
  )
}

export default XpCounter