import CircleButton from "components/CircleButton";
import { set } from "immer/dist/internal";
import Lottie from "lottie-react";
import React from "react";
import * as ReactDOM from 'react-dom/client';

import { useTranslation } from "react-i18next";
import YouTube, { YouTubeProps } from 'react-youtube';
import coinAnimation from "assets/lotti/coin.json";
import { AmplitudeService } from "services/amplitudeService";
import { Playlist, Video } from "models/videoModels";
import { useI18nStore } from "stores/i18nStore";
import { Modal } from "react-bootstrap";
import Vimeo from '@u-wave/react-vimeo'; // eslint-disable-line import/no-unresolved
import VideoPlayer from "./VideoPlayer";
import { useIsMobile } from "stores/appStore";

function randomPointNearRect(x: number, y: number, w: number, h: number, minDist: number, maxDist: number) {
    const dist = (Math.random() * (maxDist - minDist) + minDist) | 0;
    x += dist;
    y += dist;
    w -= dist * 2
    h -= dist * 2
    if (Math.random() < w / (w + h)) { // top bottom
        x = Math.random() * w + x;
        y = Math.random() < 0.5 ? y : y + h - 1;
    } else {
        y = Math.random() * h + y;
        x = Math.random() < 0.5 ? x : x + w - 1;
    }
    return [x | 0, y | 0];
}


function VideoCard({ video, playlist }: { video: Video, playlist?: Playlist }) {
    const getTranslation = useI18nStore(state => state.getTranslation);
    const onPlayerReady: YouTubeProps['onReady'] = (event: any) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    // Calcalulate the height of the video basded on the window size for desktop and mobile
    const height = !isMobile ? window.innerHeight * 0.6 : window.innerHeight * 0.4;
    const opts: YouTubeProps['opts'] = {
        height: height,
        width: "100%",
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    const playVideo = () => {
        setShowModal(true);
    };
    const [showModal, setShowModal] = React.useState(false);

    return (
        <div key={video.videoId + "-video-card"} className="d-flex align-items-stretch" style={{ "width": "100%", "height": "100%", }}>
            <div className="card w-100 d-flex align-items-center " style={{ "backgroundImage": "url(" + video.videoDetail.thumbnail + ")", backgroundSize: "cover", backgroundPosition: "left center" }}>
                {/*play button */}
                {/*                 <h5 className="text-white mb-2 mt-3 px-3">
                    {getTranslation(video.videoDetail.title)}
                </h5> */}
                <VideoPlayer video={video} showPlayer={showModal} closePlayer={() => setShowModal(false)} author={playlist?.playlistDetail.metadata.it} onVideoEnd={() => {
                    setShowModal(false);
                }}
                >
                    <CircleButton onClick={playVideo}
                        iconClassName={"fas fa-play text-primary ps-1 fs-2x"} variant="primary-subtle" iconVariant="primary" className="fa-2x my-auto cursor-pointer"></CircleButton>
                </VideoPlayer>
            </div>
        </div >
    )
}
export default VideoCard