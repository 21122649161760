import CircleButton from "components/CircleButton";
import HeaderAdmin from "components/HeaderAdmin"
import Loader from "components/Loader";
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next";
import { useProgressionStore } from "stores/progressionStore";
import { AsyncChat } from "models/chatModels";
import { useChatManagerStore } from "stores/chatManagerStore";
import { useNavigate } from "react-router-dom";
import ChatMessenger from "components/ChatMessenger/ChatMessenger";
import { Badge, Button, Card, Offcanvas } from "react-bootstrap";
import { useBootstrapBreakpoints } from "react-bootstrap/esm/ThemeProvider";
import { useBreakpointValue } from "@aws-amplify/ui-react";
import { useFetchUser, useUsersStore } from "stores/usersStore";
import { toast } from "react-hot-toast";
import { useChatStore } from "stores/chatStore";
import newMessageAudio from "assets/audio/levelup.mp3";
import { useDocumentStore, useFetchDocument } from "stores/documentStore";
import { useUserStore } from "stores/userStore";
import { useTitle } from "utils/functions";
import finn from "assets/images/logo-FinAi.svg";
import ProximityDate from "components/ProximityDate";
import { m, motion } from "framer-motion";
import HeaderWidgetPortal from "components/HeaderWidgetPortal";

let chatsToRead = -1;

function ChatManagerPage() {
    const chats = useChatManagerStore(state => state.chats);
    const { t } = useTranslation();
    const selectedChat = useChatManagerStore(state => state.selectedChat);
    const [showDocuments, setShowDocuments] = React.useState(false);
    const [showContacts, setShowContacts] = React.useState(false);
    const [showAiChats, setShowAiChats] = React.useState(false);
    const loading = useChatManagerStore(state => state.loading);
    useTitle(t('chat_manager:title'));
    React.useEffect(() => {
        if (Notification.permission !== "granted")
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    new Notification('Notifiche messaggi attive', {});
                }
            });
        useChatManagerStore.getState().getChatsData();
    }, []);

    useEffect(() => {
        if (useUsersStore.getState().usersList === null) {
            useUsersStore.getState().initUsers();
        }
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            useChatManagerStore.getState().getChatsData();
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        // if any chat becomes unread, play a sound
        if (chats) {
            const unreadChats = chats.filter(chat => !chat.asyncChatDetail.isRead && chat.asyncChatDetail.lastMessageType !== 'HR');
            if (unreadChats.length > 0 && chatsToRead !== -1 && unreadChats.length > chatsToRead) {
                const audio = new Audio(newMessageAudio);
                audio.play();
                chatsToRead = unreadChats.length;
            } else {
                chatsToRead = unreadChats.length;
            }
            // if current chat has changed, update it
            if (selectedChat && chats.find(chat => chat.asyncChatId === selectedChat.asyncChatId)) {
                // compare the objects, if they are different, update the selected chat
                if (JSON.stringify(selectedChat) !== JSON.stringify(chats.find(chat => chat.asyncChatId === selectedChat.asyncChatId))) {
                    useChatManagerStore.getState().selectChat(chats.find(chat => chat.asyncChatId === selectedChat.asyncChatId)!.asyncChatId);
                }
            }
        }
        return () => {
            chatsToRead = -1;
        };
    }, [chats, selectedChat]);

    const handleDocumentSelect = (file: File) => {
        useDocumentStore.getState().uploadDocument({
            format: file.type,
            title: file.name,
            ownerId: useUserStore.getState().user?.userProfileId!,
            type: 'chat',
            file: file,
        }).then((document) => {
            if (document) {
                useChatStore.getState().sendMessage({
                    message: JSON.stringify({
                        title: file.name,
                        type: file.type
                    }),
                    ownerId: useUserStore.getState().user?.userProfileId!,
                    asyncChatMessageDate: new Date().toISOString(),
                    documentId: document.documentId,
                    uploadDocument: false,
                    type: 'HR',
                }, selectedChat!).then(() => {

                });
            }
        });
    }


    const { user } = useFetchUser(selectedChat?.asyncChatDetail.userProfileId ?? '');

    return (
        <section className='ChatManagerPage d-flex flex-column h-100 pb-3'>
            <HeaderWidgetPortal>
                <div className="d-flex align-items-center ms-auto">
                    <CircleButton onClick={() => setShowContacts(true)} className="d-md-none me-2" variant="primary" iconClassName={'fas fa-users'}></CircleButton>
                    <button className="btn btn-outline-primary d-none d-md-block" onClick={() => setShowAiChats(!showAiChats)}>
                        {showAiChats ? t('chat_manager:hide_ai') : t('chat_manager:show_ai')}
                        <i className={"fas ms-2 " + (showAiChats ? "fa-eye-slash" : "fa-eye")}></i>
                    </button>
                </div>
            </HeaderWidgetPortal>
            {/*             <style>
                {`
                    .SidebarsLayout{
                        height: 100vh !important;
                    }
                `
                }
            </style> */}
            <div className="row flex-fill overflow-x-hidden">
                <div className="col-4  h-100 d-none d-md-block">
                    <div className='mb-4 card  h-100 '>
                        {
                            chats === null &&
                            <div className="d-flex justify-content-center align-items-center h-100 p-5">
                                <Loader />
                            </div>
                        }

                        {
                            chats && chats.length > 0 && <div className="card-body h-100 d-flex flex-column overflow-y-auto">
                                <ul className="list-group list-group-flush">
                                    {chats
                                        .filter(chat => !chat.asyncChatDetail.useAI || showAiChats)
                                        .map((chat) => <ChatManagerPageTile chat={chat} key={chat.asyncChatId} selectedChat={selectedChat} />)}
                                </ul>
                            </div>
                        }
                        {
                            chats && chats.length === 0 && <div className="d-flex justify-content-center align-items-center h-100 p-5">
                                <i className="fa-duotone fa-message-slash text-primary fa-2x me-4"></i>
                                <h4>{t('chat:no_messages')}</h4>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-12 col-md-8 h-100 pb-3">
                    {selectedChat &&
                        <Card className="h-100">
                            <Card.Body className="pb-0 d-flex flex-column">
                                <Card.Title className="fw-bold d-flex align-items-start flex-grow-0">
                                    <div className="d-flex flex-column">

                                        <span>
                                            {selectedChat.asyncChatDetail.title}
                                        </span>
                                        <small className="fs-6 text-muted">
                                            {user?.userProfileDetail.name} - {new Date(selectedChat.asyncChatDetail.creationDate ?? "").toLocaleString()}
                                        </small>
                                    </div>
                                    <div className="ms-auto flex-shrink-0">
                                        <CircleButton onClick={() => {
                                            if (!loading) {
                                                toast.promise(useChatManagerStore.getState().toggleChatAi(selectedChat.asyncChatId), {
                                                    loading: t('common:loading'),
                                                    success: selectedChat.asyncChatDetail.useAI ? t('common:deactivated') : t('common:activated'),
                                                    error: t('common:error')
                                                });
                                            }
                                        }} className="me-1 ms-auto" variant={selectedChat.asyncChatDetail.useAI ? "primary" : "secondary"} iconClassName={'fas fa-microchip-ai'}></CircleButton>
                                        <CircleButton onClick={() => {
                                            setShowDocuments(true);
                                        }} className="me-1" variant="primary" iconClassName={'fas fa-file-user'}></CircleButton>
                                        <CircleButton onClick={() => {
                                            useChatManagerStore.getState().closeChat(selectedChat.asyncChatId);
                                            toast.promise(useChatManagerStore.getState().closeChat(selectedChat.asyncChatId), {
                                                loading: t('common:deleting'),
                                                success: t('common:deleted'),
                                                error: t('common:error')
                                            });
                                        }} className="" variant="primary" iconClassName={'fas fa-trash'}></CircleButton>
                                    </div>

                                </Card.Title>
                                <div style={{
                                    marginBottom: '-1rem',
                                    height: 'calc(100%)',
                                }} >
                                    {chats && <ChatMessenger
                                        chat={selectedChat}
                                        onMessageSent={
                                            () => {
                                                useChatManagerStore.getState().getChatsData();
                                            }
                                        }
                                    />}
                                </div>

                            </Card.Body>
                        </Card>}
                    {
                        !selectedChat && !chats && <Loader />
                    }
                    {
                        selectedChat && chats && <Offcanvas placement={'end'} show={showDocuments} onHide={() => setShowDocuments(false)}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>{t('chat_manager:documents')}</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                {
                                    selectedChat.asyncChatDetail.messages.map((message) => {
                                        if (message.documentId) {
                                            return (<DocumentTile message={message} key={message.asyncChatMessageDate} />)
                                        }
                                        return null;
                                    })
                                }
                                {
                                    (selectedChat.asyncChatDetail.messages.length === 0 || selectedChat.asyncChatDetail.messages.filter(message => message.documentId).length === 0) &&
                                    <div className="d-flex justify-content-center align-items-center h-100 p-5">
                                        <strong>{t('chat_manager:no_documents')}</strong>
                                    </div>
                                }
                            </Offcanvas.Body>
                        </Offcanvas>
                    }
                    {
                        showContacts && chats && <Offcanvas backdropClassName="d-md-none" placement={'end'} show={showContacts} onHide={() => setShowContacts(false)}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>{t('chat_manager:contacts')}</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                {
                                    chats === null &&
                                    <div className="d-flex justify-content-center align-items-center h-100 p-5">
                                        <Loader />
                                    </div>
                                }

                                {
                                    chats && chats.length > 0 && <div className="card-body d-flex flex-column">
                                        <ul className="list-group list-group-flush">
                                            {chats.map((chat) => <ChatManagerPageTile chat={chat} key={chat.asyncChatId} selectedChat={selectedChat} />)}
                                        </ul>
                                    </div>
                                }
                                {
                                    chats && chats.length === 0 && <div className="d-flex justify-content-center align-items-center h-100 p-5">
                                        <i className="fa-duotone fa-message-slash text-primary fa-2x me-4"></i>
                                        <h4>{t('chat:no_messages')}</h4>
                                    </div>
                                }
                            </Offcanvas.Body>
                        </Offcanvas>
                    }
                </div>
            </div>


        </section>
    )
}

function DocumentTile({ message }: { message: any }) {
    const { document, loading, error } = useFetchDocument(message.documentId!);
    const type = document?.document.type;
    const title = document?.document.title;
    return (<>
        {!loading && !error && <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="d-flex align-items-center border-bottom py-2 hoverable cursor-pointer" onClick={() => useDocumentStore.getState().downloadDocument(message.documentId!)}>
            <i className={"fa-duotone text-primary fa-2x me-3 fa-file-" + (type === "application/pdf" ? "pdf" : "image")}></i>
            <div className="d-flex flex-column">
                <strong>
                    {title ?? " "}
                </strong>
                <small className="text-muted">{new Date(message.asyncChatMessageDate).toLocaleString()}</small>
            </div>
            <div className="ms-auto cursor-pointer">
                <i className="fa-duotone fa-download text-primary"></i>
            </div>
        </motion.div>
        }</>
    );
}

function ChatManagerPageTile({ chat, selectedChat }: { chat: AsyncChat, selectedChat: AsyncChat | null }) {
    const { user } = useFetchUser(chat.asyncChatDetail.userProfileId ?? '');
    const hasNewMessages = useMemo(() => {
        if (!chat.asyncChatDetail.isRead && chat.asyncChatDetail.lastMessageType === 'Users' && !chat.asyncChatDetail.useAI) return true;
        return false;
    }, [chat.asyncChatDetail.isRead, chat.asyncChatDetail.lastMessageType, chat.asyncChatDetail.useAI]);
    if (!user) return null;
    return (
        <li className='d-flex align-items-start list-group-item cursor-pointer hoverable px-0' onClick={() => useChatManagerStore.getState().selectChat(chat.asyncChatId)}>
            {
                (selectedChat?.asyncChatId === chat.asyncChatId &&
                    <i className="fa-duotone fa-circle-dot text-primary me-3"></i>) || <i className="fa-duotone fa-circle text-primary me-3"></i>
            }
            <div className="d-flex flex-column flex-fill">
                <h6 className='mb-0'>{user?.userProfileDetail.name + " " + user?.userProfileDetail.surname}</h6>
                <span className='mb-0'>{chat.asyncChatDetail.title}</span>
            </div>
            <div className="d-flex flex-column justify-content-start h-100 ps-2">
                <ProximityDate className='mb-0 small' date={new Date(chat.asyncChatDetail.dateLastUpdate ?? "")} />
                {hasNewMessages &&
                    <Badge className='ms-auto bg-danger-subtle text-danger'>
                        NEW
                    </Badge>}
            </div>

        </li>
    )
}


export default ChatManagerPage  