import { create } from "./createStore";
import elsa from "assets/images/else.png";
import monti from "assets/images/monti.png";
import fra from "assets/images/fra.png";
import dre from "assets/images/dre.png";
import fab from "assets/images/fab.png";
import ste from "assets/images/experts/ste.png";
import gab from "assets/images/experts/Gab.png";
import { ApiService } from "services/apiService";
import { Expert, ExpertDetail } from "models/expertModel";
import { useDocumentStore } from "./documentStore";
import { useUserStore } from "./userStore";
import { generateImageUrl } from "utils/functions";


const _experts = [
    {
        "id": "1",
        "title": {
            "en": "Gabriele Bochicchio - 2 free calls",
            "it": "Gabriele Bochicchio - 2 chiamate gratuite"
        },
        "description": {
            "en": "My name is Giovanni Rizzo, and I am a financial consultant registered with the professional association and graduated with honors in Finance. I work with Azimut Capital Management, the largest Independent savings management company in Italy. In particular, I help individuals and companies navigate wealth management with transparency and seriousness.\n\nI offer my services in person for those residing in Rimini or nearby and online for clients throughout Italy. Therefore, if you want to optimize your wealth, contact me to schedule a free introductory meeting.",
            "it": "Mettiamo insieme STOP al tuo stress finanziario! Aumenta le tue entrate, grazie a un metodo pratico e sicuro per gestire i tuoi risparmi. Costruisci così la tua stabilità finanziaria per sostenere il tuo stile di vita desiderato. \nNegli ultimi 12 mesi, grazie al risparmio ho aiutato + 100 persone a raggiungere progetti personali , come l'acquisto della prima o seconda casa, investimenti su se stessi, istruzione di qualità e vizi personali. \nSono un consulente finanziario indipendente, cioè slegato dalle banche e dai loro conflitti di interesse. \nInizia ora, scopriamo insieme quanto costi e come risparmiare."
        },
        "url": {
            "en": "https://docs.google.com/forms/d/e/1FAIpQLSfCu3i2lAjaRvnjxqL2kYFLXagi0gqX7AqsREw6LbjD7arZnQ/viewform?usp=sf_link",
            "it ": "https://docs.google.com/forms/d/e/1FAIpQLSfCu3i2lAjaRvnjxqL2kYFLXagi0gqX7AqsREw6LbjD7arZnQ/viewform?usp=sf_link"
        },
        "category": {
            "en": "Investing",
            "it": "Lavoro"
        },
        "img": gab,
        "icon": "🧑🏻‍💻",
        "descriptionBrief": {
            "it": "Aumentiamo il tuo patrimonio, senza stress",
            "en": "We increase your wealth, without stress"
        }
    },
    {
        "id": "2",
        "title": {
            "en": "Ciao Elsa - 1 free call",
            "it": "Ciao Elsa - 1 chiamata gratuita"
        },
        "description": {
            "en": "We explain difficult and boring things about pensions, severance pay, and supplementary health care in an easy way.\n\nWe help people make a decision by planning their own pension, financial and insurance future.",
            "it": "Spieghiamo facile le cose difficili e noiose su pensione, TFR e sanità integrativa.\n\nAiutiamo le persone a prendere una decisione, pianificando il proprio futuro previdenziale, finanziario e assicurativo.",
        },
        "url": {
            "en": "https://auac3ubdp9z.typeform.com/to/IGlWSLPr?utm_source=Partner&utm_medium=FunniFin",
            "it ": "https://auac3ubdp9z.typeform.com/to/IGlWSLPr?utm_source=Partner&utm_medium=FunniFin"
        },
        "category": {
            "en": "Investing",
            "it": "Lavoro"
        },
        "img": elsa,
        "icon": "🧑🏻‍💻",
        "descriptionBrief": {
            'it': "TFR e pensioni spiegati facili",
            'en': 'TFR and pensions explained easily'
        }

    },
    {
        "id": "3",
        "title": {
            "en": "Marco Iannace - 1 free call",
            "it": "Marco Iannace - 1 chiamata gratuita"
        },
        "description": {
            "en": "The MONTI CONSULTING Studio, Chartered Accountants and Statutory Auditors, was founded on January 1, 2020 by Dr. Marco Iannace and Dr. Lorenzo Tadiello and has its headquarters in Milan, in Via Vincenzo Monti 92.\n\nThe MONTI CONSULTING Studio carries out both ordinary assistance (keeping of accounts, preparation and transmission of mandatory tax returns) and extraordinary consultancy, both for individuals (in the exercise of arts and professions or in private form) and for companies.\n\nIn particular, the MONTI CONSULTING Studio has specialized in consulting for new business initiatives (also with a character of innovation) providing customers with extensive and in-depth assistance, from the feasibility study and preparation of a business plan (intended for shareholders and investors, as well as for subjects required to verify the project in order to grant contributions and financing) up to the establishment and management of ordinary activities.\n\nIn addition, in the last three years, also thanks to the collaboration with Assoedilizia, the Studio has specialized in the analysis of technical and tax documentation in the \"bonus edilizi\" field in order to issue specific opinions (preventive or final) and to affix the conformity stamp for the purpose of transferring the credit (also by means of invoice discounting).",
            "it": "Lo Studio MONTI CONSULTING, Dottori Commercialisti e Revisori Contabili, è stato fondato il primo gennaio 2020 dal Dott. Marco Iannace e dal Dott. Lorenzo Tadiello e ha la propria sede a Milano, in Via Vincenzo Monti 92.\n\nLo Studio MONTI CONSULTING svolge sia assistenza ordinaria (tenuta della contabilità, elaborazione e trasmissione delle dichiarazioni fiscali obbligatorie) che consulenza straordinaria, sia per le persone fisiche (nell’esercizio di arti e professioni o in forma privata) che per le società.\n\n In particolare, lo Studio MONTI CONSULTING si è specializzato nella consulenza a nuove iniziative imprenditoriali (anche con carattere di innovatività) fornendo ai clienti ampia e approfondita assistenza, dallo studio di fattibilità ed elaborazione di business plan (destinati a soci ed investitori, nonché a soggetti tenuti alla verifica del progetto al fine di concedere contributi e finanziamenti) fino alla costituzione e gestione dell’attività ordinaria.\n\n Inoltre, nell’ultimo triennio, anche grazie alla collaborazione con Assoedilizia, lo Studio si è specializzato nell’analisi della documentazione tecnica e fiscale in ambito “bonus edilizi” al fine di rilasciare specifici pareri (preventivi o consuntivi) e di apporre il visto di conformità ai fini della cessione del credito (anche tramite sconto in fattura).",
        },
        img: monti,
        "icon": "🧑🏻‍💻",
        url: {
            "en": "https://calendly.com/dott_iannace/30min",
            "it ": "https://calendly.com/dott_iannace/30min"
        },
        "descriptionBrief": {
            'en': "Chartered Accountants and Statutory Auditors",
            'it': 'Dottori Commercialisti e Revisori Contabili'
        }
    },
    {
        "id": "4",
        "title": {
            "en": "Francesco Pozzesi - 3 free calls",
            "it": "Francesco Pozzesi - 3 chiamate gratuite"
        },
        "descriptionBrief": {
            "it": "Dichiarazione Redditi Persone fisiche: tutto quello che devi sapere!",
            "en": "Personal Income Tax Return: everything you need to know!"
        },
        "description": {
            "it": "Francesco, nato a Domodossola nel 1994, completa i suoi studi a Pavia nel 2019 dove consegue la Laurea Magistrale in Economia e Legislazione di impresa.\n\n Dopo i primi due anni di lavoro a Milano, nel 2021 torna al paese di origine dove consegue l’abilitazione di Dottore Commercialista, professione che esercita attualmente in collaborazione con lo Studio Ariola Pozzesi Tartaglione S.r.l. a Domodossola (VB).\n\n Affiancato da professionisti con oltre 30 anni di esperienza nel settore il suo obbiettivo è aiutare i clienti unendo solide competenze tecniche alla praticità di un linguaggio semplice e diretto.\n\n Da quest’anno, inoltre, Francesco collabora con Renew Audit S.r.l. dove è coinvolto nelle attività di revisione legale si diverse società di capitali.",
            "en": "Francesco, born in Domodossola in 1994, completed his studies in Pavia in 2019 where he obtained a Master's Degree in Business Economics and Legislation.\n\n After the first two years of work in Milan, in 2021 he returned to his hometown where he obtained the qualification of Chartered Accountant, a profession he currently practices in collaboration with the Ariola Pozzesi Tartaglione S.r.l. Studio in Domodossola (VB).\n\n Assisted by professionals with over 30 years of experience in the sector, his goal is to help customers by combining solid technical skills with the practicality of a simple and direct language.\n\n This year, moreover, Francesco collaborates with Renew Audit S.r.l. where he is involved in the legal audit activities of various joint stock companies."
        },
        img: fra,
        url: {
            "en": "https://calendly.com/francesco-pozzesi/consulenza-funnifin-30-min",
            "it ": "https://calendly.com/francesco-pozzesi/consulenza-funnifin-30-min"
        },
    },
    {
        "id": "5",
        "title": {
            "en": "Andrea Noris - 3 free calls",
            "it": "Andrea Noris - 3 chiamate gratuite"
        },
        "descriptionBrief": {
            "it": "Esperto di fiscalità sulle Crypto attività (investimenti in cripto valute)",
            "en": "Expert in taxation on Crypto assets (investments in crypto currencies)"
        },
        "description": {
            "it": "Nato a Brescia nel 1991, Andrea ha conseguito la laurea magistrale in Consulenza Aziendale e Libera Professione presso l&#39;Università degli Studi della sua città e successivamente ha ottenuto l&#39;abilitazione all&#39;esercizio della professione di Dottore Commercialista e Revisore Legale. È Presidente del Consiglio di Amministrazione della società di revisione legale Renew Audit S.r.l. oltre che collaboratore del Gruppo Ascheri.\n\n Andrea ha maturato una significativa e pluriennale esperienza a Milano in materia di revisione legale presso una società multinazionale del settore ed è membro delle Commissioni &quot;Startup Innovative e PMI Innovative&quot; e &quot;Collegio Sindacale: Revisione Legale dei Conti&quot; dell&#39;Ordine dei Commercialisti di Brescia.\n\n Attualmente esercita la professione di Dottore Commercialista a Palazzolo Sull&#39;Oglio (BS), è membro del collegio sindacale e revisore legale di società di capitali, si occupa anche di tematiche fiscali legate alle cripto-attività oltre che di consulenza per startup e PMI innovative.",
            "en": "Born in Brescia in 1991, Andrea obtained a master's degree in Business Consulting and Freelance at the University of his city and subsequently obtained the qualification to practice the profession of Chartered Accountant and Statutory Auditor. He is President of the Board of Directors of the Renew Audit S.r.l. legal audit company as well as a collaborator of the Ascheri Group.\n\n Andrea has gained significant and multi-year experience in Milan in the field of legal audit at a multinational company in the sector and is a member of the \"Innovative Startups and Innovative SMEs\" and \"Board of Statutory Auditors: Legal Audit of Accounts\" commissions of the Order of Chartered Accountants of Brescia.\n\n He currently practices as a Chartered Accountant in Palazzolo Sull'Oglio (BS), is a member of the board of statutory auditors and legal auditor of joint stock companies, he also deals with tax issues related to crypto-assets as well as consulting for startups and innovative SMEs."
        },
        url: {
            "it": "https://calendly.com/andrea-noris-consulenzafunnifin-30min",
            "en": "https://calendly.com/andrea-noris-consulenzafunnifin-30min"
        },
        img: dre,
    },
    {
        "id": "6",
        "title": {
            "en": "Fabrizio Ciabatti - 1 free call",
            "it": "Fabrizio Ciabatti - 1 chiamata gratuita"
        },
        "descriptionBrief": {
            "it": "Educatore finanziario certificato AIEF: la finanza personale per la vita di tutti i giorni",
            "en": "Certified AIEF financial educator: personal finance for everyday life"
        },
        "description": {
            "it": "Fabrizio, nato a Roma nel 1989, si laurea presso la Luiss Guido Carli in Corporate Finance nel 2013.\n\nLavora da oltre 10 anni nei servizi finanziari di cui 8 in consulenza direzionale dove ha avuto modo di collaborare con più di 15 tra i primari istituti finanziari italiani (prevalentemente banche e assicurazioni).\n\nEducatore finanziario certificato AIEF, si occupa di divulgazione finanziaria sui suoi canali tra cui Linkedin dove pubblica regolarmente post di educazione finanziaria. Ha un podcast, “Finwise – l’educazione finanziaria interattiva”, disponibile gratuitamente su Spotify e sulle principali piattaforme e ha scritto un libro “Smetto quando posso – la formula per l’indipendenza finanziaria” dedicato a chi, tramite un piano di investimento strutturato, vuole raggiungere l’indipendenza finanziaria acquisendo un metodo che ha come ultimo obiettivo quello di smettere di lavorare prima della pensione.\n\nCollabora anche con primarie istituzioni nell’ambito dell’educazione finanziaria tra cui Starting Finance, che ha supportato nella stesura del libro “Instant finance” e dove è docente del corso “Personal finance” per le tematiche di finanza personale e degli investimenti, e con Directa, con cui ha realizzato webinar (disponibili su Youtube) su tematiche di educazione finanziaria principalmente riguardanti la gestione della liquidità.\n\nHa inoltre un sito personale www.finwise.it dove, oltre ad alcuni voucher sconto offerti da alcuni istituti finanziari, mette a disposizione gratuitamente simulatori e guide volte a fornire un supporto per alcune delle principali scelte di finanza personale nella vita di tutti i giorni.",
            "en": "Fabrizio, born in Rome in 1989, graduated from Luiss Guido Carli in Corporate Finance in 2013.\n\nHe has been working in financial services for over 10 years, 8 of which in management consulting where he has had the opportunity to collaborate with more than 15 of the leading Italian financial institutions (mainly banks and insurance companies).\n\nAIEF certified financial educator, he deals with financial dissemination on his channels including Linkedin where he regularly publishes financial education posts. He has a podcast, \"Finwise - interactive financial education\", available for free on Spotify and on the main platforms and has written a book \"I stop when I can - the formula for financial independence\" dedicated to those who, through a structured investment plan, want to achieve financial independence by acquiring a method that has as its ultimate goal to stop working before retirement.\n\nHe also collaborates with leading institutions in the field of financial education including Starting Finance, which he supported in writing the book \"Instant finance\" and where he is a teacher of the course \"Personal finance\" for personal finance and investment issues, and with Directa, with which he has created webinars (available on Youtube) on financial education issues mainly concerning the management of liquidity.\n\nHe also has a personal website www.finwise.it where, in addition to some discount vouchers offered by some financial institutions, he offers simulators and guides free of charge aimed at providing support for some of the main choices of personal finance in everyday life."
        },
        url: {
            "it": "https://calendly.com/fabrizio-ciabatti-financial-coach/30min",
            "en": "https://calendly.com/fabrizio-ciabatti-financial-coach/30min"
        },
        img: fab,
    },
    {
        "id": "7",
        "title": {
            "en": "Stefania Guerrini - 2 free calls",
            "it": "Stefania Guerrini - 2 chiamate gratuite"
        },
        "category": {
            "en": "Work",
            "it": "Lavoro"
        },
        description: {
            it: "Ho iniziato il mio percorso professionale spinta dall'interesse di imparare di più sul mio rapporto di lavoro dipendente; nel 2020 ho intrapreso la scelta del praticantato professionale che mi ha permesso nel 2022 di dar vita al mio studio indipendente con cui assisto aziende impiegate in diversi settori produttivi. Tutti i giorni aiuto lavoratrici e lavoratori a conoscere i propri diritti sul lavoro grazie ad un servizio di divulgazione gratuita che offro sui social network. Tramite le consulenze dedicate è possibile approfondire tematiche di interesse personale.",
            en: "I started my professional career driven by the interest in learning more about my relationship with my employer; in 2020 I chose the professional apprenticeship which allowed me in 2022 to start my independent studio with which I assist companies employed in different production sectors. Every day I help workers to know their rights at work thanks to a free dissemination service that I offer on social networks. Through dedicated consultations, it is possible to deepen personal interest issues.",
        },
        descriptionBrief: {
            it: "Consulente del lavoro: la lettura della tua busta paga non sarà più un problema!",
            en: "Labour consultant: reading your payslip will no longer be a problem!"
        },
        img: ste,
        url: {
            "it": "https://vqui.it/w/WyrCa2ns",
            "en": "https://vqui.it/w/WyrCa2ns"
        },
    }
];

export interface ExpertStore {
    expertsList: Expert[] | null;
    initExpertsStore: () => Promise<void>;
    createExpert: (newExpert: ExpertDetail) => Promise<Expert | null>;
    updateExpert: (updatedExpert: Expert) => Promise<Expert | null>;
    deleteExpert: (expertId: string) => Promise<boolean>;
    createDefaultExperts: () => Promise<void>;
}


export const useExpertsStore = create<ExpertStore>()((set, get, __) => ({
    expertsList: null,
    initExpertsStore: async () => {
        try {
            const experts = await ApiService.experts.search();
            if (experts) {
                set({
                    expertsList: experts
                });
                return experts;
            } else {
                set({
                    expertsList: []
                });
                return [];
            }
        } catch (e) {
            console.log('[ExpertsStore] Error initializing experts store', e);
            return null;
        }
    },
    createExpert: async (newExpert: ExpertDetail) => {
        try {
            const expert = await ApiService.experts.create(newExpert);
            if (expert) {
                set({
                    expertsList: [...get().expertsList ?? [], expert]
                });
                return expert;
            } else {
                return null;
            }
        } catch (e) {
            console.log('[ExpertsStore] Error creating expert', e);
            return null;
        }
    },
    updateExpert: async (updatedExpert: Expert) => {
        try {
            const expert = await ApiService.experts.update(updatedExpert);
            if (expert) {
                // Update the expert in the list using updatedExpert
                const updatedExpertIndex = get().expertsList?.findIndex(e => e.expertId === updatedExpert.expertId)!;
                const newList = [...get().expertsList!];
                newList[updatedExpertIndex] = updatedExpert;
                set({
                    expertsList: newList
                });

                return updatedExpert;
            } else {
                return null;
            }
        } catch (e) {
            console.log('[ExpertsStore] Error updating expert', e);
            return null;
        }
    },
    deleteExpert: async (expertId: string) => {
        try {
            const deleted = await ApiService.experts.delete(expertId);
            if (deleted) {
                set({
                    expertsList: get().expertsList?.filter((e) => e.expertId !== expertId)
                });
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log('[ExpertsStore] Error deleting expert', e);
            return false;
        }
    },
    createDefaultExperts: async () => {
        try {
            let newExperts = [];

            await _experts.forEach(async (expert) => {
                // create FIle from path
                const newFile = await getFile(expert.img);
                const newDocument = await useDocumentStore.getState().uploadDocument({
                    title: expert.title.it,
                    format: newFile.type,
                    ownerId: useUserStore.getState().user?.userProfileId!,
                    type: "expertImage",
                    file: newFile
                });
                const newExpert = await ApiService.experts.create({
                    title: expert.title,
                    description: expert.description,
                    url: {
                        en: expert.url.en,
                        it: expert.url.it!
                    },
                    category: [],
                    img: generateImageUrl(newDocument!)!,
                    icon: "",
                    descriptionBrief: expert.descriptionBrief,
                    companyId: "",
                    expertCreationDate: new Date().toISOString(),
                    dateLastUpdate: new Date().toISOString(),
                });
                if (newExpert) {
                    newExperts.push(newExpert);
                }
            });
            alert("Experts created successfully");
        } catch (e) {
            console.log('[ExpertsStore] Error creating default experts', e);
        }
    }
}));

async function getFile(path: string) {
    const response = await fetch(path);
    const data = await response.blob();
    return new File([data], 'filename', { type: data.type });
}