import Config from "utils/config";
import { create } from "./createStore";

export interface ThemeStore {
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
    applyTheme: () => void;
}

export const useThemeStore = create<ThemeStore>()((set, get) => ({
    darkMode: window.localStorage.getItem('theme') === 'dark',
    setDarkMode: (darkMode) => {
        const html = document.querySelector('html');
        if (darkMode || Config.forceDarkMode) {
            html!.setAttribute('data-bs-theme', 'dark');
            if (html!.classList.contains('light')) {
                html!.classList.remove('light');
            }
            html!.classList.add('dark');
            window.localStorage.setItem('theme', 'dark');
        } else {
            html!.removeAttribute('data-bs-theme');
            html!.classList.remove('dark');
            html!.classList.add('light');
            window.localStorage.setItem('theme', 'light');
        }
        set({ darkMode: darkMode });
    },
    applyTheme: () => {
        const html = document.querySelector('html');
        if (window.localStorage.getItem('theme') === 'dark') {
            get().setDarkMode(true);
        } else {
            get().setDarkMode(false);
        }
    }
}));