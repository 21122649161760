import CircleButton from 'components/CircleButton';
import GemCounter from 'components/GemCounter';
import LifepointCounter from 'components/LifepointCounter';
import XpCounter from 'components/XpCounter';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useUserStore } from 'stores/userStore';
import Config, { appThemes } from 'utils/config';
import { useTitle } from 'utils/functions';

import ChatMessenger from 'components/ChatMessenger/ChatMessenger';
import { useChatStore } from 'stores/chatStore';
import 'react-spring-bottom-sheet/dist/style.css';
import { motion } from 'framer-motion';
import TutorialTour from 'components/TutorialTour';

function UserDashboardPage() {
    const navigate = useNavigate();
    const chat = useChatStore((state) => state.activeChat);
    const ready = useChatStore((state) => state.ready);
    const { t } = useTranslation();



    useEffect(() => {
        if (chat) {
            console.log('chat init');
            useChatStore.getState().switchActiveChat();
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!useChatStore.getState().loading) {
                await useChatStore.getState().getChatData(useUserStore.getState().user!);
            }
        }, 10000);
        return () => {
            clearInterval(interval);
        };
    },);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!useChatStore.getState().loading) {
                await useChatStore.getState().refreshCurrentChat();
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    },);

    useTitle(t('main_menu:home'));

    return (
        <motion.section
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 1 } }}
            className="UserDashboardPage d-flex flex-column h-100">
            {/* counter per versione no topbar */}
            {Config.appTheme === appThemes.CLASSIC && <div className="w-100 d-flex align-items-center mb-4 mt-3">
                <CircleButton iconClassName={"fas fa-user fa-2x"} onClick={() => {
                    navigate('../profile');
                }} />
                <div className="d-flex align-items-center ms-auto">
                    <LifepointCounter />
                    <XpCounter />
                    <GemCounter />
                </div>
            </div>}

            {ready && <div className="col-12 mx-auto flex-fill  overflow-x-hidden"
                style={{
                    height: 'calc(100% - 168px)',
                    overflowY: 'auto',
                }}
            >
                <ChatMessenger chat={chat}
                />
            </div>}

{/*             <TutorialTour tutorialName="finAi" /> */}

        </motion.section >
    )
}



export default UserDashboardPage

