import { on } from "events";
import i18next from "i18next";
import React from "react";
import { create } from "zustand";
import { ApiService } from "../services/apiService";
import { useI18nStore } from "./i18nStore";

export interface SystemModalStore {
    showConfirmModal: (title: string, message: React.ReactNode, icon: string) => Promise<boolean>;
    showInfoModal: (title: string, message: React.ReactNode, children?: React.ReactNode) => Promise<boolean>;
    showWarningModal: (message: string) => Promise<boolean>;
    showErrorModal: (message: string) => Promise<boolean>;
    showComingSoonModal: () => Promise<boolean>;
    showUnsavedDataModal: () => Promise<boolean>;
    showCustomModal: (title: string, message: React.ReactNode, icon: string, onConfirm: () => void, onCancel: () => void) => Promise<boolean>;
    hideModal: () => void;
    isModalVisible: boolean;
    modalData: {
        title: string;
        message: React.ReactNode;
        icon: string;
        onConfirm: () => void;
        onCancel: () => void;
        type: "" | "info" | "confirm" | "warning" | "error";
    };
    handleModalOpening: (title: string, message: React.ReactNode, icon: string, type: "" | "error" | "warning" | "info" | "confirm", children?: React.ReactNode) => Promise<boolean>;
}

export const useSystemModalStore = create<SystemModalStore>()((set, get) => ({
    isModalVisible: false,
    modalData: {
        title: 'a',
        message: '',
        icon: '',
        onConfirm: () => { },
        onCancel: () => { },
        type: ''
    },
    handleModalOpening: async (title: string, message: React.ReactNode, icon: string, type: "" | "error" | "warning" | "info" | "confirm", children?: React.ReactNode) => {
        /*         if (get().isModalVisible) {
                    set({ isModalVisible: false });
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    return get().handleModalOpening(title, message, icon, type);
                } */
        return new Promise<boolean>((resolve, reject) => {
            set({
                isModalVisible: true,
                modalData: {
                    title,
                    message,
                    icon,
                    onConfirm: () => {
                        set({ isModalVisible: false });
                        resolve(true);
                    },
                    onCancel: () => {
                        set({ isModalVisible: false });
                        resolve(false);
                    },
                    type
                },
            });
        });
    },
    showCustomModal: (title: string, message: React.ReactNode, icon: string, onConfirm: () => void, onCancel: () => void) => {
        return get().handleModalOpening(title, message, icon, '');
    },
    showConfirmModal: (title: string, message: React.ReactNode, icon: string) => {
        return get().handleModalOpening(title, message, icon, 'confirm');
    },
    showInfoModal: (title: string, message: React.ReactNode, children?: React.ReactNode) => {
        return get().handleModalOpening(title, message, 'fa-info-circle text-info', 'info', children);
    },
    showWarningModal: (message: string) => {
        return get().handleModalOpening(i18next.t('common:warning'), message, 'fa-exclamation-triangle text-warning', 'warning');
    },
    showErrorModal: (message: string) => {
        return get().handleModalOpening(i18next.t('common:error'), message, 'fa-exclamation-triangle text-danger', 'error');
    },
    showComingSoonModal: () => {
        return get().handleModalOpening(i18next.t('common:comingSoon'), i18next.t('common:comingSoonMessage'), 'fa-person-digging', 'info');
    },
    showUnsavedDataModal: () => {
        return get().handleModalOpening(i18next.t('common:unsaved_data'), i18next.t('common:unsaved_data_warning'), 'fa-floppy-disk-circle-xmark text-warning', 'confirm');
    },
    hideModal: () => {
        set({ isModalVisible: false });
    }
}));