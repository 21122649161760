import cloneDeep from "clone-deep";
import { AsyncChat, ChatMessage } from "models/chatModels";
import { UserProfile } from "models/userModels";
import { ApiService } from "services/apiService";
import { create } from "./createStore";

export interface ChatManagerStore {
    ready: boolean;
    loading: boolean;
    chats: AsyncChat[] | null;
    selectedChat: AsyncChat | null;
    selectChat: (chatId: string) => void;
    getChatsData: () => Promise<void>;
    closeChat: (chatId: string) => Promise<void>;
    toggleChatAi: (chatId: string) => Promise<void>;
}

export const useChatManagerStore = create<ChatManagerStore>()((set, get) => ({
    ready: false,
    loading: false,
    chats: null,
    selectedChat: null,
    getChatsData: async () => {
        ApiService.asyncChat.search().then(chats => {
            if (!get().selectedChat && chats.length > 0) {
                set({ selectedChat: chats[0] });
            }
            set({ chats: cloneDeep(chats), ready: true });
        });
    },
    closeChat: async (chatId: string) => {
        set({ loading: true });
        const chat = get().chats?.find(chat => chat.asyncChatId === chatId);
        if (chat) {
            const newChats = get().chats?.filter(c => c.asyncChatId !== chatId)
            set({ chats: [...newChats ?? []] });
            ApiService.asyncChat.update(
                {
                    ...chat,
                    asyncChatDetail: {
                        ...chat.asyncChatDetail,
                        isClosed: true,
                    }
                }
            )
            if (get().selectedChat?.asyncChatId === chatId) {
                if (newChats?.length === 0) {
                    set({ selectedChat: null });
                } else {
                    set({ selectedChat: newChats?.[0] });
                }
            }
        }
        set({ loading: false });
    },
    toggleChatAi: async (chatId: string) => {
        set({
            loading: true,
            chats: cloneDeep(get().chats?.map(c => c.asyncChatId === chatId ? { ...c, asyncChatDetail: { ...c.asyncChatDetail, isAi: !c.asyncChatDetail.useAI } } : c))
        });
        const chat = get().chats?.find(chat => chat.asyncChatId === chatId);
        if (chat) {
            ApiService.asyncChat.update(
                {
                    ...chat,
                    asyncChatDetail: {
                        ...chat.asyncChatDetail,
                        useAI: !chat.asyncChatDetail.useAI,
                    }
                }
            )
            if (chat.asyncChatId === get().selectedChat?.asyncChatId) {
                set({ selectedChat: { ...chat, asyncChatDetail: { ...chat.asyncChatDetail, useAI: !chat.asyncChatDetail.useAI } } });
            }
        }
        set({ loading: false });
    },
    selectChat: (chatId: string) => {
        set({ selectedChat: get().chats?.find(chat => chat.asyncChatId === chatId) ?? null });
    }
}));

