import React from "react"

function LockWrapper({ children }) {
    return (
        <div className="lock-wrapper">
            <style>
                {`
                .lock-wrapper {
                    position: relative;
                    height: 100%;
                    width: 100%;
                }
                .lock-wrapper > *:not(.lock-wrapper__overlay) {
                    filter: blur(2px);
                }
                .lock-wrapper__overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            `}
            </style>
            {children}
            <div className="lock-wrapper__overlay">
                <i className="fas fa-lock-keyhole fa-3x text-muted"></i>
            </div>
        </div>
    )
}
export default LockWrapper