import React from 'react'
import { useThemeStore } from 'stores/themeStore'
import Config from 'utils/config';

function FunnifinLogo(props: {
    className?: string, onClick: () => void, style?: React.CSSProperties,
    width?: string
}) {
    const darkMode = useThemeStore(state => state.darkMode);
    return (
        <>
            {!darkMode && <img src={Config.companyLogo} className={"object-fit-contain " + props.className}
                style={{
                    width: (props.width ?? '6rem'), height: "auto",
                    ...props.style
                }}
                onClick={() => props.onClick()} alt="" />
            }
            {darkMode && <img src={Config.companyLogoDark} className={"object-fit-contain " + props.className}
                style={{
                    width: (props.width ?? '6rem'), height: "auto",
                    ...props.style
                }}
                onClick={() => props.onClick()} alt="" />}
        </>
    )
}

export default FunnifinLogo