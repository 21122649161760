import Loader from "components/Loader";
import QuizProgressIcon from "components/QuizProgressIcon";
import React, { useEffect, useState } from "react";
import ScrollContainer from 'react-indiana-drag-scroll';
import { Carousel } from "react-responsive-carousel";
import {
    useNavigate,
} from 'react-router-dom';
import { useEduquizStore } from "stores/eduquizStore";
import CircleButton from "components/CircleButton";
import GemCounter from "components/GemCounter";
import LifepointCounter from "components/LifepointCounter";
import XpCounter from "components/XpCounter";
import { useTranslation } from "react-i18next";
import { useI18nStore } from "stores/i18nStore";
import { useLearningMaterialsStore } from "stores/learningMaterialsStore";
import Config, { appThemes } from "utils/config";
import cloneDeep from "clone-deep";
import TutorialTour from "components/TutorialTour";
import { useVideoStore } from "stores/videoStore";
import SectionTitle from "components/SectionTitle";
import LearningMaterialsCard from "pages/learning_materials/LearningMaterialsCard";
import { useCompanyStore } from "stores/companyStore";
import Flickity from "react-flickity-component";
import PageTitle from "components/PageTitle";
import { AmplitudeService } from "services/amplitudeService";
import { getContrastColor, useTitle } from "utils/functions";
import { DiffBadge } from "components/DiffBadge";
import { VideoWidget } from "pages/user_dashboard/UserDashbordWidgetRows";
import { QuizModule } from "models/eduquizModels";
import FunnifinLogo from "components/FunnifinLogo";
import { LanguageSelectorAlt } from "components/LanguageSelector";
import { motion } from "framer-motion";
import { useIsMobile } from "stores/appStore";
import { Card } from "react-bootstrap";
import { useMemo } from "react";
import HeaderWidgetPortal from "components/HeaderWidgetPortal";



export default function EduQuizMainPage() {
    const argumentList = useEduquizStore(state => state.argumentList);
    const initEduquiz = useEduquizStore(state => state.initEduquiz);
    const selectedArgument = useEduquizStore(state => state.selectedArgument);
    const setSelectedArgument = useEduquizStore(state => state.setSelectedArgument);
    const setSelectedModule = useEduquizStore(state => state.setSelectedModule);
    const isMobile = useIsMobile();
    const initLearningMaterialsStore = useLearningMaterialsStore(state => state.initLearningMaterialsStore);
    const learningMaterialsList = useLearningMaterialsStore(state => state.learningMaterialsList);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const getTranslation = useI18nStore(state => state.getTranslation);

    const initVideoStore = useVideoStore(state => state.initVideoStore);
    const playlists = useVideoStore(state => state.playlists);
    const videos = playlists ? playlists[0].playlistDetail.videos : null;
    const [viewAll, setViewAll] = useState(false);

    useTitle(t('main_menu:eduquiz'));

    useEffect(() => {
        if (!argumentList) {
            initEduquiz(useCompanyStore.getState().currentCompany?.companyId ?? null);
        }
        // init Swiper:
    }, [argumentList, initEduquiz]);

    useEffect(() => {
        if (!learningMaterialsList) {
            initLearningMaterialsStore();
        }
    }, [initLearningMaterialsStore, learningMaterialsList]);

    useEffect(() => {
        if (!videos) {
            initVideoStore();
        }
    }, [initVideoStore, videos]);


    /* Gestione click on drag degli slider  =================================*/
    /// quando true, stoppa il click sulle slide
    const [isDragging, setIsDragging] = useState(false);
    // riferimenti agli slider, verranno settati al caricamento da flickity
    const [flickref1, setFlickref1] = useState<any>(null);
    const [flickref2, setFlickref2] = useState<any>(null);
    // effetto che si attiva al caricamento degli slider
    useEffect(() => {
        // se i riferimenti sono settati, aggiungo gli eventi
        // dragStart e dragEnd per gestire il click
        // e settare isDragging a true/false
        // il timeout serve per evitare che il click venga lanciato se l'utente continua a tenere premuto
        if (flickref1 != null) {
            flickref1.on('dragStart', () => {
                setIsDragging(true);
            });
            flickref1.on('dragEnd', () => {
                setTimeout(() => setIsDragging(false), 500);
            });
        }
        if (flickref2 != null) {
            flickref2.on('dragStart', () => {
                setIsDragging(true);
            });
            flickref2.on('dragEnd', () => {
                setTimeout(() => {
                    setIsDragging(false)
                }, 500);
            });
        }

    }, [flickref1, flickref2]);

    /* =======================================*/


    const [isFocused, setFocus] = useState({} as { [key: string]: boolean });

    const notCompletedModuleList = useMemo(() => {
        // get all modules in a single array
        const moduleList = (argumentList ?? []).map((argument) => argument.argumentDetail.modules).flat();
        // filter those completed
        const notCompletedModules = moduleList.filter((qm) => (qm?.moduleDetail.userModule?.userModuleDetail.percentageCompletion ?? 0) < 100) as QuizModule[];

        notCompletedModules.sort((a, b) => (b?.moduleDetail.userModule?.userModuleDetail.percentageCompletion ?? 0) - (a?.moduleDetail.userModule?.userModuleDetail.percentageCompletion ?? 0));
        // add the argument color to the module
        notCompletedModules.forEach((qm) => qm.moduleDetail.color = (argumentList ?? []).find((arg) => arg.argumentId === qm.moduleDetail.argumentId)?.argumentDetail.color);

        return notCompletedModules;
    }, [argumentList]);

    const currentArgumentIndex = useMemo(() => (argumentList ?? []).findIndex((argument) => argument.argumentId === selectedArgument?.argumentId), [argumentList, selectedArgument]);

    const startModule = (quizModule: QuizModule) => {
        AmplitudeService.logEvent('Start Suggested Module from Eduquiz Main Page',
            {
                moduleId: quizModule.moduleId,
                title: quizModule.moduleDetail.title.it ?? quizModule.moduleDetail.title.en ?? 'No title',
                argumentId: quizModule.moduleDetail.argumentId,

            });
        navigate('./module/' + quizModule.moduleId + '/unit/' + (quizModule.moduleDetail.unitIds[quizModule
            .moduleDetail
            .userModule
            ?.userModuleDetail
            ?.unitCompleted ?? 0]));
    }


    if (!argumentList) { return (<Loader />); }

    return (
        <motion.section
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 1 } }}
            className="EduQuizMainPage d-flex flex-column mw-100">
            <HeaderWidgetPortal>
                <div className='ms-auto d-flex'>
                    <LifepointCounter />
                    <XpCounter />
                    <GemCounter />
                </div>
            </HeaderWidgetPortal>
            <TutorialTour tutorialName="mainPage" />
            {Config.appTheme === appThemes.CLASSIC && <div className="w-100 d-flex align-items-center mb-4 mt-3">
                <CircleButton iconClassName={"fas fa-user fa-2x"} onClick={() => {
                    navigate('../profile');
                }} />
                <FunnifinLogo
                    className="ms-3"
                    onClick={() => {
                        navigate('https://beewiseapp.com/');
                    }}
                />


                <div className="d-flex align-items-center ms-auto">
                    <LanguageSelectorAlt />

                    <LifepointCounter />
                    <XpCounter />
                    <GemCounter />
                </div>
            </div>}

            {/* ESPLORA ARGOMENTI */}
            <SectionTitle title={t('eduquiz:explore_arguments')}></SectionTitle>
            <div className="mb-5 mt-2">
                <div className="d-flex gap-2 flex-wrap" >
                    {/* ARGUMENT BUTTONS */}
                    {argumentList
                        .slice(0, viewAll ? undefined : 3)
                        .map((quizArgument) => {
                            const color = quizArgument.argumentDetail.color?.replace('0xFF', '#');
                            return (
                                <button
                                    onMouseEnter={() => {
                                        isFocused[quizArgument.argumentId] = true;
                                        setFocus({ ...isFocused });
                                    }}
                                    onMouseLeave={() => {
                                        isFocused[quizArgument.argumentId] = false;
                                        setFocus({ ...isFocused });
                                    }}
                                    key={"button-" + quizArgument.argumentId} onClick={() => setSelectedArgument(quizArgument)}
                                    className={`btn btn-outline-dark rounded-pill ${(isFocused[quizArgument.argumentId] || selectedArgument?.argumentId === quizArgument.argumentId) ? "" : "EduQuizMainPageButtonInactive"}`}
                                    style={{
                                        borderColor: color,
                                        backgroundColor: `${isFocused[quizArgument.argumentId] || selectedArgument?.argumentId === quizArgument.argumentId ? color : "inherit"}`,
                                        color: `${isFocused[quizArgument.argumentId] || selectedArgument?.argumentId === quizArgument.argumentId ? "white" : color}`
                                    }}>{getTranslation(quizArgument?.argumentDetail?.title)}</button>
                            );
                        }
                        )}
                    <button className="btn btn-link text-primary" onClick={() => setViewAll(!viewAll)}>{viewAll ? t('common:view_less') : t('common:view_all')}</button>
                    {
                        Config.appTheme === appThemes.CLASSIC && <>
                            <ScrollContainer className="scroll-container " vertical={false} horizontal={true} hideScrollbars={true} style={{}}>
                                <div className="" style={{ "width": (argumentList.reduce((acc, curr) => acc + getTranslation(curr.argumentDetail.title).length * 0.6 + 1, 0)) + "em", "height": "3rem" }}>
                                    {argumentList.map((quizArgument) => {
                                        const color = quizArgument.argumentDetail.color?.replace('0xFF', '#');
                                        return (
                                            <button
                                                onMouseEnter={() => {
                                                    isFocused[quizArgument.argumentId] = true;
                                                    setFocus({ ...isFocused });
                                                }}
                                                onMouseLeave={() => {
                                                    isFocused[quizArgument.argumentId] = false;
                                                    setFocus({ ...isFocused });
                                                }}
                                                key={"button-" + quizArgument.argumentId}
                                                onClick={() => setSelectedArgument(quizArgument)} className="btn btn-outline-dark rounded-pill me-2"
                                                style={{
                                                    borderColor: color,
                                                    backgroundColor: (isFocused[quizArgument.argumentId] || selectedArgument?.argumentId === quizArgument.argumentId) ? `${color}` : "var(--bs-secondary)",
                                                    color: (isFocused[quizArgument.argumentId] || selectedArgument?.argumentId === quizArgument.argumentId) ? getContrastColor(color ?? "") : `${color}`
                                                }}>{getTranslation(quizArgument?.argumentDetail?.title)}</button>
                                        );
                                    }
                                    )}
                                </div>

                            </ScrollContainer>
                        </>
                    }
                </div>
                {selectedArgument && <Carousel
                    showArrows={false}
                    showIndicators={false}
                    showStatus={false}
                    showThumbs={false}
                    swipeable={true}
                    selectedItem={currentArgumentIndex}
                    onChange={(index) => {
                        isFocused[selectedArgument.argumentId] = false;
                        setSelectedArgument(argumentList[index]);
                    }}
                    centerMode={true}
                    centerSlidePercentage={100}

                >
                    {argumentList.map((quizArgument, index) =>
                    (<div className="pe-1" key={"ArgumentCard-" + quizArgument.argumentId}>
                        <div className="card mt-3 border-2" style={{
                            borderColor: selectedArgument.argumentDetail.color?.replace('0xFF', '#')
                            , transition: "border-color 0.5s"
                        }} >
                            <div className="card-body ">
                                <div className="d-flex flex-column remove-margin-bottom-last">
                                    <div className="text-uppercase text-start pb-1">
                                        {t('eduquiz:modules_subtitle')}
                                    </div>
                                    {selectedArgument?.argumentDetail.modules?.map((quizModule) => <div key={quizModule.moduleId} className="MainPageModuleTab d-flex align-items-center cursor-pointer mb-2 text-hoverable" onClick={
                                        () => {
                                            //make a copy of module, add the argument to it and set it as selected module
                                            let newSelectedModule = cloneDeep(quizModule);
                                            newSelectedModule.moduleDetail.argument = selectedArgument;
                                            //remove the units to force a refresh
                                            // TODO Investigate why this is needed
                                            /*  selectedModule.moduleDetail.units = undefined; */
                                            /* selectedModule.moduleDetail.units = null; */
                                            setSelectedModule(newSelectedModule);
                                            navigate('./module/' + quizModule.moduleId/* , {
                                                relative: true,
                                            } */);
                                        }
                                    }>
                                        <div className="me-3 rounded-circle p-1" style={{ width: "3rem"/* backgroundColor: quizModule.moduleDetail.color ? quizModule.moduleDetail.color.replace('0xFF', '#') : selectedArgument.argumentDetail.color.replace('0xFF', '#') */ }}>
                                            <QuizProgressIcon iconUrl={quizModule.moduleDetail.iconId}
                                                percentage={quizModule.moduleDetail.userModule?.userModuleDetail.percentageCompletion ?? 0}
                                                icon={`${quizModule.moduleDetail.fontIcon}`}
                                                color={/* quizModule.moduleDetail.color ? quizModule.moduleDetail.color.replace('0xFF', '#') : */ selectedArgument.argumentDetail.color?.replace('0xFF', '#') ?? null}
                                            />
                                        </div>
                                        <strong className="fs-6 text-start">
                                            {getTranslation(quizModule.moduleDetail.title)}
                                        </strong>
                                        <DiffBadge className={"ms-3"} diff={quizModule.moduleDetail.difficulty} />
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>))}
                </Carousel>}
            </div >
            <SectionTitle title={t('eduquiz:suggested_modules')}></SectionTitle>
            <div
                className="mb-5" >
                <Flickity
                    key="flickity-1"
                    flickityRef={(c) => {
                        if (c) {
                            setFlickref1(c);
                        }
                    }}
                    options={{
                        cellAlign: 'left',
                        pageDots: false,
                        prevNextButtons: !isMobile,
                        adaptiveHeight: true,
                        contain: true,
                        groupCells: true,
                    }}
                >
                    {notCompletedModuleList
                        .slice(0, 9)
                        .map((quizModule) => {
                            const color = quizModule.moduleDetail.color?.replace('0xFF', '#');
                            return (
                                <div className="me-3"
                                    key={quizModule.moduleId + "-moduleCard"}
                                    style={{
                                        pointerEvents: isDragging ? 'none' : 'auto',
                                        width: "340px", height: "auto", minHeight: "100%", paddingLeft: "4px"
                                    }}>
                                    <Card
                                    >
                                        {quizModule && <Card.Body className='text-center flex-column d-flex align-items-center'>
                                            <div className='flex-fill text-center flex-column d-flex align-items-center justify-content-evenly w-100'>
                                                <div className='rounded-circle bg-white bg-opacity-100'>
                                                    <QuizProgressIcon
                                                        percentage={quizModule.moduleDetail.userModule!.userModuleDetail.percentageCompletion}
                                                        icon={quizModule.moduleDetail.fontIcon ?? null} iconUrl={quizModule.moduleDetail.iconId}
                                                        color={color ?? null} width={4} />
                                                </div>
                                                <h5 className='mt-2 fs-5 fw-bolder text-body-emphasis text-truncate' style={{
                                                    lineHeight: "1.4rem",
                                                    width: "calc(100% - 1rem)",
                                                }}>{getTranslation(quizModule.moduleDetail.title)}</h5>
                                                <DiffBadge diff={quizModule.moduleDetail.difficulty} />
                                            </div>
                                            <button className="btn btn-primary border-0 bg-primary-subtle text-primary btn-block w-100 rounded-pill mt-3"
                                                onClick={() => startModule(quizModule)}>
                                                {t(quizModule.moduleDetail.userModule!.userModuleDetail.percentageCompletion !== 0 ? 'common:continue' : 'common:start')}
                                            </button>
                                        </Card.Body>}
                                    </Card>
                                </div>
                            );
                        }
                        )}
                </Flickity>
            </div>
            {Config.enabledFeatures.materials && <><SectionTitle title={t('learningMaterials:title')} viewAll={true} onViewAll={() => navigate('/welfare/materials')} />
                <div className="mb-5">
                    <Flickity
                        key="flickity-2"
                        flickityRef={(c) => {
                            if (c) {
                                setFlickref2(c);
                            }
                        }}
                        options={{
                            cellAlign: 'left',
                            pageDots: false,
                            prevNextButtons: !isMobile,
                            adaptiveHeight: true,
                            contain: true,
                            groupCells: true,
                        }}
                    >
                        {learningMaterialsList && learningMaterialsList.map((learningMaterial: any) => {
                            return (
                                <div className="me-3"
                                    key={learningMaterial.id + "-learningMaterialContainer"}
                                    style={{
                                        width: "340px",
                                        height: "278px",
                                        minHeight: "100%",
                                        pointerEvents: isDragging ? 'none' : 'auto',
                                        paddingLeft: "4px",
                                    }
                                    }
                                >
                                    <LearningMaterialsCard key={learningMaterial.id + "-learningMaterial"} learningMaterial={learningMaterial} />
                                </div>
                            );
                        })}
                    </Flickity>
                </div></>}

        </motion.section>
    )
}
