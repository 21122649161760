import { AsyncChat, ChatMessage } from "models/chatModels";
import { UserProfile } from "models/userModels";
import { ApiService } from "services/apiService";
import { create } from "./createStore";
import { useAuthStore } from "./authStore";
import { useCompanyStore } from "./companyStore";
import { useUserStore } from "./userStore";
import cloneDeep from "clone-deep";
import i18n from "i18n";
import { t } from "i18next";
import { useChatStore } from "./chatStore";
import { useDocumentStore, Document } from "./documentStore";
import { useSystemModalStore } from "./systemModalStore";
import newMessagePopSound from "assets/audio/pop.mp3";

export interface ChatMessengerStore {
    sendingMessage: boolean;
    activeChat: AsyncChat | null;
    status: Symbol;
    uploadingDocument: Document | null;
    useAiChangedForTheFirstTime: boolean;
    sendMessage: (message: string) => Promise<void>;
    openChat: (chat: AsyncChat) => void;
    openNewChat: (message: string) => Promise<void>;
    cancelDocumentUpload: () => void;
    requestDocumentUpload: (document: Document) => void;
    updateActiveChat: (chat: AsyncChat) => void;
    closeChat: () => void;
}

export const ChatMessengerStates = {
    Initial: Symbol("initial"),
    LoadingChat: Symbol("loading"),
    Running: Symbol("running"),
}

export const useChatMessengerStore = create<ChatMessengerStore>()((set, get) => ({
    status: ChatMessengerStates.Initial,
    activeChat: null,
    loading: false,
    sendingMessage: false,
    useAiChangedForTheFirstTime: true,
    uploadingDocument: null,
    getChatData: async (user: UserProfile) => {
    },
    sendMessage: async (message: string) => {
        set({ sendingMessage: true });
        let documentUploaded;
        if (get().uploadingDocument && (message === '' || !message)) {
            useSystemModalStore.getState().showWarningModal(t('chat:warning_empty_message'));
            return;
        } else if (get().uploadingDocument) {
            documentUploaded = await useDocumentStore.getState().uploadDocument(get().uploadingDocument!);
        }
        useChatStore.getState().sendMessage({
            message,
            ownerId: useUserStore.getState().user?.userProfileId!,
            asyncChatMessageDate: new Date().toISOString(),
            documentId: documentUploaded?.documentId ?? undefined,
            uploadDocument: /* sendingDocumentUploadMessage ?? */ false,
            type: !useAuthStore.getState().isUser ? 'HR' : 'Users',
        }, get().activeChat!).then(() => {
            set({ sendingMessage: false, uploadingDocument: null });
        });
    },
    openChat: (chat: AsyncChat) => {
        set({ status: ChatMessengerStates.Running, activeChat: chat, useAiChangedForTheFirstTime: true });
    },
    openNewChat: async (message) => {
        set({ status: ChatMessengerStates.LoadingChat });
        let documentUploaded;
        if (get().uploadingDocument && (message === '' || !message)) {
            useSystemModalStore.getState().showWarningModal(t('chat:warning_empty_message'));
            return;
        } else if (get().uploadingDocument) {
            documentUploaded = await useDocumentStore.getState().uploadDocument(get().uploadingDocument!);
        }
        useChatStore.getState().startChat(
            {
                message,
                ownerId: useUserStore.getState().user?.userProfileId ?? '',
                uploadDocument: false,
                asyncChatMessageDate: new Date().toISOString(),
                documentId: documentUploaded?.documentId ?? undefined,
                type: 'Users',
            }).then(() => {
                set({
                    status: ChatMessengerStates.Running,
                    activeChat: useChatStore.getState().activeChat,
                    useAiChangedForTheFirstTime: true,
                    sendingMessage: false,
                    uploadingDocument: null,
                });
            });
    },
    cancelDocumentUpload: () => {
        set({ uploadingDocument: null });
    },
    requestDocumentUpload: (document: Document) => {
        set({ uploadingDocument: document });
    },
    updateActiveChat: (chat: AsyncChat) => {
        // Controllo se la chat è effettivamente cambiata, verificando il numero di messaggi, lo stato di lettura e lo stato AI
        if ((chat.asyncChatDetail.messages.length !== get().activeChat?.asyncChatDetail.messages.length) ||
            chat.asyncChatDetail.isRead !== get().activeChat?.asyncChatDetail.isRead ||
            chat.asyncChatDetail.useAI !== get().activeChat?.asyncChatDetail.useAI) {
            // in particolare, se useAi è cambiato: 
            if (chat.asyncChatDetail.useAI !== get().activeChat?.asyncChatDetail.useAI && get().useAiChangedForTheFirstTime) {
                set({ useAiChangedForTheFirstTime: false });
            }
            // se il numero di messaggi è cambiato:
            if (chat.asyncChatDetail.messages.length !== get().activeChat?.asyncChatDetail.messages.length) {
                const audio = new Audio(newMessagePopSound);
                audio.play();
            }
            set({ activeChat: cloneDeep(chat) });
        }
    },
    closeChat: () => {
        set({ status: ChatMessengerStates.Initial, activeChat: null, useAiChangedForTheFirstTime: true });
    }
}));
