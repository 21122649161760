import React from "react";
import { Form, FormControl, FormGroup, FormLabel, FormSelect } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useI18nStore } from "stores/i18nStore";
import { generateImageUrl, getFlagEmoji } from "utils/functions";
import { useState, useMemo } from "react";
import { PlaylistDetail, VideoDetail } from "models/videoModels";
import { GoalDetail } from "models/progressionModels";

function GoalEditor(
    { goal, icon, setGoalImage, handleGoalChange }
        : {
            goal: GoalDetail,
            icon: File | null,
            setGoalImage: (file: File) => void,
            handleGoalChange: (playlist: GoalDetail) => void,
        }
) {
    const { t } = useTranslation();
    const [activeLanguage, setActiveLanguage] = React.useState("it");
    const languages = useI18nStore((state) => state.quizLanguages);
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);
/*     const description = useMemo(() => getTranslationWithLanguage(playlist.description, activeLanguage), [playlist.description, activeLanguage, getTranslationWithLanguage]);
 */    return (
        <div>
            <Form >
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        {(languages ?? []).map((language: string) =>
                            <button key={language} className={"nav-link text-secondary d-flex flex-column align-items-center " + (language === activeLanguage ? "active" : "")}
                                onClick={() => setActiveLanguage(language)} type="button">
                                <div className="text-body-emphasis fw-bold">{language.toUpperCase()}
                                </div>
                                <div>
                                    {getFlagEmoji(language)}
                                </div>
                            </button>
                        )}
                    </div>
                </nav>


                <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                    {/* <div className="tab-pane fade active show" id="nav-argument-icon" role="tabpanel" aria-labelledby="nav-argument-icon-tab">
        <h1>{fontIcon}</h1>
        {!showEmojiPicker && <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <i className='fas fa-plus' /> {t('forms:emoji')}
        </button>}
        {showEmojiPicker && <EmojiPicker autoFocusSearch={true} onEmojiClick={onEmojiClicked} />}
    </div> */}
                    {(languages ?? []).map((language: string) =>
                        <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
                            <div className="d-flex flex-column h-100">
                                <FormGroup className="mb-3">
                                    <FormLabel htmlFor="videoTitle">{t('forms:title')}</FormLabel>
                                    <FormControl type="text" id="videoTitle" placeholder={t('forms:title')} name={"title_" + language}
                                        value={getTranslationWithLanguage(goal.title, language)}
                                        onChange={(e) => handleGoalChange({
                                            ...goal,
                                            title: {
                                                ...goal.title,
                                                [language]: e.target.value,
                                            },
                                        })} />
                                </FormGroup>
                                {/*                                 <FormGroup className="mb-3">
                                    <FormLabel htmlFor="videoDescriptionBrief">{t('forms:description_brief')}</FormLabel>
                                    <FormControl as="textarea" id="videoDescriptionBrief" placeholder={t('forms:description')} name={"description_" + language}
                                        value={getTranslationWithLanguage(playlist.description, language)}
                                        onChange={(e) => handleGoalChange({
                                            ...playlist,
                                            description: {
                                                ...playlist.description,
                                                [language]: e.target.value,
                                            },
                                        })} />
                                </FormGroup> */}
                            </div>
                        </div>)}
                </div>
                <Form.Group>
                    <label className="form-label">{t('common:image')}</label>
                    <div id="playlist-banner-image" className="d-flex flex-column mb-3">
                        {!(goal.iconId || icon) && <i className="mb-2">{t('forms:messages.no_image_selected')}</i>}
                        {((goal.icon || icon)) && <img src={icon != null ? URL.createObjectURL(icon) : (generateImageUrl(goal.icon!) ?? "")} className="img-fluid object-fit-contain border rounded mb-3" alt="Slide " />}

                        <input onChange={(event) => {
                            const files = event.target.files;
                            if (files != null && files[0].type.startsWith('image/')) {
                                setGoalImage(files[0]);
                            } else {
                                // Handle invalid file type
                            }
                        }} className="form-control" type="file" id="formFile" accept="image/*" />

                        {((goal.iconId || icon) /* && !deleteBannerImage */) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {

                        }} value={goal.iconId ?? ""}>
                            <i className='fas fa-trash' /> {t('common:delete')} Logo
                        </button>}
                    </div>
{/*                     <div id="form-group" className="d-flex flex-column mb-3">
                        <label className="form-label">{t('progression_manager:video_picture_alt')}</label>
                        <input type="text" className="form-control" placeholder={t('progression_manager:video_picture_alt')}
                            onChange={(e) => handleGoalChange({
                                ...goal,
                                iconId: e.target.value,
                            })} />
                    </div> */}
                </Form.Group>
                <Form.Group>
                    <FormLabel htmlFor="experiencePoints">{t('common:experience')}</FormLabel>
                    <FormControl type="number" id="experiencePoints" placeholder={t('common:experience')}
                        value={goal.totalXp}
                        onChange={(e) => handleGoalChange({
                            ...goal,
                            totalXp: parseInt(e.target.value),
                        })} />
                </Form.Group>
            </Form>
        </div>
    )
}
export default GoalEditor