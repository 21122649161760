import CircleButton from 'components/CircleButton';
import Loader from 'components/Loader';
import PartnerCard from 'components/PartnerCard';
import React, { useEffect } from 'react';
import { usePartnerStore } from 'stores/partnerStore';
import EditPartnerModal from './EditPartnerModal';
import HeaderAdmin from 'components/HeaderAdmin';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'utils/functions';

function PartnerManagerPage() {
    const partnerList = usePartnerStore(state => state.partnerList);
    const initPartnerStore = usePartnerStore(state => state.initPartnerStore);
    const isPartnerActive = usePartnerStore(state => state.isPartnerActive);
    const { t } = useTranslation();
    useTitle(t('main_menu:partner_manager'));
    useEffect(() => {
        if (partnerList === null) {
            initPartnerStore();
        }
    }, [partnerList, initPartnerStore]);
    if (!partnerList) { return (<Loader />); }

    return (
        <section className={`PartnerManagerPage d-flex flex-column pb-5`}>

            <HeaderAdmin title='main_menu:partner_manager'>
                <CircleButton className="ms-auto" iconClassName={'fas fa-plus'} data-bs-toggle="modal" data-bs-target={"#createPartnerModal"} />
            </HeaderAdmin>
            {/*Grid with fake masonry?  -> https://getbootstrap.com/docs/5.3/examples/masonry/ */}
            <div className="row g-3 g-md-3 g-xxl-4" data-masonry="{`percentPosition`: true }" >
                {/*Col */}
                {partnerList.map(
                    (partner) =>
                        <div key={partner.partnerId} className="col-12 d-flex">

                            {/*Card Shop */}

                            <div className="card w-100">
                                <div className="card-body">
                                    <div className="d-flex mb-3">
                                        <div>
                                            <h5 className="card-title mb-0">{partner.partnerDetail.partner}</h5>
                                            <small className="text-secondary">ID: {partner.partnerId}</small><br></br>
                                        </div>
                                        <div className="ms-auto" data-bs-toggle="modal" data-bs-target={"#editPartnerModal" + partner.partnerId}>
                                            <CircleButton className="ms-auto" iconClassName={'fas fa-pencil'} />
                                        </div>
                                    </div>
                                    <div className='d-flex'>

                                        {/* PREVIEW */}
                                        <PartnerCard partner={partner} />
                                        {/* DATA */}
                                        <div className='px-3'>
                                            <span className={isPartnerActive(partner) ? "text-success fw-bold" : "text-danger fw-bold"}>{
                                                isPartnerActive(partner) ? "Published" : "Unpublished"
                                            }{
                                                    isPartnerActive(partner) ? <i className="fas fa-check-circle ms-2"></i> : <i className="fas fa-times-circle ms-2"></i>
                                                }
                                            </span>
                                            <br />
                                            <small>
                                                Created at: <strong>{new Date(partner.partnerDetail.partnerCreationDate).toLocaleDateString("it-IT")}</strong>
                                            </small>
                                            <br></br>
                                            <small>
                                                Starts at: <strong>{new Date(partner.partnerDetail.startDate).toLocaleDateString("it-IT")}</strong>
                                            </small>
                                            <br></br>
                                            <small>
                                                Expires at: <strong>{new Date(partner.partnerDetail.endDate).toLocaleDateString("it-IT")}</strong>
                                            </small>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <EditPartnerModal partner={partner} isCreating={false} />
                        </div >
                )}
                {
                    partnerList.length === 0 &&
                    <div className="col-12 d-flex">
                        <div className="card w-100">
                            <div className="card-body">
                                <h5 className="card-title mb-0">No partners found</h5>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <EditPartnerModal partner={null} isCreating={true} />
        </section >
    )
}
export default PartnerManagerPage