import ExpandingInput from "components/ExpInput";
import EmojiPicker from "emoji-picker-react";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import ReactQuill from "react-quill";
import { ApiService } from "services/apiService";
import { useEduquizManagerStore } from "stores/eduquizManagerStore";
import { useI18nStore } from "stores/i18nStore";
import { convertDifficultyFromNumber, convertDifficultyFromText, getFlagEmoji } from "utils/functions";
/* -------------------------------------------------------------------------- */
/*                            ARGUMENT PAGE MODALS                            */
/* -------------------------------------------------------------------------- */

export function EditArgumentModal(props) {
    const { t } = useTranslation();
    const editArgument = useEduquizManagerStore((state) => state.editArgument);
    const createArgument = useEduquizManagerStore((state) => state.createArgument);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const quizArgument = props.quizArgument;
    const [fontIcon, setFontIcon] = useState(quizArgument?.argumentDetail?.fontIcon ?? '');
    const isCreating = props.isCreating ?? false;
    const [image, setImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const quizLanguages = useI18nStore((state) => state.quizLanguages);
    const [activeLanguage, setActiveLanguage] = useState(quizLanguages[0]);
    const [deleteImage, setDeleteImage] = useState(false);
    const [deleteBannerImage, setDeleteBannerImage] = useState(false);
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);

    let color = quizArgument?.argumentDetail.color;
    if (!color) {
        color = 'red';
    } else {
        color = color.replace('0xFF', '#');
    }

    async function onSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const data = Object.fromEntries(formData.entries());
        // color from olor picker to data
        data.color = formData.get('color');
        if (fontIcon) {
            data.fontIcon = fontIcon;
        } else {
            data.fontIcon = '✖️';
        }
        data.image = deleteImage ? null : image;
        data.bannerImage = deleteBannerImage ? null : bannerImage;
        if (isCreating) {
            toast.promise(createArgument(data), {
                loading: t('common:saving'),
                success: <div>
                    <b>{t('common:argument')} {t('common:created')}</b>,
                </div>,
                error: <b>{t('errors:could_not_save')}</b>,
            });
            setFontIcon('');
        } else {
            toast.promise(editArgument(quizArgument, data), {
                loading: t('common:saving'),
                success: <b>{t('common:argument')} {t('common:saved')}</b>,
                error: <b>{t('errors:could_not_save')}</b>,
            });
        }
        setImage(null);
        form.reset();
        props.onHide();
    }

    function onEmojiClicked(emoji, event) {
        setFontIcon(emoji.emoji);
        setShowEmojiPicker(false);
    }

    return (
        <Modal id={isCreating ? "createArgumentModal" : "editArgumentModal"} show={props.show} onHide={() => props.onHide()} scrollable>
            <Modal.Header closeButton>
                <div className="d-flex flex-column">
                    <h1 className="modal-title fs-5" id="editArgumentModalLabel">{isCreating ? t('common:create', { capitalize: true }) : t('common:edit', { capitalize: true })} {t('common:argument')}</h1>
                    {!isCreating && <small className='text-secondary d-flex'>
                        <span>
                            ID:
                        </span>&nbsp; <div className='text-secondary' style={{ cursor: 'copy' }}
                            onClick={() => {
                                toast(t('common:copied_to_clipboard'), {
                                    icon: '📋',
                                });
                                navigator.clipboard.writeText(quizArgument?.argumentId);
                            }}>
                            {quizArgument?.argumentId}
                        </div>
                    </small>}
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmit} id={"editArgumentForm" + (quizArgument?.argumentId ?? "")}>
                    {/* TEXT TAB */}
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            {quizLanguages.map((language) =>
                                <button key={language} className={"nav-link text-secondary " + (language === activeLanguage ? "active" : "")} onClick={() => setActiveLanguage(language)} type="button">
                                    {language.toUpperCase()} {getFlagEmoji(language)}
                                </button>
                            )}
                        </div>
                    </nav>
                    <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                        {/* <div className="tab-pane fade active show" id="nav-argument-icon" role="tabpanel" aria-labelledby="nav-argument-icon-tab">
                                    <h1>{fontIcon}</h1>
                                    {!showEmojiPicker && <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                                        <i className='fas fa-plus' /> {t('forms:emoji')}
                                    </button>}
                                    {showEmojiPicker && <EmojiPicker autoFocusSearch={true} onEmojiClick={onEmojiClicked} />}
                                </div> */}
                        {quizLanguages.map((language) =>
                            <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">{t('forms:title')}</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={t('forms:title')} name={"title_" + language} defaultValue={getTranslationWithLanguage(quizArgument?.argumentDetail.title, language)} />
                                    </div>

                                    <span className="form-label">{t('forms:description')}</span>
                                    <div className="input-group mb-3">
                                        <ExpandingInput className="form-control" aria-label="Description" name={"description_" + language} defaultValue={getTranslationWithLanguage(quizArgument?.argumentDetail.description, language)} />
                                    </div>
                                </div>
                            </div>)}
                    </div>
                    {/* COLOR PICKER */}
                    <label htmlFor="colorInput" className="form-label">{t('forms:color')}</label>
                    <div className="mb-3 d-flex ">
                        <input type="text" className="form-control" id="colorInput" name="color" defaultValue={color}
                            onChange={(e) => {
                                document.getElementById('colorpicker').value = e.target.value;
                            }}
                        />
                        <input type="color" className="form-control form-control-color ms-3" id="colorpicker"
                            value={color} onChange={(e) => {
                                document.getElementById('colorInput').value = e.target.value;
                            }}
                        />
                    </div>
                    {/* ICON PICKER */}
                    <h6>Icon</h6>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link text-secondary active" id="nav-argument-icon-tab" data-bs-toggle="tab" data-bs-target="#nav-argument-icon" type="button" role="tab" aria-controls="nav-argument-icon" aria-selected="true">{t('eduquiz_manager:use_emoji')}</button>
                            <button className="nav-link text-secondary" id="nav-argument-image-tab" data-bs-toggle="tab" data-bs-target="#nav-argument-image" type="button" role="tab" aria-controls="nav-argument-image" aria-selected="false">{t('eduquiz_manager:use_image')}</button>
                        </div>
                    </nav>
                    <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                        <div className="tab-pane fade active show" id="nav-argument-icon" role="tabpanel" aria-labelledby="nav-argument-icon-tab">
                            <h1>{fontIcon}</h1>
                            {!showEmojiPicker && <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                                <i className='fas fa-plus' /> {t('forms:emoji')}
                            </button>}
                            {showEmojiPicker && <EmojiPicker autoFocusSearch={true} onEmojiClick={onEmojiClicked} />}
                        </div>
                        <div className="tab-pane fade" id="nav-argument-image" role="tabpanel" aria-labelledby="nav-argument-image-tab">
                            <div className="d-flex flex-column h-100">
                                {!(quizArgument?.argumentDetail?.iconId || image) && <h6>{t('forms:messages.no_image_selected')}</h6>}
                                {(quizArgument?.argumentDetail?.iconId || image) && <img src={image != null ? URL.createObjectURL(image) : (quizArgument?.argumentDetail?.iconId ?? "")} className="img-fluid object-fit-contain mb-3" alt="Argument banner " style={{ height: '16rem' }} />}
                                <input onChange={(event) => {
                                    setImage(event.target.files[0]);
                                }} className="form-control" type="file" id="formFile" />
                                {((quizArgument?.argumentDetail?.iconId || image) && !deleteImage) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {
                                    setDeleteImage(true)
                                }}>
                                    <i className='fas fa-trash' /> {t('common:delete')}
                                </button>}
                            </div>

                        </div>
                    </div>

                    {/* Banner PICKER */}
                    <h6>{t('forms:banner_image')}</h6>
                    <div id="nav-argument-banner-image" className="d-flex flex-column mb-3">
                        {!(quizArgument?.argumentDetail?.bannerId || bannerImage) && <h6>{t('forms:messages.no_image_selected')}</h6>}
                        {((quizArgument?.argumentDetail?.bannerId || bannerImage) && !deleteBannerImage) && <img src={bannerImage != null ? URL.createObjectURL(bannerImage) : (quizArgument?.argumentDetail?.bannerId ?? "")} className="img-fluid object-fit-contain border rounded mb-3" alt="Slide " />}

                        <input onChange={(event) => {
                            setBannerImage(event.target.files[0]);
                        }} className="form-control" type="file" id="formFile" />

                        {((quizArgument?.argumentDetail?.bannerId || image) && !deleteBannerImage) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {
                            setDeleteBannerImage(true)
                        }}>
                            <i className='fas fa-trash' /> {t('common:delete')} Banner
                        </button>}
                    </div>

                    {/* PARTNER */}
                    <div className="mb-3">
                        <label htmlFor="partnerInput" className="form-label">{t('forms:company_name')}</label>
                        <input type="text" className="form-control" id="partnerInput" name="companyName" placeholder="name@example.com" defaultValue={quizArgument?.argumentDetail?.companyName ?? 'FunniFin'} />
                    </div>

                    {/* Gems */}
                    <div className="mb-3">
                        <label htmlFor="gemsInput" className="form-label">{t('forms:gem_prize')}</label>
                        <input type="number" min={0} className="form-control" id="gemsInput" placeholder="Gems to win" defaultValue={quizArgument?.argumentDetail?.gemPrize ?? 0} name="gemPrize" />
                    </div>

                    {/* Xp */}
                    <div className="mb-3">
                        <label htmlFor="xpInput" className="form-label">{t('forms:experience_points')}</label>
                        <input type="number" min={0} className="form-control" id="xpInput" placeholder="Xp to win" defaultValue={quizArgument?.argumentDetail.experiencePoints ?? 0} name="experiencePoints" />
                    </div>



                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex flex-row">
                    <button type="button" className="btn btn-secondary"
                        onClick={() => props.onHide()}
                    >{t('common:close')}</button>
                    <button type="submit" className="btn btn-primary ms-2"
                        form={"editArgumentForm" + (quizArgument?.argumentId ?? "")}
                    >{t('common:save')}</button>
                </div>
            </Modal.Footer>
        </Modal>);
}
export function EditModuleModal(props) {
    const { t } = useTranslation();
    const editModule = useEduquizManagerStore((state) => state.editModule);
    const createModule = useEduquizManagerStore((state) => state.createModule);
    const quizModule = props.quizModule;
    const quizArgument = props.quizArgument;
    const isCreating = props.isCreating;
    const [image, setImage] = useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [deleteImage, setDeleteImage] = useState(false);
    const [deleteBannerImage, setDeleteBannerImage] = useState(false);
    const [fontIcon, setFontIcon] = useState(quizModule?.moduleDetail?.fontIcon ?? quizArgument?.argumentDetail?.fontIcon ?? '');
    const [bannerImage, setBannerImage] = useState(null);
    const quizLanguages = useI18nStore((state) => state.quizLanguages);
    const [activeLanguage, setActiveLanguage] = useState(quizLanguages[0]);
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);

    const [difficulty, setDifficulty] = useState(convertDifficultyFromText(quizModule?.moduleDetail?.difficulty));

    let color = quizModule?.moduleDetail?.color ?? quizArgument?.argumentDetail?.color;
    if (!color) {
        color = 'red';
    } else {
        color = color.replace('0xFF', '#');
    }

    async function onSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const data = Object.fromEntries(formData.entries());

        if (fontIcon) {
            data.fontIcon = fontIcon;
        }
        // add image to data
        if (image) {
            data.image = image;
        }
        if (deleteImage) {
            data.image = null;
        }
        // add banner image to data
        if (bannerImage) {
            data.bannerImage = bannerImage;
        }
        if (deleteBannerImage) {
            data.bannerImage = null;
        }
        data.difficulty = convertDifficultyFromNumber(difficulty);
        if (isCreating) {
            toast.promise(createModule(quizArgument, data), {
                loading: t('common:saving'),
                success: <b>{t('eduquiz_')} saved!</b>,
                error: <b>Could not save.</b>,
            });
            setImage(null);
            props.onHide();
        } else {
            toast.promise(editModule(quizArgument, quizModule, data), {
                loading: t('common:saving'),
                success: <b>Module saved!</b>,
                error: <b>Could not save.</b>,
            });
            setImage(null);
            props.onHide();
        }

    }

    function onEmojiClicked(emoji, event) {
        setFontIcon(emoji.emoji);
        setShowEmojiPicker(false);
    }

    return (
        <Modal id={"editModuleModal" + (quizModule?.moduleId ?? "")} show={props.show} onHide={() => props.onHide()} scrollable>
            <Modal.Header closeButton>
                <div className="d-flex flex-column">
                    <h1 className="modal-title fs-5" id={"editModuleModalLabel" + quizModule?.moduleId}>{isCreating ? 'Create' : "Edit"} Module</h1>
                    {!isCreating && <small className='text-secondary d-flex'>
                        <span>
                            ID:
                        </span>&nbsp;<div className='text-secondary' style={{ cursor: 'copy' }}
                            onClick={() => {
                                toast(t('common:copied_to_clipboard'), {
                                    icon: '📋',
                                });
                                navigator.clipboard.writeText(quizModule?.moduleId);
                            }}>
                            {quizModule?.moduleId}
                        </div>
                    </small>}
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit} id={"editModuleForm" + (quizModule?.moduleId ?? "")}>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            {quizLanguages.map((language) =>
                                <button key={language} className={"nav-link text-secondary " + (language === activeLanguage ? "active" : "")} onClick={() => setActiveLanguage(language)} type="button">
                                    {language.toUpperCase()}
                                </button>
                            )}
                        </div>
                    </nav>
                    <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                        {quizLanguages.map((language) =>
                            <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">{t('forms:title')}</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={t('forms:title')} name={"title_" + language} defaultValue={getTranslationWithLanguage(quizModule?.moduleDetail?.title, language)} />
                                    </div>

                                    <span className="form-label">{t('forms:description')}</span>
                                    <div className="input-group mb-3">
                                        <ExpandingInput className="form-control" aria-label="Description" name={"description_" + language} defaultValue={getTranslationWithLanguage(quizModule?.moduleDetail?.description, language)} />
                                    </div>
                                </div>
                            </div>)}
                    </div>
                    {/* DIFFICULTY */}
                    <div className="mb-3 d-flex flex-column">
                        <Form.Label>{t('common:difficulty')}: {t('common:' + convertDifficultyFromNumber(difficulty))}</Form.Label>
                        <input type="range"
                            min={1}
                            max={3}
                            step={1}
                            name="difficulty"
                            value={difficulty}
                            onChange={(e) => setDifficulty(e.target.value)}
                        />
                    </div>

                    {/* COLOR PICKER */}
                    <label htmlFor="colorInput" className="form-label">{t('forms:color')}</label>
                    <div className="mb-3 d-flex ">
                        <input type="text" className="form-control" id="colorInput" name="color" defaultValue={color}
                            onChange={(e) => {
                                document.getElementById('colorpicker').value = e.target.value;
                            }}
                        />
                        <input type="color" className="form-control form-control-color ms-3" id="colorpicker"
                            value={color} onChange={(e) => {
                                document.getElementById('colorInput').value = e.target.value;
                            }}
                        />
                    </div>
                    {/* ICON PICKER */}
                    <label className="form-label">Icon</label>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className={"nav-link text-secondary active"} id="nav-module-icon-tab" data-bs-toggle="tab" data-bs-target={"#nav-module-icon" + quizModule?.moduleId} type="button" role="tab" aria-controls="nav-module-icon" aria-selected="true">{t('eduquiz_manager:use_emoji')}</button>
                            <button className="nav-link text-secondary" id="nav-module-image-tab" data-bs-toggle="tab" data-bs-target={"#nav-module-image" + quizModule?.moduleId} type="button" role="tab" aria-controls="nav-module-image" aria-selected="false">{t('eduquiz_manager:use_image')}</button>
                        </div>
                    </nav>
                    <div className="tab-content p-3 border-bottom border-start rounded-bottom-3 border-end mb-3" id="nav-module-tabContent">
                        <div className="tab-pane fade active show" id={"nav-module-icon" + quizModule?.moduleId} role="tabpanel" aria-labelledby="nav-module-icon-tab">
                            <h1>{fontIcon}</h1>
                            {!showEmojiPicker && <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                                <i className='fas fa-plus' /> Choose Emoji
                            </button>}
                            {showEmojiPicker && <EmojiPicker autoFocusSearch={true} onEmojiClick={onEmojiClicked} />}
                        </div>
                        <div className="tab-pane fade" id={"nav-module-image" + quizModule?.moduleId} role="tabpanel" aria-labelledby="nav-module-image-tab">
                            {!(image) && <h6>{t('forms:messages.no_image_selected')}</h6>}
                            {((quizModule?.moduleDetail?.iconId || image) && !deleteImage) && <img src={image != null ? URL.createObjectURL(image) : (quizModule?.moduleDetail?.iconId ?? "")} className="img-fluid object-fit-contain mb-3" alt="Slide " style={{ height: '16rem' }} />}
                            <input onChange={(event) => {
                                setDeleteImage(false)
                                setImage(event.target.files[0]);
                            }} className="form-control" type="file" id="formFile" />
                            {((quizModule?.moduleDetail?.iconId || image) && !deleteImage) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {
                                setDeleteImage(true)
                            }}>
                                <i className='fas fa-trash' /> {t('common:delete')}
                            </button>}
                        </div>
                    </div>

                    {/* Banner PICKER */}
                    <h6>Banner</h6>
                    <div id="nav-argument-banner-image" className="d-flex flex-column mb-3">
                        {!(quizModule?.moduleDetail?.bannerId || bannerImage) && <h6>{t('forms:messages.no_image_selected')}</h6>}
                        {((quizModule?.moduleDetail?.bannerId || bannerImage) && !deleteBannerImage) && <img src={bannerImage != null ? URL.createObjectURL(bannerImage) : (quizModule?.moduleDetail?.bannerId ?? "")} className="img-fluid object-fit-contain border rounded mb-3" alt="Banner" />}
                        <input onChange={(event) => {
                            setDeleteBannerImage(false)
                            setBannerImage(event.target.files[0]);
                        }} className="form-control" type="file" id="formFile" />
                        {((quizModule?.moduleDetail?.bannerId || bannerImage) && !deleteBannerImage) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {
                            setDeleteBannerImage(true)
                            setBannerImage(null)
                        }}>
                            <i className='fas fa-trash' /> {t('common:delete')} Banner
                        </button>}
                    </div>

                    {/* PARTNER */}
                    <div className="mb-3">
                        <label htmlFor="partnerInput" className="form-label">Company name</label>
                        <input type="text" className="form-control" id="partnerInput" name="companyName" placeholder="name@example.com" defaultValue={quizModule?.moduleDetail?.companyName} />
                    </div>

                    {/* ORDER */}
                    {!isCreating && <div className="mb-3">
                        <label htmlFor="orderInput" className="form-label">Order</label>
                        <input type="number" min={0} className="form-control" id="orderInput" placeholder="Order" defaultValue={quizModule?.moduleDetail?.order} name="order" />
                    </div>}


                </Form>

                <Button variant="outline-light" onClick={() => toast.promise(
                    ApiService.education.fixModuleCompanyId(quizModule?.moduleId),
                    {
                        loading: 'Fixing Module...',
                        success: <b>Module Fixed!</b>,
                        error: <b>Could not fix Module</b>
                    }
                )}>Fix Module</Button>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex flex-row-reverse">
                    <button type="submit" className="btn btn-primary ms-2" form={"editModuleForm" + (quizModule?.moduleId ?? "")}  >{t('common:save')}</button>
                    <button type="button" className="btn btn-secondary"
                        onClick={() => props.onHide()}
                    >{t('common:close')}</button>
                </div>
            </Modal.Footer>
        </Modal>);
}
// Diplay an modal to edit a unit, in the same way as EditModuleModal but using unitDetail to look for the data
export function EditUnitModal(props) {
    const { t } = useTranslation();

    const editUnit = useEduquizManagerStore((state) => state.editUnit);
    const createUnit = useEduquizManagerStore((state) => state.createUnit);
    const quizUnit = props.quizUnit;
    const isCreating = props.isCreating;
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [image, setImage] = useState(null);
    const [fontIcon, setFontIcon] = useState(quizUnit?.unitDetail?.fontIcon ?? props.quizModule?.moduleDetail?.fontIcon ?? '');
    //i18n
    const quizLanguages = useI18nStore((state) => state.quizLanguages);
    const [activeLanguage, setActiveLanguage] = useState(quizLanguages[0]);
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);
    const getTranslation = useI18nStore((state) => state.getTranslation);
    async function onSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const data = Object.fromEntries(formData.entries());

        // add fonticon to data
        data.fontIcon = fontIcon;
        // add image to data
        data.image = image;
        //call the api
        if (isCreating) {
            toast.promise(
                createUnit(props.quizModule, data),
                {
                    loading: 'Creating Unit...',
                    success: <b>{t('common:unit')} {t('common:created')}</b>,
                    error: <b>{t('errors:could_not_create')}</b>
                },
            ).then(() => {
                form.reset();
                setImage(null);
                setFontIcon(props.quizModule?.moduleDetail?.fontIcon ?? "");
                props.onHide();
            });
        } else {
            toast.promise(
                editUnit(quizUnit, data, props.quizArgument),
                {
                    loading: t('common:saving'),
                    success: <b>{t('common:unit')} {t('common:saved')}</b>,
                    error: <b>{t('errors:could_not_save')}</b>
                },
            ).then(() => {
                setImage(null);
                setFontIcon(props.quizModule?.moduleDetail?.fontIcon ?? "");
                form.reset();
                props.onHide();
            });
        }

    }

    function onEmojiClicked(emoji, event) {
        setFontIcon(emoji.emoji);
        setShowEmojiPicker(false);
    }
    return (
        <Modal id={"editUnitModal" + (quizUnit?.unitId ?? props.quizModule?.moduleId ?? "")} show={props.show} onHide={() => props.onHide()} scrollable>

            <Modal.Header closeButton>
                <div className="d-flex flex-column">
                    <h1 className="modal-title fs-5" id={"editUnitModalLabel" + quizUnit?.unitId}>{isCreating ? 'Create' : "Edit"} Unit</h1>
                    {!isCreating && <small className='text-secondary d-flex'>
                        <span>
                            ID:
                        </span>&nbsp;<div className='text-secondary' style={{ cursor: 'copy' }}
                            onClick={() => {
                                toast(t('common:copied_to_clipboard'), {
                                    icon: '📋',
                                });
                                navigator.clipboard.writeText(quizUnit?.unitId);
                            }
                            }>
                            {quizUnit?.unitId}
                        </div>
                    </small>}
                    {isCreating && <small className='text-secondary'>
                        Target Module: {getTranslation(props.quizModule?.moduleDetail?.title)}
                    </small>
                    }
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmit} id={"editUnitForm" + (quizUnit?.unitId ?? "")}>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            {quizLanguages.map((language) =>
                                <button key={language} className={"nav-link text-secondary " + (language === activeLanguage ? "active" : "")} onClick={() => setActiveLanguage(language)} type="button">
                                    {language.toUpperCase()}
                                </button>
                            )}
                        </div>
                    </nav>
                    <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                        {quizLanguages.map((language) =>
                            <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">{t('forms:title')} - {language}</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={t('forms:title')} name={"title_" + language} defaultValue={getTranslationWithLanguage(quizUnit?.unitDetail?.title, language)} />
                                    </div>

                                    <span className="form-label">{t('forms:description')} - {language}</span>
                                    <div className="input-group mb-3">
                                        <ExpandingInput className="form-control" aria-label="Description" name={"description_" + language} defaultValue={getTranslationWithLanguage(quizUnit?.unitDetail?.description, language)} />
                                    </div>
                                </div>
                            </div>)}
                    </div>
                    {/* ICON PICKER */}
                    <h6>Icon</h6>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link text-secondary active" id="nav-unit-icon-tab" data-bs-toggle="tab" data-bs-target={"#nav-unit-icon" + quizUnit?.unitId} type="button" role="tab" aria-controls="nav-unit-icon" aria-selected="true">{t('eduquiz_manager:use_emoji')}</button>
                            <button className="nav-link text-secondary" id="nav-unit-image-tab" data-bs-toggle="tab" data-bs-target={"#nav-unit-image" + quizUnit?.unitId} type="button" role="tab" aria-controls="nav-unit-image" aria-selected="false">{t('eduquiz_manager:use_image')}</button>
                        </div>
                    </nav>
                    <div className="tab-content p-3 border-bottom border-start border-end mb-3" id="nav-unit-tabContent">
                        <div className="tab-pane fade active show" id={"nav-unit-icon" + quizUnit?.unitId} role="tabpanel" aria-labelledby="nav-unit-icon-tab">
                            <h1>{fontIcon}</h1>
                            {!showEmojiPicker && <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                                <i className='fas fa-plus' /> {t('eduquiz_manager:use_emoji')}
                            </button>}
                            {showEmojiPicker && <EmojiPicker autoFocusSearch={true} onEmojiClick={onEmojiClicked} />}
                        </div>
                        <div className="tab-pane fade" id={"nav-unit-image" + quizUnit?.unitId} role="tabpanel" aria-labelledby="nav-unit-image-tab">
                            {!(image) && <h6>{t('forms:messages.no_image_selected')}</h6>}
                            {(quizUnit?.unitDetail?.iconId || image) && <img src={image != null ? URL.createObjectURL(image) : (quizUnit?.unitDetail?.iconId ?? "")} className="img-fluid object-fit-contain mb-3" alt="Slide " style={{ height: '16rem' }} />}
                            <input onChange={(event) => {
                                setImage(event.target.files[0]);
                            }} className="form-control" type="file" id="formFile" />
                        </div>
                    </div>
                    {/* PARTNER */}
                    <div className="mb-3">
                        <label htmlFor="partnerInput" className="form-label">{t('forms:company_name')}</label>
                        <input type="text" className="form-control" id="unitPartnerInput" name="companyName" placeholder="name@example.com" defaultValue={quizUnit?.unitDetail?.companyName} />
                    </div>

                </form>
            </Modal.Body>

            <Modal.Footer>
                <div className="d-flex flex-row">
                    <button type="button" className="btn btn-secondary"
                        onClick={() => props.onHide()}
                    >{t('common:close')}</button>
                    <button type="submit" className="btn btn-primary ms-2"
                        form={"editUnitForm" + (quizUnit?.unitId ?? "")}
                    >{t('common:save')}</button>
                </div>
            </Modal.Footer>

        </Modal>);
}
export function EditSlideModal({ quizArgument, quizSlide, quizUnit, isCreating, onHide }) {
    const { t } = useTranslation();

    const editSlide = useEduquizManagerStore((state) => state.editSlide);
    const createSlide = useEduquizManagerStore((state) => state.createSlide);
    const [image, setImage] = useState(null);
    //i18n
    const quizLanguages = useI18nStore((state) => state.quizLanguages);
    const [activeLanguage, setActiveLanguage] = useState(quizLanguages[0]);
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);

    // state of decription input - i18n object with html strings
    const [description, setDescription] = useState(
        quizSlide?.slideDetail?.description ?? quizLanguages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {})
    );

    async function onSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const data = Object.fromEntries(formData.entries());
        if (image) {
            data.image = image;
        }

        // add description to data
        data.description = description;
        if (isCreating) {
            toast.promise(createSlide(quizArgument, quizUnit, data), {
                loading: 'Creating...',
                success: 'Created!',
                error: 'Error during creation',
            }).then(() => {
                setImage(null);
                form.reset();
                onHide();
            });
        } else {
            toast.promise(editSlide(quizArgument, quizSlide, data), {
                loading: t('common:saving'),
                success: 'Saved!',
                error: 'Error when saving',
            }).then(() => {
                setImage(null);
                form.reset();
                onHide();
            });
        }
    }
    return (
        <Modal id={`editSlideModal${quizSlide.slideId ?? quizUnit.unitId ?? ''}`} show={true} onHide={onHide} scrollable>

            <Modal.Header closeButton>
                <div className="d-flex flex-column">
                    <h1 className="modal-title fs-5" id={`editSlideModalLabel${quizSlide.slideId}`}>{isCreating ? 'Create' : 'Edit'} Slide</h1>
                    {!isCreating && <small className='text-secondary d-flex'>
                        <span>
                            ID:
                        </span>&nbsp;
                        <div className='text-secondary' style={{ cursor: 'copy' }}
                            onClick={() => {
                                toast(t('common:copied_to_clipboard'), {
                                    icon: '📋',
                                });
                                navigator.clipboard.writeText(quizSlide.slideId);
                            }
                            }>
                            {quizSlide.slideId}
                        </div>
                    </small>}
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmit} id={`editSlideForm${quizSlide.slideId ?? ''}`}>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            {quizLanguages.map((language) =>
                                <button key={language} className={"nav-link text-secondary " + (language === activeLanguage ? "active" : "")} onClick={() => setActiveLanguage(language)} type="button">
                                    {language.toUpperCase()}
                                </button>
                            )}
                        </div>
                    </nav>
                    <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                        {quizLanguages.map((language) =>
                            <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">{t('forms:title')}</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={t('forms:title')} name={"title_" + language} defaultValue={getTranslationWithLanguage(quizSlide?.slideDetail?.title, language)} />
                                    </div>

                                    <span className="form-label">{t('forms:description')}</span>
                                    <div className="mb-3">
                                        <ReactQuill
                                            theme="snow"
                                            value={getTranslationWithLanguage(description, language)}
                                            onChange={(value) => {
                                                setDescription({ ...description, [language]: value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>)}
                    </div>
                    {/* Slide image input  */}
                    <div className="mb-3 d-flex flex-column">
                        <label htmlFor="formFile" className="form-label">Slide image</label>
                        {!(image) && <h6>{t('forms:messages.no_image_selected')}</h6>}
                        {(quizSlide?.slideDetail?.bannerId || image) && <img src={image != null ? URL.createObjectURL(image) : (quizSlide?.slideDetail?.bannerId ?? "")} className="img-fluid object-fit-contain mb-3" alt="Slide " style={{ height: '16rem' }} />}
                        <input onChange={(event) => {
                            setImage(event.target.files[0]);
                        }} className="form-control" type="file" id="formFile" />
                    </div>

                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex flex-row">
                    <button type="button" className="btn btn-secondary" onClick={onHide} >{t('common:close')}</button>
                    <button type="submit" className="btn btn-primary ms-2"
                        form={`editSlideForm${quizSlide.slideId ?? ''}`}
                    >{t('common:save')}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}