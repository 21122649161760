import React from 'react'
import { Outlet } from 'react-router-dom'

function EmptyLayout(props) {
    return (
   
                  <div className='container-fluid container-md py-4 h-100'>  <Outlet /></div>
   
    )
}

export default EmptyLayout