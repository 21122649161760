import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useDocumentStore } from "./documentStore";
import { convertColorToHex, convertHexToColor, generateImageUrl, formatI18nObject } from "utils/functions";
import cloneDeep from "clone-deep";
import { QuizArgument, QuizModule, QuizSlide, QuizUnit } from "models/eduquizModels";
import { useSystemModalStore } from "./systemModalStore";


let isInitEduquizRunning = false;
export interface EduquizManagerStore {
    argumentList: QuizArgument[] | null;
    selectedArgument: QuizArgument | null;
    eduQuizCompanyId: string;
    initEduquiz: (companyId: string) => Promise<any>;
    selectArgument: (argumentId: string) => void;
    editArgument: (argument: QuizArgument, data: any) => Promise<any>;
    editModule: (argument: QuizArgument, quizModule: QuizModule, data: any) => Promise<any>;
    editUnit: (quizUnit: QuizUnit, data: any, quizArgument: QuizArgument) => Promise<any>;
    editSlide: (argument: QuizArgument, quizSlide: QuizSlide, data: any) => Promise<any>;
    createArgument: (data: any) => Promise<any>;
    createModule: (argument: QuizArgument, data: any) => Promise<any>;
    createUnit: (quizModule: QuizModule, data: any) => Promise<any>;
    createSlide: (argument: any, quizUnit: QuizUnit, data: any) => Promise<any>;
    deleteUnit: (quizUnit: QuizUnit, quizModule: QuizModule) => Promise<any>;
    deleteSlide: (quizSlide: QuizSlide, quizUnit: QuizUnit) => Promise<any>;
    deleteArgument: (argument: QuizArgument) => Promise<any>;
    deleteModule: (quizModule: QuizModule, quizArgument: QuizArgument) => Promise<any>;
    copyArgument: (argumentId: string, companyId: string) => Promise<any>;
}

export const useEduquizManagerStore = create<EduquizManagerStore>()((set, get) => ({
    argumentList: null,
    selectedArgument: null,
    eduQuizCompanyId: '',
    initEduquiz: async (companyId: string) => {
        // If the function is already running, return null
        if (isInitEduquizRunning) {
            /* console.log('initEduquiz is already running'); */
            return null;
        }
        try {
            // Set the flag variable to true to indicate that the function is running
            isInitEduquizRunning = true;
            set({ argumentList: null });
            if (companyId === '') {
                return null;
            }
            // Get the list of arguments from the API
            let argumentList = await ApiService.education.getArguments(companyId);
            // If the result is not null, set the state of the argumentList to the result
            if (argumentList !== null) {
                set({ argumentList: argumentList, eduQuizCompanyId: companyId });
                // Return the result
                return argumentList;
            } else {
                return null;
            }
        } catch {
            throw Error('Error in initEduquiz');
        } finally {
            // Set the flag variable to false to indicate that the function has finished running
            isInitEduquizRunning = false;
        }
    },
    selectArgument: (argumentId) => {
        set({ selectedArgument: (get().argumentList ?? [])?.length > 0 ? get().argumentList?.find((a) => a.argumentId === argumentId) : null });
        if (!get().selectedArgument) {
            console.error('mega error');
        }
    },
    /// Edits an argument meta data
    editArgument: async (argument, data) => {
        try {
            let _argument = { ...argument };
            // prepare the text for the api
            _argument.argumentDetail.title = { ..._argument.argumentDetail.title, ...(formatI18nObject('title', data) as any) };
            _argument.argumentDetail.description = { ..._argument.argumentDetail.description, ...(formatI18nObject('description', data) as any) };

            data.color = data.color ?? _argument.argumentDetail.color ?? '';

            data.color = convertHexToColor(data.color);

            if (data.color) {
                _argument.argumentDetail.color = data.color;
            }
            // merge the data with the argumentDetail
            _argument.argumentDetail = { ..._argument.argumentDetail, ...data };
            // add icon if needed
            let imageData = null;
            if (data.image) {
                imageData = await useDocumentStore.getState().uploadDocument({
                    file: data.image,
                    type: 'image',
                    title: data.image.name,
                    format: data.image.type,
                    ownerId: ""
                });
                _argument.argumentDetail.iconId = generateImageUrl(imageData!)!;
                _argument.argumentDetail.icon = imageData!;
            }
            if (data.image === null) {
                _argument.argumentDetail.iconId = undefined;
                _argument.argumentDetail.icon = undefined;
            }
            // banner image if needed
            if (data.bannerImage) {
                imageData = await useDocumentStore.getState().uploadDocument({
                    file: data.bannerImage,
                    type: 'image',
                    title: data.bannerImage.name,
                    format: data.bannerImage.type,
                    ownerId: ""
                });
                _argument.argumentDetail.bannerId = generateImageUrl(imageData!)!;
                _argument.argumentDetail.banner = imageData!;
            }
            if (data.bannerImage === null) {
                _argument.argumentDetail.bannerId = undefined;
                _argument.argumentDetail.banner = undefined;
            }

            // prepare the modules for the api
            const modules = [..._argument.argumentDetail.modules ?? []];
            delete _argument.argumentDetail.modules;
            // call the api
            companyIdAlert(get().eduQuizCompanyId);
            let result = await ApiService.education.putArgument(_argument);
            // if the result is not null
            if (result !== null) {
                // find the index of the argument to edit
                let _argumentList = get().argumentList ?? [];
                let index = _argumentList.findIndex((a) => a.argumentId === _argument.argumentId);
                _argument.argumentDetail.color = _argument.argumentDetail.color ? convertColorToHex(_argument.argumentDetail.color) ?? undefined : undefined;
                // replace the argument in the list
                _argument.argumentDetail.modules = modules;
                _argumentList[index] = _argument;
                set({ argumentList: [..._argumentList] });
                return result;
            } else {
                // if the result is null, return null
                console.error('EDIT ARGUMENT FAILED, argument not saved');
                return null;
            }
        }
        catch (error) {
            console.log('EDIT ARGUMENT ERROR', error);
            throw error;
        }
    },
    editModule: async (argument, quizModule, data) => {
        try {
            let _argument = { ...argument };
            let _module = { ...quizModule };
            // 1. Prepare the module for the api
            // prepare the title for the api
            _module.moduleDetail.title = { ..._module.moduleDetail.title, ...(formatI18nObject('title', data) as any) };
            _module.moduleDetail.description = { ..._module.moduleDetail.description, ...(formatI18nObject('description', data) as any) };
            // add color

            data.color = convertHexToColor(data.color);
            // merge the data with the argumentDetail
            _module.moduleDetail = { ..._module.moduleDetail, ...data };

            // remove units from the module
            const units = [...(_module.moduleDetail.units ?? [])];
            delete _module.moduleDetail.units;
            // add icon if needed
            let imageData = null;
            if (data.image) {
                imageData = await useDocumentStore.getState().uploadDocument({
                    file: data.image,
                    type: 'image',
                    title: data.image.name,
                    format: data.image.type,
                    ownerId: ""
                });
                _module.moduleDetail.iconId = generateImageUrl(imageData!)!;
                _module.moduleDetail.icon = imageData!;
            }
            if (data.image === null) {
                _module.moduleDetail.iconId = null;
                _module.moduleDetail.icon = null;
            }
            // banner image if needed
            if (data.bannerImage) {
                imageData = await useDocumentStore.getState().uploadDocument({
                    file: data.bannerImage,
                    type: 'image',
                    title: data.bannerImage.name,
                    format: data.bannerImage.type,
                    ownerId: ""
                });
                _module.moduleDetail.bannerId = generateImageUrl(imageData!);
                _module.moduleDetail.banner = imageData!;
            }
            if (data.bannerImage === null) {
                _module.moduleDetail.bannerId = null;
                _module.moduleDetail.banner = null;
            }
            /*             // banner image if needed
                        if (data.bannerImage) {
                            imageData = await useDocumentStore.getState().uploadDocument({
                                file: data.bannerImage,
                                type: 'image',
                                title: data.bannerImage.name,
                                format: data.bannerImage.type,
                            });
                            _argument.argumentDetail.bannerImage = generateImageUrl(imageData);
                        } */
            // 3. Proceed to modify the module
            // call the api
            companyIdAlert(get().eduQuizCompanyId);
            let result = await ApiService.education.putModule(_module);
            // if the result is not null
            if (result !== null) {
                // find the index of the argument to edit
                let _argumentList = get().argumentList ?? [];
                let index = _argumentList.findIndex((a) => a.argumentId === _argument.argumentId);
                // replace the units in the arguments
                _module.moduleDetail.units = units;
                // substitute the module in the argument module array by moduleId
                let _moduleIndex = (_argument.argumentDetail.modules ?? []).findIndex((m) => m.moduleId === _module.moduleId);
                _argument.argumentDetail.modules![_moduleIndex] = _module;
                // add the update argument to store
                _argumentList[index] = _argument;
                set({ argumentList: [..._argumentList] });
                return result;
            } else {
                throw new Error('EDIT MODULE FAILED, module not saved');
            }
        } catch (error) {
            console.log('EDIT MODULE ERROR', error);
            throw error;
        }
    },
    editUnit: async (quizUnit, data, quizArgument) => {
        try {
            let _unit = { ...quizUnit };
            // prepare the title for the api
            _unit.unitDetail.title = { ..._unit.unitDetail.title, ...(formatI18nObject('title', data) as any) };
            _unit.unitDetail.description = { ..._unit.unitDetail.description, ...(formatI18nObject('description', data) as any) };
            // merge the data with the argumentDetail
            _unit.unitDetail = { ..._unit.unitDetail, ...data };
            // remove questions and slides from the unit
            const questions = [...(_unit.unitDetail.questions ?? [])];
            const slides = [...(_unit.unitDetail.slides ?? [])];
            delete _unit.unitDetail.questions;
            delete _unit.unitDetail.slides;
            // add icon if needed
            let imageData = null;
            if (data.image) {
                imageData = await useDocumentStore.getState().uploadDocument({
                    file: data.image,
                    type: 'image',
                    title: data.image.name,
                    format: data.image.type,
                    ownerId: ""
                });
                _unit.unitDetail.iconId = generateImageUrl(imageData!);
            }
            // call the api
            companyIdAlert(get().eduQuizCompanyId);
            let result = await ApiService.education.putUnit(_unit);
            // if the result is not null
            if (result !== null) {
                // replace questions slides in the unit
                _unit.unitDetail.questions = questions;
                _unit.unitDetail.slides = slides;
                // Update the argument in the store
                let _argumentList = get().argumentList ?? [];
                let _argumentIndex = _argumentList.findIndex((a) => a.argumentId === quizArgument.argumentId);
                let _moduleIndex = _argumentList[_argumentIndex]?.argumentDetail.modules?.findIndex((m) => m.moduleId === quizUnit.unitDetail.moduleId)!;
                let _unitIndex = _argumentList[_argumentIndex]?.argumentDetail.modules?.[_moduleIndex]?.moduleDetail.units?.findIndex((u) => u.unitId === quizUnit.unitId)!;
                if (_argumentIndex !== -1 && _moduleIndex !== -1 && _unitIndex !== -1) {
                    _argumentList[_argumentIndex].argumentDetail.modules![_moduleIndex].moduleDetail.units![_unitIndex] = _unit;
                    set({ argumentList: [..._argumentList] });
                }
                return result;
            } else {
                return null;
            }
        } catch (error) {
            console.log('EDIT ARGUMENT ERROR', error);
            throw error;
        }
    },
    editSlide: async (argument, quizSlide, data) => {
        try {
            let _slide = { ...quizSlide };
            // prepare the title for the api
            _slide.slideDetail.title = { ..._slide.slideDetail.title, ...(formatI18nObject('title', data) as any) };
            _slide.slideDetail.description = data.description

            // merge the data with the slideDetail
            _slide.slideDetail = { ..._slide.slideDetail, ...data };
            // If slide has bannerImage, upload it
            let imageData = null;
            if (data.image) {
                imageData = await useDocumentStore.getState().uploadDocument({
                    file: data.image,
                    type: 'image',
                    title: data.image.name,
                    format: data.image.type,
                    ownerId: ""
                });
                _slide.slideDetail.bannerId = generateImageUrl(imageData!);
            }
            // call the api
            companyIdAlert(get().eduQuizCompanyId);
            let result = await ApiService.education.putSlide(_slide);
            // if the result is not null update the store
            if (result !== null) {
                //replace the slide in the store
                (argument.argumentDetail.modules ?? []).forEach((m) => {
                    (m.moduleDetail.units ?? []).forEach((u) => {
                        (u.unitDetail.slides ?? []).forEach((s) => {
                            if (s.slideId === _slide.slideId) {
                                s.slideDetail = _slide.slideDetail;
                            }
                        });
                    });
                });
                // Update the arguments in the store
                set({ argumentList: [...get().argumentList ?? []] });
                return result;
            } else {
                return null;
            }
        } catch (error) {
            console.log('EDIT ARGUMENT ERROR', error);
            throw error;
        }
    },
    createArgument: async (data) => {
        let order = 0;
        (get().argumentList ?? []).forEach((s) => {
            if ((s.argumentDetail.order ?? 0) > order) {
                order = s.argumentDetail.order ?? 0;
            }
        });
        order++;
        if (data.color) {
            // prepare the color for the api
            data.color = convertHexToColor(data.color);
        } else {
            data.color = '0xFF000000';
        }
        let imageData = null;
        let bannerId = null;
        let iconId = null;
        if (data.bannerImage) {
            imageData = await useDocumentStore.getState().uploadDocument({
                file: data.bannerImage,
                type: 'image',
                title: data.bannerImage.name,
                format: data.bannerImage.type,
                ownerId: ""
            });
            bannerId = generateImageUrl(imageData!);
        }
        if (data.image) {
            imageData = await useDocumentStore.getState().uploadDocument({
                file: data.image,
                type: 'image',
                title: data.image.name,
                format: data.image.type,
                ownerId: ""
            });
            iconId = generateImageUrl(imageData!);
        }
        try {
            let newArgument = {
                "argumentDetail": {
                    // "bannerId": data.bannerId ?? "https://d1duwxmxeujazx.cloudfront.net/upload_test/fanta_quiz/images/arguments/arguments1.jpg",
                    "code": order,
                    "color": data.color,
                    "iconId": iconId,
                    "bannerId": bannerId ?? "https://d1duwxmxeujazx.cloudfront.net/upload_test/fanta_quiz/images/arguments/arguments1.jpg",
                    // "companyLogoId": data.companyLogoId ?? "https://d1duwxmxeujazx.cloudfront.net/upload_test/fanta_quiz/images/units/slidetinaba1.jpg",
                    "companyName": data.companyName ?? 'FunniFin',
                    "description": formatI18nObject('description', data),
                    "experiencePoints": 0,
                    "fontIcon": data.fontIcon,
                    "gemPrize": 100,
                    // "iconId": "",
                    "companyId": get().eduQuizCompanyId,
                    "moduleIds": [
                    ],
                    "modules": [],
                    "order": order,
                    "title": formatI18nObject('title', data),
                }
            };
            // call the api
            companyIdAlert(get().eduQuizCompanyId);
            let result = await ApiService.education.postArgument([newArgument]);
            // if the result is not null
            if (result !== null) {
                // Update the argumentS in the store
                set({ argumentList: [...(get().argumentList ?? []), result] });
                return result;
            }

        } catch (error) {
            console.log('CREATE ARGUMENT ERROR', error);
            throw error;
        }
    },
    createModule: async (argument, data) => {
        console.log('CREATE MODULE', argument, data);
        // get the highest order number in the modules
        let order = 0;
        (argument.argumentDetail.modules ?? []).forEach((m) => {
            if ((m.moduleDetail.order ?? 0) > order) {
                order = m.moduleDetail.order ?? 0;
            }
        });
        order++;
        let imageData;
        let iconId;
        if (data.image) {
            imageData = await useDocumentStore.getState().uploadDocument({
                file: data.image,
                type: 'image',
                title: data.image.name,
                format: data.image.type,
                ownerId: ""
            });
            iconId = generateImageUrl(imageData!);
        }
        if (data.color) {
            // prepare the color for the api
            data.color = convertHexToColor(data.color);
        } else {
            data.color = '0xFF000000';
        }
        try {
            let newModule = {
                "moduleDetail": {
                    "code": order,
                    "color": data.color,
                    "companyName": "FunniFin",
                    "description": formatI18nObject('description', data),
                    "fontIcon": data.fontIcon,
                    "iconId": iconId,
                    "order": order,
                    "argumentId": argument.argumentId,
                    "title": formatI18nObject('title', data),
                    "companyId": get().eduQuizCompanyId,
                    "unitIds": [],
                    "units": [],
                    "difficulty": data.difficulty ?? "LOW"
                }
            };
            // call the api
            companyIdAlert(get().eduQuizCompanyId);
            let result = await ApiService.education.postModule([newModule]);
            // if the result is not null
            if (result !== null) {
                // update the argument using api
                argument.argumentDetail.moduleIds.push(result.moduleId);
                // temporary store and delete modules before calling api
                const modules = [...argument.argumentDetail.modules ?? [], result];
                delete argument.argumentDetail.modules;
                await ApiService.education.putArgument(argument);
                // Update the argumentS in the store
                argument.argumentDetail.modules = modules;
                set({ argumentList: [...get().argumentList ?? []] });
                return result;
            }
        } catch (error) {
            console.log('CREATE MODULE ERROR', error);
            throw error;
        }
    },
    createUnit: async (quizModule, data) => {
        // get the highest order number in the units
        let order = 0;
        (quizModule.moduleDetail.units ?? []).forEach((u) => {
            if ((u.unitDetail.order ?? 0) > order) {
                order = u.unitDetail.order ?? 0;
            }
        });
        order++;
        // add image
        let imageData;
        let iconId;
        if (data.image) {
            imageData = await useDocumentStore.getState().uploadDocument({
                file: data.image,
                type: 'image',
                title: data.image.name,
                format: data.image.type,
                ownerId: ""
            });
            iconId = generateImageUrl(imageData!);
        }
        try {
            let newUnit = {
                "unitDetail": {
                    "code": order,
                    "companyName": data.companyName ?? "FunniFin",
                    "description": formatI18nObject('description', data),
                    "moduleId": quizModule.moduleId,
                    "fontIcon": data.fontIcon,
                    "iconId": iconId,
                    "order": order,
                    "title": formatI18nObject('title', data),
                    "slideIds": [],
                    "slides": [],
                    "companyId": get().eduQuizCompanyId,
                    "color": convertHexToColor(quizModule.moduleDetail.color ?? "#000000")
                }
            };

            // call the api
            let result = await ApiService.education.postUnit([newUnit]);
            // if the result is not null
            if (result !== null) {
                // update the module using api
                quizModule.moduleDetail.unitIds.push(result.unitId);
                // temporary store and delete units before calling api
                const units = [...(quizModule.moduleDetail.units ?? []), result];
                delete quizModule.moduleDetail.units;
                companyIdAlert(get().eduQuizCompanyId);
                await ApiService.education.putModule(quizModule);
                // Update the argumentS in the store
                quizModule.moduleDetail.units = units;
                set({ argumentList: [...get().argumentList ?? []] });
                return result;
            }
        } catch (error) {
            console.log('CREATE UNIT ERROR', error);
            throw error;
        }
    },
    createSlide: async (argument, quizUnit, data) => {
        //return date in format "2023-05-16T13:17:09.844Z",
        // get the highest order number in the slides
        let order = 0;
        (quizUnit.unitDetail.slides ?? []).forEach((s) => {
            if ((s.slideDetail.order ?? []) > order) {
                order = s.slideDetail.order ?? 0;
            }
        });
        order++;
        // If slide has bannerImage, upload it
        let imageData = null;
        let bannerUrl = null;
        if (data.image) {
            imageData = await useDocumentStore.getState().uploadDocument({
                file: data.image,
                type: 'image',
                title: data.image.name,
                format: data.image.type,
                ownerId: ""
            });
            bannerUrl = generateImageUrl(imageData!);
        }
        try {
            // prepare the title for the api
            let newSlide = {
                "slideDetail": {
                    "color": "",
                    "code": order,
                    "order": order,
                    "companyName": "FunniFin",
                    "description": data.description,
                    "bannerId": bannerUrl,
                    "bannerImage": imageData,
                    "fontIcon": "",
                    "iconId": "",
                    "percentageCompletedCorrectly": 0,
                    "title": formatI18nObject('title', data),
                    "companyId": get().eduQuizCompanyId,
                    "unitId": quizUnit.unitId
                },
            };
            // call the api
            const slideResult = await ApiService.education.postSlide([newSlide]);
            // if the result is not null
            if (slideResult !== null) {
                if (!quizUnit.unitDetail.slides) quizUnit.unitDetail.slides = [];
                // add the slide to the unit
                quizUnit.unitDetail.slides.push(slideResult);
                quizUnit.unitDetail.slideIds.push(slideResult.slideId);
                // update unit with api
                companyIdAlert(get().eduQuizCompanyId);
                let unitResult = await ApiService.education.putUnit(quizUnit);
                if (unitResult === null) throw new Error('ADD SLIDE ERROR, unit not saved');
                quizUnit = unitResult.data;
                // Update the arguments in the store
                set({ argumentList: [...get().argumentList ?? []] });
                return slideResult;
            }
        } catch (error) {
            console.log('ADD SLIDE ERROR', error);
            throw new Error('ADD SLIDE ERROR');
        }

    },
    deleteUnit: async (quizUnit, quizModule) => {
        try {
            await ApiService.education.deleteUnit(quizUnit.unitId, quizModule);
            // remove the unit from the module
            quizModule.moduleDetail.units = (quizModule.moduleDetail.units ?? []).filter((u) => u.unitId !== quizUnit.unitId);
            quizModule.moduleDetail.unitIds = quizModule.moduleDetail.unitIds.filter((u) => u !== quizUnit.unitId);
            set({ argumentList: cloneDeep(get().argumentList), selectedArgument: cloneDeep(get().selectedArgument) });
        } catch (error) {
            console.log('DELETE UNIT ERROR', error);
            throw error;
        }
    },
    deleteSlide: async (quizSlide, quizUnit) => {
        try {
            await ApiService.education.deleteSlide(quizSlide.slideId, quizUnit);
            // remove the slide from the unit
            quizUnit.unitDetail.slides = (quizUnit.unitDetail.slides ?? []).filter((s) => s.slideId !== quizSlide.slideId);
            quizUnit.unitDetail.slideIds = quizUnit.unitDetail.slideIds.filter((s) => s !== quizSlide.slideId);
            set({ argumentList: cloneDeep(get().argumentList), selectedArgument: cloneDeep(get().selectedArgument) });
        } catch (e) {
            console.log('DELETE SLIDE ERROR', e);
            throw e;
        }
    },
    deleteArgument: async (argument) => {
        try {
            await ApiService.education.deleteArgument(argument.argumentId);
            // remove the argument from the list
            set({ argumentList: cloneDeep((get().argumentList ?? []).filter((a) => a.argumentId !== argument.argumentId)) });
        } catch (e) {
            console.log('DELETE ARGUMENT ERROR', e);
            throw e;
        }
    },
    deleteModule: async (quizModule, quizArgument) => {
        try {
            await ApiService.education.deleteModule(quizModule.moduleId, quizArgument);
            // remove the module from the argument
            quizArgument.argumentDetail.modules = (quizArgument.argumentDetail.modules ?? []).filter((m) => m.moduleId !== quizModule.moduleId);
            quizArgument.argumentDetail.moduleIds = quizArgument.argumentDetail.moduleIds.filter((m) => m !== quizModule.moduleId);
            set({ argumentList: cloneDeep(get().argumentList), selectedArgument: cloneDeep(get().selectedArgument) });
        } catch (e) {
            console.log('DELETE MODULE ERROR', e);
            throw e;
        }
    },
    copyArgument: async (argumentId, companyId) => {
        try {
            await ApiService.education.copyArgument(argumentId, companyId);
        }
        catch (error) {
            console.log('COPY ARGUMENT ERROR', error);
            throw error;
        }
    }
}));

function companyIdAlert(companyId: string) {
    if (companyId === '') {
        useSystemModalStore.getState().showErrorModal('Company id vuoto. Mike chiede scusa, ricarica la pagina e riprova, dovrebbe rifunzionare. Digli cosa hai fatto per ottenere questo errore. Grazie!');
        throw new Error('companyId is empty');
    }
}