
import React from 'react';
import { useTranslation } from 'react-i18next';
import AuthBlock from 'pages/auth/AuthBlock';
import Flickity from 'react-flickity-component';
import eduquiz from 'assets/images/landing/eduquiz.png';
import experts from 'assets/images/landing/expert.png';
import finai from 'assets/images/landing/finAi.png';
import riscatta from 'assets/images/landing/riscatta.png';
import video from 'assets/images/landing/video.png';
import { useI18nStore } from 'stores/i18nStore';
import { useIsMobile } from 'stores/appStore';
/* import { useI18nStore } from 'stores/i18nStore';
 *//* import LanguageSelector from 'components/LanguageSelector';
*/
/* 
    Display the landing page

    This page is shown to unauthenticated users at the root of the website
    It is meant to be customized to the client's needs
    Do not modify this file directly. Use it as a template to create a new page in src/pages/landing if you need to.

    The AuthBlock component is used to display the login and register modals, as well as the forgot password modal.
    It should never be removed, moved around or wrapped in other components. Other content can be added above or below it.

    Everything else is customizable. You can add or remove components, change the layout, etc.

    Translations are to be taken from "landing" namespace. Example:
        {t('landing:title_1')} will display the translation for "title_1" in the landing namespace
    
    To create a login button, use the LOGIN_BUTTON constant as props for the button. Example:
        <button type="button" className="btn btn-lg btn-primary" {...LOGIN_BUTTON}>Login</button>
    Do not add other bootstrap data properties to the button, they are already included in LOGIN_BUTTON and will conflict.

*/
function LandingPage() {
    const { i18n, t } = useTranslation();
    /*     const setQuizLanguage = useI18nStore((state) => state.setLanguage);
     */
    const setLanguage = (language: any) => {
        i18n.changeLanguage(language);
        /*       setQuizLanguage(language.substring(0, 2)); */
    };
    const slides = [
        {
            id: 1,
            title: {
                it: "FinAi - AI che ti supporta",
                en: "FinAi - AI that supports you",
            },
            description: {
                it: "Il tuo assistente virtuale per tutte le questioni finanziarie aziendali e non solo!",
                en: "Your virtual assistant for all corporate financial matters and more!",
            },
            image: finai,
        },
        {
            id: 2,
            title: {
                it: "Impara - Quiz interattivi",
                en: "Learn - Interactive Quizzes",
            },
            description: {
                it: "Migliora le tue competenze su busta paga, TFR, Dichiarazione dei redditi, investimento, etc…grazie alla gamification.",
                en: "Improve your skills on payroll, severance pay, tax returns, investment, etc... thanks to gamification.",
            },
            image: eduquiz,
        },
        {
            id: 3,
            title: {
                it: "Videocorsi - Formazione continua",
                en: "VideoCourses - Continuous Training",
            },
            description: {
                it: "Approfondisci tematiche economico-finanziarie con videocorsi semplici e veloci.",
                en: "Deepen economic and financial topics with simple and quick video courses.",
            },
            image: video,
        },
        {
            id: 4,
            title: {
                it: "Esperti - Supporto personalizzato",
                en: "Experts - Personalized Support",
            },
            description: {
                it: "Sfrutta l’occasione di risolvere i tuoi dubbi grazie a degli esperti di settore.",
                en: "Take the opportunity to resolve your doubts with the help of industry experts.",
            },
            image: experts,
        },

        {
            id: 5,
            title: {
                it: "Ricompense - Riscatta i tuoi progressi",
                en: "Rewards - Redeem Your Progress",
            },
            description: {
                it: "Scambia i Fins ottenuti con sconti esclusivi nella sezione ricompense.",
                en: "Exchange the Fins earned for exclusive discounts in the rewards section.",
            },
            image: riscatta,
        }
    ];
    const getTranslation = useI18nStore((state) => state.getTranslation);
    const isMobile = useIsMobile();
    return (
        <main className='bg-body'

            style={{
                flex: "1 0 auto",
            }}>
            <style>
                {`
            body, #root {
                display: flex;
                flex-direction: column;
            }
                `
                }
            </style>
            <div className="d-md-flex position-relative h-100">
                <div className="justify-content-center align-items-center bg-light d-none d-md-flex p-5 flex-grow-1
                " style={{
                        height: "calc(100% - 20rem) !important",
                    }}>
                    {/* Cover image */}
                    <div className="justify-content-center align-items-center pb-5" style={{
                        width: "80%",
                        height: "100%",
                    }}>
                        <Flickity
                            key="flickity-1"
                            className='w-100 h-100'
                            options={{
                                cellAlign: 'center',
                                pageDots: true,
                                prevNextButtons: false,
                                adaptiveHeight: true,
                                setGallerySize: false,
                                autoPlay: false,
                                pauseAutoPlayOnHover: false,
                                wrapAround: true,
                            }}
                        >
                            {
                                slides.map((slide) => (
                                    <div key={slide.id} className="d-flex flex-column h-100 w-100 justify-content-center me-3">
                                        <div className="flex-fill mx-auto w-100" style={{
                                            backgroundImage: `url(${slide.image})`,
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            maxWidth: '700px',
                                            maxHeight: '512px'
                                        }}></div>
                                        <div className='flex-shrink-0 w-100 text-center p-2 p-xl-5 mx-auto' style={{
                                            alignSelf: 'flex-end',
                                            maxWidth: '512px'
                                        }}>
                                            <h2 className="mt-4">{getTranslation(slide.title)}</h2>
                                            <p className="mt-3">{getTranslation(slide.description)}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </Flickity>

                        {/* <FunnifinLogo onClick={() => { }} width={"15rem"} /> */}
                    </div>
                </div>

                {/* Sign in form */}
                <div className={"d-flex flex-column align-items-center h-100 px-3 px-lg-5 pt-5 "}
                    style={{
                        maxWidth: isMobile ? "100%" : "515px",
                        minWidth: isMobile ? "100%" : "515px",
                    }}
                >
                    <div className='ms-auto d-flex align-items-center'>
                        <div className="d-flex pe-2 me-2 mb-4 mb-md-0" >
                            <span className={(i18n.language.includes('it') ? "fw-bolder" : "") + " px-1 cursor-pointer"} onClick={() => setLanguage('it-IT')}>IT</span>
                            /
                            <span className={(i18n.language.includes("en") ? "fw-bolder" : "") + " px-1 cursor-pointer"} onClick={() => setLanguage('en-GB')}>EN</span>
                        </div >
                        {/*                                 {Config.partnerLogo && <img src={Config.partnerLogo} className="img-fluid w-100 ms-3 object-fit-contain mb-4" alt="fin logo" style={{ "maxWidth": "256px" }} />}
 */}                            </div>
                    <div className="w-100 my-lg-auto my-5 d-flex flex-column p-5 text-center text-md-start mb-5" style={{ maxWidth: '526px' }}>
                        <AuthBlock useModal={false} />

                        {/*                         <form className="needs-validation" noValidate>
                            <div className="pb-3 mb-3">
                                <div className="position-relative">
                                    <i className="ai-mail fs-lg position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                                    <input className="form-control form-control-lg ps-5" type="email" placeholder="Email address" required />
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="position-relative">
                                    <i className="ai-lock-closed fs-lg position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                                    <div className="password-toggle">
                                        <input className="form-control form-control-lg ps-5" type="password" placeholder="Password" required />
                                        <label className="password-toggle-btn" aria-label="Show/hide password">
                                            <input className="password-toggle-check" type="checkbox" /><span className="password-toggle-indicator"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-between pb-4">
                                <div className="form-check my-1">
                                    <input className="form-check-input" type="checkbox" id="keep-signedin" />
                                    <label className="form-check-label ms-1" htmlFor="keep-signedin">Keep me signed in</label>
                                </div>
                                <a className="fs-sm fw-semibold text-decoration-none my-1" href="account-password-recovery.html">Forgot password?</a>
                            </div>
                            <button className="btn btn-lg btn-primary w-100 mb-4" type="submit">Sign in</button>
                        </form> */}
                    </div>
                </div>


            </div>

        </main>
    )
}

export default LandingPage;