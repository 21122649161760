import { Company, CompanyDetail } from "models/companyModel";
import { toast } from "react-hot-toast";
import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useI18nStore } from "./i18nStore";
import { useUsersStore } from "./usersStore";

let isInitCompanyRunning = false;

export interface CompanyUserCreationData { name: string, email: string, surname: string };
export interface CompanyManagerStore {
    companyList: Company[] | null;
    defaultCompany: Company | null;
    init: () => Promise<Company[] | null>;
    createNewCompany: (companyDetail: CompanyDetail, users: CompanyUserCreationData[], admins: CompanyUserCreationData[]) => Promise<CompanyDetail[] | null>;
    activateCompany: (company: Company) => Promise<Company[] | null>;
    deactivateCompany: (company: Company) => Promise<Company[] | null>;
    deleteCompany: (company: Company) => Promise<Company[] | null>;
    editCompany: (company: Company, companyDetail: CompanyDetail) => Promise<CompanyDetail | undefined>;
}

export const useCompanyManagerStore = create<CompanyManagerStore>()((set, _, __) => ({
    companyList: null,
    defaultCompany: null,
    init: async () => {

        if (isInitCompanyRunning) {
            return;
        }
        try {
            isInitCompanyRunning = true;
            let companies = await ApiService.company.searchCompany();
            if (companies) {
                set({ companyList: companies, defaultCompany: companies.find((company: Company) => company.companyDetail.isDefault) });
                return companies;
            } else {
                alert('Companies unavailable. Create a new company.');
                return null;
            }
        } catch (e) {
        } finally {
            isInitCompanyRunning = false;
        }
    },
    createNewCompany: async (companyDetail: CompanyDetail, users: CompanyUserCreationData[], admins: CompanyUserCreationData[]) => {
        try {
            companyDetail.expirationDate = undefined;
            let newCompany = await ApiService.company.postCompany(companyDetail);
            if (newCompany) {
                // create the users
                let usersResultToSend = users.map((user) => {
                    return {
                        companyId: newCompany.companyId,
                        email: user.email,
                        name: user.name,
                        surname: user.surname
                    };
                });
                await useUsersStore.getState().createUsers(usersResultToSend);
                let adminsResultToSend = admins.map((admin) => {
                    return {
                        companyId: newCompany.companyId,
                        email: admin.email,
                        name: admin.name,
                        surname: admin.surname
                    };
                });
                await useUsersStore.getState().createAdmins(adminsResultToSend);
                //clone quiz data
                ApiService.education.duplicateAllArguments(newCompany.companyId).then(
                    (response) => {
                        toast.success(useI18nStore.getState().getTranslation('company:arguments_cloned'));
                    }
                );
                // add it to the list
                set({ companyList: [...(useCompanyManagerStore.getState().companyList ?? []), newCompany] });
                return newCompany;
            } else {
                alert('Error creating company')
            }
        } catch (e) {
            console.log('Error creating company', e);
        }
    },
    activateCompany: async (company: Company) => {
        try {
            let companyResult = await ApiService.company.putCompany({ ...company, companyDetail: { ...company.companyDetail, isActive: true } });
            if (companyResult) {
                let companies = await ApiService.company.searchCompany();
                if (companies) {
                    set({ companyList: companies });
                    return companies;
                }
            }
        } catch (e) {
            console.log('Error activating company', e);
        }
    },
    deactivateCompany: async (company: Company) => {
        try {
            let companyResult = await ApiService.company.putCompany({ ...company, companyDetail: { ...company.companyDetail, isActive: false } });
            if (companyResult) {
                let companies = await ApiService.company.searchCompany();
                if (companies) {
                    set({ companyList: companies });
                    return companies;
                }
            }
        } catch (e) {
            console.log('Error deactivating company', e);
        }
    },
    deleteCompany: async (company: Company) => {
        try {
            await ApiService.company.deleteCompany(company);

            // delete all arguments
            /*                 ApiService.education.getArguments(company.companyId).then(
                                (argumentList) => {
                                    argumentList.forEach(async (argument: QuizArgument) => {
                                        await ApiService.education.deleteArgument(argument.argumentId);
                                    });
                                }
                            ); */
            let companies = await ApiService.company.searchCompany();
            if (companies) {
                set({ companyList: companies });
                return companies;
            }

        } catch (e) {
            console.log('Error deleting company', e);
        }
    },
    editCompany: async (company: Company, companyDetail: CompanyDetail) => {
        try {
            companyDetail.expirationDate = (companyDetail.expirationDate === "" ?? null) ? (company.companyDetail.expirationDate ?? undefined) : companyDetail.expirationDate;
            let companyResult = await ApiService.company.putCompany({ ...company, companyDetail });
            if (companyResult) {
                let companies = await ApiService.company.searchCompany();
                if (companies) {
                    set({ companyList: companies });
                    return { ...company, companyDetail }.companyDetail;
                }
            }
        } catch (e) {
            console.log('Error editing company', e);
            throw new Error('Company not found');
        }
    }
}));