import { langs } from 'assets/langs';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useI18nStore } from 'stores/i18nStore';
import { getFlagEmoji } from 'utils/functions';

function LanguageSelector() {
    const setLanguage = useI18nStore((state) => state.setLanguage);
    const quizLanguages = useI18nStore((state) => state.quizLanguages ?? []);
    const currentLanguage = useI18nStore((state) => state.currentLanguage);
    const currentLanguageName = langs.find(lang => lang.code === currentLanguage)?.name ?? 'English';
    const getLanguageName = (language) => langs.find(lang => lang.code === language)?.name ?? language;
    return (< div className="LanguageSelector dropdown" >
        <button className="btn btn-light bg-transparent border-0 dropdown-toggle d-flex align-items-center" type="button" id="dropdownMenuButtonLanguage" data-bs-toggle="dropdown" aria-expanded="false">
            {getFlagEmoji(currentLanguage)}&nbsp;
        </button>
        <ul className="dropdown-menu border-1 bg-body" aria-labelledby="dropdownMenuButtonLanguage">
            {quizLanguages.map((language) => <li key={language}><button className="dropdown-item small d-flex align-items-center" onClick={() => setLanguage(language)}>
                {getFlagEmoji(language)}&nbsp;&nbsp;{getLanguageName(language)}
            </button>
            </li>)}
        </ul>
    </div >
    );
}
export function LanguageSelectorAlt() {
    const { t, i18n } = useTranslation();
    const setLanguage = useI18nStore((state) => state.setLanguage);
    const quizLanguages = useI18nStore((state) => state.quizLanguages ?? []);
    return (<div className="d-flex" >
        <span className={(i18n?.language?.includes('it') ? "fw-bolder" : "") + " px-1 cursor-pointer"} onClick={() => setLanguage('it-IT')}>IT</span>
        /
        <span className={(i18n?.language?.includes("en") ? "fw-bolder" : "") + " px-1 cursor-pointer"} onClick={() => setLanguage('en-GB')}>EN</span>
    </div >
    );
}
export default LanguageSelector