// @ts-nocheck
import { create } from "zustand";
import i18n from "i18n";
import { useCompanyStore } from "./companyStore";
import { useUserStore } from "./userStore";

export interface I18nStore {
    currentLanguage: string | null;
    quizLanguages: string[];
    appLanguages: string[];
    initI18nStore: (languages?: string[]) => Promise<void>;
    getTranslation: (i18nObject: any) => string;
    getTranslationWithLanguage: (i18nObject: any, language: string) => string;
    setLanguage: (language: string) => Promise<void>;
    createQuizLanguage: (languageCode: string) => Promise<void>;
    deleteQuizLanguage: (languageCode: string) => Promise<void>;
}

export const useI18nStore = create<I18nStore>()((set, get) => ({
    currentLanguage: null,
    quizLanguages: ['en', 'it'],
    appLanguages: ['en', 'it'],
    initI18nStore: async (languages = ['en', 'it']) => {
        set({ quizLanguages: languages, appLanguages: i18n.languages });
        try {
            const onLanguageChanged = () => {
                //order quizLanguages, selected should be first
                const quizLanguages = get().quizLanguages;
                const currentLanguage = i18n.language.slice(0, 2).toLowerCase();
                const index = quizLanguages.indexOf(currentLanguage);
                if (index > -1) {
                    quizLanguages.splice(index, 1);
                    quizLanguages.unshift(currentLanguage);
                }
                set({ quizLanguages: quizLanguages, currentLanguage: currentLanguage });
                if (useUserStore.getState().user && useUserStore.getState().user.userProfileDetail.locale !== currentLanguage) {
                    useUserStore.getState().editUserProfileDetail({
                        locale: currentLanguage,
                        /* emailVerified: true */
                    });
                }
            };
            onLanguageChanged();
            i18n.on('languageChanged', onLanguageChanged);
        } catch (e) {
            console.log('ERROR: initI18nStore', e)
            throw e;
        }
    },
    // This function is used to get the translation of a given i18nObject
    getTranslation: (i18nObject) => {
        // if the i18nObject is null, we return a placeholder
        if (!i18nObject) return '';
        // if the current language is null, we return a placeholder
        if (!get().currentLanguage) return '';
        // if the current language is not in the i18nObject, we return the first filled translation
        if (!i18nObject[get().currentLanguage]) {
            // find first filled translation
            for (const key in i18nObject) {
                if (i18nObject[key]) return i18nObject[key];
            }
        } else {
            // if the current language is in the i18nObject, we return the translation
            return i18nObject[get().currentLanguage];
        }
    },
    getTranslationWithLanguage: (i18nObject, language) => {
        // if the i18nObject is null, we return a placeholder
        if (!i18nObject) return '';
        // if the current language is null, we return a placeholder
        if (!language) return '';
        // if the current language is not in the i18nObject, we return a placeholder
        if (!i18nObject[language]) {
            return '';
        } else {
            // if the current language is in the i18nObject, we return the translation
            return i18nObject[language];
        }
    },
    setLanguage: async (language) => {
        try {
            await i18n.changeLanguage(language);
        } catch (e) {
            console.log('ERROR: setLanguage', e)
            throw e;
        }
    },
    createQuizLanguage: async (languageCode) => {
        try {
            languageCode = languageCode.toLowerCase();
            const company = useCompanyStore.getState().companyData;
            if (company.companyDetail.languages.includes(languageCode)) throw new Error('Language already exists');
            company.companyDetail.languages.push(languageCode);
            await useCompanyStore.getState().updateCompany(company);
            set({ quizLanguages: [...company.companyDetail.languages] });
        } catch (e) {
            console.log('ERROR: createLanguage', e)
            throw e;
        }
    },
    deleteQuizLanguage: async (languageCode) => {
        try {
            languageCode = languageCode.toLowerCase();
            const company = useCompanyStore.getState().companyData;
            if (!company.companyDetail.languages.includes(languageCode)) throw new Error('Language does not exist');
            if (get().currentLanguage === languageCode) await useI18nStore.getState().setLanguage(get().quizLanguages.filter((lang) => lang !== languageCode)[0]);
            company.companyDetail.languages = company.companyDetail.languages.filter((language) => language !== languageCode);
            await useCompanyStore.getState().updateCompany(company);
            set({ quizLanguages: company.companyDetail.languages });
        } catch (e) {
            console.log('ERROR: deleteQuizLanguage', e)
            throw e;
        }
    }
}));

