import { ExpertDetail } from "models/expertModel";
import React from "react";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useI18nStore } from "stores/i18nStore";
import { getFlagEmoji } from "utils/functions";
import { useState, useMemo } from "react";

function ExpertEditor(
    { expert, expertImage, setExpertImage, handleExpertChange }
        : {
            expert: ExpertDetail,
            expertImage: File | null,
            setExpertImage: (file: File) => void,
            handleExpertChange: (expert: ExpertDetail) => void,
        }
) {
    const { t } = useTranslation();
    const [activeLanguage, setActiveLanguage] = React.useState("it");
    const languages = useI18nStore((state) => state.quizLanguages);
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);
    const description = useMemo(() => getTranslationWithLanguage(expert.description, activeLanguage), [expert.description, activeLanguage, getTranslationWithLanguage]);
    return (
        <div>
            <Form >
                <FormGroup className="mb-3">
                    <FormLabel htmlFor="expertName">{t('forms:name')}</FormLabel>
                    <FormControl type="text" id="expertName" placeholder={t('forms:name')} name="name"
                        value={expert.name}
                        onChange={(e) => handleExpertChange({ ...expert, name: e.target.value })} />
                </FormGroup>
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        {(languages ?? []).map((language: string) =>
                            <button key={language} className={"nav-link text-secondary d-flex flex-column align-items-center " + (language === activeLanguage ? "active" : "")}
                                onClick={() => setActiveLanguage(language)} type="button">
                                <div>{language.toUpperCase()}
                                </div>
                                <div>
                                    {getFlagEmoji(language)}
                                </div>
                            </button>
                        )}
                    </div>
                </nav>
                <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                    {/* <div className="tab-pane fade active show" id="nav-argument-icon" role="tabpanel" aria-labelledby="nav-argument-icon-tab">
        <h1>{fontIcon}</h1>
        {!showEmojiPicker && <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <i className='fas fa-plus' /> {t('forms:emoji')}
        </button>}
        {showEmojiPicker && <EmojiPicker autoFocusSearch={true} onEmojiClick={onEmojiClicked} />}
    </div> */}
                    {(languages ?? []).map((language: string) =>
                        <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
                            <div className="d-flex flex-column h-100">
                                <FormGroup className="mb-3">
                                    <FormLabel htmlFor="expertTitle">{t('forms:title')}</FormLabel>
                                    <FormControl type="text" id="expertTitle" placeholder={t('forms:title')} name={"title_" + language}
                                        value={getTranslationWithLanguage(expert.title, language)}
                                        onChange={(e) => handleExpertChange({
                                            ...expert,
                                            title: {
                                                ...expert.title,
                                                [language]: e.target.value,
                                            },
                                        })} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <FormLabel htmlFor="expertDescriptionBrief">{t('forms:description_brief')}</FormLabel>
                                    <FormControl as="textarea" id="expertDescriptionBrief" placeholder={t('forms:description_brief')} name={"descriptionBrief_" + language}
                                        value={getTranslationWithLanguage(expert.descriptionBrief, language)}
                                        onChange={(e) => handleExpertChange({
                                            ...expert,
                                            descriptionBrief: {
                                                ...expert.descriptionBrief,
                                                [language]: e.target.value,
                                            },
                                        })} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <FormLabel htmlFor="expertDescription">{t('forms:description')}</FormLabel>
                                    <ReactQuill
                                        theme="snow"
                                        defaultValue={description}
                                        onChange={(value) => {
                                            setTimeout(() => {
                                                handleExpertChange({
                                                    ...expert,
                                                    description: {
                                                        ...expert.description,
                                                        [language]: value,
                                                    },
                                                });
                                            }, 0);
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="">
                                    <FormLabel htmlFor="expertUrl">URL</FormLabel>
                                    <FormControl type="text" id="expertUrl" placeholder={t('forms:url')} name={"url_" + language}
                                        value={getTranslationWithLanguage(expert.url, language)}
                                        onChange={(e) => handleExpertChange({
                                            ...expert,
                                            url: {
                                                ...expert.url,
                                                [language]: e.target.value,
                                            },
                                        })} />
                                </FormGroup>
                            </div>
                        </div>)}
                </div>
                <Form.Group className="mb-3">
                    <Form.Label className="mb-0">{t('experts_manager:category')}</Form.Label>
                    <br></br>
                    <small className="mb-2 d-block text-muted">{t('experts_manager:category_description')}</small>
                    <div>
                        {[
                            "Lettura della busta paga",
                            "Investimento",
                            "TFR",
                            "Detrazioni fiscali",
                            "Dichiarazione dei redditi",
                            "Criptovalute",
                            "Fondo pensione",
                            "Societario",
                            "Genitorialità",
                            "Pianificazione finanziaria"
                        ].map((category) => (
                            <Form.Check
                                key={category}
                                type="checkbox"
                                id={`category-${category}`}
                                label={category}
                                checked={expert.category.includes(category)}
                                onChange={() => {
                                    const newCategories = expert.category.includes(category)
                                        ? expert.category.filter((c) => c !== category)
                                        : [...expert.category, category];
                                    handleExpertChange({
                                        ...expert,
                                        category: newCategories,
                                    });
                                }}
                            />
                        ))}
                    </div>
                </Form.Group>
                <Form.Group>
                    <label className="form-label">{t('experts_manager:expert_picture')}</label>
                    <div id="nav-argument-banner-image" className="d-flex flex-column mb-3">
                        {!(expert.img || expertImage) && <i className="mb-2">{t('forms:messages.no_image_selected')}</i>}
                        {((expert.img || expertImage)) && <img src={expertImage != null ? URL.createObjectURL(expertImage) : (expert.img ?? "")} className="img-fluid object-fit-contain border rounded mb-3" alt="Slide " />}

                        <input onChange={(event) => {
                            const files = event.target.files;
                            if (files != null && files[0].type.startsWith('image/')) {
                                setExpertImage(files[0]);
                            } else {
                                // Handle invalid file type
                            }
                        }} className="form-control" type="file" id="formFile" accept="image/*" />

                        {((expert.img || expertImage) /* && !deleteBannerImage */) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {

                        }} value={expert.img ?? ""}>
                            <i className='fas fa-trash' /> {t('common:delete')} Logo
                        </button>}
                    </div>
                </Form.Group>
            </Form>
        </div>
    )
}
export default ExpertEditor