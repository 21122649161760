import { create } from "./createStore";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import cloneDeep from "clone-deep";
import { useTranslation } from "react-i18next";
import i18n from 'i18next'; // Assicurati di avere configurato i18next nel tuo progetto

import en from 'locales/en.json';
import it from 'locales/it.json';
import { AmplitudeService } from "services/amplitudeService";
import Config from "utils/config";

let translations: {
    [key: string]: any
} = {
    'en': en,
    'it': it
};

export interface Tutorial {
    content: (tutorial: any) => JSX.Element;
    selector?: string;
    highlightedSelectors?: string[];
    stepInteraction?: boolean;
}

const TutorialButtons = ({ tutorial, showClose = true, showNext = true }: { tutorial: any, showClose?: boolean, showNext?: boolean }) => {
    const { t } = useTranslation();
    return <div className="d-flex justify-content-between">
        {showClose && <button className="btn btn-secondary" onClick={() => { tutorial.close(); }}>{t('common:close')}</button>}
        {showNext && <button className="ms-auto btn btn-primary" onClick={() => { tutorial.goTo(tutorial.step) }}>{t('common:continue')}</button>}
    </div>;
}
const TutorialSetter = ({ tutorial }: { tutorial: any }) => {
    const setCurrentTutorial = useTutorialStore(state => state.setCurrentTutorial);
    useEffect(() => {
        setCurrentTutorial(tutorial);
    }, [setCurrentTutorial, tutorial]);
    return null;
}

export interface TutorialStoreState {
    tutorialsCompleted: { [key: string]: boolean };
    tutorialsReady: boolean;
    requestedTutorial: string | null;
    currentTutorial: any;
    tutorials: { [key: string]: Tutorial[] };
    initTutorials: () => Promise<void>;
    onTutorialCompleted: (tutorialName: string) => Promise<void>;
    shouldShowTutorial: (tutorialName: string) => boolean;
    requestTutorial: (tutorialName: string) => Promise<void>;
    setCurrentTutorial: (tutorial: Tutorial) => Promise<void>;
    nextStep: () => Promise<void>;
}

export const useTutorialStore = create<TutorialStoreState>()((set, get, __) => ({
    tutorialsCompleted: {},
    tutorialsReady: false,
    requestedTutorial: null,
    currentTutorial: null,
    tutorials: {
        finAi: [
            {
                content: (tutorial) => (
                    <div>
                        <h3>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['FinAiTitle']}
                        </h3>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['FinAiText']}
                        </p>
                        <TutorialButtons tutorial={tutorial} showNext={false} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
        ],
        mainPage: [
            {
                content: (tutorial) => (
                    <div>
                        <h3>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['Welcome']}
                        </h3>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['Discover']}
                        </p>
                        <TutorialButtons tutorial={tutorial} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                selector: ".EduQuizMainPage",
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['Access']}
                        </p>
                        <TutorialButtons tutorial={tutorial} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                stepInteraction: true,
                selector: ".MainPageModuleTab",
                content: (tutorial) => {
                    return (
                        <RouteChangingStep tutorial={tutorial} condition={(pathname: string) => pathname.includes('/module')}>
                            <span>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['SelectModule']}
                            </span>
                        </RouteChangingStep>)
                }
            },
        ],
        modulePage: [
            {
                stepInteraction: true,
                selector: ".ModulePageUnitCard",
                content: (tutorial) => {
                    return (
                        <RouteChangingStep tutorial={tutorial} condition={(pathname: string) => pathname.includes('/unit')}>
                            <p>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['ClickUnit']}
                            </p>
                        </RouteChangingStep>)
                }
            }
        ],
        playlists: [
            {
                /* selector: ".ModulePageUnitCard", */
                content: (tutorial) => {
                    return (
                        <div>
                            <h3>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['PlaylistsTitle']}
                            </h3>
                            <p>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['Playlists']}
                            </p>
                            <TutorialButtons tutorial={tutorial} />
                            <TutorialSetter tutorial={tutorial} />
                        </div>)
                },
            },
            {
                selector: ".PlaylistsPageNav",
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['ArgumentFilter']}
                        </p>
                        <TutorialButtons tutorial={tutorial} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                selector: ".PlaylistsPageNav",
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['ArgumentFilter']}
                        </p>
                        <TutorialButtons tutorial={tutorial} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                selector: ".PlaylistPageCard",
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['PlaylistCard']}
                        </p>
                        <TutorialButtons tutorial={tutorial} showNext={false} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            }
        ],
        experts: [
            {
                /* selector: ".ModulePageUnitCard", */
                content: (tutorial) => {
                    return (
                        <div>
                            <h3>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['ExpertsTitle']}
                            </h3>
                            <p>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['ExpertsSubtitle']}
                            </p>
                            <TutorialButtons tutorial={tutorial} />
                            <TutorialSetter tutorial={tutorial} />
                        </div>)
                },
            },
            {
                selector: ".ExpertsPageNav",
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['ArgumentFilter']}
                        </p>
                        <TutorialButtons tutorial={tutorial} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                selector: ".ExpertCard",
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['ExpertsCard']}
                        </p>
                        <TutorialButtons tutorial={tutorial} showNext={false} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            }
        ],
        learnView: [
            {
                /* selector: ".ModulePageUnitCard", */
                content: (tutorial) => {
                    return (
                        <div>
                            <h3>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['StartLesson']}
                            </h3>
                            <p>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['ReadSlides']}
                            </p>
                            <TutorialButtons tutorial={tutorial} />
                            <TutorialSetter tutorial={tutorial} />
                        </div>)
                },
            },
            {
                selector: ".SlideControls",
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['UseButtons']}
                        </p>
                        <TutorialButtons tutorial={tutorial} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                selector: ".EduquizSlideCard",
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['PressSides']}
                        </p>
                        <TutorialButtons tutorial={tutorial} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                content: (tutorial) => (
                    <div>
                        <h3>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['AllForNow']}
                        </h3>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['GoodLesson']}
                        </p>
                        <TutorialButtons tutorial={tutorial} showNext={false} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
        ],
        "gameView": [
            {
                content: (tutorial) => (
                    <div>
                        <h3>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['TimeToTest']}
                        </h3>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['AnswerQuestions']}
                        </p>
                        <TutorialButtons tutorial={tutorial} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                selector: ".LifepointCounter",
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['SeeLives']}
                        </p>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['NewLives']}
                        </p>
                        <TutorialButtons tutorial={tutorial} showClose={false} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                selector: ".GemCounter",
                highlightedSelectors: ['.XpCounter'],
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['GetFins']}
                        </p>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['RedeemFins']}
                        </p>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['AccumulateXP']}
                        </p>
                        <TutorialButtons tutorial={tutorial} showClose={false} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                selector: ".slider-wrapper",
                content: (tutorial) => (
                    <div>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['ClickQuestion']}
                        </p>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['AnswerAll']}
                        </p>
                        <TutorialButtons tutorial={tutorial} showNext={false} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            }
        ],
        progressionPage: [
            {
                content: (tutorial) => (
                    <div>
                        <h3>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['ProgressReport']}
                        </h3>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['SeeProgress']}
                        </p>
                        <TutorialButtons tutorial={tutorial} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                selector: ".GoalList",
                content: (tutorial) => {
                    return (
                        <div>
                            <h5>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['ExperiencePath']}
                            </h5>
                            <p>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['AccumulateXP']}
                            </p>
                            <TutorialButtons tutorial={tutorial} />
                            <TutorialSetter tutorial={tutorial} />
                        </div>)
                }
            },
            {
                selector: ".CompletedModuleCards",
                content: (tutorial) => {
                    return (
                        <div>
                            <p>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['SeeCompletedModules']}
                            </p>
                            <TutorialButtons tutorial={tutorial} />
                            <TutorialSetter tutorial={tutorial} />
                        </div>)
                }
            },
            {
                selector: ".AchievementCards",
                content: (tutorial) => {
                    return (
                        <div>
                            <p>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['CompleteGoals']}
                            </p>
                            <TutorialButtons tutorial={tutorial} showNext={false} />
                            <TutorialSetter tutorial={tutorial} />
                        </div>)
                }
            }],
        shop: [
            {
                content: (tutorial) => (
                    <div>
                        <h3>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['ShopOpen']}
                        </h3>
                        <p>
                            {translations[i18n.language.slice(0, 2)]['tutorial']['ChooseDiscounts']}
                        </p>
                        <TutorialButtons tutorial={tutorial} />
                        <TutorialSetter tutorial={tutorial} />
                    </div>)
            },
            {
                selector: ".ShopItemCard",
                content: (tutorial) => {
                    return (
                        <div>
                            <p>
                                {translations[i18n.language.slice(0, 2)]['tutorial']['ClickDiscount']}
                            </p>
                            <TutorialButtons tutorial={tutorial} showNext={false} />
                            <TutorialSetter tutorial={tutorial} />
                        </div>)
                }
            }
        ]
    },
    initTutorials: async () => {
        if (!Config.enabledFeatures.tutorials) {
            set({ tutorialsReady: true });
            return;
        }
        if (!get().tutorialsReady) {
            const tutostring = localStorage.getItem('tutorialsCompleted');
            //fetch tutorials completed JSON from cookies
            if (tutostring) {
                let tutorialsCompleted = JSON.parse(tutostring);
                set({
                    tutorialsCompleted: tutorialsCompleted,
                    tutorialsReady: true,
                });
            } else {
                set({
                    tutorialsCompleted: {
                        'mainPage': false,
                        'shop': false,
                        'modulePage': false,
                        'gameView': false,
                        'progPage': false,
                        'learnView': false,
                        'experts': false,
                        'playlists': false,
                        'finAi': false,
                    },
                    tutorialsReady: true,
                });
                localStorage.setItem('tutorialsCompleted', JSON.stringify({
                    'mainPage': false,
                    'shop': false,
                    'modulePage': false,
                    'gameView': false,
                    'progPage': false,
                    'learnView': false,
                    'playlists': false,
                    'experts': false,
                    'finAi': false,
                }));
            }
        }
    },
    onTutorialCompleted: async (tutorialName) => {
        let tutorialsCompleted = get().tutorialsCompleted;
        tutorialsCompleted[tutorialName] = true;
        //check if tutorial was finished    
        if (get().tutorials[tutorialName].length === get().currentTutorial.step) {
            AmplitudeService.logEvent('Tutorial Finished', { tutorial: tutorialName });
        } else {
            AmplitudeService.logEvent('Tutorial Skipped', {
                tutorial: tutorialName, step: get().currentTutorial.step
            });
        }
        localStorage.setItem('tutorialsCompleted', JSON.stringify(tutorialsCompleted));
        set({ tutorialsCompleted: cloneDeep(tutorialsCompleted), requestedTutorial: null });
    },
    shouldShowTutorial: (tutorialName) => {
        if (!get().tutorialsReady || !Config.enabledFeatures.tutorials) return false;
        let tutorialsCompleted = get().tutorialsCompleted;
        if (tutorialsCompleted[tutorialName] != null) {
            return !tutorialsCompleted[tutorialName];
        } else {
            //check if tutorial is one the existing keys
            if (['mainPage', 'shop', 'modulePage', 'gameView', 'progPage', 'learnView', 'experts', 'playlists', 'finAi'].includes(tutorialName)) {

                return true;
            }
            else {
                return false;
            }
        }
    },
    requestTutorial: async (tutorialName) => {
        if (!Config.enabledFeatures.tutorials) return;
        if (get().requestedTutorial !== null) {
            await get().onTutorialCompleted(get().requestedTutorial!);
        }
        if (!get().requestedTutorial && get().shouldShowTutorial(tutorialName)) {
            AmplitudeService.logEvent('Tutorial presented', { tutorial: tutorialName });
            set({ requestedTutorial: tutorialName, currentTutorial: tutorialName });
        }
    },
    setCurrentTutorial: async (tutorial) => {
        set({ currentTutorial: tutorial });
    },
    nextStep: async () => {
        let tutorial = get().currentTutorial;
        if (tutorial) {
            tutorial.goTo(tutorial.step);
        }
    },
}));

function RouteChangingStep({ tutorial, condition, ...props }: { tutorial: any, condition: (pathname: string) => boolean, children: any }) {
    //check if location changes using useEffect
    const location = useLocation();
    const onTutorialCompleted = useTutorialStore(state => state.onTutorialCompleted);
    React.useEffect(() => {
        if (condition(location.pathname)) {
            tutorial.close();
        }
    }, [condition, location, onTutorialCompleted, tutorial]);
    return (
        <div>
            {props.children}
            <TutorialSetter tutorial={tutorial} />
        </div>)
}