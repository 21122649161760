import AnimatedText from 'components/AnimatedText';
import CircleButton from 'components/CircleButton';
import FinLogo from 'components/FinLogo';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import React from 'react'
import { useChatMessengerStore } from 'stores/chatMessengerStore';
import { useChatStore } from 'stores/chatStore';
import { useSystemModalStore } from 'stores/systemModalStore';

function ChatAILoaderMessage() {
    const chat = useChatMessengerStore(state => state.activeChat);
    return (
        <div className="ChatMessageElement d-flex py-2 w-100 align-items-center" >
            <div
                className="ChatMessageElement__avatar rounded-circle bg-light flex-shrink-0 text-primary d-flex justify-content-center align-items-center me-2 p-2"
                style={{ width: '36px', height: '36px' }}
            >
                <FinLogo />
            </div>
            <div className={`ChatMessageElement__message pe-3 rounded d-flex align-items-start  p-3 flex-column bg-light me-2 text-primary`}
                style={{ maxWidth: "60%" }}
            >
                <div className="d-flex align-items-center">
                    &nbsp;
                    <i className="fa-duotone  fa-spinner-third fa-spin fa-1x me-2 "></i>
                    <motion.div
                        initial={{ opacity: 0, display: 'none' }}
                        animate={[
                            "initialWarning",
                            "normalWarning"
                        ]}
                        variants={{
                            initialWarning: { opacity: 1, scale: 1.3 },
                            normalWarning: { opacity: 1, scale: 1, display: 'flex' }
                        }}
                        transition={{ delay: 60 }}
                        className="align-items-center"
                    >
                        <CircleButton iconClassName="fas fa-warning" variant="transparent" iconVariant="body-tertiary" className="me-auto"
                            onClick={() => {
                                useSystemModalStore.getState().showConfirmModal('chat:ai_warning',
                                    <>
                                        {t('chat:ai_warning_text')}
                                    </>,
                                    'fa-exclamation-triangle').then((result) => {
                                        if (result) {
                                            useChatStore.getState().restoreChat(chat!);
                                        }
                                    });
                            }}
                        ></CircleButton>
                    </motion.div>
                    <AnimatedText delay={0.5}
                        i18nPrefix="chat:load_messages."
                        strings={[
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                        ]} />
                </div>
            </div>
        </div>
    )
}

export function ChatAdminLoaderMessage() {
    return <div className="ChatMessageElement d-flex py-2 w-100" >
        <div className={`ChatMessageElement__message pe-3 rounded d-flex align-items-start p-3 flex-column bg-secondary-subtle ms-auto text-primary`}
            style={{ maxWidth: "60%" }}
        >
            <i className="fa-duotone  fa-spinner-third fa-spin fa-1x"></i>
        </div>
        <div
            className="ChatMessageElement__avatar rounded-circle bg-light flex-shrink-0 text-primary d-flex justify-content-center align-items-center ms-2 p-2"
            style={{ width: '36px', height: '36px' }}
        >
            <i className="fa-duotone fa-user text-secondary"></i>
        </div>
    </div>
};

export function ChatUserLoaderMessage() {
    return <div className="ChatMessageElement d-flex py-2 w-100" >
        <div className={`ChatMessageElement__message pe-3 rounded d-flex align-items-start p-3 flex-column bg-primary-subtle ms-auto text-primary`}
            style={{ maxWidth: "60%" }}
        >
            <i className="fa-duotone  fa-spinner-third fa-spin fa-1x"></i>
        </div>
        <div
            className="ChatMessageElement__avatar rounded-circle bg-primary-subtle  text-primary d-flex justify-content-center align-items-center ms-2"
            style={{ width: '36px', height: '36px' }}
        >
            <i className="fa-duotone fa-user"></i>
        </div>
    </div>;
}

export default ChatAILoaderMessage