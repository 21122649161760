import React from "react"
import { useTranslation } from "react-i18next";

function PageTitle({ title, viewAll, onViewAll, children }: { title: string, viewAll?: boolean, onViewAll?: () => void, children?: React.ReactNode }) {
    const { t } = useTranslation();
    return (
        <div className="mb-4 d-flex d-md-none justify-content-between align-items-center">
            <h1 className="mb-0">
                {title}
            </h1>
            {viewAll && <div className="text-danger cursor-pointer" onClick={onViewAll}>
                <small>
                    {t('common:view_all')}
                </small>
                <i className="fa fa-angle-right ms-1"></i>
            </div>}
            {
                children
            }
        </div>
    )
}
export default PageTitle