import toast from "react-hot-toast";
import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useCompanyStore } from "./companyStore";
import { useEduquizManagerStore } from "./eduquizManagerStore";
import { QuizQuestion, QuizUnit } from "models/eduquizModels";
import { t } from "i18next";

export interface QuizConfiguratorStore {
    quizUnit: QuizUnit | null;
    originalQuizUnit: QuizUnit | null;
    selectedQuestionIndex: number;
    isUnitModified: () => boolean;
    isQuestionModified: (question: QuizQuestion) => boolean;
    getSelectedQuestion: () => QuizQuestion | null;
    getUnit: (unitId: string) => Promise<any>;
    save: () => Promise<void>;
    addQuestion: () => void;
    changeSelectedQuestionIndex: (newIndex: number) => void;
    isAnswerCorrect: (answerId: string) => boolean;
    setAnswerAsCorrect: (answerId: string) => void;
    setAnswerAsWrong: (answerId: string) => void;
    editQuestionTitle: (newTitle: string, lang: string) => void;
    editQuestionSuggestion: (newSuggestion: string, lang: string) => void;
    editAnswerTitle: (answerId: string, newTitle: string, lang: string) => void;
    addAnswer: () => void;
    deleteQuestion: (questionToDelete: QuizQuestion) => void;
}

export const useQuizConfiguratorStore = create<QuizConfiguratorStore>()((set, get, __) => ({
    quizUnit: null,
    originalQuizUnit: null,
    selectedQuestionIndex: 0,
    isUnitModified: () => {
        let isModified = JSON.stringify(get().quizUnit) !== JSON.stringify(get().originalQuizUnit);
        return isModified;
    },
    isQuestionModified: (question) => {
        return (question.isEdited || (question.questionDetail.answers ?? []).some((answer) => answer.isEdited || answer.isNew)) && !question.isNew;
    },
    getSelectedQuestion: () => {
        return get()?.quizUnit?.unitDetail?.questions?.[get().selectedQuestionIndex ?? 0] ?? null;
    },
    getUnit: async (unitId) => {
        try {
            const quizUnit = await ApiService.education.getUnit(unitId);
            if (quizUnit) {
                // If no questions, create one
                if (!quizUnit.unitDetail.questions || quizUnit.unitDetail.questions.length === 0) {
                    quizUnit.unitDetail.questions = [];
                    quizUnit.unitDetail.questions.push({
                        questionId: 'NEW-0',
                        questionDetail: {
                            companyId: quizUnit.unitDetail.companyId!,
                            unitId: quizUnit.unitId,
                            creationDate: new Date().toISOString(),
                            dateLastUpdate: new Date().toISOString(),
                            gemPrize: 10,
                            experiencePoints: 50,
                            title: {
                                en: 'Insert text',
                                it: 'Insert text',
                            },
                            description: {
                                en: 'Insert text',
                                it: 'Insert text',
                            },
                            suggestionText: {
                                en: 'Insert text',
                                it: 'Insert text',
                            },
                            answerIds: ['NEW-0', 'NEW-1'],
                            answers: [
                                {
                                    answerId: 'NEW-0',
                                    answerDetail: {
                                        questionId: 'NEW-0',
                                        companyId: quizUnit.unitDetail.companyId!,
                                        creationDate: new Date().toISOString(),
                                        dateLastUpdate: new Date().toISOString(),
                                        title: {
                                            en: 'Insert text',
                                            it: 'Inserire testo',
                                        },
                                        description: {
                                            en: '',
                                            it: '',
                                        },
                                        order: 0,
                                        code: 0,
                                    },
                                    isNew: true,
                                },
                                {
                                    answerId: 'NEW-1',
                                    answerDetail: {
                                        companyId: quizUnit.unitDetail.companyId!,
                                        questionId: 'NEW-0',
                                        creationDate: new Date().toISOString(),
                                        dateLastUpdate: new Date().toISOString(),
                                        title: {
                                            en: 'Insert text',
                                            it: 'Inserire testo',
                                        },
                                        description: {
                                            en: '',
                                            it: '',
                                        },
                                        order: 1,
                                        code: 1,
                                    },
                                    isNew: true,
                                },
                            ],
                            correctAnswerIds: ["NEW-0"],
                        },
                        isNew: true,
                    });
                }
                set({ quizUnit, selectedQuestionIndex: 0, originalQuizUnit: JSON.parse(JSON.stringify(quizUnit)) });
                return quizUnit;
            }
            return null;
        } catch {
            console.log("Error getting unit from API service in useQuizConfiguratorStore getUnit() function call.");
            return null;
        }
    },
    save: async function () {
        toast.loading(t('common:saving'));
        const _quizUnit = get().quizUnit;
        if (!_quizUnit) throw new Error('No quiz unit to save');
        for (let i = 0; i < (_quizUnit.unitDetail.questions ?? []).length; i++) {
            const question = (_quizUnit.unitDetail.questions ?? [])[i];
            if (question.isNew) {
                // Post a new question and get the new questionId
                question.questionDetail.unitId = _quizUnit.unitId;
                // temp store answers 
                let tempAnswers = [...question.questionDetail.answers ?? []];
                let newQuestion = await ApiService.education.postQuestion([question]);
                // restore answer
                question.questionDetail.answers = tempAnswers;
                question.questionId = newQuestion.questionId;
                delete question.isNew;
                let newAnswerIds = [];
                // get an array of the indexes of correct answers
                const correctAnswers = question.questionDetail.answers.filter((answer) => question.questionDetail.correctAnswerIds.indexOf(answer.answerId) !== -1);
                let correctAnswerIndexes = correctAnswers.map((answer) => (question.questionDetail.answers ?? []).findIndex((a) => a.answerId === answer.answerId));
                // Post the new anwers
                let newAnswers = await ApiService.education.postAnswers(question.questionDetail.answers.map((answer) => {
                    delete answer.isNew;
                    answer.answerDetail.questionId = newQuestion.questionId;
                    return answer;
                }));
                newAnswerIds = newAnswers.map((answer) => answer.answerId);
                // Update the question with the new answerIds
                question.questionDetail.answerIds = newAnswerIds;
                // also add the correct question id to the question, based on the index
                question.questionDetail.correctAnswerIds = correctAnswerIndexes.map((answerIndex) => question.questionDetail.answerIds[answerIndex]);
                // temp store answers 
                tempAnswers = [...question.questionDetail.answers];
                // Put the new question
                const editResponse = await ApiService.education.putQuestion(question);
                // restore answers
                question.questionDetail.answers = tempAnswers;
                // if newQuestion is null, toast error
                if (!editResponse) {
                    toast.error('Error saving question');
                    return;
                }
                question.questionDetail.answers = newAnswers;
                _quizUnit.unitDetail.questionIds = [..._quizUnit.unitDetail.questionIds, newQuestion.questionId];
                await ApiService.education.putUnit(_quizUnit);
            } else if (question.isEdited) {
                let newAnswerIds = [] as string[];
                // get an array of the indexes of correct answers
                const correctAnswers = question.questionDetail.answers!.filter((answer) => question.questionDetail.correctAnswerIds.indexOf(answer.answerId) !== -1);
                let correctAnswerIndexes = correctAnswers.map((answer) => question.questionDetail.answers!.findIndex((a) => a.answerId === answer.answerId));
                question.questionDetail.answers!.forEach(async (answer) => {
                    if (answer.isNew) {
                        // Post a new answer
                        answer.answerDetail.questionId = question.questionId;
                        let newAnswer = await ApiService.education.postAnswer(answer);
                        answer = newAnswer;
                        delete answer.isNew;
                        newAnswerIds.push(newAnswer.answerId);
                    } else if (answer.isEdited) {
                        // Put a new version of the answer
                        delete answer.isEdited;
                        await ApiService.education.putAnswer(answer);
                    }
                });
                // remove "NEW-*" ids from the answerIds
                question.questionDetail.answerIds = question.questionDetail.answerIds.filter((answerId) => !answerId.startsWith('NEW-'));
                // add the new answerIds
                question.questionDetail.answerIds = [...question.questionDetail.answerIds, ...newAnswerIds];
                // also add the correct question id to the question, based on the index
                question.questionDetail.correctAnswerIds = correctAnswerIndexes.map((answerIndex) => question.questionDetail.answerIds[answerIndex]);
                delete question.isEdited;
                // Put a new version of the question
                await ApiService.education.putQuestion(question);
            } else {
                // If the question is not new or edited, just check the answers
                question.questionDetail.answers!.forEach(async (answer) => {
                    if (answer.isEdited) {
                        delete answer.isEdited;
                        // Put a new version of the answer
                        await ApiService.education.putAnswer(answer);
                    }
                });
            }
        }
        set({ quizUnit: { ..._quizUnit }, originalQuizUnit: JSON.parse(JSON.stringify(_quizUnit)) });
        useEduquizManagerStore.getState().initEduquiz(useEduquizManagerStore.getState().eduQuizCompanyId);
        toast.dismiss();
        toast.success('Saved!');
    },
    addQuestion: function () {
        const _quizUnit = get().quizUnit;
        if (!_quizUnit) throw new Error('No quiz unit to add question to');
        // get highest order of questions
        let highestOrder = (_quizUnit.unitDetail.questions ?? []).reduce((acc, question) => {
            if (question.questionDetail.order ?? 0 > acc) {
                return question.questionDetail.order || 0;
            }

            return acc;
        }, 0);
        highestOrder++;
        // add a new question
        (_quizUnit.unitDetail.questions ?? []).push({
            questionId: 'NEW-' + highestOrder,
            questionDetail: {
                gemPrize: 10,
                unitId: _quizUnit.unitId,
                experiencePoints: 50,
                order: highestOrder,
                creationDate: new Date().toISOString(),
                dateLastUpdate: new Date().toISOString(),
                code: highestOrder,
                companyId: _quizUnit.unitDetail.companyId!,
                title: {
                    en: 'Insert text',
                    it: 'Insert text',
                },
                description: {
                    en: 'Insert text',
                    it: 'Insert text',
                },
                suggestionText: {
                    en: 'Insert text',
                    it: 'Insert text',
                },
                answerIds: ['NEW-0', 'NEW-1'],
                answers: [
                    {
                        answerId: 'NEW-0',
                        answerDetail: {
                            questionId: 'NEW-' + highestOrder,
                            title: {
                                en: 'Insert text',
                                it: 'Insert text',
                            },
                            description: {
                                en: '',
                                it: '',
                            },
                            order: 0,
                            code: 0,
                            creationDate: new Date().toISOString(),
                            dateLastUpdate: new Date().toISOString(),
                            companyId: get().getSelectedQuestion()?.questionDetail.companyId ?? '',
                        },
                        isNew: true,
                    },
                    {
                        answerId: 'NEW-1',
                        answerDetail: {
                            companyId: get().getSelectedQuestion()?.questionDetail.companyId ?? '',
                            questionId: 'NEW-' + highestOrder,
                            title: {
                                en: 'Insert text',
                                it: 'Insert text',
                            },
                            description: {
                                en: '',
                                it: '',
                            },
                            order: 1,
                            code: 1,
                            creationDate: new Date().toISOString(),
                            dateLastUpdate: new Date().toISOString(),
                        },
                        isNew: true,
                    },
                ],
                correctAnswerIds: ["NEW-0"],
            },
            isNew: true,
        });
        set({ quizUnit: { ..._quizUnit }, selectedQuestionIndex: (_quizUnit.unitDetail.questions ?? []).length - 1 });
    },

    changeSelectedQuestionIndex: (newIndex) => {
        set({ selectedQuestionIndex: newIndex });
    },
    //UTILS
    isAnswerCorrect: (answerId) => {
        if (!get().getSelectedQuestion()) throw new Error('No question selected');
        return get().getSelectedQuestion()!.questionDetail.correctAnswerIds?.indexOf(answerId) !== -1 ?? false;
    },
    setAnswerAsCorrect: (answerId) => {
        let _question = get().getSelectedQuestion();
        if (!_question) throw new Error('No question selected');
        // add the question to _question.questionDetail.correctAnswerIds if not already present
        if (_question.questionDetail.correctAnswerIds.indexOf(answerId) === -1) {
            _question.questionDetail.correctAnswerIds.push(answerId);
        }
        _question.isEdited = true;
        if (!get().quizUnit) throw new Error('No quiz unit');
        set({ quizUnit: { ...get().quizUnit! } });
    },
    setAnswerAsWrong: (answerId) => {
        let _question = get().getSelectedQuestion();
        if (!_question) throw new Error('No question selected');
        // remove the question from _question.questionDetail.correctAnswerIds if present
        if (_question.questionDetail.correctAnswerIds.indexOf(answerId) !== -1) {
            _question.questionDetail.correctAnswerIds = _question.questionDetail.correctAnswerIds.filter((id) => id !== answerId);
        }
        _question.isEdited = true;
        if (!get().quizUnit) throw new Error('No quiz unit');
        set({ quizUnit: { ...get().quizUnit! } });
    },
    editQuestionTitle: async (newTitle, lang) => {
        let _question = get().getSelectedQuestion();
        if (!_question) throw new Error('No question selected');
        _question.questionDetail.title[lang] = newTitle;
        _question.isEdited = true;
        if (!get().quizUnit) throw new Error('No quiz unit');
        set({ quizUnit: { ...get().quizUnit! } });
    },
    editQuestionSuggestion: async (newSuggestion, lang) => {
        let _question = get().getSelectedQuestion();
        if (!_question) throw new Error('No question selected');
        _question.questionDetail.suggestionText[lang] = newSuggestion;
        _question.isEdited = true;
        if (!get().quizUnit) throw new Error('No quiz unit');
        set({ quizUnit: { ...get().quizUnit! } });
    },
    editAnswerTitle: async (answerId, newTitle, lang) => {
        let _question = get().getSelectedQuestion();
        if (!_question) throw new Error('No question selected');
        let _answer = (_question.questionDetail.answers ?? []).find((answer) => answer.answerId === answerId);
        if (!_answer) throw new Error('No answer found');
        _answer.answerDetail.title[lang] = newTitle;
        _answer.isEdited = true;
        if (!get().quizUnit) throw new Error('No quiz unit');
        set({ quizUnit: { ...get().quizUnit! } });
    },
    addAnswer: async () => {
        let _question = get().getSelectedQuestion();
        if (!_question) throw new Error('No question selected');
        _question.isEdited = true;
        // Get the highest order number
        let highestOrder = 0;
        (_question.questionDetail.answers ?? []).forEach((answer) => {
            if ((answer.answerDetail.order ?? 0) > highestOrder) {
                highestOrder = answer.answerDetail.order ?? 0;
            }
        });
        highestOrder++;
        (_question.questionDetail.answers ?? []).push({
            answerId: 'NEW-' + (_question.questionDetail.answers ?? []).length,
            answerDetail: {
                companyId: _question.questionDetail.companyId ?? '',
                order: highestOrder,
                code: highestOrder,
                creationDate: new Date().toISOString(),
                dateLastUpdate: new Date().toISOString(),
                title: {
                    en: 'Insert text',
                    it: 'Inserire testo',
                },
                description: {
                    en: "",
                    it: ""
                },
                questionId: _question.questionId
            },
            isNew: true,
        });
        _question.isEdited = true;
        if (!get().quizUnit) throw new Error('No quiz unit');
        set({ quizUnit: { ...get().quizUnit! } });
    },
    deleteQuestion: async (questionToDelete) => {
        // delete question from quizUnit
        let _quizUnit = get().quizUnit;
        if (!_quizUnit) throw new Error('No quiz unit');

        if (!questionToDelete.questionId.includes('NEW-')) {
            await ApiService.education.deleteQuestion(questionToDelete, _quizUnit);
        }

        _quizUnit.unitDetail.questions = (_quizUnit?.unitDetail.questions ?? []).filter((question) => question.questionId !== questionToDelete.questionId);
        _quizUnit.unitDetail.questionIds = _quizUnit?.unitDetail.questionIds.filter((id) => id !== questionToDelete.questionId) ?? [];

        // if questions are now empty, add a new one
        if (_quizUnit.unitDetail.questions.length === 0) {
            get().addQuestion();
        }
        // select the first question
        set({ quizUnit: { ..._quizUnit }, selectedQuestionIndex: 0 });
    }
}));
