import React, { useEffect, useRef } from 'react';

function ExpandingInput(props) {
    const defaultValue = props.defaultValue ?? "";
    const handleChange = (event) => {
        event.target.style.height = 'auto';
        event.target.style.height = `${event.target.scrollHeight}px`;
        if (props.onChange) {
            props.onChange(event);
        }
    };
    const ref = useRef(null)
    useEffect(() => {
        ref.current.style.height = `${ref.current.scrollHeight}px`;
        // if for some reason the height is 0, try again in 10ms after the DOM has updated
        if (ref.current.style.height === '0px') {
            setTimeout(() => {
                try {
                    ref.current.style.height = `${ref.current.scrollHeight}px`;
                    //console.log('ExpandingInput: useEffect -DELAYED SET HEIGHT', ref.current.style.height, ' name', props.name);
                    // if still 0px, calculate the height manually
                    if (ref.current.style.height === '0px') {
                        const lineHeight = parseInt(window.getComputedStyle(ref.current).lineHeight);
                        // get approximate width of container based on percentage from window width
                        const width = window.document.body.clientWidth * 0.5;
                        const charecthersPerRow = width / (lineHeight * 0.6);
                        const rows = Math.ceil(defaultValue.length / charecthersPerRow);
                        const height = rows * lineHeight;
                        //console.log(height, lineHeight, width, charecthersPerRow, rows);
                        ref.current.style.height = `${height}px`;
                        //console.log('ExpandingInput: useEffect -DELAYED SET HEIGHT -MANUAL', ref.current.style.height, ' name', props.name);
                    }
                }
                catch (e) {
                    console.error('ExpandingInput: useEffect -DELAYED SET HEIGHT -ERROR', e, ' name', props.name);
                }
            }, 50);
        }
    });
    return (
        <textarea
            ref={ref}
            rows={1}
            className={`form-control ${props.className ? ' ' + props.className : ''}`}
            defaultValue={defaultValue}
            name={props.name}
            onInput={handleChange}
            style={{ ...props.style }}
        />
    );
}

export default ExpandingInput;