import { on } from 'events';
import React from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { CompanyUserCreationData } from 'stores/companyManagerStore';

function CompanyManageUsers({ onNewUser }: { onNewUser: (newUsers: CompanyUserCreationData[]) => void }) {
    const { t } = useTranslation();
    const formRef = React.useRef<HTMLFormElement>(null);
    const [csvUsers, setCsvUsers] = React.useState<CompanyUserCreationData[]>([]);
    const [customUsers, setCustomUsers] = React.useState<CompanyUserCreationData[]>([]);

    const handleCsvChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files != null) {
            //check if the file is a csv
            const reader = new FileReader();
            reader.onload = function (e) {
                const csv = e.target?.result;
                // parse the csv
                const usersFound = (csv as string).split('\n').map((line) => {
                    const [name, surname, email] = line.split(',').map(field => field.trim());
                    return {
                        name,
                        surname,
                        email,
                    };
                });
                setCsvUsers(usersFound);
                onNewUser([...customUsers, ...usersFound]);
            }
            reader.readAsText(files[0]);
        }
    }
    const deleteUser = (email: string) => {
        setCustomUsers(customUsers.filter(user => user.email !== email));
        onNewUser(customUsers.filter(user => user.email !== email));
    }
    return (
        <div>
            <h5>{t('company:importUsers')}</h5>
            <p>
                {t('company:importUsersDescription')}
            </p>
            <input accept=".csv" onChange={handleCsvChange} className="form-control mb-3" type="file" id="formFile" />
            {/*             <Alert variant="info" className="d-flex align-items-center">
                <i className="fas fa-info-circle me-3"></i>
                <div>
                    {t('company:importUsersHint')}
                </div>
            </Alert> */}
            <p>
                {t('company:manuallyAddUser')}
            </p>
            <Form ref={formRef} onSubmit={(e: any) => {
                e.preventDefault();
                // get form data
                const formData = new FormData(e.target);
                formRef.current?.reset();
                setCustomUsers([...customUsers, {
                    name: formData.get('name') as string,
                    surname: formData.get('surname') as string,
                    email: formData.get('email') as string,
                } as CompanyUserCreationData]);
                onNewUser([...customUsers, {
                    name: formData.get('name') as string,
                    surname: formData.get('surname') as string,
                    email: formData.get('email') as string,
                } as CompanyUserCreationData]);
            }}>
                <Form.Group className="mb-3">
                    <Form.Label>{t('forms:name')}</Form.Label>
                    <Form.Control type="text" placeholder={t('forms:name')} required name="name" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{t('forms:surname')}</Form.Label>
                    <Form.Control type="text" placeholder={t('forms:surname')} required name="surname" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{t('forms:email')}</Form.Label>
                    <Form.Control type="email" placeholder={t('forms:email')} required name="email" />
                </Form.Group>
                <Button variant="primary" type="submit">
                    {t('common:add')}
                </Button>
            </Form>
            {
                customUsers.length > 0 && (
                    <table className="table mt-3 table-sm table-borderless">
                        <thead>
                            <tr>
                                <th>{t('forms:name')}</th>
                                <th>{t('forms:surname')}</th>
                                <th>{t('forms:email')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {customUsers.map((admin, index) => (
                                <tr key={index}>
                                    <td>{admin.name}</td>
                                    <td>{admin.surname}</td>
                                    <td>{admin.email}</td>
                                    <td onClick={() => deleteUser(admin.email)}><i className="fas fa-trash cursor-pointer hoverable"></i></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            }
        </div>
    )
}

export default CompanyManageUsers