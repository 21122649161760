import { AmplitudeService } from "services/amplitudeService";
import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useGemsStore } from "./gemsStore";
import { ShopItem } from "models/shopModels";
import { AxiosError } from "axios";

interface ShopStore {
    shopItems: ShopItem[] | null;
    initShop: () => Promise<any>;
    buyShopItem: (shopItemId: string) => Promise<boolean>;
    checkShopItemDates: (shopItem: any) => boolean;
}

export const useShopStore = create<ShopStore>()((set, get, __) => ({
    shopItems: null,
    initShop: async () => {
        try {
            let shopItems = await ApiService.shop.getShopItems();
            if (shopItems) {
                set({ shopItems: shopItems });
                return shopItems;
            } else {
                set({ shopItems: [] });
                return null;
            }
        } catch (e) {
            console.error(e);
            return null;
        }
    },
    buyShopItem: async (shopItemId) => {
        try {
            // get shopItem
            let shopItem = get().shopItems?.find((shopItem) => shopItem.shopItemId === shopItemId);
            if (!shopItem) {
                throw new Error('no_item');
            }
            if (shopItem.shopItemDetail.gems > (await useGemsStore.getState().getUserGems()).gemDetail.currentGems) {
                throw new Error('no_gems');
            }
            if (shopItem.shopItemDetail.userShopItem.userShopItemDetail.status === 1) {
                throw new Error('already_bought');
            }
            shopItem.shopItemDetail.userShopItem.userShopItemDetail.status = 1;
            let boughtShopItem = await ApiService.shop.putShopItem(shopItem);
            if (boughtShopItem.status === 200) {
                AmplitudeService.logEvent('Shop Item Bought', { shopItemId: shopItemId, gems: shopItem.shopItemDetail.gems, shopItemName: shopItem.shopItemDetail.title['it'] });
                return true;
            }
            return false;
        } catch (e) {
            console.log('Error buying shop item', e);
            if ((e as AxiosError).response?.status === 409) {
                throw new Error('no_stock');
            }
            console.error(e);
            throw e;
        }
    },
    checkShopItemDates: (shopItem) => {
        const today = new Date();
        const startDate = new Date(shopItem.shopItemDetail.shopItemStartDate);
        const endDate = new Date(shopItem.shopItemDetail.shopItemExpirationDate);
        if (today >= startDate && today <= endDate) {
            return true;
        }
        return false;
    }
}));
