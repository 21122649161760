import { use } from 'i18next'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import InfoButton from './InfoButton'

function SecondaryHeader({ children = null, title = '', backTarget }: { children?: React.ReactNode, title: string, backTarget?: string }) {
    const navigate = useNavigate();
    const location = useLocation();
    const handleNavigateUp = () => {
        if (backTarget) {
            navigate(backTarget);
        } else {
            const pathSegments = location.pathname.split('/').filter(Boolean);
            if (pathSegments.length > 1) {
                const newPath = '/' + pathSegments.slice(0, -1).join('/');
                navigate(newPath);
            } else {
                navigate('/');
            }
        }
    };

    return (
        <div className='d-flex align-items-center mb-3' style={{
            minHeight: "48px",
        }}>
            <i className="fa-duotone fa-times fa-2x ms-1 me-3 cursor-pointer" onClick={() => (backTarget ? navigate(backTarget) : handleNavigateUp())} />
            <h3 className="w-auto mb-0 me-auto">{title}</h3>
            {children}
        </div>
    )
}

export default SecondaryHeader