import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useUserStore } from "./userStore";

//* This is the experiencePointsStore.js file
//* It is used to store the experiencepoints of the user
//* Call the refreshExperiencePoints() function to update the experiencepoints of the user. 
//* Provide a expectedExperiencePoints parameter to set the experiencepoints to a specific value before the API call is made.
//  This is useful when you want to update the experiencepoints of the user before waiting for the API call to complete and the expectedExperiencePoints are known.
export const useExperiencePointsStore = create()((set, get, __) => {
    return {
        currentExperiencePointData: null,
        getUserExperiencePoints: async () => {
            if (get().currentExperiencePointData === null) {
                await get().refreshExperiencePoints();
            } else {
                return get().currentExperiencePointData;
            }
        },
        refreshExperiencePoints: async (expectedExperiencePoints) => {
            /*        try { */
            if (expectedExperiencePoints !== undefined) {
                set({ experiencepoints: expectedExperiencePoints });
            }
            let experiencepointResponse = await ApiService.experiencePoints.getExperiencePointsByUserProfileId(useUserStore.getState().user?.userProfileId);
            set({ currentExperiencePointData: experiencepointResponse });
            return get().currentExperiencePointData;
        },
    };
});