import { formatI18nObject, generateImageUrl } from "utils/functions";
import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useDocumentStore } from "./documentStore";
import { useUserStore } from "./userStore";
import { ShopItem, UserShopItem } from "models/shopModels";

export interface ShopManagerStore {
    shopItems: ShopItem[] | null;
    userShopItems: UserShopItem[] | null;
    isInitShopManagerRunning: boolean;
    initShopManager: () => Promise<any>;
    editShopItem: (shopItem: any, data: any) => Promise<any>;
    createShopItem: (data: any) => Promise<void>;
    deleteShopItem: (shopItem: any) => Promise<void>;
    requestUserShopItems: () => Promise<any>;
}

export const useShopManagerStore = create<ShopManagerStore>()((set, get, api) => ({
    shopItems: null,
    userShopItems: null,
    isInitShopManagerRunning: false,
    initShopManager: async () => {
        if (get().isInitShopManagerRunning) {
            return;
        }
        set({ isInitShopManagerRunning: true });
        try {
            let shopItems = await ApiService.shop.getShopItems();
            if (shopItems) {
                // order shop items by shopitemCreationDate
                shopItems.sort((a: ShopItem, b: ShopItem) => {
                    return new Date(b.shopItemDetail.shopItemCreationDate).getTime() - new Date(a.shopItemDetail.shopItemCreationDate).getTime();
                });
                set({ shopItems: shopItems });
                return shopItems;
            } else {
                set({ shopItems: [] });
                return null;
            }
        } catch (e) {
            console.log('Error initializing shop manager', e);
            return null;
        } finally {
            set({ isInitShopManagerRunning: false });
        }
    },
    editShopItem: async (shopItem, data) => {
        let imageData = null;
        let logoId;
        if (data.image) {
            imageData = await useDocumentStore.getState().uploadDocument({
                file: data.image,
                type: 'image',
                title: data.image.name,
                format: data.image.type,
                ownerId: useUserStore.getState().user?.userProfileId ?? ""
            });
            logoId = generateImageUrl(imageData as any);
        }
        let bannerImageData = null;
        let bannerImageId;
        if (data.bannerImage) {
            bannerImageData = await useDocumentStore.getState().uploadDocument({
                file: data.bannerImage,
                type: 'image',
                title: data.bannerImage.name,
                format: data.bannerImage.type,
                ownerId: useUserStore.getState().user?.userProfileId ?? ""
            });
            bannerImageId = generateImageUrl(bannerImageData as any);
        }
        try {
            // update shop item
            shopItem.shopItemDetail = {
                ...shopItem.shopItemDetail,
                description: {
                    ...shopItem.shopItemDetail.description,
                    ...formatI18nObject('description', data)
                },
                descriptionBrief: {
                    ...shopItem.shopItemDetail.descriptionBrief,
                    ...formatI18nObject('descriptionBrief', data)
                },
                howTo: {
                    ...shopItem.shopItemDetail.howTo,
                    ...formatI18nObject('howTo', data)
                },
                title: {
                    ...shopItem.shopItemDetail.title,
                    ...formatI18nObject('title', data)
                },
                termsAndConditions: {
                    ...shopItem.shopItemDetail.termsAndConditions,
                    ...formatI18nObject('termsAndConditions', data)
                },
                company: data.company,
                gems: data.gems,
                counter: data.counter,
                shopItemExpirationDate: data.shopItemExpirationDate,
                shopItemStartDate: data.shopItemStartDate,
                logoId: logoId ? logoId : shopItem.shopItemDetail.logoId,
                bannerId: bannerImageId ? bannerImageId : shopItem.shopItemDetail.bannerId,
                banner: bannerImageData ? bannerImageData : shopItem.shopItemDetail.banner,
                logo: imageData ? imageData : shopItem.shopItemDetail.logo,
            }
            let updatedShopItemResult = await ApiService.shop.putShopItem(shopItem);
            if (updatedShopItemResult !== null) {
                // update shop items
                set({ shopItems: [...(get().shopItems ?? [])] });
                return updatedShopItemResult;
            } else {
                return null;
            }
        } catch (e) {
            console.error(e)
            return null;
        }
    },
    createShopItem: async (data) => {
        let imageData = null;
        let logoId;
        if (data.image) {
            imageData = await useDocumentStore.getState().uploadDocument({
                file: data.image,
                type: 'image',
                title: data.image.name,
                format: data.image.type,
                ownerId: useUserStore.getState().user?.userProfileId ?? ""
            });
            logoId = generateImageUrl(imageData as any);
        }
        let bannerImageData = null;
        let bannerImageId;
        if (data.bannerImage) {
            bannerImageData = await useDocumentStore.getState().uploadDocument({
                file: data.bannerImage,
                type: 'image',
                title: data.bannerImage.name,
                format: data.bannerImage.type,
                ownerId: useUserStore.getState().user?.userProfileId ?? ""
            });
            bannerImageId = generateImageUrl(bannerImageData as any);
        }
        let newShopItem = {
            shopItemDetail: {
                description: formatI18nObject('description', data),
                descriptionBrief: formatI18nObject('descriptionBrief', data),
                howTo: formatI18nObject('howTo', data),
                title: formatI18nObject('title', data),
                termsAndConditions: formatI18nObject('termsAndConditions', data),
                company: data.company,
                gems: data.gems,
                counter: data.counter,
                logoId: logoId,
                bannerId: bannerImageId,
                shopItemExpirationDate: data.shopItemExpirationDate,
                shopItemStartDate: data.shopItemStartDate,
                banner: bannerImageData,
                logo: imageData,
            },
        };
        const newItem = await ApiService.shop.postShopItem(newShopItem);
        set({ shopItems: [newItem, ...(get().shopItems ?? [])] });
    },
    deleteShopItem: async (shopItem) => {
        try {
            await ApiService.shop.deleteShopItem(shopItem);
        }
        catch (e) {
            console.log('Error deleting shop item', e);
        }
        finally {
            set({ shopItems: [...(get().shopItems ?? []).filter(item => item.shopItemId !== shopItem.shopItemId)] });
        }
    },
    requestUserShopItems: async () => {
        try {
            let userShopItems = await ApiService.shop.getUserShopItems(null);
            set({ userShopItems: userShopItems });
            return userShopItems;
        }
        catch (e) {
            console.log('Error requesting user shop items', e);
            return null;
        }
    }

}));
