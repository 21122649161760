import React from 'react';
// React DOM is used to render the root component
// React Router is used to define the routes for the application
import { Route, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
/* PROGRESS BAR CSS*/
import 'react-circular-progressbar/dist/styles.css';
// BOOTSTRAP CSS IMPORT - should ONLY be used in index.js, never in any other file
import 'bootstrap/dist/css/bootstrap.css';
//flickity css
import 'flickity/css/flickity.css';
// BOOTSTRAP VARIABLES CSS IMPORT - should ONLY be used in index.js, never in any other file
import './bootstrapVariables.css';
// INDEX CSS IMPORT - should ONLY be used in index.js, never in any other file
import './index.css';
// CUSTOM VARIABLES CSS IMPORT - should ONLY be used in index.js, never in any other file
import './customVariables.css';
// other imports
import 'react-tooltip/dist/react-tooltip.css';

import 'react-quill/dist/quill.snow.css';

import EmptyLayout from 'layouts/EmptyLayout';
import Loader from 'components/Loader';
import AnalyticsPage from 'pages/analytics/AnalyticsPage';
import LoginAdmin from 'pages/auth/login_admin/LoginAdminPage';
import EduQuizArgumentPage from 'pages/eduquiz_manager/EduQuizArgumentPage';
import EduQuizConfiguratorPage from 'pages/eduquiz_manager/EduQuizConfiguratorPage';
import EduQuizGamePage from 'pages/eduquiz_simulator/EduQuizGamePage';
import EduQuizMainPage from 'pages/eduquiz_simulator/EduQuizMainPage';
import EduQuizModulePage from 'pages/eduquiz_simulator/EduQuizModulePage';
import ProfilePage from 'pages/profile/ProfilePage';
import ShopManagerPage from 'pages/shop_manager/ShopManagerPage';
import UsersPage from 'pages/users/UsersPage';
import MainLayout from './layouts/MainLayout';
import LandingPage from './pages/landing/LandingPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import EduQuizManagerPage from './pages/eduquiz_manager/EduQuizManagerPage';
import Splash from './pages/splash/Splash';
import { AuthStates, useAuthStore } from './stores/authStore';
// import Swiper styles
import PartnerManagerPage from 'pages/partnerManager/PartnerManagerPage';
import ShopDetail from 'pages/shop/ShopDetail';
import ShopPage from 'pages/shop/ShopPage';
import ShopSuccess from 'pages/shop/ShopSuccess';
import 'swiper/css';

import LanguageManagerPage from 'pages/languages_manager/LanguageManagerPage';
import Config from 'utils/config';
import './i18n';
import SidebarsLayout from 'layouts/SidebarsLayout';
import ProgressionPage from 'pages/progression/ProgressionPage';
import RankingPage from 'pages/ranking/RankingPage';

import SurveyWrapper from 'SurveyWrapper';
import SurveyPage from 'pages/survey/SurveyPage';
import VideoPage from 'pages/videos/VideoPage';
import LearningMaterialsPage from 'pages/learning_materials/LearningMaterialsPage';
import CompletedModulesPage from 'pages/progression/CompletedModulesPage';
import ExpertsPage from 'pages/experts/ExpertsPage';
import ExpertDetail from 'pages/experts/ExpertDetail';
import TopArgumentsPage from 'pages/dashboard/TopArgumentsPage';
import CompaniesManager from 'pages/companies_manager/CompaniesManagerPage';
import CompanyUsersPage from 'pages/companies_manager/CompanyUsersPage';
import ProgressionManagerPage from 'pages/progression_manager/ProgressionManagerPage';
import LogoutPage from 'pages/auth/LogoutPage';
import CompanyEduquizManagerPage from 'pages/companies_manager/CompanyEduquizManagerPage';
import ChatPage from 'pages/chat/ChatPage';
import FullscreenLayout from 'layouts/FullscreenLayout';
import ChatManagerPage from 'pages/chat_manager/ChatManagerPage';
import HRDashboardPage from 'pages/hr_dashboard/HRDashboardPage';
import ExpertsManagerPage from 'pages/experts_manager/ExpertsManagerPage';
import UserDashboardPage from 'pages/user_dashboard/UserDashboardPage';
import VideoManagerPage from 'pages/video_manager/VideoManagerPage';
import PlaylistPage from 'pages/videos/PlaylistPage';
import PlaylistsPage from 'pages/playlists/PlaylistsPage';
import TutorialsWrapper from 'TutorialsWrapper';
import AdminDashboardPage from 'pages/dashboard/AdminDashboardPage';

let shownInstallPrompt = false;

/// The RequireAuth function is used to protect routes that should only be accessible to authenticated users.
/// If the user is not authenticated, the user is redirected to the landing page.
function RequireAuth({ children }: { children: React.ReactNode }) {

    let location = useLocation();
    const authState = useAuthStore((state) => state.authenthicationState);

    if (authState === AuthStates.LoggingOut) {
        console.log('⬅️ Require Auth: Logging out, redirecting to logout page');
        return <Navigate to="/logout" state={{ from: location }
        } replace />;
    }
    if (authState === AuthStates.Unauthenticated || authState === AuthStates.AuthenticationExpired || authState === AuthStates.AuthenticationFailed || authState === AuthStates.Unauthorized) {
        console.log('⬅️ Require Auth: Not Authenticated, redirecting to login');
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }
        } replace />;
    }
    if (authState === AuthStates.Authenticated) {
        return <>{children}</>;
    }
    return <Loader />;
}

function RequireAdmin({ children }: { children: React.ReactNode }) {
    let location = useLocation();
    const authState = useAuthStore((state) => state.authenthicationState);
    if (authState === AuthStates.Authenticated && !useAuthStore.getState().isUser) {
        return <>{children}</>;
    }
    return <Navigate to="/login" state={{ from: location }
    } replace />;
}

const AppRoutes =
    <>
        <Route /* path="/"  element={<Login />} *//* element={<Root />} */>
            {/* UNAUTHENTICATED ROUTES */}
            <Route path="/login" element={<LandingPage />} />
            <Route path="/login-admin" element={<LoginAdmin />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="*" element={<Splash />} />
            {/* ADMIN ROUTES */}
            <Route path="/admin/eduquiz" element={<RequireAuth><RequireAdmin>{getAdminLayout()}</RequireAdmin></RequireAuth>}>

                <Route path="" element={<EduQuizMainPage />} />
                <Route path="module/:moduleId" element={<EduQuizModulePage />} />
                <Route path="module/:moduleId/unit/:unitId" element={<EduQuizGamePage />} />
            </Route>
            <Route path="/admin" element={<RequireAuth><RequireAdmin>{getAdminLayout()}</RequireAdmin></RequireAuth>}>
                <Route path="dashboard" element={<AdminDashboardPage />} />
                {Config.enabledFeatures.quizManagement && <>
                    <Route path="eduquizManager" element={<EduQuizManagerPage companyId={null} />}>  </Route>
                    <Route path="arguments/:argumentId" element={<EduQuizArgumentPage />} />
                    <Route path="quiz/:unitId" element={<EduQuizConfiguratorPage />} />
                    <Route path="topArguments" element={<TopArgumentsPage />} />
                </>}
                <Route path="analytics" element={<AnalyticsPage />} />
                {Config.enabledFeatures.userManagement && <Route path="users" element={<UsersPage />} />}
                {Config.enabledFeatures.shopManagement && <Route path="shopManager" element={<ShopManagerPage />} />}
                {Config.enabledFeatures.languageManagement && <Route path="languageManager" element={<LanguageManagerPage />} />}
                {Config.enabledFeatures.partnerManagement && <Route path="partnerManager" element={<PartnerManagerPage />} />}
                <Route path="profile" element={<ProfilePage />} />
            </Route>
            {/* SYSTEM ROUTES */}
            <Route path="/system" element={<RequireAuth><RequireAdmin>{getSystemLayout()}</RequireAdmin></RequireAuth>}>
                <Route path="*" element={<DashboardPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="analytics" element={<AnalyticsPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="shopManager" element={<ShopManagerPage />} />
                <Route path="company_manager" element={<CompaniesManager />} />
                <Route path="company_manager/:companyId/manage_users" element={<CompanyUsersPage />} />
                <Route path="company_manager/:companyId/manage_quiz" element={<CompanyEduquizManagerPage />} />
                <Route path="progression_manager" element={<ProgressionManagerPage />} />
                <Route path="experts_manager" element={<ExpertsManagerPage />} />
                <Route path="chat_manager" element={<ChatManagerPage />} />
                <Route path="video_manager" element={<VideoManagerPage />} />
            </Route>
            {/* HR ROUTES */}
            <Route path="/hr" element={<RequireAuth><RequireAdmin>{getSystemLayout()}</RequireAdmin></RequireAuth>}>
                <Route path="chat_manager" element={<ChatManagerPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="*" element={<HRDashboardPage />} />
            </Route>
            {/* USER ROUTES */}
            <Route path="/welfare/eduquiz/module" element={<RequireAuth><EmptyLayout></EmptyLayout></RequireAuth>}>
                <Route path=":moduleId/unit/:unitId" element={<EduQuizGamePage />} />
                <Route path=":moduleId" element={<EduQuizModulePage />} />
            </Route>
            <Route path="/survey" element={<RequireAuth>
                <SurveyWrapper>
                    <SurveyPage />
                </SurveyWrapper>
            </RequireAuth>}></Route>
            <Route path="/welfare" element={<RequireAuth><SurveyWrapper><TutorialsWrapper>{getUserLayout()}</TutorialsWrapper></SurveyWrapper></RequireAuth>}>
                <Route path="dashboard" element={<UserDashboardPage />} />
                <Route path="eduquiz" element={<EduQuizMainPage />} />
                <Route path="profile" element={<ProfilePage />} />
                {Config.enabledFeatures.shop && <>
                    <Route path="shop" element={<ShopPage />} />
                </>}
                {Config.enabledFeatures.progression && <>
                    <Route path="progression" element={<ProgressionPage />} />
                </>}
                {Config.enabledFeatures.videos && <>
                    <Route path="videos" element={<VideoPage />} />
                </>}

                <Route path="experts" element={<ExpertsPage />} />
                <Route path="playlists" element={<PlaylistsPage />} />


                {/*         <Route path="*" element={<Navigate to={Config.userHomepagePath} />} /> */}
            </Route>
            <Route path="/welfare" element={<RequireAuth><SurveyWrapper><EmptyLayout /></SurveyWrapper></RequireAuth>}>
                <Route path="experts/:expertId" element={<ExpertDetail />} />

                <Route path="playlist/:playlistId" element={<PlaylistPage />} />
                {Config.enabledFeatures.materials && <>
                    <Route path="materials" element={<LearningMaterialsPage />} />
                </>}
                {Config.enabledFeatures.videos && <>
                    <Route path="playlist/:playlistId" element={<PlaylistPage />} />
                </>}
                {Config.enabledFeatures.progression && <>
                    <Route path="progression/completedModules" element={<CompletedModulesPage />} />
                </>}
                {Config.enabledFeatures.shop && <>
                    <Route path="shop/:shopItemId" element={<ShopDetail />} />
                </>}
                {Config.enabledFeatures.ranking && <Route path="ranking" element={<RankingPage />} />}
            </Route>
            <Route path="/welfare" element={<RequireAuth><FullscreenLayout /></RequireAuth>}>
                <Route path="shop/:shopItemId/success" element={<ShopSuccess />} />
                <Route path="chat" element={<ChatPage />} />
            </Route>

            <Route path="/learn" element={<Navigate to="/welfare" />} />
        </Route>
    </>;

/// The getUserLayout function is used to get the layout for the current user based on the theme in Config
function getUserLayout() {
    switch (Config.appTheme) {
        case "SIDEBARS":
            return <SidebarsLayout />;
        default:
            return <EmptyLayout />;
    }
}

/// The getAdminLayout function is used to get the layout for the current user based on the theme in Config
function getAdminLayout() {
    switch (Config.appTheme) {
        case "SIDEBARS":
            return <SidebarsLayout />;
        default:
            return <MainLayout />;
    }
}

function getSystemLayout() {
    switch (Config.appTheme) {
        case "SIDEBARS":
            return <SidebarsLayout />;
        default:
            return <MainLayout />;
    }
}

export default AppRoutes