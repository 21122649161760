import React from "react"
import { Outlet } from "react-router-dom"

function FullscreenLayout() {
  return (
    <div className="FullscreenLayout bg-primary text-white w-100 h-100 d-flex flex-column overflow-hidden">
        <style>
            {`
            body {
                background-color: var(--bs-primary) !important;
            }
            `}
        </style>
        <Outlet /></div>
  )
}
export default FullscreenLayout