export const markdownGuide = `
# Markdown Guide

Markdown is a lightweight markup language that allows you to format text using simple syntax. It is commonly used for writing documentation, README files, and blog posts.

## Headers

You can create headers by using the '#' symbol followed by a space and the header text. The number of '#' symbols determines the level of the header.
Example:
# Heading 1
## Heading 2
### Heading 3
## Emphasis
You can emphasize text by using asterisks or underscores.
Example:
*Italic text*
_Italic text_
**Bold text**
__Bold text__

## Lists

You can create ordered and unordered lists.
Example:
- Item 1
- Item 2
- Item 3
1. Item 1
2. Item 2
3. Item 3

## Links
You can create links by using square brackets for the link text and parentheses for the URL.
Example:

[GitHub](https://github.com)

## Images

You can display images by using an exclamation mark followed by square brackets for the alt text and parentheses for the image URL.
Example:
![Alt Text](https://example.com/image.jpg)

## Blockquotes

You can create blockquotes by using the greater than symbol.
Example:
> This is a blockquote.

## Horizontal Rule

You can create a horizontal rule by using three or more hyphens, asterisks, or underscores.
Example:
---
## Tables
You can create tables by using pipes and hyphens.
Example:
| Name  | Age |
|-------|-----|
| John  | 25  |
| Sarah | 30  |
`;
export const markdownGuide_IT = `
# Guida a Markdown

Markdown è un linguaggio di markup leggero che consente di formattare il testo utilizzando una sintassi semplice. Ogni messaggio inviato in chat può essere formattato utilizzando Markdown. 
Ecco una guida rapida per iniziare.

## Intestazioni

Puoi creare intestazioni utilizzando il simbolo '#' seguito da uno spazio e dal testo dell'intestazione. Il numero di simboli '#' determina il livello dell'intestazione.
Esempio:

\`\`\`
# Intestazione 1
## Intestazione 2
### Intestazione 3
\`\`\`

# Intestazione 1
## Intestazione 2
### Intestazione 3

## Enfasi

Puoi enfatizzare il testo utilizzando asterischi o trattini bassi.
Esempio:

\`\`\`
*Testo in corsivo*
**Testo in grassetto**
\`\`\`

*Testo in corsivo*
**Testo in grassetto**

## Elenchi

Puoi creare elenchi ordinati e non ordinati.
Esempio:

\`\`\`
- Elemento 1
- Elemento 2
- Elemento 3
\`\`\`

- Elemento 1
- Elemento 2
- Elemento 3

\`\`\`
1. Elemento 1
2. Elemento 2
3. Elemento 3
\`\`\`

1. Elemento 1
2. Elemento 2
3. Elemento 3

## Collegamenti

Puoi creare collegamenti utilizzando parentesi quadre per il testo del collegamento e parentesi tonde per l'URL.
Esempio:

\`\`\`
[Funnifin](https://www.funnifin.com/)
\`\`\`

[Funnifin](https://www.funnifin.com/)


## Tabelle

Puoi creare tabelle utilizzando pipe e trattini.
Esempio:

\`\`\`
| Nome  | Età |
|-------|-----|
| John  | 25  |
| Sarah | 30  |
\`\`\`

| Nome  | Età |
|-------|-----|
| John  | 25  |
| Sarah | 30  |
`;