import CircleButton from 'components/CircleButton';
import Loader from 'components/Loader';
import Mark from 'components/Mark';
import QuizProgressIcon from 'components/QuizProgressIcon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEduquizManagerStore } from 'stores/eduquizManagerStore';
import { useI18nStore } from 'stores/i18nStore';
import { convertColorToHex } from 'utils/functions';
import { EditArgumentModal, EditModuleModal, EditSlideModal, EditUnitModal } from './EduQuizModals';
import toast from 'react-hot-toast';
import { QuizArgument, QuizModule, QuizSlide, QuizUnit } from 'models/eduquizModels';

function EduQuizArgumentPage() {
    let { argumentId } = useParams();
    const initEduquiz = useEduquizManagerStore((state) => state.initEduquiz);
    const argumentList = useEduquizManagerStore((state) => state.argumentList);
    const { t } = useTranslation();
    const navigate = useNavigate();
    let selectedArgument = undefined as QuizArgument | undefined;
    const [showEditArgumentModal, setshowEditArgumentModal] = useState(false);
    if (argumentList !== null) {
        try {
            selectedArgument = argumentList.find(x => x.argumentId === argumentId);
        } catch (e) {
            selectedArgument = argumentList[0];
        }
    }
    useEffect(() => {
        if (!argumentList || !selectedArgument) {
            if (useEduquizManagerStore.getState().eduQuizCompanyId === "") {
                navigate('/system/company_manager');
            }
            useEduquizManagerStore.getState().initEduquiz(useEduquizManagerStore.getState().eduQuizCompanyId);
        }
    }, [selectedArgument, argumentList, initEduquiz, navigate]);
    if (!selectedArgument) return <Loader></Loader>;

    return (
        <section className='EduQuizArgumentPage d-flex pt-3 flex-column'>
            <h4 className='fw-bold mb-3'>{t('eduquiz_manager:title')}</h4>
            <div className='mb-4 '>
                <h5 className='text-secondary cursor-pointer d-block' onClick={() => navigate(-1)}><i className='fal fa-angle-left'></i> {t('eduquiz_manager:all_arguments')} </h5>
                <div className='d-flex align-items-center'>
                    <EduQuizArgumentButton key={selectedArgument.argumentId} quizArgument={selectedArgument} />
                    <Mark>
                        {t('common:argument')}
                    </Mark>
                    <div className='ms-auto'>
                        <CircleButton iconClassName={"fal fa-pencil"} className="fs-5" onClick={() => setshowEditArgumentModal(true)}></CircleButton>
                    </div>
                    <EditArgumentModal quizArgument={selectedArgument} show={showEditArgumentModal} onHide={() => setshowEditArgumentModal(false)} />
                </div>
            </div>
            <div className='d-flex flex-row'>
                <div className='ms-4' style={{
                    position: 'relative',
                    borderBottom: '4px solid var(--bs-white)',
                    borderLeft: '4px solid var(--bs-white)',
                    width: "2rem",
                    height: "3rem",
                    top: "-1rem",
                }} />
                <div className='d-flex flex-row ms-3 flex-fill'>
                    <EduQuizArgumentContent quizArgument={selectedArgument} />
                </div>
            </div>
        </section>
    );

}

/* -------------------------------------------------------------------------- */
/*                             ARGUMENT TAB BUTTON                            */
/* -------------------------------------------------------------------------- */
function EduQuizArgumentButton(props: { quizArgument: QuizArgument }) {
    const quizArgument = props.quizArgument;
    const getTranslation = useI18nStore((state) => state.getTranslation);
    let color = quizArgument.argumentDetail.color;
    if (!color) {
        color = 'red';
    } else {
        color = color.replace('0xFF', '#');
    }

    return (
        <button style={{ 'border': `2px solid ${color}`, color: color, cursor: "default !important" }} className='EduQuizArgumentButton btn bg-white me-3 rounded-pill fw-bold' >
            {getTranslation(quizArgument.argumentDetail.title)}
        </button>
    )
}
/* -------------------------------------------------------------------------- */
/*                                ARGUMENT TAB                                */
/* -------------------------------------------------------------------------- */
function EduQuizArgumentContent(props: { quizArgument: QuizArgument }) {
    const { t } = useTranslation();
    const quizArgument = props.quizArgument;
    const [showModuleModal, setshowModuleModal] = useState(false);
    return (
        <div className='card flex-fill mb-3'>
            <div className='accordion'>
                {(quizArgument.argumentDetail.modules ?? []).map((quizModule) => <EduQuizModuleAccordion key={quizModule.moduleId} quizModule={quizModule} quizArgument={quizArgument} />)}
            </div>
            <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold my-3 mx-3'
                onClick={() => setshowModuleModal(true)}
            >
                <i className='fas fa-plus' /> {t('common:add')} {t('common:module')}
            </button>
            <EditModuleModal quizArgument={quizArgument} isCreating={true} show={showModuleModal} onHide={() => setshowModuleModal(false)} />
        </div>
    )
}
/* -------------------------------------------------------------------------- */
/*                                   MODULE                                   */
/* -------------------------------------------------------------------------- */
// renders an accordion
function EduQuizModuleAccordion(props: { quizModule: QuizModule, quizArgument: QuizArgument }) {
    const quizModule = props.quizModule;
    const quizArgument = props.quizArgument;
    const { t } = useTranslation();
    const getTranslation = useI18nStore((state) => state.getTranslation);
    const deleteModule = useEduquizManagerStore((state) => state.deleteModule);
    const [showEditModuleModal, setshowEditModuleModal] = useState(false);
    const [showEditUnitModal, setshowEditUnitModal] = useState(false);
    const [showCreateUnitModal, setshowCreateUnitModal] = useState(false);
    const handleModuleDelete = (e: any) => {
        e.stopPropagation();
        if (window.confirm(t('eduquiz_manager:delete_module_confirm'))) {
            toast.promise(deleteModule(quizModule, quizArgument), {
                loading: t('common:deleting'),
                success: t('common:deleted'),
                error: t('common:error')
            });
        }
    }
    return (
        <div key={quizModule.moduleId} className='EduQuizModuleAccordion accordion-item border-0 p-0' id={`accordion${quizModule.moduleId}`}>
            <h2 className="accordion-header d-flex align-items-center p-3" id={`heading${quizModule.moduleId}`}>
                <div className="d-flex align-items-center flex-fill" data-bs-toggle="collapse" data-bs-target={`#collapse${quizModule.moduleId}`}>
                    <div className='me-3'>
                        <QuizProgressIcon percentage={100} icon={quizModule.moduleDetail.fontIcon}
                            color={convertColorToHex(quizModule.moduleDetail.color ?? "")} width={4} iconUrl={quizModule.moduleDetail.iconId} />
                    </div>
                    <div className='flex-fill m-0 d-flex align-items-center pe-3'>
                        <h5 className='mb-0 me-3'>
                            {getTranslation(quizModule.moduleDetail.title)}
                        </h5>
                        <Mark>
                            {t('common:module')}
                        </Mark>
                    </div>
                    <div className='ms-auto me-3 d-flex align-items-center' data-bs-toggle="collapse" data-bs-target="">
                        <div data-bs-toggle="dismiss">
                            <CircleButton iconClassName={"fal fa-trash"} className={"me-3 fs-5"} variant="danger" onClick={handleModuleDelete}></CircleButton>
                        </div>
                        <div>
                            <CircleButton iconClassName={"fal fa-pencil"} className={"mx-1 fs-5"} onClick={() => setshowEditModuleModal(true)}></CircleButton>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="accordion-button collapsed pr-3 " type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${quizModule.moduleId}`} aria-expanded="false" aria-controls={`collapse${quizModule.moduleId}`}>
                    </button>
                </div>
            </h2>
            <EditModuleModal quizModule={quizModule} quizArgument={quizArgument} show={showEditModuleModal} onHide={() => setshowEditModuleModal(false)} />
            <EditUnitModal key={'module' + quizModule.moduleId} isCreating={true} quizArgument={quizArgument} quizModule={quizModule} show={showCreateUnitModal} onHide={() => setshowCreateUnitModal(false)} />
            <div id={`collapse${quizModule.moduleId}`} className="accordion-collapse collapse" aria-labelledby={`heading${quizModule.moduleId}`} data-bs-parent={`#accordion${quizModule.moduleId}`}>
                <div className="accordion-body">
                    <div className='d-flex flex-row align-items-center'>
                        <div className='rounded-circle d-flex align-items-center me-3' style={{ width: '64px', height: '64px' }}>
                        </div>

                        <div className='d-flex flex-column flex-fill mb-3'>
                            {(quizModule.moduleDetail.units ?? []).map((quizUnit) => <EduQuizUnitAccordion key={quizUnit.unitId} quizUnit={quizUnit} quizArgument={quizArgument} quizModule={quizModule} />)}
                            <div className="border-4 border rounded p-3 d-flex position-relative">
                                <div className='ms-4' style={{
                                    position: 'absolute',
                                    borderBottom: '4px solid var(--bs-light)',
                                    borderLeft: '4px solid var(--bs-light)',
                                    width: "2rem",
                                    height: "3.1rem",
                                    top: "-0.3rem",
                                    left: "-77.2px"
                                }} />
                                <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100'
                                    onClick={() => setshowCreateUnitModal(true)}
                                >
                                    <i className='fas fa-plus' /> {t('common:add')} {t('common:unit')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
/* -------------------------------------------------------------------------- */
/*                                    UNIT                                    */
/* -------------------------------------------------------------------------- */
function EduQuizUnitAccordion(props: { quizUnit: QuizUnit, quizModule: QuizModule, quizArgument: QuizArgument }) {
    const quizUnit = props.quizUnit;
    const quizModule = props.quizModule;
    const quizArgument = props.quizArgument;
    const navigate = useNavigate();
    const getTranslation = useI18nStore((state) => state.getTranslation);
    const { t } = useTranslation();
    const [showSlideModal, setshowSlideModal] = useState(false);
    const [showUnitModal, setshowUnitModal] = useState(false);
    const deleteUnit = useEduquizManagerStore((state) => state.deleteUnit);
    const doDeleteUnit = () => {
        if (window.confirm(t('eduquiz_manager:delete_unit_confirm'))) {
            toast.promise(deleteUnit(quizUnit, quizModule), {
                loading: <>{t('common:deleting')} {t('common:unit')}</>,
                success: <>{t('common:deleted')}</>,
                error: <>{t('common:error')}</>,
            });
        }
    }
    return (
        <div className='EduQuizUnitAccordion position-relative d-none-last-parent accordion'>
            <div className='ms-4' style={{
                position: 'absolute',
                borderBottom: '4px solid var(--bs-light)',
                borderLeft: '4px solid var(--bs-light)',
                width: "2rem",
                height: "3.1rem",
                top: "-0.1rem",
                left: "-4.6rem"
            }} />
            {<div className='ms-4 d-none-last-child' style={{
                position: 'absolute',
                borderLeft: '4px solid var(--bs-light)',
                width: "1rem",
                height: "100%",
                top: "3rem",
                left: "-4.6rem"
            }} />}
            <EditUnitModal quizModule={quizModule} quizArgument={quizArgument} quizUnit={quizUnit} isCreating={false} show={showUnitModal} onHide={() => setshowUnitModal(false)} />
            <div key={quizUnit.unitId} className='accordion-item border rounded border-4 mb-3' id={`accordion${quizUnit.unitId}`}>
                <div className='ms-4 d-none-last-child' style={{
                    position: 'absolute',
                    borderLeft: '4px solid var(--bs-light)',
                    width: "1rem",
                    height: "100%",
                    top: "3rem",
                    left: "-4.6rem"
                }} />
                <h2 className="accordion-header d-flex align-items-center p-3" id={`heading${quizUnit.unitId}`}>
                    <div className="d-flex align-items-center flex-fill" data-bs-toggle="collapse" data-bs-target={`#collapse${quizUnit.unitId}`}>
                        <div className='me-3'>
                            <QuizProgressIcon percentage={100} icon={quizUnit.unitDetail.fontIcon} iconUrl={quizUnit.unitDetail.iconId} color={convertColorToHex(quizModule.moduleDetail.color ?? "")} width={3} />
                        </div>
                        <div className='flex-fill m-0 d-flex align-items-center'>
                            <h5 className='mb-0 me-3'>
                                {getTranslation(quizUnit.unitDetail.title)}
                            </h5>
                            <Mark>
                                {t('common:unit')}
                            </Mark>
                        </div>
                        <div data-bs-toggle="collapse" data-bs-target="" className='ms-auto me-3 d-flex' >
                            <div data-bs-toggle="dismiss">
                                <CircleButton iconClassName={"fal fa-trash"} variant='danger' className={"me-3 fs-5"} onClick={doDeleteUnit}></CircleButton>
                            </div>
                            <div>
                                <CircleButton iconClassName={"fal fa-pencil text-black'"} className={"mx-1 fs-5"} onClick={() => setshowUnitModal(true)}></CircleButton>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="accordion-button collapsed pr-3" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${quizUnit.unitId}`} aria-expanded="false" aria-controls={`collapse${quizUnit.unitId}`}>
                        </button>
                    </div>
                </h2>
                <div id={`collapse${quizUnit.unitId}`} className="accordion-collapse collapse" aria-labelledby={`heading${quizUnit.unitId}`} data-bs-parent={`#accordion${quizUnit.unitId}`}>
                    <div className="px-3">
                        <div className="d-flex flex-row">
                            <div className='ps-4 ms-2'></div>
                            <div className='d-flex flex-column flex-fill ps-5 pb-3 position relative' style={{
                                borderLeft: '4px solid var(--bs-light)',
                            }}>
                                <div className="bg-light" style={{
                                    position: 'absolute',
                                    left: "56px",
                                    height: "4px",
                                    width: "2.1rem",
                                    top: "9rem"
                                }}></div>
                                <div className="border-4 border rounded p-3 d-flex flex-column">
                                    {(quizUnit.unitDetail.slides ?? []).sort(
                                        (a, b) => (a.slideDetail.order ?? 0) - (b.slideDetail.order ?? 0)
                                    ).map((quizSlide) => <EduQuizSlideTile key={quizSlide.slideId} quizSlide={quizSlide} quizArgument={quizArgument} quizUnit={quizUnit} />)}
                                    <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill mt-3 border-3 fw-bold mb-3' onClick={() => setshowSlideModal(true)} /* data-bs-toggle="modal" data-bs-target={"#editSlideModal" + quizUnit.unitId} */>
                                        <i className='fas fa-plus' /> {t('common:add')} {t('common:slide')}
                                    </button>
                                    {showSlideModal && <EditSlideModal quizArgument={quizArgument} isCreating={true} quizSlide={{}} quizUnit={quizUnit} onHide={() => setshowSlideModal(false)} />}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row position-relative">
                            <div className='ps-4 ms-2'></div>
                            <div className='d-flex flex-column flex-fill ps-5 pb-3' style={{
                                borderLeft: '4px solid var(--bs-light)',
                            }}>
                                <div className='d-flex flex-column-reverse'
                                    style={{
                                        position: 'absolute',
                                        left: "36px",
                                        height: "40px",
                                        width: "2.1rem",
                                        /* bottom: "9rem" */
                                    }}>
                                    <div className="bg-light" style={{ height: "4px", }}></div>
                                </div>
                                {quizUnit.unitDetail.questionIds?.length > 0 && <div className="border-4 border rounded p-3 cursor-pointer" onClick={() => navigate(`/admin/quiz/${quizUnit.unitId}`)}>
                                    <div className='d-flex flex-fill m-0 d-flex align-items-center' >

                                        <span className='fs-5 me-2'>{t('eduquiz_manager:end_unit_quiz')}</span>
                                        <Mark>
                                            {t('common:quiz')}
                                        </Mark>
                                        <div className='ms-auto'>
                                            <CircleButton iconClassName={"fal fa-angle-right "} className={"mx-1"}></CircleButton>
                                        </div>
                                    </div>
                                </div>}
                                {(!quizUnit.unitDetail.questionIds || quizUnit.unitDetail.questionIds.length === 0) && <div className="border-4 border rounded p-3 d-flex">
                                    <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={() => navigate(`/admin/quiz/${quizUnit.unitId}`)} >
                                        <i className='fas fa-plus' /> {t('common:add')} {t('common:quiz')}
                                    </button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
/* -------------------------------------------------------------------------- */
/*                                    SLIDE                                   */
/* -------------------------------------------------------------------------- */
function EduQuizSlideTile({ quizSlide, quizUnit, quizArgument }: { quizSlide: QuizSlide, quizUnit: QuizUnit, quizArgument: QuizArgument }) {
    const { t } = useTranslation();
    const getTranslation = useI18nStore((state) => state.getTranslation);
    const [showSlideModal, setshowSlideModal] = useState(false);
    const deleteSlide = useEduquizManagerStore((state) => state.deleteSlide);
    const doDeleteSlide = () => {
        if (window.confirm('Are you sure you want to delete this slide?')) {
            toast.promise(deleteSlide(quizSlide, quizUnit), {
                loading: <>{t('common:deleting')} {t('common:slide')}</>,
                success: <>{t('common:deleted')}</>,
                error: <>{t('common:error')}</>,
            });
        }
    }
    return (
        <>
            <div className='EduQuizSlideAccordion border-bottom border-1 cursor-pointer' >
                <div className='d-flex flex-row justify-content-between' id={`accordion${quizSlide.slideId}`}/* data-bs-toggle="modal" data-bs-target={`#editSlideModal${quizSlide.slideId}`} */  >
                    <div className='d-flex align-items-center'>
                        {getTranslation(quizSlide.slideDetail.title)}
                        &nbsp;&nbsp;
                        <Mark>
                            {t('common:slide')}
                        </Mark>
                    </div>
                    <div className='d-flex'>
                        <CircleButton iconClassName={"fal fa-pencil"} variant='primary' className={"me-3 my-2"} onClick={() => setshowSlideModal(true)}></CircleButton>
                        <CircleButton iconClassName={"fal fa-trash"} variant='danger' className={"me-3 my-2"} onClick={doDeleteSlide}></CircleButton>
                    </div>
                </div>
            </div>
            {showSlideModal && <EditSlideModal quizArgument={quizArgument} quizSlide={quizSlide} isCreating={false} quizUnit={quizUnit} onHide={() => setshowSlideModal(false)} />}
        </>
    )
}

export default EduQuizArgumentPage