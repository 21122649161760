import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import Loader from "components/Loader";
import { useProgressionStore } from "stores/progressionStore";
import { ProgressionModuleCard } from "./ProgressionPage";
import CircleButton from "components/CircleButton";
import { useNavigate } from "react-router-dom";
import SecondaryHeader from "components/SecondaryHeader";

function CompletedModulesPage() {
    const { t } = useTranslation();
    const completedModules = useProgressionStore(state => state.completedModules);
    const getUserQuizStatistics = useProgressionStore(state => state.getUserQuizStatistics);
    const navigate = useNavigate();
    //useEffect for quizStatistics
    useEffect(() => {
        if (completedModules === null) {
            console.log('🧑‍💼 ProfilePage: useEffect for quizStatistics');
            getUserQuizStatistics();
        }
    }, [completedModules, getUserQuizStatistics]);
    if (!completedModules) return <Loader></Loader>;
    return (
        <section className='d-flex flex-column'>
            <SecondaryHeader title={t('progression:completed_modules')} backTarget="/welfare/profile" />
            <div className='mb-5'>
                <div className="row">
                    {completedModules && completedModules.map((quizModule) => {
                        return <div className="col-12 col-md-4"><ProgressionModuleCard quizModule={quizModule} /></div>;
                    })}
                </div>
            </div>
        </section>
    )
}
export default CompletedModulesPage;