import React from "react";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserProfileDetail } from "models/userModels";

function UserEditor({ user, handleUserChange }: { user: UserProfileDetail, handleUserChange: (user: UserProfileDetail) => void }) {
    const { t } = useTranslation();
    return (
        <div>
            <Form >
                {/* Name */}
                <FormGroup className="mb-3">
                    <FormLabel htmlFor="videoDescription">{t('forms:name')}</FormLabel>
                    <FormControl id="videoDescription" placeholder={t('forms:name')} value={user.name}
                        onChange={(value) => handleUserChange({
                            ...user,
                            name: value.target.value
                        })} />
                </FormGroup>
                {/* Surname */}
                <FormGroup className="mb-3">
                    <FormLabel htmlFor="videoDescription">{t('forms:surname')}</FormLabel>
                    <FormControl id="videoDescription" placeholder={t('forms:surname')} value={user.surname}
                        onChange={(value) => handleUserChange({
                            ...user,
                            surname: value.target.value
                        })} />
                </FormGroup>
            </Form>
        </div>
    )
}
export default UserEditor