import React from 'react';

function AnalyticsPage() {
    //MOCK UP OF ANALYTICS PAGE, display random data 
    function renderMockAnalyticsData() {
        return (
            <div>
                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Total Users</h5>
                                <h6 className="card-subtitle mb-2 text-muted">Total number of users</h6>
                                <p className="card-text">100</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Retention</h5>
                                <h6 className="card-subtitle mb-2 text-muted">Percentage of users who return</h6>
                                <p className="card-text">100%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }


    return (
        <section className='AnalyticsPage d-flex pt-3 flex-column'>
            <h4 className='fw-bold mb-3'>Analytics</h4>
            <div className="card">
                <div className="card-body">
                    <p className="card-text">Analytics Page</p>
                    {/* MOCK UP OF ANALYTICS PAGE, display random data */}
                    {renderMockAnalyticsData()}
                </div>
            </div>
        </section>
    )
}

export default AnalyticsPage