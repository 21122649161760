import React, { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { useI18nStore } from 'stores/i18nStore';
import { useTranslation } from 'react-i18next';
import { useExpertsStore } from 'stores/expertsStore';
import StringParser from 'components/StringParser';
import { AmplitudeService } from 'services/amplitudeService';
import { useTitle } from 'utils/functions';
import CircleButton from 'components/CircleButton';
import SecondaryHeader from 'components/SecondaryHeader';
import { useUserStore } from 'stores/userStore';
import { PopupButton } from "react-calendly";

function ExpertBooking({ url }: { url: string }) {
    const navigate = useNavigate();
    let { expertId } = useParams();
    const expertsList = useExpertsStore(state => state.expertsList);

    const { t } = useTranslation();
    const userDetail = useUserStore(state => state.user?.userProfileDetail);
    const getTranslation = useI18nStore(state => state.getTranslation);
    useTitle(t('experts:title'));

    useEffect(() => {
        if (expertsList === null) {
            useExpertsStore.getState().initExpertsStore();
        }
    }, [expertsList]);

    const meetingsContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
        script.type = "text/javascript";
        script.async = true;
        script.onload = () => {
            if (meetingsContainerRef.current) {
                // Initialize the HubSpot Meetings embed script
                (window as any).HubSpotConversations && (window as any).HubSpotConversations.widget.load();
            }
        };
        document.body.appendChild(script);

        return () => {
            // Cleanup the script when the component is unmounted
            document.body.removeChild(script);
        };
    }, []);


    if (!expertsList) { return (<Loader />); }

    const expert = expertsList.find(expert => expert.expertId === expertId);


    if (!expert) {
        return (<Loader />);
    }

    return (
        <div className={`ExpertBooking d-flex h-100 w-100`}>
            <div
                ref={meetingsContainerRef}
                className="meetings-iframe-container w-100 h-100"
                data-src={`${url}?embed=true&firstname=${encodeURIComponent(userDetail?.name || '')}&lastname=${encodeURIComponent(userDetail?.surname || '')}&email=${encodeURIComponent(userDetail?.email || '')}`}
            ></div>
        </div>
    )
}

export default ExpertBooking;