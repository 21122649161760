import HeaderAdmin from 'components/HeaderAdmin';
import Loader from 'components/Loader';
import React, { FormEvent, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useUsersStore } from 'stores/usersStore';
import { useTranslation } from 'react-i18next';
import { UserProfile, UserProfileDetail } from 'models/userModels';
import { useNavigate, useParams } from 'react-router-dom';
import CircleButton from 'components/CircleButton';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import CompanyManageUsers from 'components/createCompanyWizard/CompanyManageUsers';
import CompanyManageAdmin from 'components/createCompanyWizard/CompanyManageAdmin';
import { CompanyUserCreationData, useCompanyManagerStore } from 'stores/companyManagerStore';
import { toast } from 'react-hot-toast';
import { useSystemModalStore } from 'stores/systemModalStore';
import { generateImageUrl, useTitle } from 'utils/functions';
import PlaylistEditor from 'pages/video_manager/PlaylistEditor';
import { useVideoStore } from 'stores/videoStore';
import UserEditor from 'components/UserEditor';

function CompanyUsersPage() {
    const usersList = useUsersStore((state) => state.usersList);
    const initUsers = useUsersStore((state) => state.initUsers);
    //use state for usersListToShow
    const [userListToShow, setUserListToShow] = React.useState(usersList);
    const { companyId } = useParams<{ companyId: string }>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showManageUsers, setShowManageUsers] = React.useState(false);
    const [showManageAdmins, setShowManageAdmins] = React.useState(false);
    const [admins, setAdmins] = useState([] as CompanyUserCreationData[]);
    const [usersToImport, setUsersToImport] = useState([] as CompanyUserCreationData[]);
    const createNewUsers = useUsersStore((state) => state.createUsers);
    const deleteUser = useUsersStore((state) => state.deleteUser);
    const resetUserPassword = useUsersStore((state) => state.resetUserPassword);
    const showModal = useSystemModalStore((state) => state.showConfirmModal);
    const [showEditModal, setShowEditModal] = useState("");
    const [userEdit, setUserEdit] = useState({} as UserProfileDetail);
    useTitle(t('company:manage_users'));
    // This effect is used to initialize the users list
    useEffect(() => {
        console.log('🧑‍🤝‍🧑 UserPage: useEffect');
        if (usersList === null) {
            console.log('🧑‍🤝‍🧑 UserPage: useEffect: initUsers');
            initUsers();
        } else {
            // get company id from routing using react router
            setUserListToShow(usersList.filter((user: UserProfile) => user.userProfileDetail.companyId === companyId));
            console.log('🧑‍🤝‍🧑 UserPage: useEffect: usersList', usersList);
        }
    }, [usersList, initUsers, companyId]);

    const companyList = useCompanyManagerStore(state => state.companyList);
    useEffect(() => {
        if (!companyList) {
            useCompanyManagerStore.getState().init();
        }
    }, [companyList]);

    const company = companyList?.find(company => company.companyId === companyId);
    // If the users list is not initialized yet, we show a loading indicator
    if (usersList === null || usersList === undefined) return (<Loader />);
    //create headers file for usersList. eliminate the userDetail field in the user first, and move it up one level before creating the header
    const usersCSV = usersList.map((user: UserProfile) => {
        const { userProfileDetail, ...userWithoutProfileDetail } = user;
        return { ...userWithoutProfileDetail, ...userProfileDetail };
    });
    console.log(usersCSV);
    /* 
        const importUsers = async (users: CompanyUserCreationData[]) => {
            await useUsersStore.getState().createUsers(usersToImport.map(
                (user) => {
                    return {
                        ...user,
                        companyId: companyId ?? ""
                    }
                }
            ));
        }
    
        const importAdmins = async (admins: CompanyUserCreationData[]) => {
            await useUsersStore.getState().createAdmins(admins.map(
                (user) => {
                    return {
                        ...user,
                        companyId: companyId ?? ""
                    }
                }
            ));
        }
     */
    const headersCSV = [
        { label: 'userId', key: 'userProfileId' },
        { label: 'name', key: 'name' },
        { label: 'surname', key: 'surname' },
        { label: 'nickname', key: 'nickname' },
        { label: 'email', key: 'email' },
        { label: 'group', key: 'group' },
        /*         { label: 'accesses', key: 'accesses' }, */
        { label: 'enabled', key: 'enabled' },
        { label: 'hasSocial', key: 'hasSocial' },
        { label: 'registrationDate', key: 'registrationDate' },
        { label: 'dateLastUpdate', key: 'dateLastUpdate' },
    ];


    function searchUsers(event: FormEvent<HTMLFormElement>) {
        // Prevent the form from reloading the page
        event.preventDefault();
        // Get the values from the form
        const name = (event.currentTarget.elements.namedItem('inputName') as HTMLInputElement).value;
        const surname = (event.currentTarget.elements.namedItem('inputSurname') as HTMLInputElement).value;
        const nickname = (event.currentTarget.elements.namedItem('inputNickname') as HTMLInputElement).value;
        const email = (event.currentTarget.elements.namedItem('inputEmail') as HTMLInputElement).value;
        const registrationDate = (event.currentTarget.elements.namedItem('inputRegistrationDate') as HTMLInputElement).value;
        /*         const group = (event.currentTarget.elements.namedItem('inputGroup') as HTMLSelectElement).value;
                const enabled = (event.currentTarget.elements.namedItem('inputEnabled') as HTMLSelectElement).value;
                const hasSocial = (event.currentTarget.elements.namedItem('inputHasSocial') as HTMLSelectElement).value; */
        // Create the filter object
        const filter: {
            [key: string]: string | undefined;
            name?: string,
            surname?: string,
            nickname?: string,
            email?: string,
            registrationDate?: string,
            /*             group?: string,
                        enabled?: boolean,
                        hasSocial?: boolean, */
        } = {};
        if (name !== '') filter.name = name;
        if (surname !== '') filter.surname = surname;
        if (nickname !== '') filter.nickname = nickname;
        if (email !== '') filter.email = email;
        if (registrationDate !== '') filter.registrationDate = new Date(registrationDate).toISOString();
        /*         if (group !== 'Choose...') filter.group = group;
        
        
                if (enabled !== 'Choose...') filter.enabled = enabled === 'Yes' ? true : false;
                if (hasSocial !== 'Choose...') filter.hasSocial = hasSocial === 'Yes' ? true : false; */
        // Filter users based on the filter object
        const filteredUsers = (usersList ?? []).filter((user: UserProfile) => {
            for (const key in filter) {
                if (key === 'registrationDate') {
                    // return true if date is after registrationDate. set timezones correctly.
                    if (new Date(user.userProfileDetail[key]).getTime() < new Date((filter[key] as string) ?? "").getTime()) return false;
                } else {
                    //if not contained in the string, return false
                    if (!(user.userProfileDetail[key] ?? "").toLowerCase().includes((filter[key] as string).toLowerCase())) return false;
                }
            }
            return true;
        });
        // Update the users list in the store
        setUserListToShow(filteredUsers);
    }

    return (
        <section className='CompanyUsersPage d-flex flex-column'>
            <div className='d-flex align-items-center'>
                <CircleButton className='text-white' iconClassName={"fas fa-angle-left"} onClick={() => {
                    navigate('/system/company_manager');
                }} />
                <h3 className='flex-grow-1 ms-2 mb-0'>{company?.companyDetail.company}</h3>
            </div>
            <HeaderAdmin title='main_menu:users'>
                <Button variant="primary" className='me-2' onClick={() => {
                    setShowManageUsers(true);
                }}>
                    {t('company:importUsers')}
                </Button>
                <Button variant="primary" className='me-2' onClick={() => {
                    setShowManageAdmins(true);
                }}>
                    {t('company:createAdmin')}
                </Button>

                <CSVLink data={(userListToShow ?? []).map((user: UserProfile) => {
                    const { userProfileDetail, ...userWithoutProfileDetail } = user;
                    return { ...userWithoutProfileDetail, ...userProfileDetail };
                }

                )} headers={headersCSV} filename={'users.csv'} className='btn btn-primary ms-auto'>
                    Download CSV
                    <i className="fas fa-download ms-2"></i>
                </CSVLink>
            </HeaderAdmin>
            <div className='d-flex align-items-center d-md-flex d-none my-3'>
                <Button variant="primary" className='me-2' onClick={() => {
                    setShowManageUsers(true);
                }}>
                    {t('company:importUsers')}
                </Button>
                <Button variant="primary" className='me-2' onClick={() => {
                    setShowManageAdmins(true);
                }}>
                    {t('company:createAdmin')}
                </Button>

                <CSVLink data={(userListToShow ?? []).map((user: UserProfile) => {
                    const { userProfileDetail, ...userWithoutProfileDetail } = user;
                    return { ...userWithoutProfileDetail, ...userProfileDetail };
                }

                )} headers={headersCSV} filename={'users.csv'} className='btn btn-primary ms-auto'>
                    Download CSV
                    <i className="fas fa-download ms-2"></i>
                </CSVLink>
            </div>
            <Modal show={showManageUsers} onHide={() => setShowManageUsers(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {t('company:importUsers')}</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <CompanyManageUsers onNewUser={(newUsers) => {
                        setUsersToImport(newUsers);
                    }} />
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowManageUsers(false)}>
                        {t('common:cancel')}
                    </Button>
                    <Button variant="primary" disabled={usersToImport.length === 0} onClick={() => {
                        toast.promise(createNewUsers(usersToImport.map(
                            (user) => {
                                return {
                                    ...user,
                                    companyId: companyId ?? ""
                                }
                            }
                        )), {
                            loading: t('common:creating'),
                            success: t('common:user') + " " + t('common:created'),
                            error: (e) => t('errors:' + e.message ?? 'error')
                        });
                        setShowManageUsers(false);
                    }}>
                        {t('common:import')}
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal show={showManageAdmins} onHide={() => setShowManageAdmins(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {t('company:createAdmin')}</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <CompanyManageAdmin admins={admins} handleNewAdmin={(admin) => {
                        setAdmins([...admins, admin]);
                    }
                    } />
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowManageAdmins(false)}>
                        {t('common:cancel')}
                    </Button>
                    <Button variant="primary"
                        disabled={admins.length === 0}
                        onClick={() => {
                            toast.promise(useUsersStore.getState().createAdmins(admins.map(
                                (user) => {
                                    return {
                                        ...user,
                                        companyId: companyId ?? ""
                                    }
                                }
                            )), {
                                loading: t('common:creating'),
                                success: t('common:created'),
                                error: t('common:error')
                            }).then(() => {
                                setAdmins([]);
                            }
                            );
                        }}>
                        {t('common:import')}
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Search form */}
            <div className="card mb-3">
                <div className="card-body">
                    <form className="row g-3" onSubmit={searchUsers}>

                        <div className="col-md-6">
                            <label htmlFor="inputName" className="form-label">{t('forms:name')}</label>
                            <input type="text" className="form-control" id="inputName" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputSurname" className="form-label">{t('forms:surname')}</label>
                            <input type="text" className="form-control" id="inputSurname" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputNickname" className="form-label">{t('forms:nickname')}</label>
                            <input type="text" className="form-control" id="inputNickname" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputEmail" className="form-label">{t('forms:email')}</label>
                            <input type="text" className="form-control" id="inputEmail" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputRegistrationDate" className="form-label">{t('forms:registrationDate')}</label>
                            <input type="date" className="form-control" id="inputRegistrationDate" />
                        </div>
                        {/*                         <div className="col-md-6">
                            <label htmlFor="inputGroup" className="form-label">Group</label>
                            <select id="inputGroup" className="form-select">
                                <option>Choose...</option>
                                <option>Admin</option>
                                <option>User</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputEnabled" className="form-label">Enabled</label>
                            <select id="inputEnabled" className="form-select">
                                <option >Choose...</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputHasSocial" className="form-label">Has Social</label>
                            <select id="inputHasSocial" className="form-select">
                                <option >Choose...</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div> */}
                        <div className="col-md-6">

                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">{t('common:search')}</button>
                        </div>
                    </form>
                </div>
            </div>
            {/* Users list */}

            <div className="card mb-3">
                <div className="card-body">
                    {
                        <table className="table table-hover table-responsive table-fixed">
                            <thead>
                                <tr>
                                    <th scope="col">{t('users:name')}</th>
                                    <th scope="col" className='col-1 text-center'>{t('users:registration_date')}</th>
                                    <th scope="col" className='col-1 text-center'>{t('users:verified')}</th>
                                    <th scope="col" className='col-1 text-center'>{t('users:enabled')}</th>
                                    <th scope="col" className='col-1 text-center'>{t('users:group')}</th>
                                    <th scope="col" className='col-1 text-center'>{t('users:language')}</th>
                                    <th scope="col" className='col-1 text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(userListToShow ?? [])?.map((user: UserProfile, index: number) => {
                                    return (
                                        <tr key={index} className="">
                                            <td>
                                                <div className="d-flex flex-column">
                                                    <h6 className='mb-0'>
                                                        {user.userProfileDetail.name ?? user.userProfileDetail.nickname}
                                                        &nbsp;
                                                        {user.userProfileDetail.surname}
                                                    </h6>
                                                    <small className='text-muted'>
                                                        {user.userProfileDetail.email}
                                                    </small>
                                                </div>
                                            </td>

                                            <td className='text-center align-middle'>
                                                {new Date(user.userProfileDetail.registrationDate).toLocaleDateString()}
                                            </td>

                                            <td className='text-center align-middle'>
                                                <span className={"fas fa-fw fa-" + (user.userProfileDetail.emailVerified ? 'shield-check text-success' : 'shield-xmark text-secondary')}></span>
                                            </td>

                                            <td className='text-center align-middle'>
                                                <span className={"fas fa-fw fa-" + (user.userProfileDetail.enabled ? 'check-circle text-success' : 'times-circle text-secondary')}></span>
                                            </td>


                                            <td className='align-middle'>
                                                <span className={"badge rounded-pill ms-auto my-auto d-block " + (user.userProfileDetail.group === "Admin" ? 'bg-secondary' : 'bg-primary')}>{user.userProfileDetail.group === "Admin" ? 'ADMIN' : 'USER'}</span>
                                            </td>
                                            <td className='text-center align-middle'>
                                                {t('languages:' + (user.userProfileDetail.locale ?? "-"))}
                                            </td>
                                            {/* actions dropdown */}
                                            <td className='text-center align-middle'>
                                                <div className="dropdown">
                                                    <button className="btn btn-link text-dark" type="button" id={"dropdownMenuButton" + index} data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fa-ellipsis-v"></i>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby={"dropdownMenuButton" + index}>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                //TODO
                                                                useSystemModalStore.getState().showInfoModal(
                                                                    'common:details',
                                                                    <ul className="list-group list-group-flush">
                                                                        <li className="px-0 list-group-item">{t('forms:name')}: {user.userProfileDetail.name} {user.userProfileDetail.surname}</li>
                                                                        <li className="px-0 list-group-item">ID: {user.userProfileId}</li>
                                                                        <li className="px-0 list-group-item">{t('forms:email')}: {user.userProfileDetail.email}</li>
                                                                        <li className="px-0 list-group-item">{t('forms:registrationDate')}: {new Date(user.userProfileDetail.registrationDate).toLocaleDateString()}</li>
                                                                        <li className="px-0 list-group-item">{t('commmon:group')}: {user.userProfileDetail.group}</li>
                                                                        <li className="px-0 list-group-item">{t('language_manager:language')}: {t('languages:' + (user.userProfileDetail.locale ?? "-"))}</li>
                                                                    </ul>
                                                                );
                                                            }}>
                                                                {t('common:details')}
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                setShowEditModal(user.userProfileId);
                                                                setUserEdit({ ...user.userProfileDetail });
                                                            }}>
                                                                {t('common:edit')}
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                showModal(
                                                                    t('users:reset_password'),
                                                                    t('users:reset_password_confirm'),
                                                                    'fa-repeat').then((result) => {
                                                                        if (result) {
                                                                            resetUserPassword(user.userProfileId);
                                                                            toast.promise(resetUserPassword(user.userProfileId), {
                                                                                loading: t('common:please_wait'),
                                                                                success: t('common:done'),
                                                                                error: (e) => t('errors:' + e.message ?? 'error')
                                                                            });
                                                                        }
                                                                    });
                                                            }}>
                                                                {t('users:reset_password')}
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                useSystemModalStore.getState().showConfirmModal(
                                                                    t('users:delete_user'),
                                                                    t('users:delete_user_confirm'),
                                                                    'fa-trash').then((result) => {
                                                                        if (result) {
                                                                            deleteUser(user.userProfileId);
                                                                            toast.promise(deleteUser(user.userProfileId), {
                                                                                loading: t('common:deleting'),
                                                                                success: t('common:deleted'),
                                                                                error: (e) => t('errors:' + e.message ?? 'error')
                                                                            });
                                                                        }
                                                                    });
                                                            }}>
                                                                {t('common:delete')}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                {/* if empty */}
                                {(!userListToShow || userListToShow.length === 0) &&
                                    <tr>
                                        <td colSpan={7} className="text-center">
                                            {t('users:no_users_found')}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>}
                </div>
            </div>
            <Modal id="editUserModal" onHide={() => setShowEditModal("")} show={showEditModal !== ""} scrollable>
                <ModalHeader closeButton>
                    <ModalTitle>{t('common:edit')}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <UserEditor user={userEdit} handleUserChange={(playlistDet) => {
                        setUserEdit({
                            ...userEdit,
                            ...playlistDet
                        });
                    }} />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={async () => {
                        setShowEditModal("");
                        try {
                            toast.promise(useUsersStore.getState().editUser({
                                userProfileId: showEditModal,
                                userProfileDetail: {
                                    ...userEdit,
                                }
                            }), {
                                loading: t('common:loading'),
                                success: t('common:saved'),
                                error: (e) => t('common:error') + e.message
                            });
                            toast.dismiss();
                        }
                        catch (e) {
                            toast.dismiss();
                            toast.error(t('common:error'));
                        }
                    }
                    }>{t('common:save')}</button>
                </ModalFooter>
            </Modal>
        </section >
    )
}

export default CompanyUsersPage