import Loader from 'components/Loader';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthStates, useAuthStore } from 'stores/authStore';

function LogoutPage() {
    const authState = useAuthStore((state) => state.authenthicationState);
    const navigate = useNavigate();
    useEffect(() => {
        if (authState !== AuthStates.LoggingOut) {
            navigate('/login');
        }
    }, [authState, navigate]);
    return (
        <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
            <Loader></Loader>
        </div>
    )
}

export default LogoutPage