import CircleButton from "components/CircleButton";
import Loader from "components/Loader";
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import { useDocumentStore } from "stores/documentStore";
import ExpertEditor from "./ExpertEditor";
import { useI18nStore } from "stores/i18nStore";
import { generateImageUrl, useTitle } from "utils/functions";
import { useExpertsStore } from "stores/expertsStore";
import { Expert, ExpertDetail } from "models/expertModel";
import { useUserStore } from "stores/userStore";
import HeaderWidgetPortal from "components/HeaderWidgetPortal";

const expertInitialData: ExpertDetail = {
    category: [],
    companyId: "",
    dateLastUpdate: new Date().toISOString(),
    description: {},
    descriptionBrief: {},
    expertCreationDate: new Date().toISOString(),
    icon: "",
    img: "",
    title: {},
    url: {}
};

function ExpertsManagerPage() {

    const expertsList = useExpertsStore(state => state.expertsList);
    const { t } = useTranslation();
    const [showExpertGoal, setShowCreateExpert] = React.useState(false);
    const [newExpert, setNewExpert] = React.useState<ExpertDetail>(expertInitialData);
    const [expertImage, setExpertImage] = useState<File | null>(null);
    React.useEffect(() => {
        if (expertsList === null) {
            useExpertsStore.getState().initExpertsStore();
        }
    }, [expertsList]);
    useTitle(t('experts_manager:title'));
    const createNewExpert = async () => {
        setShowCreateExpert(false);
        let newImage = null;
        if (expertImage != null) {
            newImage = await useDocumentStore.getState().uploadDocument({
                format: expertImage.type,
                title: expertImage.name,
                ownerId: useUserStore.getState().user?.userProfileId ?? "",
                type: "expertImage",
                file: expertImage as File
            });
        }
        if (newImage != null) {
            newExpert.img = generateImageUrl(newImage)!;
        }
        toast.promise(useExpertsStore.getState().createExpert(newExpert), {
            loading: t('common:loading'),
            success: t('common:created'),
            error: (e) => t('common:error') + e.message
        }).then(() => {
            setNewExpert(expertInitialData);
            setExpertImage(null);
        });
    };

    return (
        <section className='ExpertsPage d-flex flex-column'>
            <HeaderWidgetPortal>
                <CircleButton className='ms-auto' iconClassName={"fas fa-plus"} onClick={
                    () => {
                        setShowCreateExpert(true);
                    }
                } />
            </HeaderWidgetPortal>
            {/*                       <button onClick={() => {
                useExpertsStore.getState().createDefaultExperts();
            }}>CARICA</button> */}
            {
                expertsList === null ? <Loader /> : expertsList.length === 0 ? <div className='text-center'>{t('experts_manager:no_experts')}<br></br>
                    <Button variant="primary" onClick={() => useExpertsStore.getState().createDefaultExperts()}>Create default experts</Button>
                </div> :
                    <div className=' mb-4 card'>
                        <div className="card-body d-flex flex-column">
                            <ul className="list-group list-group-flush">
                                {Array.isArray(expertsList) && expertsList.map((expert) => <ExpertListTile key={expert.expertId} expert={expert} />)}
                            </ul>
                        </div>
                    </div>
            }
            <Modal id="createExpertModal" onHide={() => setShowCreateExpert(false)} show={showExpertGoal} scrollable>
                <ModalHeader closeButton>
                    <ModalTitle>{t('experts_manager:create_expert')}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <ExpertEditor expert={newExpert} handleExpertChange={setNewExpert} setExpertImage={setExpertImage} expertImage={null} />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={() => createNewExpert()}>{t('common:save')}</button>
                </ModalFooter>
            </Modal>
        </section>
    )
}

/* -------------------------------------------------------------------------- */
/*                            ExpertListTile                                 */
/* -------------------------------------------------------------------------- */

function ExpertListTile(props: { expert: Expert }) {
    const expert = props.expert;
    const { t } = useTranslation();
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [expertImage, setExpertImage] = useState<File | null>(null);
    const uploadDocument = useDocumentStore(state => state.uploadDocument);
    const [showFeaturesModal, setShowFeaturesModal] = React.useState(false);
    useEffect(() => {
        setExpertEdit(expert.expertDetail);
    }, [expert, showEditModal]);

    const updateExpert = async () => {
        setShowEditModal(false);
        let newImage = null;
        if (expertImage != null) {
            newImage = await uploadDocument({
                format: expertImage.type,
                title: expertImage.name,
                ownerId: useUserStore.getState().user?.userProfileId ?? "",
                type: "expertImage",
                file: expertImage as File
            });
        }
        if (newImage != null) {
            expertToEdit.img = generateImageUrl(newImage)!;
        }
        toast.promise(useExpertsStore.getState().updateExpert({
            expertId: expert.expertId,
            expertDetail: {
                ...expertToEdit,
            }
        }), {
            loading: t('common:loading'),
            success: t('common:saved'),
            error: (e) => t('common:error') + e.message
        });
    }

    const getTranslation = useI18nStore(state => state.getTranslation);
    const [expertToEdit, setExpertEdit] = useState(expert.expertDetail);

    return (
        <>
            <li className="ExpertListTile list-group-item d-flex flex-row align-items-center ps-0" >
                <img src={expert.expertDetail.img} alt="company logo" className="rounded-circle object-fit-cover me-3" style={{ width: "50px", height: "50px" }} />
                <h6 className='mb-0 d-flex flex-column align-items-start' style={{ width: "250px" }}>
                    <span>{expert.expertDetail.name}</span>

                    <span>{getTranslation(expert.expertDetail.title)}</span>
                    <span className="small text-muted" >{getTranslation(expert.expertDetail.descriptionBrief)}</span>
                    <div className="d-flex flex-row flex-wrap">
                        {
                            expert.expertDetail.category.sort().map((category, index) => {
                                return <span key={index} className="badge bg-primary rounded-pill me-1 mb-1">{category}</span>
                            })
                        }
                    </div>
                </h6>
                <div className='ms-auto d-flex align-items-center'>
                    <button className="btn btn-sm btn-outline-primary"
                        onClick={() => {
                            setShowEditModal(true);
                        }}
                    ><i className="fa-duotone fa-pencil"></i></button>
                    <button className="btn btn-sm btn-outline-danger ms-2" onClick={() => {
                        if (window.confirm(t('experts_manager:delete_expert_confirm'))) {
                            toast.promise(useExpertsStore.getState().deleteExpert(expert.expertId), {
                                loading: t('common:loading'),
                                success: t('common:deleted'),
                                error: (e) => t('common:error') + e.message
                            });
                        }
                    }}><i className="fa-duotone fa-trash"></i></button>
                </div>
            </li>
            {/* EDIT MODAL */}
            <Modal id="editCompanyModal" onHide={() => setShowEditModal(false)} show={showEditModal} scrollable>
                <ModalHeader closeButton>
                    <ModalTitle>{t('common:edit')}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <ExpertEditor expert={expertToEdit} handleExpertChange={setExpertEdit} setExpertImage={setExpertImage} expertImage={expertImage} />
                </ModalBody>
                <ModalFooter>
                    {/* SaVE */}
                    <button className="btn btn-primary rounded-pill" onClick={() => updateExpert()}>{t('common:save')}</button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default ExpertsManagerPage