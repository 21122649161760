import InfoButton from "components/InfoButton"
import React from "react"
import { useTranslation } from "react-i18next"
import { useVideoStore } from "stores/videoStore";
import VideoCard from "./VideoCard";
import SectionTitle from "components/SectionTitle";
import { useNavigate } from "react-router-dom";
import CircleButton from "components/CircleButton";
import ScrollContainer from "react-indiana-drag-scroll";
import { Video } from "models/videoModels";
import { VideoWidget } from "pages/user_dashboard/UserDashbordWidgetRows";
function VideoPage() {
    const { t } = useTranslation();
    const videos = useVideoStore(state => state.videos);
    const navigate = useNavigate();
    const initVideoStore = useVideoStore(state => state.initVideoStore);
    React.useEffect(() => {
        initVideoStore();
    }, [initVideoStore]);

    const playlists = React.useMemo(() => {
        if (!videos) return {};
        // Split videos into playlists
        const playlists: { [key: string]: Video[] } = {}; // Add index signature
        for (let i = 0; i < videos.length; i++) {
            const video = videos[i];
            if (playlists[video!.videoDetail.playlist] === undefined) {
                playlists[video!.videoDetail.playlist] = [
                    video
                ];
            } else {
                playlists[video.videoDetail.playlist].push(video);
            }
        }
        return playlists as { [key: string]: Video[] };
    }, [videos]);

    return (
        <section className='d-flex flex-column'>
            <div className='d-flex align-items-center mb-3'>
                <CircleButton className='me-3' iconClassName='fas fa-arrow-left' onClick={() => {
                    navigate(-1);
                }}></CircleButton>
                <h3 className="w-auto mb-0 me-auto">{t('videos:title')}</h3>
                <InfoButton id={'videoPageInfo'} title={t('videos:info_modal_title')}>
                    <p>{t('videos:info_modal_text')}</p>
                </InfoButton>
            </div>
            
            {Object.keys(playlists).map((playlist, index) => (
                <div key={index}>
                    <SectionTitle title={t("titles:" + playlist)} />
                    <div className='mb-5'>
                        <ScrollContainer className="d-flex">
                            {playlists[playlist].map((video, i) => (
                                <div className="d-flex col-4 me-3" style={{ height: "160px" }} key={`vid-${playlist}-${i}`}>
                                    <VideoCard video={video} />
                                </div>
                            ))}
                        </ScrollContainer>
                    </div>
                </div>
            ))}
        </section>
    )
}
export default VideoPage