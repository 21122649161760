
import React from 'react'

function InfoButton({ id, title, ...props }) {
    return (
        <>
{/*             <div className="modal fade" id={"info-modal-" + id} tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title" >{title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-center cursor-pointer" style={{ width: "1.5rem", height: "1.6rem" }} data-bs-toggle="modal" data-bs-target={"#info-modal-" + id}>
                <i className="fa-regular fa-info-circle text-primary" style={{ fontSize: "22px" }}></i>
            </div> */}
        </>

    )
}

export default InfoButton