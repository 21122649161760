import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSystemModalStore } from "stores/systemModalStore";


function SystemModal() {
    const { t } = useTranslation();
    const isModalVisible = useSystemModalStore(state => state.isModalVisible);
    const modalData = useSystemModalStore(state => state.modalData);
    return (
        <Modal
            show={isModalVisible}
            onHide={modalData.onCancel}
            centered
            scrollable
        >
            <Modal.Body>
                <div className='d-flex align-items-center mb-3'>
                    <i className={'fa-duotone fa-3x me-3 ' + (modalData.icon ?? "fa-question-circle")} />
                    <h3 className='text-center mb-0'>
                        {t(modalData.title)}
                    </h3>
                </div>
                <p className='mb-3 text-break'>
                    {modalData.message}
                </p>
                <div className='d-flex align-items-center'>
                    {modalData.type === "confirm" && <button className='btn btn-secondary rounded-pill px-5' onClick={modalData.onCancel}>
                        {t('common:cancel')}
                    </button>}
                    <button className='ms-auto btn btn-success rounded-pill px-5' onClick={modalData.onConfirm} >
                        {t('common:confirm')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>);
}
export default SystemModal