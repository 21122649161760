import React, { useEffect } from 'react'
import Tour from 'reactour';
import { useTutorialStore } from 'stores/tutorialStore';

function TutorialTour({ tutorialName }) {
    const requestTutorial = useTutorialStore((state) => state.requestTutorial);
    const requestedTutorial = useTutorialStore((state) => state.requestedTutorial);
    const tutorials = useTutorialStore((state) => state.tutorials);
    const onTutorialCompleted = useTutorialStore((state) => state.onTutorialCompleted);
    useEffect(() => {
        if (requestedTutorial !== tutorialName)
            requestTutorial(tutorialName);
    }, [requestTutorial, tutorialName, requestedTutorial]);
    return (
        <Tour
            steps={tutorials[tutorialName] ?? [{}]}
            isOpen={requestedTutorial === tutorialName}
            rounded={16}
            showNumber={false}
            disableInteraction={true}
            showNavigation={false}
            showButtons={false}
            showCloseButton={false}
            updateDelay={2}
            maskClassName='text-primary opacity-75'
            className='helpbox'
            onRequestClose={() => {
                onTutorialCompleted(tutorialName);
            }}></Tour>
    )
}

export default TutorialTour