import i18n from 'i18n';
import React from 'react'

function ProximityDate({ date, className }: { date: Date, className?: string }) {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    let shownDate = '';
    const isSameDay = now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear();
    if (isSameDay) {
        // if less than a minute
        if (diff < 60000) {
            shownDate = i18n.t('common:just_now');
        } else {
            // display hours and minutes
            shownDate = `${date.getHours()}:${date.getMinutes()}`;
        }
    } else {
        // display date
        shownDate = date.toLocaleDateString();
    }
    return (
        <span className={className}>{shownDate}</span>
    )
}

export default ProximityDate