import userPlaceholder from 'assets/images/userph.jpg';
import HeaderAdmin from 'components/HeaderAdmin';
import Spinner from 'components/Spinner';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useCompanyStore } from 'stores/companyStore';
import { useEduquizManagerStore } from 'stores/eduquizManagerStore';
import { useI18nStore } from 'stores/i18nStore';
import { useShopManagerStore } from 'stores/shopManagerStore';
import { useUsersStore } from 'stores/usersStore';
import { CountUp } from 'use-count-up';
import Config from 'utils/config';
import { convertColorToHex, generateImageUrl, useTitle } from 'utils/functions';


export default function HRDashboardPage() {
    const navigate = useNavigate();

    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);

    // Get the list of users and sort them by registration date
    const usersList = useUsersStore(state => state.usersList?.sort((a, b) => (a.userProfileDetail.registrationDate > b.userProfileDetail.registrationDate) ? -1 : 1));
    const initUsers = useUsersStore((state) => state.initUsers);

    const [startingDate, setStartingDate] = React.useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
    const [endingDate, setEndingDate] = React.useState(new Date());

    useTitle(t('dashboard:title'));

    // This effect is used to initialize the users list
    useEffect(() => {
        if (!usersList) {
            initUsers();
        } else {
        }
    }, [usersList, initUsers]);

    // Get the list of arguments
    const argumentList = useEduquizManagerStore(state => state.argumentList);
    const initEduquiz = useEduquizManagerStore((state) => state.initEduquiz);

    // This effect is used to initialize the arguments list
    useEffect(() => {
        if (!argumentList) {
            initEduquiz(useCompanyStore.getState().currentCompany?.companyId ?? null);
        } else {
        }
    }, [argumentList, initEduquiz]);

    // Get the list of shop items
    const shopItems = useShopManagerStore((state) => state.shopItems);
    const initShopManager = useShopManagerStore((state) => state.initShopManager);
    const requestUserShopItems = useShopManagerStore((state) => state.requestUserShopItems);
    const userShopItems = useShopManagerStore((state) => state.userShopItems);

    // This effect is used to initialize the shopItems list
    useEffect(() => {
        if (shopItems === null) {
            initShopManager();
        }
    }, [shopItems, initShopManager]);

    // This effect is used to initialize the userShopItems list
    useEffect(() => {
        if (userShopItems === null) {
            requestUserShopItems();
        }
    }, [userShopItems, requestUserShopItems]);
    // match shopItems with userShopItems, by listing the count of each userShopitem per shopItem. the count increase when userShopItem.userShopItemDetail.shopItemId === shopItem.shopItemId
    const shopItemsWithCount = (shopItems ?? []).map((shopItem) => {
        const count = (userShopItems ?? []).filter(userShopItem => userShopItem.userShopItemDetail.shopItemId === shopItem.shopItemId).length;
        return { ...shopItem, count, name: shopItem.shopItemDetail.company };
    });
    // sort by count desc
    shopItemsWithCount?.sort((a, b) => (a.count > b.count) ? -1 : 1);
    // add fill color
    shopItemsWithCount?.forEach((shopItem, index) => {
        shopItem.fill = Config.chartColors[index];
    });

    // Calculate acccesses per day
    const userData = usersList?.map((user) => { return { userId: user.userProfileId, ...user.userProfileDetail } });
    let accessData;
    let days = [];;
    let weekTotalAccesses;
    if (userData) {
        // Count the number of accesses per day
        const accessCounts = userData
            ?.flatMap((user) => user.accesses)
            .reduce((counts, access) => {
                const date = (access ?? []).slice(0, 10);
                counts[date] = (counts[date] || 0) + 1;
                return counts;
            }, {});

        // Convert the access counts to an array of objects
        accessData = Object.entries(accessCounts).map(([date, count]) => ({
            date: date/* .slice(5) */, // update date to mm-dd format
            count,
        }));

        // Order the accessData by date, with the newest first
        accessData.sort((a, b) => (a.date > b.date) ? 1 : -1);

        // Get the names of the days of the week
        const today = new Date();
        const weekDaysNames = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            const dayName = date.toLocaleDateString(undefined, { weekday: 'short' });
            weekDaysNames.push(dayName.charAt(0).toUpperCase() + dayName.slice(1));
        }

        // Calculate the number of accesses between startingDate and endingDate
        let currentDate = new Date(startingDate);
        while (currentDate <= endingDate) {
            const count = accessData?.find((data) => {
                const dataDate = new Date(data.date);
                return dataDate.toLocaleDateString() === currentDate.toLocaleDateString();
            })?.count || 0;
            days.push({
                dayName: currentDate.toLocaleDateString().split('/')[0]
                    + '/' + currentDate.toLocaleDateString().split('/')[1]
                , count
            });
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Calculate the total number of accesses for the week
        weekTotalAccesses = days.reduce((total, day) => total + day.count, 0);
    }

    // Calculate the number of arguments completed
    const argumentsCompletedCount = argumentList?.reduce((total, argument) => total + (argument?.argumentDetail?.totalCompleted ?? 0), 0);

    // Calculate the number of modules completed
    const modulesCompletedCount = argumentList?.reduce((total, argument) =>
        total + (argument?.argumentDetail?.modules?.reduce((total, module) => total + (module?.moduleDetail?.totalCompleted ?? 0), 0) ?? 0), 0);

    // Calculate the number of units completed
    const unitsCompletedCount = argumentList?.reduce((total, argument) =>
        total + (argument?.argumentDetail?.modules?.reduce((total, module) =>
            total + (module?.moduleDetail?.units?.reduce((total, unit) => total + (unit?.unitDetail?.totalCompleted ?? 0), 0) ?? 0), 0) ?? 0), 0);

    // Create a list of all modules
    const quizModules = argumentList?.flatMap((argument) => argument?.argumentDetail?.modules ?? []);
    // get the percentage of completionsfor each module.
    quizModules?.forEach((module) => {
        // for each module get the how much in percentage it has contributed to the total completions of the argument
        module.moduleDetail.percentageCompleted = module.moduleDetail.totalCompleted / argumentList?.find((argument) => argument.argumentId === module.moduleDetail.argumentId)?.argumentDetail?.totalCompleted;
    });

    return (<section className='HRDashboardPage d-flex flex-column px-1' >
        <HeaderAdmin title='dashboard:title' />
        <div className='row mb-3' style={{
        marginTop: "-1rem"
    }}>

            <HRDashboardPageCard rows="3" >
                <h4>{t('common:arguments')}<br />{t('dashboard:completed')}</h4>
                <h2 className='mb-0'>{argumentsCompletedCount !== undefined ? <CountUp isCounting end={argumentsCompletedCount} />
                    : <Spinner />}</h2>
            </HRDashboardPageCard>
            <HRDashboardPageCard rows="3" >
                <h4>{t('common:modules')}<br />{t('dashboard:completed')}</h4>
                <h2 className='mb-0'>{modulesCompletedCount !== undefined ? <CountUp isCounting end={modulesCompletedCount} />
                    : <Spinner />}</h2>
            </HRDashboardPageCard>
            <HRDashboardPageCard rows="3">
                <h4>{t('common:units')}<br />{t('dashboard:completed')}</h4>
                <h2 className='mb-0'>{unitsCompletedCount !== undefined ? <CountUp isCounting end={unitsCompletedCount} />
                    : <Spinner />}</h2>
            </HRDashboardPageCard>
            <HRDashboardPageCard rows="3">
                <h4>{t('dashboard:shop_items')}<br />{t('dashboard:sold')}</h4>
                <h2 className='mb-0'>{userShopItems?.length !== undefined ? <CountUp isCounting end={userShopItems?.length} />
                    : <Spinner />}</h2>
            </HRDashboardPageCard>
            {/* USERS */}

            <HRDashboardPageCard rows="9">
                <div className="card-title">
                    <h4>{t('dashboard:accesses')}</h4>
                    <h2 className='mb-0'><CountUp isCounting={weekTotalAccesses} end={weekTotalAccesses} /></h2>
                </div>
                <div className="d-flex align-items-center mb-3">
                    <div className="form-group me-3">
                        <label htmlFor="startDate">{t('common:from')}:</label>
                        <input type="date" id="startDate" className='form-control' value={startingDate.toISOString().split('T')[0]} onChange={
                            (e) => setStartingDate(new Date(e.target.value))
                        } />
                    </div>
                    <div className="form-group">
                        <label htmlFor="endDate">{t('common:to')}:</label>
                        <input type="date" id="endDate" className='form-control' value={endingDate.toISOString().split('T')[0]} onChange={
                            (e) => setEndingDate(new Date(e.target.value))
                        } />
                    </div>
                </div>
                {days && <ResponsiveContainer width={"100%"} height={420}>
                    <LineChart data={days} margin={{ top: 2, right: 8, bottom: 0, left: 8 }}>
                        <Line type="monotone" dataKey="count" stroke="var(--bs-primary)" strokeWidth={3} animationBegin={0} />
                        <XAxis dataKey="dayName" height={28} tick={{ stroke: "var(--bs-primary)", color: "var(--bs-primary)", }} axisLine={false} tickMargin={8} />
                        <YAxis dataKey="count" width={32} axisLine={false} stroke="var(--bs-gray)" />
                        <Tooltip content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <div className="card">
                                        <div className="card-body">{`${t('dashboard:accesses')} ${label}: ${payload[0].value}`}</div>
                                    </div>
                                );
                            }
                            return null;
                        }} />
                        <CartesianGrid stroke="var(--bs-gray)" strokeDasharray="10 10" />
                    </LineChart>
                </ResponsiveContainer>}
                {!days && <div className="my-5 w-100 d-flex align-items-center justify-content-center"><Spinner /></div>}
            </HRDashboardPageCard>

            <HRDashboardPageCard rows="3">
                <div className="card-title">
                    <h4>{t('dashboard:users')}</h4>
                    <h2><CountUp isCounting={usersList} end={usersList?.length} /></h2>
                </div>
                {usersList?.slice(0, 6).map(user => (
                    <div className="user-tile d-flex" key={user.userProfileDetail.nickname}>
                        <img className='rounded-circle me-3' src={generateImageUrl(user.userProfileDetail.avatar) ?? userPlaceholder} height={48} width={48} alt={`${user.userProfileDetail.name} ${user.userProfileDetail.surname}`} />
                        <div className="user-info mw-0">
                            <h6 className="text-truncate">{user.userProfileDetail.nickname}</h6>
                            <p className="text-truncate">{`${user.userProfileDetail.name} ${user.userProfileDetail.surname}`}</p>
                        </div>
                    </div>
                ))}
                {!usersList && <div className="my-5 w-100 d-flex align-items-center justify-content-center"><Spinner /></div>}
                {/* view all users button */}
                <button className="btn btn-primary btn-block w-100 btn-sm mt-auto" onClick={() => navigate('../users')}>{t('common:view_all')}</button>
            </HRDashboardPageCard>
        </div>
    </section >
    )

}
function HRDashboardPageCard(props) {
    return (
        <div className={'col-12 gy-3 gx-3 col-lg-' + props.rows}>
            <div className='card  d-flex align-items-stretch h-100'>
                <div className='card-body d-flex flex-column'>
                    {props.children}
                </div>
            </div>
        </div>
    )
}