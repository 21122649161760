
import illustration2 from 'assets/images/illu-1.png';
import illustration from 'assets/images/logo_carry.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'utils/config';
import AuthBlock from 'pages/auth/AuthBlock';
import { LOGIN_BUTTON } from 'pages/auth/login/LoginComponents';


/* 
    Display the landing page

    This page is shown to unauthenticated users at the root of the website
    It is meant to be customized to the client's needs
    Do not modify this file directly. Use it as a template to create a new page in src/pages/landing if you need to.

    The AuthBlock component is used to display the login and register modals, as well as the forgot password modal.
    It should never be removed, moved around or wrapped in other components. Other content can be added above or below it.

    Everything else is customizable. You can add or remove components, change the layout, etc.

    Translations are to be taken from "landing" namespace. Example:
        {t('landing:title_1')} will display the translation for "title_1" in the landing namespace
    
    To create a login button, use the LOGIN_BUTTON constant as props for the button. Example:
        <button type="button" className="btn btn-lg btn-primary" {...LOGIN_BUTTON}>Login</button>
    Do not add other bootstrap data properties to the button, they are already included in LOGIN_BUTTON and will conflict.

*/
function LandingPage() {
    const { t } = useTranslation();
    return (
        <>

            {/* HEADER SECTION */}
            <section className="container pt-5 py-md-2 py-lg-4 pt-xl-5 mt-4 mt-md-5 bg-gradient-to-dark">
                <div className="row align-items-center mt-2 mt-sm-0 pt-4 pt-sm-5">

                    <div className="col-md-6 offset-lg-1 col-lg-5 col-xxl-6 offset-xxl-1 order-md-2 pb-4 pb-md-0 mb-2 mb-sm-3 mb-md-0">
                        <img src={illustration} className="img-fluid w-75 mx-auto ms-lg-auto d-block" alt="fin logo" />
                    </div>

                    <div className="col-md-6 col-lg-6 col-xxl-5 order-md-1">
                        <div className="pe-md-4 pe-xl-0">
                            <h1 className="display-1 mb-3">
                                {t('landing:title_1')} </h1>
                            <p className="mb-4 form-floating">
                                {t('landing:claim_1')}
                                <br></br>
                                {t('landing:text_1')}
                            </p>
                            <button type="button" className="btn btn-lg btn-primary" {...LOGIN_BUTTON}>{t('landing:get_started')}</button>
                        </div>
                    </div>
                </div>
            </section>




            {/* SECOND SECTION */}
            <section className="container py-5 py-md-2 py-lg-4 pt-xl-5 mb-4 mb-md-5 bg-gradient-to-light">
                <div className="row align-items-center mt-2 mt-sm-0 pt-4 pt-sm-5">

                    <div className="col-md-6 offset-lg-1 col-lg-5 col-xxl-6 offset-xxl-1 order-md-1 pb-4 pb-md-0 mb-2 mb-sm-3 mb-md-0">
                        <img src={illustration2} className="img-fluid w-75 mx-auto me-lg-auto d-block" alt="" />
                    </div>

                    <div className="col-md-6 col-lg-6 col-xxl-5 order-md-2">
                        <div className="pe-md-4 pe-xl-0">
                            <h1 className="display-1 mb-3">
                                {t('landing:how_it_works')}
                            </h1>
                            <ul className="list-unstyled  pb-1 pb-lg-2 mb-4">
                                <li className="d-flex pb-1 mb-2"><i className="fa fa-check fs-4 me-2 text-primary text-primary fs-4 me-2" />{t('landing:guide_1')} </li>
                                <li className="d-flex pb-1 mb-2"><i className="fa fa-check fs-4 me-2 text-primary text-primary fs-4 me-2">
                                </i>{t('landing:guide_2')}</li>
                                <li className="d-flex pb-1 mb-2"><i className="fa fa-check fs-4 me-2 text-primary text-primary fs-4 me-2" />{t('landing:guide_3')}</li>
                                <li className="d-flex pb-1 mb-2"><i className="fa fa-check fs-4 me-2 text-primary text-primary fs-4 me-2" />{t('landing:guide_4')}</li>
                                <li className="d-flex pb-1 mb-2"><i className="fa fa-check fs-4 me-2 text-primary text-primary fs-4 me-2" />{t('landing:guide_5')}</li>
                            </ul>

                            <button type="button" className="btn btn-lg btn-primary" {...LOGIN_BUTTON} >{t('landing:get_started')}</button>
                        </div>
                    </div>
                </div>
            </section>

            {/* ⚠️ WARNING⚠️ */}
            {/* This component contains all auth components. Never change, move or delete it. Additional content can be added above or below */}
            <AuthBlock />

        </>
    )
}

export default LandingPage;