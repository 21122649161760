import React from "react";

export function FeatureCheckbox({ label, checked, onChange, icon }: { label: string, checked: boolean, icon: string, onChange: (event: any) => void }) {
    return (
        <div className="w-100 d-flex align-items-center mb-3 hoverable cursor-pointer" onClick={onChange}>
            {
                checked && (
                    <i className="fas fa-check-circle fa-2x text-success me-2"></i>
                )
            }
            {
                !checked && (
                    <i className="fas fa-times-circle fa-2x text-danger me-2"></i>
                )
            }
            <span>
                {label}
            </span>
            <i className={"ms-auto text-primary fas fa-fw fa-" + icon}></i>
        </div>
    );
}