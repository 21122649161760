import React from "react"
import { useTranslation } from "react-i18next";

function SectionTitle({ title, viewAll = false, onViewAll = () => { }, children, className }: { title: string, className?: string, viewAll?: boolean, onViewAll?: () => void, children?: React.ReactNode }) {
    const { t } = useTranslation();
    return (
        <div className={" d-flex justify-content-between " + (className ?? "mb-3")}>
            <h5 className="mb-0 text-body-emphasis">
                {title}
            </h5>
            {viewAll && <div className="text-danger cursor-pointer" onClick={onViewAll}>
                <small>
                    {t('common:view_all')}
                </small>
                <i className="fa fa-angle-right ms-1"></i>
            </div>}
            {children}
        </div>
    )
}
export default SectionTitle