import logo from 'assets/images/LOGO.png';
import logodark from 'assets/images/LOGODARK.png';
import coin from 'assets/images/cash.svg';

export const appThemes = {
    "CLASSIC": "CLASSIC",
    "SIDEBARS": "SIDEBARS",
};

/**
 * Represents the configuration settings for the application.
 */
export default class Config {

    static log = false;

    static _appTheme = appThemes.SIDEBARS;

    static get appTheme() {
        return Object.freeze(this._appTheme);
    }

    static production = true;

    static enabledFeatures = {
        "quiz": true, //TODO not yet fully implemented, will be used to enable/disable quiz features when it makes sense to do so (we only have quiz features for now)
        "shop": true,
        "shopManagement": true,
        "quizManagement": true,
        "partnerManagement": true,
        "userManagement": true,
        "languageManagement": true,
        "progressionManagement": true,
        "investingTools": true,
        "progression": true,
        "ranking": true,
        "videos": true,
        "materials": true,
        "companyManagement": true,
        "amplitude": true,
        "selfRegistration": false,
        "initialSurvey": true,
        "feedback": true,
        "tutorials": false,
        "chat": true,
    };


    /**
     * The base URL for file storage.
     * @type {string}
     */
    static fileStorageBaseUrl = "https://d1duwxmxeujazx.cloudfront.net/";
    static defaultFileStorageBaseUrl = "https://d1duwxmxeujazx.cloudfront.net/";

    /**
     * The maximum number of slides per unit. 
     * @type {number}
     */
    static maxSlidesPerUnit = 8;

    /**
     * The owning company name
     * @type {string}
     */
    static defaultCompanyName = "FunniFin";

    /**
     * The company logo.
     * @type {string}
     */
    static companyLogo = logo;
    static companyLogoDark = logodark
    static partnerLogo = null;

    /**
     * The regular expression for password validation.
     * @type {RegExp}
     */
    // eslint-disable-next-line no-useless-escape
    static passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-\/\|\+\=\"\!\@\#\%\&\,\>\<\'\:\;\_\~\`])[A-Za-z\d\S]{8,}$/;

    /**
     * The regular expression for email validation.
     * @type {RegExp}
     */
    static emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    /**
     * The URL for Amplitude API.
     * @type {string}
     */
    static amplitudeUrl = "https://amplitude.com/api/2/";

    /**
     * The API key for Amplitude.
     * @type {string}
     */
    static amplitudeApiKey = "c695bc983f33e4750e01bdcdbd1047cd";

    /**
     * The base URL for the API.
     * @type {string}
     */
    static baseUrl = 'https://welfare.funnifin.it/services/api/v1/';
    /* static baseUrl = 'https://api-test.fanta-trading.it/services/api/v1/'; */
    /*     static baseUrl = 'https://beewise.funnifin.it/services/openapi-ui/'; // DEV */
    /* static baseUrl = 'https://api-test.fanta-trading.it/services/api/v1/'; // DEV */
    //const baseUrl = 'https://api.fanta-trading.it/services/api/v1/'; //PROD
    // // beewise
    /**
     * Color scale for the charts.
     * @type {string[]}
        */
    static chartColors = ["#1cafb9", "#ff8360", "#e8e288", "#7a7265", "#832161", "#52050A", "#EEEEEE"];

    //  COGNITO
    static region = "eu-central-1"
    static userPoolId = 'eu-central-1_BOPTaWhrR';
    static userPoolWebClientId = "6na5c3ne8vntva8k67fvfiri20";
    static domain = "fanta-trading-cpw8au6e73h47vdj-test.auth.eu-central-1.amazoncognito.com";

    /* 
        static region = "eu-central-1"
    static userPoolId = 'eu-central-1_BOPTaWhrR';
    static userPoolWebClientId = "6na5c3ne8vntva8k67fvfiri20";
    static domain = "'fanta-trading-cpw8au6e73h47vdj-test.auth.eu-central-1.amazoncognito.com";
    */

    static landingPage = "split";
    // The alternate login image, if present, causes a different image to be shown in the heading of the login modal. If null, the company logo is shown.
    static alternateLoginImage = null;

    static instagramUrl = "https://www.instagram.com/funnifin_app/";
    static linkedinUrl = "https://www.linkedin.com/company/funnifin/";
    static partnerUrl = "https://www.kampaay.com/";

    static defaultDashboardPreferences = ['chat', 'mixed', 'video', 'ranking', 'quote'];

    static userHomepagePath = "/welfare/dashboard";

    static forceDarkMode = false;

    static coinImage = coin;
}

