import { AsyncChat } from "models/chatModels";
import React, { useEffect, useRef } from "react"
import CircleButton from "../CircleButton";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useChatStore } from "stores/chatStore";
import Loader from "../Loader";
import { useUserStore } from "stores/userStore";
import { useAuthStore } from "stores/authStore";
import Markdown from 'marked-react';
import { useSystemModalStore } from "stores/systemModalStore";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-hot-toast";
import { marked } from "marked";
import '@mdxeditor/editor/style.css'
import { useI18nStore } from "stores/i18nStore";
import FinLogo from "../FinLogo";
import { markdownGuide, markdownGuide_IT } from "./markdownGuide";
import ChatInitialView from "./ChatInitialView";
import AnimatedText from "components/AnimatedText";
import { ChatMessengerStates, useChatMessengerStore } from "stores/chatMessengerStore";
import ChatMessageElement from "./ChatMessageElement";
import ChatAILoaderMessage, { ChatAdminLoaderMessage, ChatUserLoaderMessage } from "./ChatLoaderMessages";

// Definizione delle varianti di animazione
const messageVariants = {
  hidden: {
  },
  visible: {
  },
  exit: {
    transition: {
      staggerChildren: 0.1,
    }
  },
};


export interface ChatProps {
  chat: AsyncChat | null;
  onMessageSent?: () => void;
}
let totalMessages = -1;

function ChatMessenger({ chat, onMessageSent }: ChatProps) {

  const status = useChatMessengerStore(state => state.status);
  const sendingMessage = useChatMessengerStore(state => state.sendingMessage);
  const { t } = useTranslation();
  const useAiChangedForTheFirstTime = useChatMessengerStore(state => state.useAiChangedForTheFirstTime);
  const uploadingDocument = useChatMessengerStore(state => state.uploadingDocument);
  const activeChat = useChatMessengerStore(state => state.activeChat);
  useEffect(() => {
    // Apri la chat se non è attiva e viene fornita una chat prop
    if (!activeChat && chat) {
      useChatMessengerStore.getState().openChat(chat);
    }

    // Passa a una chat diversa se la chat attiva e la nuova chat sono diverse
    if (activeChat && chat && activeChat.asyncChatId !== chat.asyncChatId) {
      useChatMessengerStore.getState().openChat(chat);
      return;
    }

    // Se non c'è una chat attiva e nessuna chat prop, non fare nulla
    if (!activeChat && !chat) {
      // non fare nulla
      return;
    }

    // Se c'è una chat attiva e non viene fornita alcuna chat prop, chiudi la chat attiva
    if (activeChat && !chat) {
      useChatMessengerStore.getState().closeChat();
    }

    // Aggiorna la chat attiva se sia activeChat che chat props sono presenti
    if (activeChat && chat) {
      useChatMessengerStore.getState().updateActiveChat(chat);
    }
  }, [chat, activeChat]);

  useEffect(() => {
    setTimeout(async () => {
      // scroll last message into view
      const chatMessages = document.querySelector('.ChatMessenger_messages');
      scrollDownMessages();
      /*       if (totalMessages !== activeChat?.asyncChatDetail.messages.length && totalMessages !== -1) {
              //play sound
              scrollDownMessages();
            } */
      /*       if (totalMessages === -1) {
              totalMessages = activeChat?.asyncChatDetail.messages.length;
              chatMessages?.scrollTo({
                top: chatMessages.scrollHeight,
                behavior: 'auto',
              });
            } */
      return () => {
        totalMessages = -1;
      }
    }, 70);
  }, [activeChat?.asyncChatDetail.messages.length]);

  const inputRef = useRef<HTMLInputElement>(null);

  const [formattedText, setFormattedText] = React.useState('');

  useEffect(() => {
    if (activeChat &&
      ((activeChat.asyncChatDetail.lastMessageType !== 'Users' && useAuthStore.getState().isUser) ||
        (activeChat.asyncChatDetail.lastMessageType === 'Users' && !useAuthStore.getState().isUser)
        || activeChat.asyncChatDetail.lastMessageType === 'System'
      ) &&
      !activeChat.asyncChatDetail.isRead
    ) {
      useChatStore.getState().markAsRead(activeChat);
    }
  }, [activeChat]);

  useEffect(() => {
    totalMessages = -1;
  }, [chat?.asyncChatId]);

  useEffect(() => {
    if (activeChat?.asyncChatDetail.status !== "PROCESSED" && activeChat?.asyncChatDetail.useAI && useAuthStore.getState().isUser) {
      scrollDownMessages();
    }
  }, [activeChat?.asyncChatDetail.status, activeChat?.asyncChatDetail.useAI]);

  useEffect(() => {
    if (useAiChangedForTheFirstTime) {
      return;
    }
    if (useAuthStore.getState().isUser) {
      if (activeChat?.asyncChatDetail.useAI === true) {
        toast('FinAi è pronto a rispondere alle tue domande', {
          icon: <FinLogo width="32" />,
        });
      } else if (activeChat?.asyncChatDetail.useAI === false) {
        toast('Il nostro team è pronto a rispondere alle tue domande', {
          icon: <i className="fa-duotone fa-user-tie" />,
        });
      }
    }
  }, [activeChat?.asyncChatDetail.useAI, useAiChangedForTheFirstTime]);

  const onMessageSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const message = (event.target as any).elements[0].value;
    if ((message === '' || !message) && uploadingDocument) {
      useSystemModalStore.getState().showWarningModal(t('chat:warning_empty_message'));
    };
    if (message) {
      if (activeChat) {
        useChatMessengerStore.getState().sendMessage(message).then(() => {
          onMessageSent && onMessageSent();
          scrollDownMessages();
        });
      } else {
        useChatMessengerStore.getState().openNewChat(message);
      }

      (event.target as any).elements[0].value = '';
    } else {
      toast.error(t('chat:warning_empty_message'));
    }
  };

  const scrollDownMessages = () => {
    setTimeout(() => {
      const chatMessages = document.querySelector('.ChatMessenger_messages');
      chatMessages?.scrollTo({
        top: chatMessages.scrollHeight,
        behavior: 'smooth',
      });
    }, 200);
  }
  const handleDocumentSelect = (file: File) => {
    useChatMessengerStore.getState().requestDocumentUpload({
      format: file.type,
      title: file.name,
      ownerId: useUserStore.getState().user?.userProfileId!,
      type: 'chat',
      file: file,
    });
  }
  const chatButtonRef = useRef<HTMLButtonElement>(null);
  const handleInput = () => {
    if (inputRef.current) {
      const text = marked(inputRef.current.value);
      setFormattedText(text as string);
    }
  };
  const [isDragging, setIsDragging] = React.useState(false);
  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
    // Aggiungi eventuali stili per indicare che l'area è pronta per il drop
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setIsDragging(false);
    }
    // Rimuovi eventuali stili aggiunti in handleDragOver
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      // Verifica che il file sia uno dei tipi supportati
      if (!file.type.match(/text\/*|application\/pdf/)) {
        toast.error(t('chat:unsupported_file_type'));
        return;
      }

      // Gestisci il caricamento del file
      handleDocumentSelect(file);
      // Puoi aggiungere qui la logica per caricare il file al server
    }
  };

  /*   useEffect(() => {
      const handleWindowDragOver = (event: DragEvent) => {
        event.preventDefault();
      };
  
      const handleWindowDrop = (event: DragEvent) => {
        event.preventDefault();
      };
  
      window.addEventListener('dragover', handleWindowDragOver);
      window.addEventListener('drop', handleWindowDrop);
  
      return () => {
        window.removeEventListener('dragover', handleWindowDragOver);
        window.removeEventListener('drop', handleWindowDrop);
      };
    }); */

  return (
    <div className="ChatMessenger d-flex flex-column flex-fill position-relative"
      style={{ height: "100%" }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <style>
        {`
         .ChatMessageElement {
          animation: fadeIn 0.5s;
        }
        .ChatMessageElement code{
          color: var(--bs-body-text) !important;
        }
        .ChatMessageElement pre {
          color: var(--bs-body-text) !important;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}
      </style>
      {(status === ChatMessengerStates.Running) && activeChat && <motion.div
        variants={messageVariants}
        initial="hidden"
        animate="visible"
        className="ChatMessenger_messages overflow-auto overflow-x-hidden custom-scrollbar d-flex flex-column px-3 pb-3" style={{
          position: 'relative',
          flex: '1 1 0px',
        }}>
        {/* REGULAR CHAT MESSAGES */}
        <AnimatePresence>
          {activeChat.asyncChatDetail.messages.map((message, index) => (
            <ChatMessageElement index={index} chatId={activeChat.asyncChatId} key={"chat-message-" + activeChat.asyncChatId + "-" + index} message={message} prevMessage={activeChat?.asyncChatDetail.messages[index - 1]} onDocumentSelect={handleDocumentSelect} />
          ))}
        </AnimatePresence>

        {/* LOADER MESSAGE FOR USER (CHAT IS LOADED; MESSAGE IS BEING SENT) */}
        {sendingMessage && useAuthStore.getState().isUser && <ChatUserLoaderMessage />}

        {/* LOADER MESSAGE AI (CHAT IS LOADED; MESSAGE IS BEING SENT) */}
        {activeChat.asyncChatDetail.status !== "PROCESSED" && activeChat.asyncChatDetail.useAI && useAuthStore.getState().isUser && <ChatAILoaderMessage />}

        {/* LOADER MESSAGE FOR ADMIN  (CHAT IS LOADED; MESSAGE IS BEING SENT, OPERATOR IS ADMIN)*/}
        {sendingMessage && !useAuthStore.getState().isUser && !activeChat.asyncChatDetail.useAI && <ChatAdminLoaderMessage />}

        {/* NO MESSAGES (LOADED; CHAT; NO MESSAGES IN CHAT; NOT HAPP NORMALLY) */}
        {
          activeChat?.asyncChatDetail?.messages?.length === 0 && !(status === ChatMessengerStates.LoadingChat) &&
          <div className="ChatMessenger__empty d-flex justify-content-center align-items-center">
            {t('chat:no_messages')}
          </div>
        }
      </motion.div>}
      {/* INITIAL VIEW (NO CHAT BUT LOADED) */}
      {
        (status === ChatMessengerStates.Initial) && <ChatInitialView />
      }
      {/* PRE LOADER  (MOSTRATO DURANTE CREAZIONE CHAT)*/}
      {
        (status === ChatMessengerStates.LoadingChat) && <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }} className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Loader></Loader>
        </motion.div>
      }
      {/* CHAT INPUT */}
      <div className="ChatMessenger__input d-flex flex-column rounded-top " style={{ width: "inherit" }}>
        {
          uploadingDocument && !sendingMessage && !(status === ChatMessengerStates.LoadingChat) && <div className="d-flex flex-row align-items-center px-2">
            {uploadingDocument.format === 'application/pdf' && <i className="fas me-2 fa-file-pdf" />}
            {uploadingDocument.format !== 'application/pdf' && <i className="fas me-2 fa-file-image" />}
            <span className="text-bold">{uploadingDocument.title}</span>
            <Button variant="link" className="text-body-tertiary ms-auto" onClick={() => {
              useChatMessengerStore.getState().cancelDocumentUpload();
            }}>
              <i className="fas fa-times"></i>
            </Button>
          </div>
        }
        {
          isDragging && <div className="ChatMessenger_dragAlert d-flex flex-row align-items-center px-2 py-3 w-100 card justify-content-center mb-3 bg-primary-subtle text-primary"
          >
            <i className="fas me-3 fa-file-upload h4 mb-0" />
            <span className="text-bold h4 mb-0">Rilascia il file per caricarlo</span>
          </div>
        }
        <form

          name="Funnifin Chat Message"
          id="ChatMessengerForm"
          onSubmit={onMessageSubmit}
          className="flex-fill border bg-light border-1 border-secondary-subtle  rounded d-flex mt-3">
          <input autoFocus type="text" className="form-control-plaintext p-3" placeholder={t('chat:placeholder')} />
          {
            !useAuthStore.getState().isAdmin && <i className="fas fa-question-circle text-body-secondary me-2 my-auto small" onClick={() => {
              useSystemModalStore.getState().showInfoModal('chat:help', <>
                <Markdown
                  openLinksInNewTab={true}
                  value={useI18nStore.getState().currentLanguage === "it" ? markdownGuide_IT : markdownGuide} />
              </>, 'fa-question-circle');
            }}></i>
          }
          {/*           <button onClick={() => ref.current?.setMarkdown('new markdown')}>Set new markdown</button>
          <button onClick={() => console.log(ref.current?.getMarkdown())}>Get markdown</button>
          <MDXEditor ref={ref} markdown="hello world" onChange={console.log} /> */}
          {/*           <MDXEditor markdown="# Hello world" plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), thematicBreakPlugin()]} />
 */}{/*           <MDXEditor markdown={t('chat:placeholder')} onChange={console.log} />
 */}
          {/*           <div
            className="markdown-preview"
            dangerouslySetInnerHTML={{ __html: formattedText }}
          /> */}
          <div className="my-auto pe- d-flex flex-row px-2">
            {activeChat && <CircleButton iconClassName="fas fa-paperclip" iconVariant="secondary" variant="white" className="me-2 text-white"
              onClick={(event) => {
                event.preventDefault();
                // open file select dialog
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = '.pdf,.txt,.pptx,.tex,.doc,.docx';
                fileInput.onchange = (e) => {
                  if (fileInput.files) {
                    handleDocumentSelect(fileInput.files[0]);
                  }
                };
                fileInput.click();
              }}
            ></CircleButton>}
            <CircleButton variant="primary" iconClassName="fas fa-paper-plane"
              onClick={(event) => {
                event.preventDefault();
                if (!sendingMessage) {
                  chatButtonRef.current?.click();
                }
              }}
            ></CircleButton>
            <button ref={chatButtonRef} type="submit" style={{ display: 'none' }}></button>
          </div>
        </form>
      </div>
    </div >
  )
}




export default ChatMessenger

