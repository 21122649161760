import ChatMessenger from "components/ChatMessenger/ChatMessenger";
import Chat from "components/ChatMessenger/ChatMessenger";
import CircleButton from "components/CircleButton";
import Loader from "components/Loader";
import { use } from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useChatStore } from "stores/chatStore";
import { useUserStore } from "stores/userStore";

function ChatPage() {
    const { t } = useTranslation();
    const chat = useChatStore((state) => state.activeChat);
    const ready = useChatStore((state) => state.ready);
    const currentUser = useUserStore((state) => state.user);
    const navigate = useNavigate();
    useEffect(() => {
        if (currentUser) {
            useChatStore.getState().getChatData(currentUser);
        }
    }, [currentUser]);
    useEffect(() => {
        const interval = setInterval(() => {
            if (chat) {
                useChatStore.getState().getChatData(useUserStore.getState().user!);
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    },);
    return (
        <section className='h-100 d-flex flex-column bg-primary'>
            <div className='col-12  mx-auto d-flex justify-content-start align-items-center pt-5 pb-3 px-3'>
                <CircleButton onClick={() => navigate(-1)} className="" iconClassName={'far text-white fa-2x fa-times'}></CircleButton>
                <h3 className="text-light mb-0 ms-2 pt-1">{t('chat:title')}</h3>
            </div>
            {!ready && <Loader />}
            {ready && <div className="col-12 mx-auto flex-fill bg-primary">
                <ChatMessenger chat={chat}
                />
            </div>}
        </section>
    );
}
export default ChatPage