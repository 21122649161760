import CircleButton from 'components/CircleButton';
import HeaderAdmin from 'components/HeaderAdmin';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { useI18nStore } from 'stores/i18nStore';
import { getFlagEmoji, useTitle } from 'utils/functions';

function LanguageManagerPage() {
    const quizLanguages = useI18nStore(state => state.quizLanguages);
    const currentLanguage = useI18nStore(state => state.currentLanguage);
    const createLanguage = useI18nStore(state => state.createQuizLanguage);
    const setLanguage = useI18nStore(state => state.setLanguage);
    const deleteQuizLanguage = useI18nStore(state => state.deleteQuizLanguage);
    const appLanguages = useI18nStore(state => state.appLanguages);
    //usestate for language code
    const [languageCode, setLanguageCode] = React.useState('');
    const { t } = useTranslation();
    useTitle(t('language_manager:title'));
    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            toast.promise(createLanguage(languageCode.toLowerCase()), {
                loading: t('common:saving'),
                success: t('common:saved'),
                error: t('errors:could_not_save')
            });
            e.target.reset();
        }
        catch (e) {
            console.log('ERROR: createLanguage', e)
        }
    };

    return (
        <section className='LanguageManagerPage d-flexflex-column'>
            <HeaderAdmin title='language_manager:title'>
                <CircleButton data-bs-toggle="modal" data-bs-target="#languageModal" className={'btn-primary '} iconClassName='fas fa-plus' />
            </HeaderAdmin>
            <div className="card mb-3">
                <div className='card-body'>
                    <h6 className="card-title">
                        {t('language_manager:available_quiz_languages')}
                    </h6>
                    {quizLanguages.map((language) => {
                        return (
                            <div key={language} className="my-3 d-flex align-items-center">
                                {getFlagEmoji(language)}
                                <div className="flex-fill ms-2"><strong>
                                    {language.toUpperCase()}
                                </strong>
                                    <LanguageAvailableBadge language={language} appLanguages={appLanguages} t={t} />
                                </div>
                                {/* create */}
                                <CircleButton onClick={() => setLanguage(language)} className={'ms-auto ' + (language === currentLanguage ? "btn-success" : "btn-secondary")} iconClassName={'fas ' + (language === currentLanguage ? "fa-check" : "fa-circle")} />
                                {/* delete */}
                                <CircleButton disabled={quizLanguages?.length <= 1} onClick={() => { if (window.confirm(t('language_manager:delete_language_confirm'))) deleteQuizLanguage(language) }} className={'ms-2 btn-danger'} iconClassName={'fas fa-trash'} />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="modal fade" id="languageModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{t('language_manager:add_language')}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={onSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="languageCode" className="form-label">{t('language_manager:language_code')}</label>
                                    <br></br>
                                    <small className="form-text text-muted">{t('language_manager:language_code_hint')}
                                    </small>
                                    <div className="input-group mb-3 mt-2">
                                        <input type="text" className="form-control" id="languageCode" value={languageCode} maxLength={2} minLength={2} required onChange={(event) => setLanguageCode(event.target.value.toUpperCase())} />
                                    </div>
                                    <div className="">
                                        {t('language_manager:detected_language')}:&nbsp;&nbsp;
                                        {getFlagEmoji(languageCode)}
                                    </div>

                                </div>
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">{t('language_manager:add_language')}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>



            {/* PREVIEW */}
        </section>
    )
}

function LanguageAvailableBadge({ language, appLanguages, t }) {
    if (appLanguages?.includes(language.toLowerCase())) {
        return (
            <small className="badge ms-3 bg-success">
                <i className='fas fa-check'></i> {t('language_manager:app_language_available')}
            </small>
        );
    } else {
        return (
            <>
                <small className="badge ms-3 bg-warning" data-tooltip-id="my-tooltip" >
                    ⚠️ {t('language_manager:app_language_not_available')}
                </small>
                <Tooltip id="my-tooltip">
                    {t('language_manager:app_language_hint')}
                </Tooltip>
            </>
        );
    }
}

export default LanguageManagerPage