
import React, { forwardRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/* import coin from "../assets/coin.svg"; */
import { useGemsStore } from 'stores/gemsStore';
import Config from "utils/config";

const GemCounter = forwardRef((props, ref) => {
    const currentGemsData = useGemsStore((state) => state.currentGemsData);
    const navigate = useNavigate();
    // init gems if currentGemsData is null
    useEffect(() => {
        if (currentGemsData === null) {
            useGemsStore.getState().getUserGems();
        }
    }, [currentGemsData]);
    return (
        <div ref={ref} className="GemCounter mx-2 d-flex cursor-pointer" onClick={() => navigate("/welfare/shop")}>
            <img
                src={Config.coinImage}
                className="me-1"
                alt="coin"
                style={{ height: "1.5rem" }}
            ></img>
            {currentGemsData && <h5 className="mb-0">{currentGemsData?.gemDetail.currentGems ?? "   0"}</h5>}
            {!currentGemsData && <h5 className="mb-0">&nbsp;&nbsp;&nbsp;&nbsp;</h5>}
        </div>
    );
});

export default GemCounter;