import GemCounter from 'components/GemCounter';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import lifeShop from "../../assets/images/life-shop.png";
import { useShopStore } from 'stores/shopStore';
import Loader from 'components/Loader';
import Config, { appThemes } from 'utils/config';
import TutorialTour from 'components/TutorialTour';
import { useI18nStore } from 'stores/i18nStore';
import PageTitle from 'components/PageTitle';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'utils/functions';
import { AnimatePresence, motion } from 'framer-motion';
import HeaderWidgetPortal from 'components/HeaderWidgetPortal';
import LifepointCounter from 'components/LifepointCounter';
import XpCounter from 'components/XpCounter';


function ShopPage() {
    const navigate = useNavigate();
    const shopItems = useShopStore(state => state.shopItems);
    const initShop = useShopStore(state => state.initShop);
    const getTranslation = useI18nStore(state => state.getTranslation);
    const { t } = useTranslation();
    useTitle(t('main_menu:shop'));
    // useEffect for initShop
    useEffect(() => {
        if (shopItems === null) {
            console.log('🛒 ShopPage: useEffect for initShop');
            initShop();
        }
    }, [shopItems, initShop]);

    if (!shopItems) { return (<Loader />); }
    const variantChildren = {
        "initial": {
            opacity: 0,
            scale: 0.4,
            y: 100,
        },
        "animate": {
            opacity: 1,
            scale: 1,
            y: 0,
        },
        "hover": {
            /*     y: -16, */
            /*             scale: 1.05,
                        zIndex: 100000, */
            transition: {
                type: "spring",
                bounce: 0,
            }
        }
    };
    const slashMotion = {
        "hover": {
            scale: 1.17,
            transition: {
                type: "spring",
                bounce: 0,
            }
        },
        transition: {
            type: "spring",
            bounce: 0,
        }
    };
    const variantContainer = {
        "animate": {
            transition: {
                duration: 0.15,
                staggerChildren: 0.5
            }
        },
        "hover": {
            transition: {
                type: "spring",
                bounce: 0.5,
            }
        },
        "initial": {
        }
    }
    return (
        <section className={`ShopPage d-flex flex-column`}>
            {
                Config.appTheme === appThemes.SIDEBARS && <HeaderWidgetPortal>
                    <div className='ms-auto d-flex'>
                        <LifepointCounter />
                        <XpCounter />
                        <GemCounter />
                    </div>
                </HeaderWidgetPortal>
            }
            <TutorialTour tutorialName="shop" />
            <div className="d-flex align-items-center">
                {Config.appTheme === appThemes.CLASSIC && <i className="fa-duotone fa-times fa-2x me-4 cursor-pointer" onClick={() => navigate(-1)} />}
                {Config.appTheme === appThemes.CLASSIC && <PageTitle title={t('main_menu:shop')}>
                    <div className="d-flex ms-auto">
                        <GemCounter />
                    </div>
                </PageTitle>}
            </div>
            <h5 className='mb-2'>
                {t('shop:subtitle')}
            </h5>
            {/*Grid with fake masonry?  -> https://getbootstrap.com/docs/5.3/examples/masonry/ */}
            <AnimatePresence mode='popLayout'>
                {shopItems && <motion.div
                    initial="initial"
                    animate="animate"
                    /* key={shopItems.length} */
                    variants={variantContainer}
                    className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1  g-3 mb-4 pt-3" >
                    {/*Col */}
                    {shopItems.filter(
                        (shopItem) => shopItem.shopItemDetail.counter > 0
                    ).map(
                        (shopItem) =>
                            <motion.div
                                whileHover={"hover"}
                                key={shopItem.shopItemId}
                                variants={variantChildren}
                                className="ShopItemCard col" onClick={() => navigate('./' + shopItem.shopItemId, {
                                    // @ts-ignore
                                    relative: true
                                })}>
                                {/*Card Shop */}
                                <div className="card h-100 cursor-pointer">

                                    <div className="card-img-top" style={{ clipPath: "padding-box" }}>
                                        <motion.img
                                            variants={slashMotion} src={shopItem.shopItemDetail.bannerId}
                                            alt={""}
                                            style={{ width: "100%", height: "128px", objectFit: "cover", objectPosition: "0 70%" }} />
                                    </div>
                                    {/*Indicator coin*/}
                                    <div className='d-flex position-absolute px-3 py-3 start-0 top-0'>
                                        <h4 className='mb-0 fw-bolder text-white' style={{
                                            // text outline effect
                                            WebkitTextStroke: "1px var(--bs-dark)",
                                            //font shadow black
                                            textShadow: "1px 1px 1px #000000",
                                            color: "var(--bs-white)"
                                        }}>
                                            {shopItem.shopItemDetail.gems}
                                        </h4>
                                        <img
                                            src={Config.coinImage}
                                            className="img-fluid ms-2 me-1"
                                            alt="coin"
                                            style={{ height: "1.4rem" }}
                                        ></img>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">{getTranslation(shopItem.shopItemDetail.title)}</h5>
                                        <span className=" fs-xs">{getTranslation(shopItem.shopItemDetail.descriptionBrief)}</span>
                                    </div>
                                </div>
                            </motion.div>)}

                </motion.div>}

            </AnimatePresence>


        </section >

    )
}

export default ShopPage