import React from "react";
import { Form, FormControl, FormGroup, FormLabel, FormSelect } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useI18nStore } from "stores/i18nStore";
import { getFlagEmoji } from "utils/functions";
import { useState, useMemo } from "react";
import { VideoDetail } from "models/videoModels";

function VideoEditor(
    { video, thumbnail: videoImage, setVideoThumbnail, handleVideoChange }
        : {
            video: VideoDetail,
            thumbnail: File | null,
            setVideoThumbnail: (file: File) => void,
            handleVideoChange: (video: VideoDetail) => void,
        }
) {
    const { t } = useTranslation();
    const [activeLanguage, setActiveLanguage] = React.useState("it");
    const languages = useI18nStore((state) => state.quizLanguages);
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);
/*     const description = useMemo(() => getTranslationWithLanguage(video.description, activeLanguage), [video.description, activeLanguage, getTranslationWithLanguage]);
 */    return (
        <div>
            <Form >
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        {(languages ?? []).map((language: string) =>
                            <button key={language} className={"nav-link text-secondary d-flex flex-column align-items-center " + (language === activeLanguage ? "active" : "")}
                                onClick={() => setActiveLanguage(language)} type="button">
                                <div className="text-body-emphasis fw-bold text-uppercase"
                                >{language.toUpperCase()}
                                </div>
                                <div>
                                    {getFlagEmoji(language)}
                                </div>
                            </button>
                        )}
                    </div>
                </nav>


                <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                    {/* <div className="tab-pane fade active show" id="nav-argument-icon" role="tabpanel" aria-labelledby="nav-argument-icon-tab">
        <h1>{fontIcon}</h1>
        {!showEmojiPicker && <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <i className='fas fa-plus' /> {t('forms:emoji')}
        </button>}
        {showEmojiPicker && <EmojiPicker autoFocusSearch={true} onEmojiClick={onEmojiClicked} />}
    </div> */}
                    {(languages ?? []).map((language: string) =>
                        <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
                            <div className="d-flex flex-column h-100">
                                <FormGroup className="mb-3">
                                    <FormLabel htmlFor="videoTitle">{t('forms:title')}</FormLabel>
                                    <FormControl type="text" id="videoTitle" placeholder={t('forms:title')} name={"title_" + language}
                                        value={getTranslationWithLanguage(video.title, language)}
                                        onChange={(e) => handleVideoChange({
                                            ...video,
                                            title: {
                                                ...video.title,
                                                [language]: e.target.value,
                                            },
                                        })} />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <FormLabel htmlFor="videoDescriptionBrief">{t('forms:description_brief')}</FormLabel>
                                    <FormControl as="textarea" id="videoDescriptionBrief" placeholder={t('forms:description')} name={"description_" + language}
                                        value={getTranslationWithLanguage(video.description, language)}
                                        onChange={(e) => handleVideoChange({
                                            ...video,
                                            description: {
                                                ...video.description,
                                                [language]: e.target.value,
                                            },
                                        })} />
                                </FormGroup>
                            </div>
                            <FormGroup className="">
                                <FormLabel htmlFor="videoUrl">URL</FormLabel>
                                <FormControl type="text" id="videoUrl" placeholder={t('forms:url')} name={"url_" + language}
                                    value={video.uri}
                                    onChange={(e) => handleVideoChange({
                                        ...video,
                                        uri: e.target.value,
                                    })} />
                            </FormGroup>
                        </div>)}
                </div>
                {/* 
                    Options:
                              "Lettura della busta paga",
          "Detrazioni fiscali",
          "Fondo pensione",
          "Investimento ",
          "TFR"
                */}
                <Form.Group className="mb-3">
                    <Form.Label className="mb-0">{t('video_manager:category')}</Form.Label>
                    <br></br>
                    <small className="mb-2 d-block text-muted">{t('video_manager:category_description')}</small>
                    <div>
                        {[
                            "Lettura della busta paga",
                            "Detrazioni fiscali",
                            "Fondo pensione",
                            "Investimento ",
                            "TFR"
                        ].map((category) => (
                            <Form.Check
                                key={category}
                                type="checkbox"
                                id={`category-${category}`}
                                label={category}
                                checked={video.category.includes(category)}
                                onChange={() => {
                                    const newCategories = video.category.includes(category)
                                        ? video.category.filter((c) => c !== category)
                                        : [...video.category, category];
                                    handleVideoChange({
                                        ...video,
                                        category: newCategories,
                                    });
                                }}
                            />
                        ))}
                    </div>
                </Form.Group>
                <Form.Group>
                    <label className="form-label">{t('video_manager:video_picture')}</label>
                    <div id="video-banner-image" className="d-flex flex-column mb-3">
                        {!(video.thumbnail || videoImage) && <i className="mb-2">{t('forms:messages.no_image_selected')}</i>}
                        {((video.thumbnail || videoImage)) && <img src={videoImage != null ? URL.createObjectURL(videoImage) : (video.thumbnail ?? "")} className="img-fluid object-fit-contain border rounded mb-3" alt="Slide " />}

                        <input onChange={(event) => {
                            const files = event.target.files;
                            if (files != null && files[0].type.startsWith('image/')) {
                                setVideoThumbnail(files[0]);
                            } else {
                                // Handle invalid file type
                            }
                        }} className="form-control" type="file" id="formFile" accept="image/*" />

                        {((video.thumbnail || videoImage) /* && !deleteBannerImage */) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {

                        }} value={video.thumbnail ?? ""}>
                            <i className='fas fa-trash' /> {t('common:delete')} Logo
                        </button>}
                    </div>
                    <div id="form-group" className="d-flex flex-column mb-3">
                        <label className="form-label">{t('video_manager:video_picture_alt')}</label>
                        <input type="text" className="form-control" placeholder={t('video_manager:video_picture_alt')}
                            onChange={(e) => handleVideoChange({
                                ...video,
                                thumbnail: e.target.value,
                            })} />
                    </div>
                </Form.Group>
                <FormGroup className="mb-3">
                    <FormLabel htmlFor="videoTitle">{t('common:minutes')}</FormLabel>
                    <FormControl type="number" id="videoMinutes" placeholder={t('common:minutes')} name="minutes"
                        value={video.minutes}
                        onChange={(e) => handleVideoChange({
                            ...video,
                            minutes: parseFloat(e.target.value),
                        })} />
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel htmlFor="videoTitle">{t('forms:order')}</FormLabel>
                    <FormControl type="number" id="videoOrder" placeholder={t('forms:order')} name="order"
                        value={video.order}
                        onChange={(e) => handleVideoChange({
                            ...video,
                            order: parseInt(e.target.value),
                        })} />
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel htmlFor="videoType">Host</FormLabel>
                    <FormSelect id="videoType" name="type" value={video.type} onChange={(e) => handleVideoChange({
                        ...video,
                        type: e.target.value,
                    })}>
                        <option value="vimeo">Vimeo</option>
                        <option value="youtube">Youtube</option>
                    </FormSelect>
                </FormGroup>
            </Form>
        </div>
    )
}
export default VideoEditor