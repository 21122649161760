import { UserProfile } from "models/userModels";
import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useI18nStore } from "./i18nStore";
import { useEffect, useState } from "react";
interface UsersStoreState {
    usersList: UserProfile[] | null;
    loading: boolean;
    initUsers: () => Promise<any | null>;
    createUsers: (users: UserCreationData[]) => Promise<any | null>;
    createAdmins: (admins: UserCreationData[]) => Promise<any | null>;
    deleteUser: (userId: string) => Promise<any | null>;
    deleteUserData: (userId: string) => Promise<any | null>;
    getUser: (userId: string) => Promise<UserProfile | null>;
    resetUserPassword: (userId: string) => Promise<any | null>;
    editUser: (user: UserProfile) => Promise<any | null>;
}

interface UserCreationData {
    name: string;
    email: string;
    surname: string;
    companyId: string;
}

export const useUsersStore = create<UsersStoreState>()((set, get, __) => ({
    usersList: null,
    loading: false,
    initUsers: async () => {
        if (get().loading) {
            return;
        }
        set({ loading: true });
        try {
            let users = await ApiService.users.search();
            if (users !== null) {
                set({ usersList: users });
                return users;
            } else {
                return null;
            }
        } catch {
            console.error('Error fetching users');
            return null;
        } finally {
            set({ loading: false });
        }
    },
    createUsers: async (users: UserCreationData[]) => {
        try {
            let usersResultToSend = users.map((user) => {
                return {
                    userProfileDetail: {
                        companyId: user.companyId,
                        confirmed: false,
                        dateLastUpdate: new Date().toISOString(),
                        email: user.email,
                        emailVerified: false,
                        enabled: true,
                        locale: useI18nStore.getState().currentLanguage ?? 'en',
                        mobilePhoneNumber: '',
                        mobilePhoneVerified: false,
                        name: user.name,/* 
                        group: 'User', */
                        registrationDate: new Date().toISOString(),
                        surname: user.surname
                    }
                };
            });
            if (usersResultToSend.length !== 0) {
                const chunkSize = 20;
                const usersChunks = [];

                for (let i = 0; i < usersResultToSend.length; i += chunkSize) {
                    usersChunks.push(usersResultToSend.slice(i, i + chunkSize));
                }
                let newUsers
                for (let i = 0; i < usersChunks.length; i++) {
                    newUsers = await ApiService.users.create(usersChunks[i]);
                }
                if (newUsers) {
                    // feetch the users again
                    let users = await ApiService.users.search();
                    if (users !== null) {
                        set({ usersList: users });
                        return users;
                    } else {
                        throw new Error('user_email_exists');
                    }
                } else {
                    throw new Error('user_email_exists');
                }
            } else {
                return null;
            }
        } catch (e) {
            throw new Error('user_email_exists');
        }
    },
    createAdmins: async (admins: UserCreationData[]) => {
        try {
            let adminsResultToSend = admins.map((admin) => {
                return {
                    userProfileDetail: {
                        companyId: admin.companyId,
                        confirmed: false,
                        dateLastUpdate: new Date().toISOString(),
                        email: admin.email,
                        emailVerified: false,
                        enabled: true,
                        locale: useI18nStore.getState().currentLanguage ?? 'en',
                        mobilePhoneNumber: '',
                        mobilePhoneVerified: false,
                        name: admin.name,
                        group: 'Admin',
                        registrationDate: new Date().toISOString(),
                        surname: admin.surname
                    }
                };
            });
            if (adminsResultToSend.length !== 0) {
                const newAdmins = await ApiService.users.create(adminsResultToSend);
                if (newAdmins) {
                    // feetch the users again
                    let users = await ApiService.users.search();
                    if (users !== null) {
                        set({ usersList: users });
                        return users;
                    } else {
                        return null;
                    }
                }
            } else {
                return null;
            }
        } catch {
            return null;
        }
    },
    deleteUser: async (userId: string) => {
        const result = await ApiService.users.delete(userId);
        if (result) {

            // feetch the users again
            let users = await ApiService.users.search();
            if (users !== null) {
                set({ usersList: users });
                return users;
            } else {
                return null;
            }
        }
    },
    deleteUserData: async (userId: string) => {
        const result = await ApiService.users.deleteData(userId);
        if (result) {
            // feetch the users again
            let users = await ApiService.users.search();
            if (users !== null) {
                set({ usersList: users });
                return users;
            } else {
                return null;
            }
        }
    },
    resetUserPassword: async (userId: string) => {
        try {
            const result = await ApiService.users.resetPassword(userId);
            if (result) {
                return result;
            }
        } catch {
            return null;
        }
    },
    getUser: async (userId: string) => {
        if (get().usersList && get().usersList?.find((user) => user.userProfileId === userId)) {
            return get().usersList?.find((user) => user.userProfileId === userId) ?? null;
        } else {
            const user = await ApiService.users.get(userId);
            return user;
        }
    },
    editUser: async (user: UserProfile) => {
        try {
            const result = await ApiService.users.put(user);
            if (result) {
                // feetch the users again
                let users = await ApiService.users.search();
                if (users !== null) {
                    set({ usersList: users });
                    return users;
                } else {
                    return null;
                }
            }
        } catch {
            return null;
        }
    }
}));

export function useFetchUser(userId: string) {
    const [user, setUser] = useState<UserProfile | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        setLoading(true);
        if (userId == null) {
            setLoading(false);
            return;
        }
        useUsersStore.getState().getUser(userId).then((user) => {
            if (user == null) {
                throw new Error('User not found');
            }
            setUser(user);
            setLoading(false);
        }).catch((error) => {
            setError(error);
            setLoading(false);
        });

    }, [userId]);

    return { user, loading, error };
}