import { Widget } from "@typeform/embed-react";
import React from "react";
import { Modal } from "react-bootstrap";
import { useFeedbackStore } from "stores/feedbackStore";

function FeedbackModal() {
    /*   const { t } = useTranslation();
     */  const isFeedbackModalVisible = useFeedbackStore(state => state.isFeedbackModalVisible);
    const onFeedbackSubmit = useFeedbackStore(state => state.onFeedbackSubmit);
    const currentFeedback = useFeedbackStore(state => state.currentFeedback);
    const feedbackData = useFeedbackStore(state => state.feedbackData);
    return (
        <Modal
            show={isFeedbackModalVisible}
            onHide={() => onFeedbackSubmit(currentFeedback ?? "")}
            centered
            size='lg'
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <Widget id={currentFeedback ?? "NO_FEEDBACK"}
                    /* 
                      className="flex-fill" */
                    autoResize={true}
                    autoFocus
                    onClose={() => currentFeedback && onFeedbackSubmit(currentFeedback)}
                    onSubmit={() => currentFeedback && onFeedbackSubmit(currentFeedback)}
                    onEndingButtonClick={() => currentFeedback && onFeedbackSubmit(currentFeedback)}
                    hubspot={true}
                    hidden={{
                        subject: feedbackData ?? 'NO_SUBJECT',
                    }}></Widget>
            </Modal.Body>
        </Modal>);
}
export default FeedbackModal