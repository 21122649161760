
import React from 'react';
import { useTranslation } from 'react-i18next';
import AuthBlock from 'pages/auth/AuthBlock';
import { LOGIN_BUTTON } from 'pages/auth/login/LoginComponents';
import scemo from 'assets/images/cover.png';
import Config from 'utils/config';
import FunnifinLogo from 'components/FunnifinLogo';
/* import { useI18nStore } from 'stores/i18nStore';
 *//* import LanguageSelector from 'components/LanguageSelector';
*/
/* 
    Display the landing page

    This page is shown to unauthenticated users at the root of the website
    It is meant to be customized to the client's needs
    Do not modify this file directly. Use it as a template to create a new page in src/pages/landing if you need to.

    The AuthBlock component is used to display the login and register modals, as well as the forgot password modal.
    It should never be removed, moved around or wrapped in other components. Other content can be added above or below it.

    Everything else is customizable. You can add or remove components, change the layout, etc.

    Translations are to be taken from "landing" namespace. Example:
        {t('landing:title_1')} will display the translation for "title_1" in the landing namespace
    
    To create a login button, use the LOGIN_BUTTON constant as props for the button. Example:
        <button type="button" className="btn btn-lg btn-primary" {...LOGIN_BUTTON}>Login</button>
    Do not add other bootstrap data properties to the button, they are already included in LOGIN_BUTTON and will conflict.

*/
function LandingPage() {
    const { i18n, t } = useTranslation();
    /*     const setQuizLanguage = useI18nStore((state) => state.setLanguage);
     */
    const setLanguage = (language) => {
        i18n.changeLanguage(language);
        /*       setQuizLanguage(language.substring(0, 2)); */
    };
    return (
        <section className="bg-body">

            {/* HEADER SECTION */}
            <section className="container pt-5 py-md-2 py-lg-4 pt-xl-5 mb-4 mb-md-5 h-100">
                <style>
                    {`

                    `}
                </style>
                <div className="row align-items-center mt-2 mt-sm-0 pt-4 pt-sm-5">
                    <div className="col-12 mb-0">
                        {/* CONTACT AND LANGUAGE */}
                        <div className='d-flex flex-column flex-md-row align-items-center'>
                            <FunnifinLogo className="img-fluid w-100 me-md-auto mb-4" alt="fin logo" style={{ "maxWidth": "256px" }} />
                            <div className='d-flex align-items-center'>
                                <div className="d-flex pe-2 me-2 mb-4 mb-md-0" >
                                    <span className={(i18n.language.includes('it') ? "fw-bolder" : "") + " px-1 cursor-pointer"} onClick={() => setLanguage('it-IT')}>IT</span>
                                    /
                                    <span className={(i18n.language.includes("en") ? "fw-bolder" : "") + " px-1 cursor-pointer"} onClick={() => setLanguage('en-GB')}>EN</span>
                                </div >
                                {/*                                 {Config.partnerLogo && <img src={Config.partnerLogo} className="img-fluid w-100 ms-3 object-fit-contain mb-4" alt="fin logo" style={{ "maxWidth": "256px" }} />}
 */}                            </div>


                        </div>
                    </div>

                    <div className="col-md-6 col-lg-6 col-xxl-7 order-md-2 pb-4 pb-md-0 mb-2 mb-sm-3 mb-md-0">
                        <img src={scemo} className="img-fluid w-100 ms-lg-auto d-block" alt="fin logo" style={{ "maxWidth": "600px" }} />
                    </div>

                    <div className="col-md-6 col-lg-6 col-xxl-5 order-md-1 pb-4 ">
                        <div className="pe-md-4 pe-xl-0">
                            <h2 className="my-3 fw-bolder">
                                {t('landing:title_1')} </h2>
                            <p className="mb-3 text-muted mt-4 form-floating" style={{ fontSize: "1.3rem" }}>
                                {t('landing:text_1')}
                            </p>

                            <p className='h5 mb-5' style={{ fontWeight: "800" }}>{t('landing:claim_1')}</p>
                            <button type="button" className="btn btn-lg btn-primary float-end float-md-start" {...LOGIN_BUTTON}>{t('landing:get_started')}</button>
                        </div>
                    </div>
                </div>
            </section>
            {/* ⚠️ WARNING⚠️ */}
            {/* This component contains all auth components. Never change, move or delete it. Additional content can be added above or below */}
            <AuthBlock useModal={false} />

        </section>
    )
}

export default LandingPage;