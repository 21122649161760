import { Company } from "models/companyModel";
import Config from "utils/config";
import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useI18nStore } from "./i18nStore";
import { useUserStore } from "./userStore";
import { useAuthStore } from "./authStore";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import cloneDeep from "clone-deep";

export interface AppLayout {
    headerWidget?: React.ReactNode;
}

export interface AppStore {
    headerWidget: React.ReactNode | null;
    isMobile?: boolean;
    setHeaderWidget: (layout: React.ReactNode | null) => void;
    checkMobile: () => void;
}

export const useAppStore = create<AppStore>()((set, get, _) => ({
    headerWidget: null,
    isMobile: window.innerWidth <= 768,
    setHeaderWidget: (layout: React.ReactNode) => {
        set({ headerWidget: layout });
    },
    checkMobile: () => {
        set({ isMobile: window.innerWidth <= 768 ? true : false });
    }
}));

export function useIsMobile() {
    return useAppStore(state => state.isMobile);
}