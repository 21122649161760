import CircleButton from "components/CircleButton";
import CreateCompanyWizard from "components/createCompanyWizard/CreateCompanyWizard";
import HeaderAdmin from "components/HeaderAdmin"
import Loader from "components/Loader";
import { Company } from "models/companyModel";
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCompanyManagerStore } from "stores/companyManagerStore"
import logo from "assets/images/LOGO.png";
import { useUsersStore } from "stores/usersStore";
import { UserProfile } from "models/userModels";
import { CountUp } from 'use-count-up';
import toast from "react-hot-toast";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import CompanyManageConfig from "components/createCompanyWizard/CompanyManageConfig";
import { useDocumentStore } from "stores/documentStore";
import CompanyManageFeatures from "components/createCompanyWizard/CompanyManageFeatures";
import { useProgressionStore } from "stores/progressionStore";
import { use } from "i18next";
import GoalEditor from "./GoalEditor";
import { Goal } from "models/progressionModels";
import { useI18nStore } from "stores/i18nStore";
import { GoalDetail } from "models/progressionModels";
import { useSystemModalStore } from "stores/systemModalStore";
import { generateImageUrl, useTitle } from "utils/functions";
import HeaderWidgetPortal from "components/HeaderWidgetPortal";
const initialGoal = {
    button: "",
    code: 0,
    companyId: "",
    dateLastUpdate: "",
    description: {
        en: "",
        it: ""
    },
    disabledFontIcon: "",
    disabledIconId: "",
    fontIcon: "",
    goalCreationDate: "",
    iconId: "",
    isActive: false,
    order: 0,
    title: {
        en: "",
        it: ""
    },
    totalXp: 0,
    url: ""
};



function ProgressionManagerPage() {

    const goalList = useProgressionStore(state => state.goalList);
    const { t } = useTranslation();
    const [showCreateGoal, setShowCreateGoal] = React.useState(false);
    const [goalEdit, setGoalEdit] = useState<GoalDetail>(initialGoal);
    const [goalImage, setGoalImage] = useState<File | null>(null);
    React.useEffect(() => {
        if (goalList === null) {
            useProgressionStore.getState().getGoalList();
        }
    }, [goalList]);
    useTitle(t('progression_manager:title'));
    return (
        <section className='ProgressionManagerPage d-flexflex-column'>
            <HeaderWidgetPortal>
                <CircleButton className='ms-auto' iconClassName={"fas fa-plus"} onClick={
                    () => {
                        setShowCreateGoal(true);
                    }
                } />
            </HeaderWidgetPortal>
            {
                goalList === null ? <Loader /> :
                    <div className=' mb-4 card'>
                        <div className="card-body d-flex flex-column">
                            <ul className="list-group list-group-flush">
                                {Array.isArray(goalList) && goalList.map((goal) => <GoalListTile key={goal.goalId} goal={goal} />)}
                            </ul>
                        </div>
                    </div>
            }
            <Modal id="createGoalModal" onHide={() => setShowCreateGoal(false)} show={showCreateGoal} scrollable>
                <ModalHeader closeButton>
                    <ModalTitle>{t('progression_manager:create_goal')}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <GoalEditor goal={goalEdit}
                        icon={null}
                        setGoalImage={(file) => { }}
                        handleGoalChange={(ge) => {
                            setGoalEdit(ge);
                        }} />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" onClick={() => {
                        setShowCreateGoal(false)
                        setGoalEdit(initialGoal);
                    }}>{t('common:cancel')}</button>
                    <button className="btn btn-primary" onClick={async () => {
                        setShowCreateGoal(false);
                        toast.loading(t('progression_manager:goalCreateProgress'));
                        try {
                            /*                             const goal = await createGoal(goalEdit, goalImage);
                             */
                            toast.dismiss();
                            toast.success(t('progression_manager:goalCreated'));
                        }
                        catch (e) {
                            toast.dismiss();
                            toast.error(t('progression_manager:goalCreateError'));
                        }
                    }
                    }>{t('common:save')}</button>
                </ModalFooter>
            </Modal>
        </section >
    )
}

/* -------------------------------------------------------------------------- */
/*                            GoalListTile                                 */
/* -------------------------------------------------------------------------- */

function GoalListTile(props: { goal: Goal }) {
    const goal = props.goal;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showEditModal, setShowEditModal] = React.useState(false);
    const uploadDocument = useDocumentStore(state => state.uploadDocument);

    const [goalEdit, setGoalEdit] = useState(goal.goalDetail);
    const [goalImage, setGoalImage] = useState<File | null>(null);


    useEffect(() => {
        setGoalEdit(goal.goalDetail);
        setGoalImage(null);
    }, [goal]);
    const handleDelete = async () => {
        const doDelete = await useSystemModalStore.getState().showConfirmModal(
            t('common:delete'),
            t('progression_manager:delete_goal_confirm',
                { goal: getTranslation(goal.goalDetail.title) }),
            "fa-duotone fa-trash",
        );
        if (doDelete) {
            toast.promise(useProgressionStore.getState().deleteGoal(goal.goalId), {
                loading: t('common:deleting'),
                success: t('common:deleted'),
                error: (e) => t('common:error') + e.message
            });
        }
    }
    const getTranslation = useI18nStore(state => state.getTranslation);

    return (
        <>
            <li className="CompanyListTile list-group-item d-flex flex-row align-items-center ps-0" >
                {goal.goalDetail.icon ?
                    <img src={generateImageUrl(goal.goalDetail.icon) ?? ""} alt="logo" className="me-3" style={{ width: "4rem", height: "4rem", objectFit: "contain" }} /> :
                    <span style={{ width: "50px" }} className="text-center">{goal.goalDetail.fontIcon}</span>
                }
                <h6 className='mb-0 d-flex align-items-center' style={{ width: "250px" }}>
                    {getTranslation(goal.goalDetail.title)}
                </h6>
                <div className='d-flex align-items-center text-muted mx-auto flex-fill ps-3'>
                    <h6 className='mb-0 me-2 ' >
                        {t('common:experience')}:
                    </h6>
                    <span className='badge bg-primary me-4'>
                        {goal.goalDetail.totalXp}
                    </span>
                </div>
                <div className='ms-auto d-flex align-items-center'>
                    <button className="btn btn-sm btn-outline-primary"
                        onClick={() => {
                            setShowEditModal(true);
                        }}
                    ><i className="fa-duotone fa-pencil"></i></button>
                    <button className="btn btn-sm btn-outline-danger ms-2" onClick={handleDelete}><i className="fa-duotone fa-trash"></i></button>
                </div>
            </li>
            {/* EDIT MODAL */}
            <Modal id="editCompanyModal" onHide={() => setShowEditModal(false)} show={showEditModal} scrollable>
                <ModalHeader closeButton>
                    <ModalTitle>{t('common:edit')}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <GoalEditor goal={goalEdit}
                        icon={goalImage}
                        setGoalImage={(file) => {
                            setGoalImage(file);
                        }}
                        handleGoalChange={(ge) => {
                            setGoalEdit(ge);
                        }} />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={async () => {
                        setShowEditModal(false);
                        toast.loading(t('progression_manager:companyEditProgress'));
                        try {
                            if (goalImage != null) {
                                const logoDocument = await uploadDocument({
                                    format: "image",
                                    title: "Company logo",
                                    ownerId: "",
                                    type: "companyLogo",
                                    file: goalImage as File
                                });
                                if (logoDocument == null) {
                                    toast.error("Image upload error");
                                    return;
                                }
                                goalEdit.iconId = logoDocument.documentId;
                                goalEdit.icon = logoDocument;
                            }
                            useProgressionStore.getState().updateGoal({
                                goalDetail: goalEdit,
                                goalId: goal.goalId
                            });
                            toast.dismiss();
                            toast.success(t('common:saved'));
                        }
                        catch (e) {
                            toast.dismiss();
                            toast.error(t('common:error'));
                        }
                    }
                    }>{t('common:save')}</button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default ProgressionManagerPage