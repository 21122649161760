import axios from "axios";
import Config from "utils/config";
import * as amplitude from "@amplitude/analytics-browser";
import { toast } from "react-hot-toast";

const baseUrl = Config.amplitudeUrl;
const apiKey = Config.amplitudeApiKey;

const defaultHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + apiKey,
    }
}

/* 
atterraggio pagina: business.funnifin.com → Atterrando su prima pagine Suvay → Atterra nella home Skip tutorial / mostrato tutorial

HOME formazione

Atterraggio sui moduli suggeriti Atterraggio sui singoli argomenti Atterraggio su materiali didattici Atterraggio sui Video

Atterraggio su sezione progressione Atterraggio su sezione Classifica

Atterraggio negozio scelta sconto click acquisto

Atterraggio sezione strumenti click budget

Atterraggio sezione esperti scelta esperto clik per calendly
*/
export const events = {
    "shownTutorial": "shown_tutorial",
    "skippedTutorial": "skipped_tutorial",
    "finishedTutorial": "finished_tutorial",
    "videoOpened": "video_opened",
    "videoFinished": "video_finished",
    "expertAppointment": "expert_appointment",
    "unitCompleted": "unit_completed",
    "shopItemBought": "shop_item_bought",
}

export const AmplitudeService = {
    initialized: false,
    init: (userId = undefined) => {
        if (AmplitudeService.initialized) {
            return;
        }
        try {
            if (Config.enabledFeatures.amplitude) {
                amplitude.init(apiKey, userId, {
                    defaultTracking: {
                        sessions: true,
                        fileDownloads: true,
                    },
                });
                AmplitudeService.initialized = true;
            }
        }
        catch (e) {
            console.error('Error initializing Amplitude', e);   
            AmplitudeService.initialized = false;
        }
    },
    reset: () => {
        amplitude.reset();
    },
    identify: (user) => {
        if (!Config.enabledFeatures.amplitude || !Config.production) {
            amplitude.setOptOut(true);
            return;
        } else {
            amplitude.setOptOut(false);
        }

        const identifyEvent = new amplitude.Identify();
        identifyEvent.set('name', user.userProfileDetail.name + ' ' + user.userProfileDetail.surname);
        identifyEvent.set('email', user.userProfileDetail.email);
        identifyEvent.set('companyId', user.userProfileDetail.companyId);
        amplitude.setUserId(user.userProfileId);
        identifyEvent.add('user_identified_count', 1);
        amplitude.identify(identifyEvent);
    },
    logEvent: (key, data) => {
        if (Config.enabledFeatures.amplitude && Config.production) {
            console.log('AmplitudeService.logEvent', key, data);
            amplitude.track(key, data);
        } else if (Config.enabledFeatures.amplitude && !Config.production) {
/*             toast('Amplitude event: ' + key, { icon: '📊', position: "top-right" });
 */        }
    },
    getEventTest: async () => {
        const startTime = '2022-01-01T00:00:00.000Z';
        const endTime = '2022-01-31T23:59:59.999Z';

        try {
            const response = await axios.get(`${baseUrl}events/segmentation`, {
                params: {
                    e: 'event_type',
                    start: startTime,
                    end: endTime,
                    api_key: apiKey,
                },
                withCredentials: false,
            });
            const eventCount = response.data.data.series.reduce(
                (total, series) => total + series.value,
                0
            );

            console.log(`There were ${eventCount} events in the specified timeframe.`);
        } catch (error) {
            console.error(error);
        }
    },
};