import React from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function DiffBadge({ diff, className }: { diff: string, className?: string }) {
    let icon = "fas fa-signal-bars-weak";
    const t = useTranslation().t;
    switch (diff) {
        case "LOW":
            icon = "me-1 fa-duotone fa-signal-bars-weak";
            break;
        case "MEDIUM":
            icon = "me-1 fa-duotone fa-signal-bars-fair";
            break;
        case "HARD":
            icon = "me-1 fa-duotone fa-signal-bars-strong";
            break;
        default:
            icon = "me-1 fa-duotone fa-signal-bars-weak";
            break;
    }
    return (
        <Badge className={"bg-light text-dark " + className }>
            {t('common:' + diff)}
        </Badge>
    )
}