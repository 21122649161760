export const quotes = [{
    "quote": {
        "it": "La vita non è fatta di ottenere e avere, ma di dare e di essere.",
        "en": "Life isn’t about getting and having, it’s about giving and being."
    },
    "author": "Kevin Kruse"
}, {
    "quote": {
        "it": "Qualunque cosa la mente dell'uomo possa concepire e credere, può realizzarla.",
        "en": "Whatever the mind of man can conceive and believe, it can achieve."
    },
    "author": "Napoleon Hill"
}, {
    "quote": {
        "it": "Non cercare il successo, ma piuttosto cerca di essere utile.",
        "en": "Strive not to be a success, but rather to be of value."
    },
    "author": "Albert Einstein"
}, {
    "quote": {
        "it": "Due strade divergevano in un bosco, e io - io presi quella meno battuta, e questo ha fatto tutta la differenza.",
        "en": "Two roads diverged in a wood, and I—I took the one less traveled by, And that has made all the difference."
    },
    "author": "Robert Frost"
}, {
    "quote": {
        "it": "Attribuisco il mio successo a questo: non ho mai dato o accettato scuse.",
        "en": "I attribute my success to this: I never gave or took any excuse."
    },
    "author": "Florence Nightingale"
}, {
    "quote": {
        "it": "Sbagli il 100% dei tiri che non privi.",
        "en": "You miss 100% of the shots you don’t take."
    },
    "author": "Wayne Gretzky"
}, {
    "quote": {
        "it": "Ho sbaglieto più di 9000 tiri nella mia carriera. Ho perso quasi 300 partite. 26 volte mi è stato affidato il tiro vincente e ho sbagliato. Ho fallito più e più volte nella mia vita. Ed è per questo che ho avuto successo.",
        "en": "I’ve missed more than 9000 shots in my career. I’ve lost almost 300 games. 26 times I’ve been trusted to take the game winning shot and missed. I’ve failed over and over and over again in my life. And that is why I succeed."
    },
    "author": "Michael Jordan"
}, {
    "quote": {
        "it": "La cosa più difficile è la decisione di agire, il resto è semplicemente tenacia.",
        "en": "The most difficult thing is the decision to act, the rest is merely tenacity."
    },
    "author": "Amelia Earhart"
}, {
    "quote": {
        "it": "Ogni strike mi porta più vicino al prossimo fuoricampo.",
        "en": "Every strike brings me closer to the next home run."
    },
    "author": "Babe Ruth"
}, {
    "quote": {
        "it": "La chiarezza di uno scopo è il punto di partenza di ogni successo.",
        "en": "Definiteness of purpose is the starting point of all achievement."
    },
    "author": "W. Clement Stone"
}, {
    "quote": {
        "it": "Dobbiamo bilanciare il consumo appariscente con il capitalismo consapevole.",
        "en": "We must balance conspicuous consumption with conscious capitalism."
    },
    "author": "Kevin Kruse"
}, {
    "quote": {
        "it": "La vita è ciò che ti accade mentre sei impegnato a fare altri piani.",
        "en": "Life is what happens to you while you’re busy making other plans."
    },
    "author": "John Lennon"
}, {
    "quote": {
        "it": "Diveniamo ciò che pensiamo.",
        "en": "We become what we think about."
    },
    "author": "Earl Nightingale"
}, {
    "quote": {
        "it": "Vent'anni da ora sarai più deluso dalle cose che non hai fatto che da quelle che hai fatto, quindi getta via le cime, salpa lontano dal porto sicuro, cogli i venti portanti nelle tue vele. Esplora, Sogna, Scopri.",
        "en": "Twenty years from now you will be more disappointed by the things that you didn’t do than by the ones you did do, so throw off the bowlines, sail away from safe harbor, catch the trade winds in your sails.  Explore, Dream, Discover."
    },
    "author": "Mark Twain"
}, {
    "quote": {
        "it": "La vita è 10% ciò che mi accade e 90% di come reagisco ad essa.",
        "en": "Life is 10% what happens to me and 90% of how I react to it."
    },
    "author": "Charles Swindoll"
}, {
    "quote": {
        "it": "Il modo più comune in cui le persone rinunciano al loro potere è pensare che non ne abbiano.",
        "en": "The most common way people give up their power is by thinking they don’t have any."
    },
    "author": "Alice Walker"
}, {
    "quote": {
        "it": "La mente è tutto. Ciò che pensi diventi.",
        "en": "The mind is everything. What you think you become."
    },
    "author": "Buddha"
}, {
    "quote": {
        "it": "Il miglior momento per piantare un albero era 20 anni fa. Il secondo momento migliore è ora.",
        "en": "The best time to plant a tree was 20 years ago. The second best time is now."
    },
    "author": "Chinese Proverb"
}, {
    "quote": {
        "it": "Una vita senza introspezione non vale la pena di essere vissuta.",
        "en": "An unexamined life is not worth living."
    },
    "author": "Socrates"
}, {
    "quote": {
        "it": "L'ottanta percento del successo è presentarsi.",
        "en": "Eighty percent of success is showing up."
    },
    "author": "Woody Allen"
}, {
    "quote": {
        "it": "Il tuo tempo è limitato, quindi non sprecarlo vivendo la vita di qualcun altro.",
        "en": "Your time is limited, so don’t waste it living someone else’s life."
    },
    "author": "Steve Jobs"
}, {
    "quote": {
        "it": "Io non sono un prodotto delle mie circostanze. Sono un prodotto delle mie decisioni.",
        "en": "I am not a product of my circumstances. I am a product of my decisions."
    },
    "author": "Stephen Covey"
}, {
    "quote": {
        "it": "Ogni bambino è un artista.  Il problema è come rimanere un artista una volta cresciuto.",
        "en": "Every child is an artist.  The problem is how to remain an artist once he grows up."
    },
    "author": "Pablo Picasso"
}, {
    "quote": {
        "it": "Non puoi superare l'oceano finché non hai il coraggio di perdere di vista la riva.",
        "en": "You can never cross the ocean until you have the courage to lose sight of the shore."
    },
    "author": "Christopher Columbus"
}, {
    "quote": {
        "it": "Ho imparato che la gente dimenticherà ciò che hai detto, la gente dimenticherà ciò che hai fatto, ma la gente non dimenticherà mai come li hai fatti sentire.",
        "en": "I’ve learned that people will forget what you said, people will forget what you did, but people will never forget how you made them feel."
    },
    "author": "Maya Angelou"
}, {
    "quote": {
        "it": "Che tu creda di poterlo fare o di non poterlo fare, hai ragione.",
        "en": "Whether you think you can or you think you can’t, you’re right."
    },
    "author": "Henry Ford"
}, {
    "quote": {
        "it": "Le due giornate più importanti della tua vita sono il giorno in cui sei nato e il giorno in cui scopri il perché.",
        "en": "The two most important days in your life are the day you are born and the day you find out why."
    },
    "author": "Mark Twain"
}, {
    "quote": {
        "it": "Qualunque cosa tu possa fare o sognare di fare, incominciala. L'audacia ha in sé genio, potere e magia.",
        "en": "Whatever you can do, or dream you can, begin it.  Boldness has genius, power and magic in it."
    },
    "author": "Johann Wolfgang von Goethe"
}, {
    "quote": {
        "it": "La migliore vendetta è un successo massiccio.",
        "en": "The best revenge is massive success."
    },
    "author": "Frank Sinatra"
}, {
    "quote": {
        "it": "Le persone spesso dicono che la motivazione non dura. Beh, neanche il bagno.  Ecco perché lo raccomandiamo quotidianamente.",
        "en": "People often say that motivation doesn’t last. Well, neither does bathing.  That’s why we recommend it daily."
    },
    "author": "Zig Ziglar"
}, {
    "quote": {
        "it": "La vita si restringe o si espande in proporzione al coraggio di ognuno.",
        "en": "Life shrinks or expands in proportion to one’s courage."
    },
    "author": "Anais Nin"
}, {
    "quote": {
        "it": "Se senti una voce dentro di te dire “non puoi dipingere”, allora dipingi e quella voce sarà zittita.",
        "en": "If you hear a voice within you say “you cannot paint,” then by all means paint and that voice will be silenced."
    },
    "author": "Vincent Van Gogh"
}, {
    "quote": {
        "it": "C'è solo un modo per evitare le critiche: non fare nulla, non dire nulla e non essere nulla.",
        "en": "There is only one way to avoid criticism: do nothing, say nothing, and be nothing."
    },
    "author": "Aristotle"
}, {
    "quote": {
        "it": "La sola persona che sei destinato a diventare è la persona che decidi di essere.",
        "en": "The only person you are destined to become is the person you decide to be."
    },
    "author": "Ralph Waldo Emerson"
}, {
    "quote": {
        "it": "Vai fiducioso nella direzione dei tuoi sogni. Vivi la vita che ti sei immaginato.",
        "en": "Go confidently in the direction of your dreams.  Live the life you have imagined."
    },
    "author": "Henry David Thoreau"
}, {
    "quote": {
        "it": "Quando mi troverò davanti a Dio alla fine della mia vita, spero di non avere un solo briciolo di talento rimasto e potrò dire: ho usato tutto ciò che mi hai dato.",
        "en": "When I stand before God at the end of my life, I would hope that I would not have a single bit of talent left and could say, I used everything you gave me."
    },
    "author": "Erma Bombeck"
}, {
    "quote": {
        "it": "Poche cose possono aiutare un individuo più che attribuirgli delle responsabilità e fargli sapere che vi fidate di lui.",
        "en": "Few things can help an individual more than to place responsibility on him, and to let him know that you trust him."
    },
    "author": "Booker T. Washington"
}, {
    "quote": {
        "it": "Certe cose catturano il tuo sguardo, ma esso insegue solo quelle che catturano il cuore.",
        "en": "Certain things catch your eye, but pursue only those that capture the heart."
    },
    "author": " Ancient Indian Proverb"
}, {
    "quote": {
        "it": "Tutto ciò che hai sempre desiderato è dall'altra parte della paura.",
        "en": "Everything you’ve ever wanted is on the other side of fear."
    },
    "author": "George Addair"
}, {
    "quote": {
        "it": "Possiamo facilmente perdonare un bambino che ha paura del buio; la vera tragedia della vita è quando gli uomini hanno paura della luce.",
        "en": "We can easily forgive a child who is afraid of the dark; the real tragedy of life is when men are afraid of the light."
    },
    "author": "Plato"
}, {
    "quote": {
        "it": "Insegna alla tua lingua a dire, “Non lo so”, e progredirai.",
        "en": "Teach thy tongue to say, “I do not know,” and thous shalt progress."
    },
    "author": "Maimonides"
}, {
    "quote": {
        "it": "Inizia da dove sei. Usa ciò che hai. Fai ciò che puoi.",
        "en": "Start where you are. Use what you have.  Do what you can."
    },
    "author": "Arthur Ashe"
}, {
    "quote": {
        "it": "Cadi sette volte, alzati otto.",
        "en": "Fall seven times and stand up eight."
    },
    "author": "Japanese Proverb"
}, {
    "quote": {
        "it": "Qunado una porta della felicità si chiude, un'altra si apre, ma spesso fissiamo così a lungo quella chiusa che non vediamo quella che è stata aperta per noi.",
        "en": "When one door of happiness closes, another opens, but often we look so long at the closed door that we do not see the one that has been opened for us."
    },
    "author": "Helen Keller"
}, {
    "quote": {
        "it": "Tutto è bellezza, ma non tutti possono vederla.",
        "en": "Everything has beauty, but not everyone can see."
    },
    "author": "Confucius"
}, {
    "quote": {
        "it": "Quanto è bello che nessuno debba aspettare un solo momento prima di iniziare a migliorare il mondo.",
        "en": "How wonderful it is that nobody need wait a single moment before starting to improve the world."
    },
    "author": "Anne Frank"
}, {
    "quote": {
        "it": "Quando lascio andare ciò che sono, divento ciò che potrei essere.",
        "en": "When I let go of what I am, I become what I might be."
    },
    "author": "Lao Tzu"
}, {
    "quote": {
        "it": "La vita non è misurata dal numero di respiri che prendiamo, ma dai momenti che ci tolgono il respiro.",
        "en": "Life is not measured by the number of breaths we take, but by the moments that take our breath away."
    },
    "author": "Maya Angelou"
}, {
    "quote": {
        "it": "La felicità non è qualcosa di pronto. Viene dalle tue azioni.",
        "en": "Happiness is not something readymade.  It comes from your own actions."
    },
    "author": "Dalai Lama"
}, {
    "quote": {
        "it": "Se ti offrono un posto su un'astronave, non chiedere che posto! Sali e basta.",
        "en": "If you’re offered a seat on a rocket ship, don’t ask what seat! Just get on."
    },
    "author": "Sheryl Sandberg"
}, {
    "quote": {
        "it": "Primo, avere un ideale pratico definito e chiaro; un obiettivo, una meta. Secondo, avere i mezzi necessari per raggiungere i tuoi obiettivi; saggezza, denaro, materiali e metodi. Terzo, adattare tutti i tuoi mezzi a quell'obiettivo.",
        "en": "First, have a definite, clear practical ideal; a goal, an objective. Second, have the necessary means to achieve your ends; wisdom, money, materials, and methods. Third, adjust all your means to that end."
    },
    "author": "Aristotle"
}, {
    "quote": {
        "it": "Se non c'è vento, prendi i remi.",
        "en": "If the wind will not serve, take to the oars."
    },
    "author": "Latin Proverb"
}, {
    "quote": {
        "it": "Non puoi cadere se non ti arrampichi. Ma non c'è gioia a vivere tutta la vita a terra.",
        "en": "You can’t fall if you don’t climb.  But there’s no joy in living your whole life on the ground."
    },
    "author": "Unknown"
}, {
    "quote": {
        "it": "Dobbiamo credere che siamo dotati per qualcosa, e che questa cosa, a qualunque costo, deve essere raggiunta.",
        "en": "We must believe that we are gifted for something, and that this thing, at whatever cost, must be attained."
    },
    "author": "Marie Curie"
}, {
    "quote": {
        "it": "Troppi di noi non stanno vivendo i propri sogni perché stanno vivendo le proprie paure.",
        "en": "Too many of us are not living our dreams because we are living our fears."
    },
    "author": "Les Brown"
}, {
    "quote": {
        "it": "Le sfide sono ciò che rendono la vita interessante e superarle è ciò che rende la vita significativa.",
        "en": "Challenges are what make life interesting and overcoming them is what makes life meaningful."
    },
    "author": "Joshua J. Marine"
}, {
    "quote": {
        "it": "Sono stato impressionato dall'urgenza di fare. Sapere non è sufficiente; dobbiamo applicare. Essere disposti non è sufficiente; dobbiamo fare.",
        "en": "I have been impressed with the urgency of doing. Knowing is not enough; we must apply. Being willing is not enough; we must do."
    },
    "author": "Leonardo da Vinci"
}, {
    "quote": {
        "it": "Le limitazioni vivono solo nella nostra mente. Ma se usiamo la nostra immaginazione, le nostre possibilità diventano illimitate.",
        "en": "Limitations live only in our minds.  But if we use our imaginations, our possibilities become limitless."
    },
    "author": "Jamie Paolinetti"
}, {
    "quote": {
        "it": "Non ho fallito il test. Ho solo trovato 100 modi per farlo sbagliato.",
        "en": "I didn’t fail the test. I just found 100 ways to do it wrong."
    },
    "author": "Benjamin Franklin"
}, {
    "quote": {
        "it": "Una persona che non ha mai commesso un errore non ha mai provato nulla di nuovo.",
        "en": "A person who never made a mistake never tried anything new."
    },
    "author": " Albert Einstein"
}, {
    "quote": {
        "it": "Colui che dice che non può essere fatto non dovrebbe interrompere colui che lo sta facendo.",
        "en": "The person who says it cannot be done should not interrupt the person who is doing it."
    },
    "author": "Chinese Proverb"
}, {
    "quote": {
        "it": "Non c'è traffico quando percorri il miglio in più",
        "en": "There are no traffic jams along the extra mile."
    },
    "author": "Roger Staubach"
}, {
    "quote": {
        "it": "Preferisco morire di passione che di noia.",
        "en": "I would rather die of passion than of boredom."
    },
    "author": "Vincent van Gogh"
}, {
    "quote": {
        "it": "Costruisci i tuoi sogni, altrimenti qualcun' altro ti assumerà per costruire i suoi.",
        "en": "Build your own dreams, or someone else will hire you to build theirs."
    },
    "author": "Farrah Gray"
}, {
    "quote": {
        "it": "Le battaglie che contano non sono quelle per le medaglie d'oro. Le lotte dentro di te - le battaglie invisibili dentro di noi - è lì che si trova.",
        "en": "The battles that count aren’t the ones for gold medals. The struggles within yourself–the invisible battles inside all of us–that’s where it’s at."
    },
    "author": "Jesse Owens"
}, {
    "quote": {
        "it": "L'educazione costa denaro. Ma anche l'ignoranza.",
        "en": "Education costs money.  But then so does ignorance."
    },
    "author": "Sir Claus Moser"
}, {
    "quote": {
        "it": "Ho imparato negli anni che quando la mente è decisa, questo diminuisce la paura.",
        "en": "I have learned over the years that when one’s mind is made up, this diminishes fear."
    },
    "author": "Rosa Parks"
}, {
    "quote": {
        "it": "Non importa quanto lentamente vai, fintanto che non ti fermi.",
        "en": "It does not matter how slowly you go as long as you do not stop."
    },
    "author": "Confucius"
}, {
    "quote": {
        "it": "Sogna in grande e osa fallire.",
        "en": "Dream big and dare to fail."
    },
    "author": "Norman Vaughan"
}, {
    "quote": {
        "it": "Le nostre vite iniziano a finire il giorno in cui diventiamo silenziosi su cose che contano.",
        "en": "Our lives begin to end the day we become silent about things that matter."
    },
    "author": "Martin Luther King Jr."
}, {
    "quote": {
        "it": "Fai ciò che puoi, con ciò che hai, dove sei.",
        "en": "Do what you can, where you are, with what you have."
    },
    "author": "Teddy Roosevelt"
}, {
    "quote": {
        "it": "Se fai ciò che hai sempre fatto, otterrai ciò che hai sempre ottenuto.",
        "en": "If you do what you’ve always done, you’ll get what you’ve always gotten."
    },
    "author": "Tony Robbins"
}, {
    "quote": {
        "it": "Sognare, dopotutto, è una forma di pianificazione.",
        "en": "Dreaming, after all, is a form of planning."
    },
    "author": "Gloria Steinem"
}, {
    "quote": {
        "it": "La domanda non è chi me lo permetterà; è chi mi fermerà.",
        "en": "The question isn’t who is going to let me; it’s who is going to stop me."
    },
    "author": "Ayn Rand"
}, {
    "quote": {
        "it": "Quando tutto sembra andare contro di te, ricorda che l'aereo decolla contro il vento, non a favore.",
        "en": "When everything seems to be going against you, remember that the airplane takes off against the wind, not with it."
    },
    "author": "Henry Ford"
}, {
    "quote": {
        "it": "Non sono gli anni della tua vita che contano. È la vita nei tuoi anni.",
        "en": "It’s not the years in your life that count. It’s the life in your years."
    },
    "author": "Abraham Lincoln"
}, {
    "quote": {
        "it": "Cambia i tuoi pensieri e cambierai il tuo mondo.",
        "en": "Change your thoughts and you change your world."
    },
    "author": "Norman Vincent Peale"
}, {
    "quote": {
        "it": "L'unico modo per fare un buon lavoro è amare ciò che fai.",
        "en": "The only way to do great work is to love what you do."
    },
    "author": "Steve Jobs"
}, {
    "quote": {
        "it": "Se puoi sognarlo, puoi realizzarlo.",
        "en": "If you can dream it, you can achieve it."
    },
    "author": "Zig Ziglar"
}, {
    "quote": {
        "it": "Non devi essere perfetto per essere straordinario.",
        "en": "You don’t have to be perfect to be amazing."
    },
    "author": "La Mamma"
}];