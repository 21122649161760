import React from 'react'
import { useTranslation } from 'react-i18next';

function HeaderAdmin(props) {
    const { t } = useTranslation();
    return (
        <div className="d-flex align-items-center mb-md-3 mt-md-2 d-md-none">
            <h3 className="mb-0 text-truncate text-body-emphasis">
                {t(props.title)}
            </h3>
            <div className="ms-auto">
                {props.children}
            </div>
        </div>
    )
}

export default HeaderAdmin