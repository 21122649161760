import CircleButton from 'components/CircleButton';
import EduQuizManagerPage from 'pages/eduquiz_manager/EduQuizManagerPage'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCompanyManagerStore } from 'stores/companyManagerStore';

function CompanyEduquizManagerPage() {
  const companyId = useParams<{ companyId: string }>().companyId;
  const companyList = useCompanyManagerStore(state => state.companyList);
  useEffect(() => {
    if (!companyList) {
      useCompanyManagerStore.getState().init();
    }
  }, [companyList]);
  const company = companyList?.find(company => company.companyId === companyId);

  const navigate = useNavigate();
  if (!company) return <>Errore: Compagnia non trovata, avvisa Mike!</>
  return (
    <div>
      <div className='d-flex align-items-center'>
        <CircleButton className='text-white' iconClassName={"fas fa-angle-left"} onClick={() => {
          navigate('/system/company_manager');
        }} />
        <h3 className='flex-grow-1 ms-2 mb-0'>{company?.companyDetail.company}</h3>
      </div>
      <EduQuizManagerPage companyId={companyId ?? null} />
    </div>
  )
}

export default CompanyEduquizManagerPage