import React from 'react'
import finn from "assets/images/logo-FinAi.svg";

function FinLogo({ width }: { width?: string }) {
    return (
        <img src={finn} className="img-fluid" alt=""
            style={{ width: width ? width + "px" : "auto", height: width ? width + "px" : "auto" }}
        ></img>
    )
}

export default FinLogo