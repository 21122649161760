import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useCompanyStore } from "./companyStore";
import { useUserStore } from "./userStore";
import cloneDeep from "clone-deep";

let previousRankingListHash = null;
//* This store is used to manage the progression page
//* 
export const useRankingStore = create()((set, get, __) => {
    return {
        rankingList: null,
        initRanking: async () => {
            try {
                let rankingList = await ApiService.ranking.getRankingList(useCompanyStore.getState().currentCompany.companyId);
                const rankingListHash = JSON.stringify(rankingList);
                if (rankingList) {
                    if (rankingListHash !== previousRankingListHash) {
                        previousRankingListHash = rankingListHash;
                        set({ rankingList: cloneDeep(rankingList) });
                    }
                } else {
                    set({ rankingList: [] });
                }
            } catch (e) {
                console.log('[RankingStore] Error getting ranking list', e);
                set({ rankingList: [] });
            }
        },
        getUserQuizStatistics: async () => {
            try {
                let quizArguments = await ApiService.education.getUserArgumentsWithUnits(useUserStore.getState().user.userProfileId);
                // loop through the arguments 
                let argumentsCompleted = 0;
                let modulesCompleted = 0;
                let unitsCompleted = 0;
                let completedModules = [];
                for (let i = 0; i < quizArguments.length; i++) {
                    if (quizArguments[i].argumentDetail.userArgument.userArgumentDetail.percentageCompletion === 100) {
                        argumentsCompleted++;
                    }
                    for (let j = 0; j < quizArguments[i].argumentDetail.modules.length; j++) {
                        if (quizArguments[i].argumentDetail.modules[j].moduleDetail.userModule.userModuleDetail.percentageCompletion === 100) {
                            completedModules.push(quizArguments[i].argumentDetail.modules[j]);
                            modulesCompleted++;
                        }
                        for (let k = 0; k < quizArguments[i].argumentDetail.modules[j].moduleDetail.units.length; k++) {
                            if (quizArguments[i].argumentDetail.modules[j].moduleDetail.units[k].unitDetail.userUnit.userUnitDetail.percentageCompletion === 100) {
                                unitsCompleted++;
                            }
                        }
                    }
                }
                set({ eduquizStatistics: { argumentsCompleted: argumentsCompleted, modulesCompleted: modulesCompleted, unitsCompleted: unitsCompleted }, completedModules: completedModules });
                return { argumentsCompleted: argumentsCompleted, modulesCompleted: modulesCompleted, unitsCompleted: unitsCompleted };
            }
            catch (e) {
                console.error('[RankingStore] Error getting user quiz statistics', e);
                return null;
            }
        },
    };
});