

import React from 'react';
import Config from 'utils/config';
import { landingPagesLibrary } from './landingPagesLibrary';


/* 
    Display the landing page chosen in the config file

*/
function LandingPage() {
    if (!Config.landingPage) {
        return (
            <div className="alert alert-danger" role="alert">
                <strong>Error:</strong> No landing page configured. Please check the configuration file.
            </div>
        )
    }
    const LandingPage = landingPagesLibrary[Config.landingPage];
    if (!LandingPage) {
        return (
            <div className="alert alert-danger" role="alert">
                <strong>Error:</strong> The configured landing page does not exist. Please check the configuration file.
            </div>
        )
    }
    return (
        <>
            <LandingPage />
        </>
    )
}

export default LandingPage;