import { CompanyDetail } from 'models/companyModel';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { FeatureCheckbox } from './FeatureCheckbox';

function CompanyManageFeatures({ handleFeaturesChange, company }: { handleFeaturesChange: (feature: string) => void, company: CompanyDetail }) {
    const { t } = useTranslation();
    return (
        <div>
            <h5>{t('company:selectFeatures')}</h5>
            <p>
                {t('company:selectFeaturesDescription')}
            </p>
            {/* 
    Per ogni feature, creare un checkbox custom
     */}
            <div className="flex-column d-flex align-items-center pt-3">
                <FeatureCheckbox
                    label={"Shop Management"} checked={company.enabledFeatures.shopManagement} onChange={function (event: any): void {
                        handleFeaturesChange("shopManagement");
                    }} icon={"shopping-cart"} />
                <FeatureCheckbox
                    label={"Quiz Management"} checked={company.enabledFeatures.quizManagement} onChange={function (event: any): void {
                        handleFeaturesChange("quizManagement");
                    }} icon={"question-circle"} />
                <FeatureCheckbox
                    label={"Partner Management"} checked={company.enabledFeatures.partnerManagement} onChange={function (event: any): void {
                        handleFeaturesChange("partnerManagement");
                    }} icon={"handshake"} />
                <FeatureCheckbox
                    label={"User Management"} checked={company.enabledFeatures.userManagement} onChange={function (event: any): void {
                        handleFeaturesChange("userManagement");
                    }} icon={"users"} />
                <FeatureCheckbox
                    label='Progression Management' checked={company.enabledFeatures.progressionManagement} onChange={function (event: any): void {
                        handleFeaturesChange("progressionManagement");
                    }
                    } icon={"chart-line"} />
                <FeatureCheckbox
                    label={"Language Management"} checked={company.enabledFeatures.languageManagement} onChange={function (event: any): void {
                        handleFeaturesChange("languageManagement");
                    }} icon={"language"} />
                <FeatureCheckbox
                    label={"Investing Tools"} checked={company.enabledFeatures.investingTools} onChange={function (event: any): void {
                        handleFeaturesChange("investingTools");
                    }} icon={"chart-line"} />
                <FeatureCheckbox
                    label={"Progression"} checked={company.enabledFeatures.progression} onChange={function (event: any): void {
                        handleFeaturesChange("progression");
                    }} icon={"chart-bar"} />
                <FeatureCheckbox
                    label={"Ranking"} checked={company.enabledFeatures.ranking} onChange={function (event: any): void {
                        handleFeaturesChange("ranking");
                    }} icon={"trophy"} />
                <FeatureCheckbox
                    label={"Videos"} checked={company.enabledFeatures.videos} onChange={function (event: any): void {
                        handleFeaturesChange("videos");
                    }} icon={"video"} />
                <FeatureCheckbox
                    label={"Learning Materials"} checked={company.enabledFeatures.materials} onChange={function (event: any): void {
                        handleFeaturesChange("materials");
                    }} icon={"book"} />
                <FeatureCheckbox
                    label={"Amplitude Tracking"} checked={company.enabledFeatures.amplitude} onChange={function (event: any): void {
                        handleFeaturesChange("amplitude");
                    }} icon={"signal"} />
                <FeatureCheckbox
                    label={"Self Registration"} checked={company.enabledFeatures.selfRegistration} onChange={function (event: any): void {
                        handleFeaturesChange("selfRegistration");
                    }} icon={"user-plus"} />
                <FeatureCheckbox
                    label={"Feedback"} checked={company.enabledFeatures.feedback} onChange={function (event: any): void {
                        handleFeaturesChange("feedback");
                    }} icon={"comment"} />
                <FeatureCheckbox
                    label={"Survey Iniziale"} checked={company.enabledFeatures.initialSurvey} onChange={function (event: any): void {
                        handleFeaturesChange("initialSurvey");
                    }} icon={"clipboard-list"} />
                <FeatureCheckbox
                    label={"Tutorials"} checked={company.enabledFeatures.tutorials} onChange={function (event: any): void {
                        handleFeaturesChange("tutorials");
                    }} icon={"graduation-cap"} />
                <FeatureCheckbox
                    label={"Chat"} checked={company.enabledFeatures.chat} onChange={function (event: any): void {
                        handleFeaturesChange("chat");
                    }} icon={"envelope"} />
            </div>
        </div>
    )
}

export default CompanyManageFeatures