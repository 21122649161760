import Loader from "components/Loader";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { useI18nStore } from "stores/i18nStore";
import Config from 'utils/config';
import TutorialTour from "components/TutorialTour";
import { DiffBadge } from "components/DiffBadge";
import { useVideoStore } from "stores/videoStore";
import VideoCard from "./VideoCard";
import CircleButton from "components/CircleButton";
import PageTitle from "components/PageTitle";
import { useTitle } from "utils/functions";
import VideoPlayer from "./VideoPlayer";
import { Playlist, Video } from "models/videoModels";
import { Badge, Card, Row } from "react-bootstrap";
import Mark from "components/Mark";
import { VideoWidget } from "pages/user_dashboard/UserDashbordWidgetRows";
import { useFeedbackStore } from "stores/feedbackStore";
import SecondaryHeader from "components/SecondaryHeader";
import { useIsMobile } from "stores/appStore";
import Vimeo from '@u-wave/react-vimeo'; // eslint-disable-line import/no-unresolved
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";

export default function PlaylistPage() {
    let { playlistId } = useParams();
    const navigate = useNavigate();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const playlists = useVideoStore(state => state.playlists);
    const { t } = useTranslation();
    const [currentVideo, setCurrentVideo] = React.useState<Video | null>(null);
    const [showModal, setShowModal] = React.useState(false);
    const [videoReady, setVideoReady] = React.useState(false);
    // get the playlist from the store
    useEffect(() => {
        if (!playlists) {
            useVideoStore.getState().initVideoStore();
        }
    }, [playlists, playlistId]);

    const playlist = useMemo(() => {
        return playlists?.find(p => p.playlistId === playlistId);
    }, [playlists, playlistId]);

    useTitle(t('videos:title'));
    const minutesOfPlaylist = (currentPlaylist: Playlist) => currentPlaylist.playlistDetail.videos.reduce((acc, video) => acc + video.videoDetail.minutes, 0);
    const isMobile = useIsMobile();
    if (!playlist) {
        return <Loader />
    }

    return (
        <section className="PlaylistPage d-flex flex-column pb-5">
            <SecondaryHeader title={""} backTarget="/welfare/playlists">
                {/* SHARE BUTTON */}
                <CircleButton
                    iconClassName="fas fa-share-from-square"
                    className="ms-auto"
                    iconVariant="secondary"
                    variant="transparent"
                    onClick={() => {
                        // Share the page
                        if (navigator.share) {
                            navigator.share({
                                title: getTranslation(playlist.playlistDetail.title),
                                text: getTranslation(playlist.playlistDetail.description),
                                url: window.location.href
                            })
                                .then(() => console.log('Successful share'))
                                .catch((error) => console.log('Error sharing', error));
                        } else {
                            // Fallback: copy the title to clipboard
                            navigator.clipboard.writeText(getTranslation(playlist.playlistDetail.title));
                            toast.success(t('common:copied_to_clipboard'));
                        }
                    }} />
            </SecondaryHeader>
            <div className="card ">
                <div className="card-body p-4">
                    <Row className="gx-0">
                        <div className="col-12 col-md-5 col-xl-4 ps-md-4 mb-4 mb-md-0 d-flex flex-column order-2 order-md-1">
                            <h3 className="pt-3">
                                {getTranslation(playlist?.playlistDetail?.title)}
                            </h3>
                            <h4 className="font-fancy mb-3">
                                by {getTranslation(playlist?.playlistDetail?.metadata)}
                            </h4>
                            <div>
                                {playlist && <div className='text-uppercase mb-4'>
                                    {playlist.playlistDetail.videos.length} {t('videos:lessons')} | {minutesOfPlaylist(playlist)} MIN
                                </div>}
                            </div>
                            <style>
                                {`.removePMargin p {
                                                        margin: 0;
                                                    }`}
                            </style>
                            <div className="removePMargin flex-fill flex-grow-1 mb-4 text-truncate-3" dangerouslySetInnerHTML={{ __html: getTranslation(playlist?.playlistDetail.description) }}>

                            </div>
                            <button className="btn btn-primary rounded-pill align-self-baseline" onClick={() => {
                                setShowModal(true);
                                setCurrentVideo(playlist!.playlistDetail.videos[0]);
                            }}>
                                {t('videos:start_course')}
                            </button>
                        </div>
                        <div className="d-none d-md-block col-1 col-xl-2 order-2"></div>
                        <div className="col-12 col-md-6 order-1 order-md-3 mb-3 mb-md-0">
                            <Vimeo
                                video={playlist?.playlistDetail.videos[0].videoDetail.uri}
                                autoplay={true}
                                volume={0}
                                responsive
                                className='rounded-3'
                                style={{
                                    clipPath: "border-box",
                                    display: videoReady ? 'block' : 'none',

                                }}
                            onReady={() => { setVideoReady(true) }}
                            />
                            {!videoReady &&
                                <Skeleton height={"320px"} className='rounded-3'></Skeleton>}
                        </div>
                    </Row>

                    {/*                     <VideoWidget className="mb-3" />
 */}                    {/* PLAYLIST LIST */}
                    <Card className="bg-body-tertiary mt-5 border-0">
                        <Card.Body className="p-4">
                            <div className="text-uppercase pb-4">
                                {t('videos:all_lessons')}
                            </div>
                            <div className="d-flex flex-column remove-margin-bottom-last">
                                {playlist?.playlistDetail.videos?.map((video, index) => {
                                    return (<div key={video.videoId} className={"d-flex flex-column cursor-pointer flex-md-row w-100 justify-content-start align-items-start mb-5"}
                                    >
                                        <VideoPlayer video={video}
                                            showPlayer={showModal && currentVideo?.videoId === video.videoId}
                                            closePlayer={() => setShowModal(false)}
                                            author={playlist.playlistDetail.metadata?.it}
                                            onNextVideoRequested={
                                                playlist.playlistDetail.videos.length > index + 1 ?
                                                    () => {
                                                        //navigate to the next video
                                                        const index = playlist.playlistDetail.videos.findIndex(v => video.videoId === v.videoId);
                                                        if (index < playlist.playlistDetail.videos.length - 1) {
                                                            setCurrentVideo(playlist.playlistDetail.videos[index + 1]);
                                                        } else {
                                                            setCurrentVideo(playlist.playlistDetail.videos[0]);
                                                            setShowModal(false);
                                                            alert('pover')
                                                        }
                                                    } : undefined
                                            }
                                            onVideoEnd={(videoId) => {
                                                //navigate to the next video
                                                const index = playlist.playlistDetail.videos.findIndex(video => video.videoId === videoId);
                                                if (index < playlist.playlistDetail.videos.length - 1) {
                                                    setCurrentVideo(playlist.playlistDetail.videos[index + 1]);
                                                } else {
                                                    useFeedbackStore.getState().checkCourseFeedback(playlist.playlistId, playlist.playlistDetail.title.it);
                                                    setCurrentVideo(playlist.playlistDetail.videos[0]);
                                                    setShowModal(false);
                                                }
                                            }}
                                        >
                                            <img
                                                onClick={() => {
                                                    setCurrentVideo(video);
                                                    setShowModal(true);
                                                }}
                                                src={video.videoDetail.thumbnail} alt="thumbnail" className="me-3 mb-3 mb-md-0 img-fluid rounded-3 border border-body " style={{
                                                    width: "auto",
                                                    objectFit: "cover",
                                                    objectPosition: "left",
                                                    height: isMobile ? "100%" : "83px",
                                                }} />

                                            <div onClick={() => {
                                                setCurrentVideo(video);
                                                setShowModal(true);
                                            }} className="d-flex flex-column justify-content-between align-items-start p-md-2 flex-fill">
                                                <div className="d-flex justify-content-between w-100">

                                                    <h5 className='mb-0'>{getTranslation(video.videoDetail.title)}</h5>
                                                    <small style={{
                                                        minWidth: "46px",
                                                        textAlign: "right"
                                                    }}>
                                                        {video.videoDetail.minutes} min
                                                    </small>
                                                </div>
                                                <div className='m-0 mb-3 text-truncate-1' dangerouslySetInnerHTML={{ __html: getTranslation(video.videoDetail.description) }}></div>
                                                <div className="W-100 progress rounded-pill bg-white" style={{ width: "100%", height: "0.5rem" }}>
                                                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: "0%" }} ></div>
                                                </div>
                                            </div>
                                        </VideoPlayer>
                                    </div>);
                                })}
                                {/*                         {!playlist.playlistDetail.units && <div className="w-50 mx-auto"><Loader /></div>}
                        {playlist.playlistDetail.units && <TutorialTour tutorialName="modulePage" />} */}
                            </div>
                        </Card.Body>
                    </Card>

                </div>
            </div>
        </section >
    )
}
