import React from 'react';
import { useTranslation } from 'react-i18next';
import { useI18nStore } from 'stores/i18nStore';
import { convertColorToHex, convertColorToRGBString } from 'utils/functions';

function PartnerCard({ partner, className = "" }) {
    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const getBackgroundColor = (partner) => {
        /* console.log(partner.partnerDetail.backGroundColor1, partner.partnerDetail.backGroundColor2); */
        if (partner.partnerDetail.backGroundColor1 && partner.partnerDetail.backGroundColor2) {
            return { background: `linear-gradient(90deg, ${convertColorToHex(partner.partnerDetail.backGroundColor1)} 0%, ${convertColorToHex(partner.partnerDetail.backGroundColor2)} 100%)` };
        }
        else if (partner.partnerDetail.backGroundColor1 && !partner.partnerDetail.backGroundColor2) {
            return { backgroundColor: partner.partnerDetail.backGroundColor1 };
        } else if (partner.partnerDetail.backGroundColor2 && !partner.partnerDetail.backGroundColor1) {
            return { backgroundColor: partner.partnerDetail.backGroundColor2 };
        } else {
            return { backgroundColor: "var(--bs-primary)" };
        }
    }
    if (!partner || !partner.partnerDetail) return null;
    return (
        <div className={"card card-hover card-lifted " + className} style={{ ...getBackgroundColor(partner), width: "100%" }}>
            <div style={{ position: "relative" }}>
                <img
                    src={partner.partnerDetail.bannerId}
                    key={partner.partnerDetail.bannerId}
                    className="card-img-top w-100"
                    alt={partner.partnerDetail.partner}
                    style={{ width: "100%", height: "128px", objectFit: "cover" }}
                />
                <div
                    className='rounded-top'
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgb(" + convertColorToRGBString(partner.partnerDetail.backGroundColor1 ?? "0,0,0") + ", 0.2)", /* Change the color and opacity as needed */
                        zIndex: 1, /* Make sure the colored layer is behind the image */
                    }}
                />
                <h1
                    className="p-3 mb-0 text-shadowed "
                    style={{
                        color: convertColorToHex(partner.partnerDetail.textColor1),
                        position: "absolute",
                        top: 8,
                        left: 0,
                        width: "100%",
                        height: "auto",
                        zIndex: 2,
                    }}
                >
                    {getTranslation(partner.partnerDetail.title)}
                </h1>
            </div>
            <div className="card-body d-flex align-items-center" >
                <span className=" text-shadowed fs-xs flex-fill" style={{ color: convertColorToHex(partner.partnerDetail.textColor2) }}>{getTranslation(partner.partnerDetail.description)}</span>
                {(partner.partnerDetail.url && partner.partnerDetail.url !== "") && <button className='btn ms-3'
                    style={{
                        backgroundColor: convertColorToHex(partner.partnerDetail.callToActionColor),
                        color: convertColorToHex(partner.partnerDetail.button) ?? "var(--bs-dark)",
                    }}
                    onClick={() => window.open(partner.partnerDetail.url, "_blank")}
                >{t('common:discover')}
                </button>}
            </div>
        </div>
    )
}

export default PartnerCard