import { CompanyDetail } from 'models/companyModel';
import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getFlagEmoji } from 'utils/functions';
import Select from 'react-select';
import { useI18nStore } from 'stores/i18nStore';
import { langs } from 'assets/langs';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Config from 'utils/config';

function CompanyManageConfig(
    { company, partnerLogoImage, setBannerImage, handleConfigChange, handleLanguageChange, handleUrlChange, handleDashboardPreferencesChange }
        : {
            company: CompanyDetail, partnerLogoImage: File | null,
            setBannerImage: (file: File) => void, handleConfigChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
            handleUrlChange: (language: string, value: string) => void,
            handleLanguageChange: (e: any) => void,
            handleDashboardPreferencesChange: (items: string[]) => void
        }
): JSX.Element {
    const { t } = useTranslation();
    const [activeLanguage, setActiveLanguage] = useState("en");
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);
    const languageOptions = langs.map((lang) => {
        return { value: lang.code, label: lang.name };
    });
    return (
        <div>
            <h5>{t('company:configureCompany')}</h5>
            <Form >
                <Form.Group className="mb-3">
                    <Form.Label>{t('forms:company_name')}</Form.Label>
                    <Form.Control type="text" onChange={handleConfigChange} placeholder={t('company:company_name_placeholder')} defaultValue={company.company} name="company" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{t('common:expires_at')}</Form.Label>
                    <Form.Control type="date" onChange={handleConfigChange} placeholder={t('common:expires_at')} defaultValue={company.expirationDate} name="expirationDate" />
                </Form.Group>
                {/*                             <Form.Group className="mb-3" controlId="companyDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Enter company description" />
        </Form.Group> */}
                <Form.Group className="mb-3">
                    <label className="form-label">{t('company:partnerLogo')}</label>
                    <div id="nav-argument-banner-image" className="d-flex flex-column mb-3">
                        {!(company.companyLogo || partnerLogoImage) && <i className="mb-2">{t('forms:messages.no_image_selected')}</i>}
                        {((company.companyLogo || partnerLogoImage)) && <img src={partnerLogoImage != null ? URL.createObjectURL(partnerLogoImage) : (company.companyLogo ?? "")} className="img-fluid object-fit-contain border rounded mb-3" alt="Slide " />}

                        <input onChange={(event) => {
                            const files = event.target.files;
                            if (files != null && files[0].type.startsWith('image/')) {
                                setBannerImage(files[0]);
                            } else {
                                // Handle invalid file type
                            }
                        }} className="form-control" type="file" id="formFile" accept="image/*" />

                        {((company.companyLogo || partnerLogoImage) /* && !deleteBannerImage */) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {

                        }} defaultValue={company.companyLogo ?? ""}>
                            <i className='fas fa-trash' /> {t('common:delete')} Logo
                        </button>}
                    </div>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{t('company:selectLanguages')}</Form.Label>
                    <Select
                        className=""
                        options={languageOptions}
                        onChange={handleLanguageChange}
                        isMulti={true}
                        value={languageOptions.filter((option) => (company.languages ?? [])?.includes(option.value))}
                    />
                </Form.Group>
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        {(company.languages ?? []).map((language) =>
                            <button key={language} className={"nav-link text-secondary d-flex flex-column align-items-center " + (language === activeLanguage ? "active" : "")} onClick={() => setActiveLanguage(language)} type="button">
                                <div>{language.toUpperCase()}
                                </div>
                                <div>
                                    {getFlagEmoji(language)}
                                </div>
                            </button>
                        )}
                    </div>
                </nav>
                <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                    {/* <div className="tab-pane fade active show" id="nav-argument-icon" role="tabpanel" aria-labelledby="nav-argument-icon-tab">
                <h1>{fontIcon}</h1>
                {!showEmojiPicker && <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                    <i className='fas fa-plus' /> {t('forms:emoji')}
                </button>}
                {showEmojiPicker && <EmojiPicker autoFocusSearch={true} onEmojiClick={onEmojiClicked} />}
            </div> */}
                    {(company.languages ?? []).map((language) =>
                        <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
                            <div className="d-flex flex-column h-100">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">{t('company:company_url')}</label>
                                    <input type="text"
                                        className="form-control" id="exampleFormControlInput1" placeholder={t('forms:title')} name={"title_" + language} defaultValue={getTranslationWithLanguage(company.multiLanguageUrl, language)}
                                        onChange={(e) => handleUrlChange(language, e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>)}
                </div>
                <Form.Group className="mb-5">
                    <Form.Label>{t('company:dashboardPreferences')}</Form.Label>
                    <DragDropContext
                        onDragEnd={(result) => {
                            if (!result.destination) {
                                return;
                            }
                            const items = Array.from((company.defaultDashboardPreferences && company.defaultDashboardPreferences.length > 0)
                                ? company.defaultDashboardPreferences : Config.defaultDashboardPreferences);
                            const [reorderedItem] = items.splice(result.source.index, 1);
                            items.splice(result.destination.index, 0, reorderedItem);
                            handleDashboardPreferencesChange(items);
                        }}
                    >
                        <Droppable droppableId="droppable" type='DASH_PREFERENCES'>

                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {((company.defaultDashboardPreferences && company.defaultDashboardPreferences.length > 0)
                                        ? company.defaultDashboardPreferences : Config.defaultDashboardPreferences).map((item, index) => (
                                            <Draggable key={item} draggableId={item} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="d-flex justify-content-between align-items-center p-3 mb-3 bg-light rounded"
                                                    >
                                                        <span>{t('user_dashboard:widgets.' + item)}</span>
                                                        <i className="fas fa-bars" />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}

                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Form.Group>
            </Form>
        </div>
    );
}

export default CompanyManageConfig