import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useShopStore } from 'stores/shopStore';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { useI18nStore } from 'stores/i18nStore';
import { useUserStore } from 'stores/userStore';
import CircleButton from 'components/CircleButton';
import { useGemsStore } from 'stores/gemsStore';

function ShopSuccess() {
    const navigate = useNavigate();
    let { shopItemId } = useParams();
    const initShop = useShopStore(state => state.initShop);
    const shopItems = useShopStore(state => state.shopItems);
    const t = useTranslation().t;
    const user = useUserStore(state => state.user);
    const getTranslation = useI18nStore(state => state.getTranslation);
    // useEffect for initShop
    useEffect(() => {
        if (shopItems === null) {
            console.log('🛒 ShopDetail: useEffect for initShop');
            initShop();
        }
    }, [shopItems, initShop]);
    useEffect(() => {
        useGemsStore.getState().refreshGems();
    }, []);

    if (!shopItems) { return (<Loader />); }
    const shopItem = shopItems.find(shopItem => shopItem.shopItemId === shopItemId);
    if (!shopItem) { return (<Loader />); }
    return (
        <div className={`ShopSucces container `}>
            <div className='row text-center flex-column  align-items-center justify-content-center'>
                <div className='col-12 col-md-8 py-3 py-md-4 d-flex flex-row'>
                    <i className='fas fa-times fa-2x' onClick={() => navigate(-1)}></i>
                </div>
                <div className='col-12 col-md-8 mt-md-5 pt-lg-5'>
                    <img src={shopItem.shopItemDetail.logoId} className='img-fluid rounded bg-light mb-4 p-3' alt='logo' style={{
                        maxHeight: '200px',
                    }} />
                    <h1 className='mb-0'>🎉 {t('shop:congratulations')} {user.userProfileDetail.name}! 🎉</h1>
                    <h1 className='mb-0'>{t('shop:obtained')}: {getTranslation(shopItem.shopItemDetail.title)}</h1>
                    <span className='font-fancy  text-warning'>by</span>
                    <h2 className='mb-4 font-fancy text-warning'>{shopItem.shopItemDetail.company}</h2>
                    <p className='mb-4 fs-4 '>{t('shop:email', { email: user?.userProfileDetail?.email ?? "" })}</p>
                    <button className='btn btn-light' onClick={() => navigate('/welfare/dashboard')}>
                        {t('common:back')}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default ShopSuccess