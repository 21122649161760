import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader';
import { useExpertsStore } from 'stores/expertsStore';
import { useTranslation } from 'react-i18next';
import { useI18nStore } from 'stores/i18nStore';
import PageTitle from 'components/PageTitle';
import { useTitle } from 'utils/functions';
import { AnimatePresence, motion } from 'framer-motion';
import { Expert } from 'models/expertModel';
import { useVideoStore } from 'stores/videoStore';
import { Card } from 'react-bootstrap';
import { useIsMobile } from 'stores/appStore';
import { Playlist } from 'models/videoModels';
import Mark from 'components/Mark';
import TutorialTour from 'components/TutorialTour';

function PlaylistsPage() {
    const navigate = useNavigate();
    const init = useVideoStore(state => state.initVideoStore);
    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const playlists = useVideoStore(state => state.playlists);
    const [categories, setCategories] = React.useState<string[]>([]);
    const [filters, setFilter] = React.useState<string[]>([]);
    const handleFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
        const selectedFilter = e.currentTarget.value;
        // Se il filtro selezionato è già presente nell'array filter, lo rimuovo
        if (filters.includes(selectedFilter)) {
            setFilter(filters.filter(f => f !== selectedFilter));
        } else {
            setFilter([...filters, selectedFilter]);
        }
    }
    // useEffect for initShop
    useEffect(() => {
        if (playlists === null) {
            init();
        } else {
            if (categories.length === 0) {
                // Appiattire l'array di array di stringhe in un unico array di stringhe
                const flattenedCategories = playlists.flatMap(playlist => playlist.playlistDetail.category);
                // Rimuovere i duplicati
                const uniqueCategories = Array.from(new Set(flattenedCategories));
                setCategories(uniqueCategories);
                setFilter([]);
            }
        }
    }, [playlists, init]);

    const [filteredPlaylists, setFilteredPlaylists] = React.useState<Playlist[]>([]);

    useEffect(() => {
        if (playlists) {
            if (filters.length === 0) {
                setFilteredPlaylists([...playlists]);
                return;
            }
            const filteredExperts = [];
            setFilteredPlaylists([]);
            for (const expert of playlists) {
                if (expert.playlistDetail.category.some(category => filters.includes(category))) {
                    filteredExperts.push(expert);
                }
            }
            setFilteredPlaylists([...filteredExperts]);
        }
    }, [filters, playlists]);
    useTitle(t('videos:title'));
    const isMobile = useIsMobile();
    if (!playlists) { return (<Loader />); }
    const variantChildren = {
        "initial": {
            opacity: 0,
            scale: 0.4,
            y: 100,
        },
        "animate": {
            opacity: 1,
            scale: 1,
            y: 0,
        }
    };
    const slashMotion = {
        "hover": {
            scale: 1.08,
            transition: {
                type: "spring",
                bounce: 0.5,
            }
        }
    };
    const variantContainer = {
        "animate": {
            transition: {
                duration: 0.15,
                staggerChildren: 0.05
            }
        },
        "initial": {
            transition: {
                duration: 0.1,
            }
        }
    }
    const minutesOfPlaylist = (currentPlaylist: Playlist) => currentPlaylist.playlistDetail.videos.reduce((acc, video) => acc + video.videoDetail.minutes, 0);

    return (
        <motion.section
            className={`PlaylistsPage d-flex flex-column`}>
            <TutorialTour tutorialName="playlists" />

            <h5>
                {t('videos:subtitle')}
            </h5>
            <div className="nav my-3 PlaylistsPageNav">
                {
                    categories
                        .map((category, index) => (
                            <button key={index} className="btn btn-outline-primary rounded-pill me-2 mb-2"
                                style={{
                                    backgroundColor: filters.includes(category) ? "var(--bs-primary)" : "var(--bs-white)",
                                    color: filters.includes(category) ? "var(--bs-white)" : "var(--bs-tertiary-color)",
                                    borderColor: filters.includes(category) ? "var(--bs-primary)" : "var(--bs-tertiary-color)",
                                }}
                                onClick={handleFilter} value={category}>
                                {category}
                            </button>
                        ))
                }
            </div>
            <AnimatePresence mode='popLayout'>
                <motion.div
                    key={filters.length > 0 ? "playlistContainer" : filteredPlaylists.length}
                    variants={variantContainer}
                    initial="initial"
                    animate="animate"
                    exit="initial"
                    className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1  g-md-3 gy-3 mb-4" data-masonry="{`percentPosition`: true }"
                >
                    {
                        filteredPlaylists
                            .map(
                                (playlist, index) =>
                                    <motion.div
                                        onClick={() => navigate('/welfare/playlist/' + playlist.playlistId)}
                                        id={playlist.playlistId}
                                        key={playlist.playlistId}
                                        variants={variantChildren}
                                        className="PlaylistPageCard col" >
                                        {/*Card Shop */}
                                        <motion.div
                                            variants={variantChildren}
                                            whileHover={"hover"}
                                            className="card card-hover h-100  bg-primary-subtle">
                                            <div className="card-img-top position-relative" style={{
                                                width: "100%",
                                                height: isMobile ? "180px" : "180px",
                                                marginTop: isMobile ? "-24px" : "-24px"
                                            }} >

                                                <motion.img
                                                    variants={slashMotion}
                                                    src={playlist.playlistDetail.thumbnail} alt="playlist.playlistDetail" className="position-absolute bottom-0 mx-auto w-100 px-5 px-md-3" style={{
                                                        objectPosition: "bottom",
                                                        objectFit: "contain",
                                                        height: "100%",
                                                        transformOrigin: "bottom",
                                                    }} />
                                            </div>
                                            {/*   <img
                                                alt=""
          
                                                src={playlist.playlistDetail.thumbnail} className="card-img-top playlist.playlistDetail bg-primary-subtle w-100 h-auto px-3"></img> */}
                                            <div className="card-body rounded-bottom d-flex flex-column bg-body">
                                                <h5 className="card-title mb-3">{getTranslation(playlist.playlistDetail.title)}</h5>

                                                {/*  <div className="nav">
                                                    {
                                                        playlist.playlistDetail.category
                                                            .map((category, index) => (
                                                                <button key={index} className="btn btn-outline-primary border-0 rounded-pill me-2 mb-1"
                                                                    style={{
                                                                        fontSize: "0.8rem",
                                                                        backgroundColor: "var(--bs-primary-subtle)",
                                                                        color: "var(--bs-primary)",
                                                                    }}
                                                                    value={category}>
                                                                    {category}
                                                                </button>
                                                            ))
                                                    }
                                                </div> */}
                                                <div className='text-uppercase mt-auto'>
                                                    {playlist.playlistDetail.videos.length} {t('videos:lessons')} | {minutesOfPlaylist(playlist)} MIN
                                                </div>
                                                <span className="font-fancy h5 pt-2 mb-0">by {getTranslation(playlist.playlistDetail.metadata)}</span>
                                            </div>
                                        </motion.div>
                                    </motion.div>
                            )}
                </motion.div>
            </AnimatePresence>
        </motion.section >

    )
}

export default PlaylistsPage

/* 

{/* <motion.div
                                        id={playlist.playlistId}
                                        key={playlist.playlistId}
                                        variants={variantChildren}
                                        className="col" >
                                        <Card className='bg-primary-subtle border-0 h-100 cursor-pointer w-100' onClick={() => navigate('/welfare/playlist/' + playlist.playlistId)}>
                                            <Card.Body className='text-start flex-column d-flex align-items-start justify-content-start'>
                                                <h5 className='fs-5 mb-2 fw-bolder flex-fill' style={{
                                                    lineHeight: "1.4rem",
                                                    maxWidth: "55%",
                                                }}>

                                                    {getTranslation(playlist.playlistDetail.title)}
                                                </h5>
                                                <span className='text-uppercase small'

                                                >
                                                    {playlist.playlistDetail.videos.length} {t('videos:lessons')} | {playlist.playlistDetail.videos.reduce((acc, video) => acc + video.videoDetail.minutes, 0)} MIN
                                                </span>
                                                <h5 className='mt-1 mb-0 fs-5 font-fancy '>by {playlist.playlistDetail.metadata?.it}
                                                </h5>
                                            </Card.Body>
                                            <img src={playlist?.playlistDetail.thumbnail ?? ""} className="position-absolute" alt=""
                                                style={{
                                                    height: "108%",
                                                    objectFit: "contain", objectPosition: "bottom center",
                                                    zIndex: 1002,
                                                    bottom: 0,
                                                    right: "0rem",
                                                    maxWidth: "55%"
                                                }} />
                                        </Card>
                                    </motion.div> */