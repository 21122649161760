import React, { useEffect } from 'react'
import { useTutorialStore } from 'stores/tutorialStore';

const TutorialsWrapper = (props) => {
    const tutorialsReady = useTutorialStore((state) => state.tutorialsReady);
    const initTutorials = useTutorialStore((state) => state.initTutorials);
    useEffect(() => {
        if (!tutorialsReady) {
            initTutorials();
        }
    }, [initTutorials, tutorialsReady]);
    if (!tutorialsReady) return (<></>);
    return (
        <>
            {props.children}
        </>
    )
}

export default TutorialsWrapper;
