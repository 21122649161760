import { Amplify, Auth } from 'aws-amplify';
import { AuthStates } from '../stores/authStore';
import { toast } from 'react-hot-toast';
import Config from 'utils/config';
import { AmplitudeService } from './amplitudeService';
import { resetAllStores } from 'stores/createStore';

export const AuthService = {
    initCompleted: false,
    init: async () => {
        Amplify.configure({
            Auth: {
                // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
                // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

                // REQUIRED - Amazon Cognito Region
                region: Config.region,

                // OPTIONAL - Amazon Cognito Federated Identity Pool Region
                // Required only if it's different from Amazon Cognito Region
                // identityPoolRegion: 'XX-XXXX-X',

                // OPTIONAL - Amazon Cognito User Pool ID
                userPoolId: Config.userPoolId,

                // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
                userPoolWebClientId: Config.userPoolWebClientId,

                // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
                mandatorySignIn: false,

                // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
                // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
                signUpVerificationMethod: 'code', // 'code' | 'link'

                // OPTIONAL - Configuration for cookie storage
                // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
                /*         cookieStorage: {
                            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
                            domain: '.yourdomain.com',
                            // OPTIONAL - Cookie path
                            path: '/',
                            // OPTIONAL - Cookie expiration in days
                            expires: 365,
                            // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
                            sameSite: 'strict' | 'lax',
                            // OPTIONAL - Cookie secure flag
                            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
                            secure: true,
                        }, */

                // OPTIONAL - customized storage object
                // storage: MyStorage,

                // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
                authenticationFlowType: 'USER_SRP_AUTH',

                // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
                // clientMetadata: { myCustomKey: 'myCustomValue' },

                // OPTIONAL - Hosted UI configuration
                oauth: {
                    domain: Config.domain,
                    scope: [
                        "phone",
                        "email",
                        "openid",
                        "profile",
                        "aws.cognito.signin.user.admin"
                    ],
                    /*             redirectSignIn: 'http://localhost:3000/',
                                redirectSignOut: 'http://localhost:3000/', */
                    redirectSignIn: 'http://localhost:3000/',
                    redirectSignOut: 'http://localhost:3000/',
                    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
                },
            },
        });
        Auth.configure();
        AuthService.initCompleted = true;
        return AuthStates.Authenticated;
    },
    checkAuthSession: async () => {
        if (!AuthService.initCompleted) {
            console.log('[AuthService] Not initialized yet');
            return null;
        }
        try {
            const session = await Auth.currentSession();
            return session;
        } catch {
            console.log('[AuthService] No session found');
            return null;
        }
    },
    login: async (email, password) => {
        console.log('[AuthService] Login started');
        if (!AuthService.initCompleted) {
            return AuthStates.Initial;
        }
        try {
            const user = await Auth.signIn(email, password);
            return user;
        } catch (error) {
            console.log('[AuthService] Error during login', error);
            if (error.code === "UserNotConfirmedException") {
                throw (new Error("UserNotConfirmedException"));
            }
            toast.error(error.message);
        }
        return null;
    },
    logout: async () => {
        console.log('[AuthService] Logout started');
        try {
            await Auth.signOut();
            AmplitudeService.reset();
            resetAllStores();
            return AuthStates.NotAuthenticated;
        } catch (error) {
            console.log('[AuthService] Error during logout', error);
        }
        return null;
    },
    registerAccount: async (email, password, firstName, lastName) => {
        try {
            const user = await Auth.signUp({
                username: email,
                password,
                attributes: {
                    /* userType: "0", */
                    email: email,
                    given_name: firstName,
                    family_name: lastName,
                },
            });
            return user;
        } catch (e) {
            console.log('[AuthService] Error during registration', e);
            throw e;
        }
    },
    confirmAccount: async (email, code) => {
        try {
            const user = await Auth.confirmSignUp(email, code);
            return user;
        } catch (e) {
            console.log('[AuthService] Error during confirmation', e);
            throw e;
        }
    },
    resendEmail: async (email) => {
        try {
            const user = await Auth.resendSignUp(email);
            return user;
        } catch (e) {
            console.log('[AuthService] Error during resend', e);
            throw e;
        }
    },
    requestPasswordReset: async (email) => {
        try {
            const user = await Auth.forgotPassword(email);
            return user;
        } catch (e) {
            console.log('[AuthService] Error during password reset request', e);
            throw e;
        }
    },
    confirmPasswordReset: async (email, code, newPassword)/* : Promise<User> */ => {
        try {
            const user = await Auth.forgotPasswordSubmit(email, code, newPassword);
            return user;
        } catch (e) {
            console.log('[AuthService] Error during password reset confirmation', e);
            throw e;
        }
    },
    newPassword: async (user, newPassword) => {
        try {
            const res = await Auth.completeNewPassword(user, newPassword, {});
            return res;
        } catch (e) {
            console.log('[AuthService] Error during new password', e);
            throw e;
        }
    }
};