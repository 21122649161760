import QuizProgressIcon from "components/QuizProgressIcon";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import esp from "../../assets/images/xp.png";
import coriandoli from "../../assets/images/bg-coriandoli.png";

import { useEduquizStore } from "stores/eduquizStore";
import { usePartnerStore } from "stores/partnerStore";
import PartnerCard from "components/PartnerCard";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";
import { useI18nStore } from "stores/i18nStore";
import { AmplitudeService } from "services/amplitudeService";
import { useFeedbackStore } from "stores/feedbackStore";
import Config from "utils/config";
export default function EduQuizEndView({ ...props }) {

    const selectedUnit = useEduquizStore(state => state.selectedUnit);
    const terminateQuiz = useEduquizStore(state => state.terminateQuiz);
    const partnerList = usePartnerStore(state => state.partnerList);
    const initPartnerStore = usePartnerStore(state => state.initPartnerStore);

    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);

    useEffect(() => {
        if (partnerList === null) {
            initPartnerStore();
        }
    }, [partnerList, initPartnerStore]);

    // on close, terminate the quiz
    React.useEffect(() => {
        return () => {
            terminateQuiz();
        }
    }, [terminateQuiz]);

    useEffect(() => {
        useFeedbackStore.getState().checkUnitFeedback(selectedUnit!.unitId, selectedUnit!.unitDetail.title.it);
    }, [selectedUnit!.unitId, selectedUnit!.unitDetail.title.it]);

    const navigate = useNavigate();
    // get index of the current unit
    const unitIndex = selectedUnit!.unitDetail.module!.moduleDetail.units!.findIndex(unit => unit.unitId === selectedUnit!.unitId);
    // get the next unit
    const nextUnit = selectedUnit!.unitDetail.module!.moduleDetail.units![unitIndex + 1];


    useEffect(() => {
        AmplitudeService.logEvent("Unit completed", {
            unitId: selectedUnit!.unitId,
            moduleId: selectedUnit!.unitDetail.module!.moduleId,
            unitTitle: selectedUnit!.unitDetail.title.it,
            moduleTitle: selectedUnit!.unitDetail.module!.moduleDetail.title.it,
        });
        // if no nexts unit, module is completed
        if (!nextUnit) {
            AmplitudeService.logEvent("Module completed", {
                moduleId: selectedUnit!.unitDetail.module!.moduleId,
                moduleTitle: selectedUnit!.unitDetail.module!.moduleDetail.title.it,
            });
        }
    }, [nextUnit, selectedUnit]);




    const navigateToNextUnit = () => {
        // If there is no next unit, navigate to the module page
        if (!nextUnit) {
            navigate(-1);
            return;
        }
        navigate(`/welfare/eduquiz/module/${selectedUnit!.unitDetail.module!.moduleId}/unit/${nextUnit.unitId}`);
    }

    const color = (selectedUnit!.unitDetail.module!.moduleDetail.color ?? "").replace("0xFF", "#");
    if (!partnerList) return <Loader />;
    return (/*    <section className={`EduQuizEndView d-flex flex-column`}> */
        <section className={`EduQuizLearnView custom-height d-flex flex-column h-100 position-relative`}>
            <div className="d-flex align-items-center mb-4 mt-3 z-3">
                <i className="fa-duotone fa-times fa-2x me-4 cursor-pointer" onClick={() => navigate(-1)} />
                <h4 className="mb-0 h5 text-truncate">
                    {getTranslation(selectedUnit?.unitDetail?.title)}
                </h4>
            </div>

            <div className="row mb-3 mt-md-5 z-3" >
                <div className="col-12 col-md-6">
                    <div className="h-100">
                        <div className="card-body d-flex flex-column justify-content-evenly align-items-center align-items-md-start  mb-md-5 mb-md-0 mt-md-4">
                            <div className="ll mx-auto mb-3 d-block d-md-none">
                                <QuizProgressIcon color={color} percentage={100} icon={selectedUnit!.unitDetail.fontIcon} iconUrl={selectedUnit!.unitDetail.iconId} completed={selectedUnit!.unitDetail.userUnit!.userUnitDetail.finishedCorrectlyOnce} width={10} />
                            </div>
                            {nextUnit && <><h1 className="pe-md-5 display-4 text-md-start text-center mb-4">
                                {t('eduquiz:doing_great')} <br />{t('eduquiz:ready_for_next_unit')}
                            </h1>
                            </>}
                            {!nextUnit && <> <h1 className="pe-md-5 display-4 text-md-start text-center mb-3">
                                {t('eduquiz:module_completed')}
                            </h1>
                            </>}

                            <i className="fa-duotone fa-graduation-cap text-primary fa-6x d-none d-md-bock" style={{ fontSize: "19vw" }} />
                        </div>
                    </div>
                </div>


                <div className="col-12 col-md-6">
                    <div className="d-flex flex-column h-100">

                        <div className="flex-grow-md-1 d-flex flex-column">


                            <div className="card mb-3 flex-grow-md-0">
                                <div className="card-body pt-4">
                                    <div className="flex-grow-md-1 flex-shrink-0 position-relative " >
                                        <div className="ll mx-auto mb-3 d-none d-md-block">
                                            <QuizProgressIcon color={color} textIconClass="fs-1" percentage={100} icon={selectedUnit!.unitDetail.fontIcon} iconUrl={selectedUnit!.unitDetail.iconId} completed={selectedUnit!.unitDetail.userUnit!.userUnitDetail.finishedCorrectlyOnce} width={10} />
                                        </div>
                                    </div>
                                    <div className="card mb-3 ">
                                        <div className="card-body d-flex fw-bold align-items-center h4">
                                            <span>
                                                {t('eduquiz:rewards')}
                                            </span>
                                            <span className="fs-5 ms-auto" style={{
                                                // text outline effect
                                                WebkitTextStroke: "1px var(--bs-dark)",
                                                //font shadow black
                                                textShadow: "1px 1px 1px #000000",
                                                color: "var(--bs-white)"
                                            }}>
                                                {selectedUnit!.unitDetail.userUnit!.userUnitDetail.gemsPrize}

                                                <img
                                                    src={Config.coinImage}
                                                    className="img-fluid ms-2 me-1"
                                                    alt="coin"
                                                    style={{ height: "1.4rem" }}
                                                ></img>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card fw-bold">
                                        <div className="card-body fw-bold d-flex align-items-center h4">
                                            <span>
                                                {t('common:xp')}
                                            </span>
                                            <span className="ms-auto fs-5"
                                                style={{
                                                    // text outline effect
                                                    WebkitTextStroke: "1px var(--bs-dark)",
                                                    //font shadow black
                                                    textShadow: "1px 1px 1px #000000",
                                                    color: "var(--bs-white)"
                                                }}
                                            >
                                                +{selectedUnit!.unitDetail.userUnit!.userUnitDetail.experiencePoints}
                                                <img
                                                    src={esp}
                                                    className="img-fluid ms-2 me-1"
                                                    alt="coin"
                                                    style={{ height: "1.4rem" }}
                                                ></img>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!nextUnit && <div style={{ marginBottom: "250px" }}>
                                <PartnerCard partner={partnerList[0]/* partnerList.find(partner => partner.partnerId === selectedUnit!.unitDetail.module.moduleDetail.description) */} className="mx-auto  d-block d-md-none" />
                            </div>}

                        </div>
                        <div className="flex-grow-md-0 pb-5 pb-md-0">
                            <div className="card d-none">
                                {nextUnit && <div className="card-body">
                                    <h5 className="mb-3">
                                        {t('eduquiz:next_unit')} {getTranslation(nextUnit?.unitDetail?.title)}
                                    </h5>
                                    <div className="d-flex align-items-center mt-auto d-none">
                                        <button className="btn btn-primary rounded-pill px-4 py-2 ms-md-auto  " onClick={() => navigateToNextUnit()}>
                                            {t('common:continue')}
                                        </button>
                                    </div>
                                </div>}
                                {!nextUnit && <div className="card-body">
                                    <h5 className="mb-3">
                                        {t('eduquiz:module_completed')}
                                    </h5>
                                    <div className="d-flex align-items-center mt-auto d-none">
                                        <button className="btn btn-primary rounded-pill px-4 py-2 ms-md-auto" onClick={() => navigateToNextUnit()}>
                                            {t('eduquiz:next_argument')}
                                        </button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* SLIDE PAGE CONTROLS */}
            <div className="EduQuizEndViewSlideControls d-block d-md-flex z-3 flex-md-column justify-content-between align-items-start mt-auto w-100 py-4 fixed-bottom container-lg" style={{ bottom: "0" }}>
                {/* Page indicator */}
                {nextUnit && <h5 className="mb-4">
                    {t('eduquiz:next_unit')} {getTranslation(nextUnit?.unitDetail?.title)}
                </h5>}
                {!nextUnit && <h5 className="mb-4">
                    {t('eduquiz:module_completed')}
                </h5>}
                <div className="w-100 flex-md-row d-block d-md-flex flex-column align-items-center justify-content-between">
                    <div className=" d-flex card rounded-pill p-4 align-items-center flex-row justify-content-between ">
                        {/*Progress bar */}
                        <div className="W-100 col progress rounded-pill" style={{ width: "10rem", height: "1rem" }}>
                            <div className="progress-bar bg-primary" role="progressbar" style={{ width: "100%" }} ></div>
                        </div>
                        <div className="ms-3 ">
                            {
                                t('eduquiz:questions_completed', { currentUnits: selectedUnit!.unitDetail.questions!.length, totalUnits: selectedUnit!.unitDetail.questions!.length })
                            }
                        </div>
                    </div>
                    <div className="d-grid gap-2 d-md-block mt-3 mt-md-0">
                        <button className="btn btn-primary rounded-pill px-4 py-2 ms-md-auto btn-block" onClick={() => navigateToNextUnit()}>
                            {t('common:continue')}
                        </button></div>
                </div>
            </div>

            <img
                src={coriandoli}
                className="d-none d-md-block img-fluid top-0 start-0 position-fixed opacity opacity-50"
                alt="coin"

            ></img>

            <img src={coriandoli} className="h-50 img-fluid object-fit-cover position-fixed start-0 top-0 d-block d-md-none opacity-50" alt="coin" style={{ objectPosition: 'left' }} />




        </section >
    );
}