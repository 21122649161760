import React from 'react'
import { useTranslation } from 'react-i18next';
import { CompanyUserCreationData } from 'stores/companyManagerStore';
import Config from 'utils/config';

function CompanyManageUsersRecap({ usersToImport }: { usersToImport: CompanyUserCreationData[] }) {
    const { t } = useTranslation();
    return (
        <div>
            <h5>{t('company:importUsersRecap')}</h5>
            <p>
                {t('company:importUsersRecapDescription')}
            </p>
            <h6>{t('company:foundUsers', {
                amount: usersToImport.filter(user => {
                    return Config.emailRegex.test(user.email);
                }).length
            })}</h6>
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('forms:name')}</th>
                        <th>{t('forms:surname')}</th>
                        <th>{t('forms:email')}</th>
                    </tr>
                </thead>
                <tbody>
                    {(usersToImport).filter(user => {
                        return Config.emailRegex.test(user.email);
                    }).map((user, index) => (
                        <tr key={index}>
                            <td>{user.name}</td>
                            <td>{user.surname}</td>
                            <td>{user.email}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default CompanyManageUsersRecap