import { motion } from 'framer-motion'
import { Expert } from 'models/expertModel';
import React from 'react'
import { useNavigate } from 'react-router';
import { useIsMobile } from 'stores/appStore';
import { useI18nStore } from 'stores/i18nStore';

function ExpertsCard({ expert }: { expert: Expert }) {
    const navigate = useNavigate();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const isMobile = useIsMobile();
    const variantChildren = {
        "initial": {
            opacity: 0,
            scale: 0.4,
            y: 100,
        },
        "animate": {
            opacity: 1,
            scale: 1,
            y: 0,
        }
    };
    const slashMotion = {
        "hover": {
            scale: 1.08,
            transition: {
                type: "spring",
                bounce: 0.5,
            }
        }
    };
    return (
        <motion.div

            id={expert.expertId}
            key={expert.expertId}
            variants={variantChildren}
            className="ExpertCard cursor-pointer col" onClick={() => navigate('./' + expert.expertId, {
                // @ts-ignore
                relative: true
            })}>
            <motion.div
                variants={variantChildren}
                whileHover={"hover"} className="card  bg-primary-subtle h-100">
                <div
                    className="card-img-top position-relative"
                    style={{
                        width: "100%",
                        height: !isMobile ? "180px" : "240px",
                        marginTop: "-24px",
                    }} >
                    <motion.img
                        variants={slashMotion}
                        src={expert.expertDetail.img} alt="" className="position-absolute bottom-0 mx-auto w-100 px-5 px-md-3" style={{
                            objectPosition: "bottom",
                            objectFit: "contain",
                            height: "100%",
                            transformOrigin: "bottom",
                        }} />
                </div>
                <div className="card-body d-flex flex-column bg-body rounded-bottom">
                    <span className="mb-2 text-uppercase">{getTranslation(expert.expertDetail.title)}</span>
                    <h5 className="card-title mb-3">{getTranslation(expert.expertDetail.descriptionBrief)}</h5>
                    <div className="nav mt-auto ">
                        {
                            expert.expertDetail.category
                                .map((category, index) => (
                                    <button key={index} className="btn btn-outline-primary border-0 rounded-pill me-2 mb-1 text-truncate"
                                        style={{
                                            fontSize: "0.8rem",
                                            backgroundColor: "var(--bs-primary-subtle)",
                                            color: "var(--bs-primary)",
                                        }}
                                    /* onClick={handleFilter}  */ value={category}>
                                        {category}
                                    </button>
                                ))
                        }
                    </div>
                        <span className="font-fancy text-end h5 pt-2 mb-0">{expert.expertDetail.name}</span>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default ExpertsCard