import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { CompanyUserCreationData } from 'stores/companyManagerStore';

function CompanyManageAdmin({ admins, handleNewAdmin }: { admins: CompanyUserCreationData[], handleNewAdmin: (newAdmin: CompanyUserCreationData) => void }) {
    const { t } = useTranslation();
    const formRef = React.useRef<HTMLFormElement>(null);
    return (
        <div>
            <h5>{t('company:createAdmin')}</h5>
            <p>
                {t('company:createAdminDescription')}
            </p>
            <Form ref={formRef} onSubmit={(e: any) => {
                e.preventDefault();
                // get form data
                const formData = new FormData(e.target);
                formRef.current?.reset();
                handleNewAdmin({
                    name: formData.get('name') as string,
                    surname: formData.get('surname') as string,
                    email: formData.get('email') as string,
                } as CompanyUserCreationData);
            }}>
                <Form.Group className="mb-3">
                    <Form.Label>{t('forms:name')}</Form.Label>
                    <Form.Control type="text" placeholder={t('forms:name')} required name="name" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{t('forms:surname')}</Form.Label>
                    <Form.Control type="text" placeholder={t('forms:surname')} required name="surname" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{t('forms:email')}</Form.Label>
                    <Form.Control type="email" placeholder={t('forms:email')} required name="email" />
                </Form.Group>
                <Button variant="primary" type="submit">
                    {t('common:add')}
                </Button>
            </Form>
            {
                admins.length > 0 && (
                    <table className="table mt-3 table-sm table-borderless">
                        <thead>
                            <tr>
                                <th>{t('forms:name')}</th>
                                <th>{t('forms:surname')}</th>
                                <th>{t('forms:email')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {admins.map((admin, index) => (
                                <tr key={index}>
                                    <td>{admin.name}</td>
                                    <td>{admin.surname}</td>
                                    <td>{admin.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            }
        </div >
    )
}

export default CompanyManageAdmin