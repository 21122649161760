import GemCounter from 'components/GemCounter';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useShopStore } from 'stores/shopStore';
import Loader from 'components/Loader';
import toast from 'react-hot-toast';
import { useGemsStore } from 'stores/gemsStore';
import { useI18nStore } from 'stores/i18nStore';
import { useTranslation } from 'react-i18next';
import ShopErrorModal from './ShopErrorModal';
import { useTitle } from 'utils/functions';
import Config from 'utils/config';
import SecondaryHeader from 'components/SecondaryHeader';
import { ShopItem } from 'models/shopModels';

function ShopDetail() {
    const navigate = useNavigate();
    let { shopItemId } = useParams();
    const initShop = useShopStore(state => state.initShop);
    const shopItems = useShopStore(state => state.shopItems);
    const buyShopItem = useShopStore(state => state.buyShopItem);

    const checkShopItemDates = useShopStore(state => state.checkShopItemDates);

    const currentGemsData = useGemsStore(state => state.currentGemsData);
    const getUserGems = useGemsStore(state => state.getUserGems);
    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const [showErrorModal, setShowErrorModal] = React.useState<string | null>(null);
    useTitle(t('main_menu:shop'));


    // useEffect for initShop
    useEffect(() => {
        if (shopItems === null) {
            console.log('🛒 ShopDetail: useEffect for initShop');
            initShop();
        }
    }, [shopItems, initShop]);
    //useEffect for currentGemsData
    useEffect(() => {
        if (currentGemsData === null) {
            console.log('🛒 ShopDetail: useEffect for currentGemsData');
            getUserGems();
        }
    }, [currentGemsData, getUserGems]);
    if (!shopItems || !currentGemsData) { return (<Loader />); }
    const shopItem = shopItems.find(shopItem => shopItem.shopItemId === shopItemId);
    if (!shopItem) { return (<Loader />); }
    const onShopItemConfirmedBuy = (shopItemId: string) => {
        toast.promise(buyShopItem(shopItemId), {
            loading: t('common:please_wait'),
            success: t('shop:success_buy'),
            error: t('shop:error'),
        }).then(() => {
            navigate('success');
        }).catch((e) => {
            setShowErrorModal('error_' + e.message);
        });
    }

    const canUserBuyShopItem = (shopItem: ShopItem) => {
        return currentGemsData.gemDetail.currentGems >= shopItem.shopItemDetail.gems;
    };
    return (
        <section className={`ShopDetail d-flex flex-column container-lg`}>
            {/* HEADER */}
            <SecondaryHeader title={getTranslation(shopItem.shopItemDetail.title)}>
                <div className="d-flex ms-auto">
                    <GemCounter />
                </div>
            </SecondaryHeader>
            {/* BODY */}
            <div className='card mb-5 '>
                <div className='card-body p-md-4 mb-5 mb-md-0'>
                    {/* BANNER IMAGE */}
                    <div className="row mb-4">
                        <div className='col-12 col-md-6 col-xxl-4 '>
                            <img src={shopItem.shopItemDetail.bannerId} className="img-fluid rounded-4" alt="Sponsor" style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                        </div>
                    </div>
                    {/* DESCRIPTION */}
                    <div className="row mb-4">
                        <h3 className='mb-2'>{t('shop:informations')}</h3>
                        <p>
                            {getTranslation(shopItem.shopItemDetail.description)}
                            <br></br>
                            <br></br>
                            📲 &nbsp; {t('shop:visit_website')} <a className='text-decoration-none' href={getTranslation(shopItem.shopItemDetail.howTo)} rel="noreferrer" target="_blank"> {t('shop:website')} </a> {t('shop:for_more_informations')}.
                        </p>
                    </div>
                    {/* REQUIREMENTS */}
                    <div className="row mb-4 d-flex justify-content-center align-items-center">
                        <h3 className='mb-3'>{t('shop:requirements')}</h3>
                        <p className="fs-6 ms-auto fw-bold d-flex flex-rowjustify-content-center align-items-center d-block" >
                            {canUserBuyShopItem(shopItem) && (
                                <i className='fa-duotone fa-check-circle text-success me-2' />)}
                            {!canUserBuyShopItem(shopItem) && (
                                <i className='fa-duotone fa-times-circle text-danger me-2' />)}
                            {currentGemsData.gemDetail.currentGems} / {shopItem.shopItemDetail.gems}
                            <img
                                src={Config.coinImage}
                                className="img-fluid ms-2 me-1 d-block"
                                alt="coin"
                                style={{ height: "1.2rem" }}
                            ></img>

                        </p>
                        <p className="fs-6 ms-auto d-flex flex-rowjustify-content-center align-items-center d-block" >

                            {checkShopItemDates(shopItem) && (
                                <i className='fa-duotone fa-check-circle text-success me-2' />)}
                            {!checkShopItemDates(shopItem) && (
                                <i className='fa-duotone fa-times-circle text-danger me-2' />)}

                            {t('shop:redeem_before')}&nbsp;<strong>{new Date(shopItem.shopItemDetail.shopItemExpirationDate).toLocaleDateString("it-IT")}</strong>
                            <i className='fa-duotone fa-calendar ms-2 text-primary' />

                        </p>
                        <p className="fs-6 ms-auto  flex-row justify-content-center align-items-center d-none" >
                            {shopItem.shopItemDetail.counter > 0 && (
                                <i className='fa-duotone fa-check-circle text-success me-2' />)}
                            {shopItem.shopItemDetail.counter === 0 && (
                                <i className='fa-duotone fa-times-circle text-danger me-2' />)}
                            <strong>{(shopItem.shopItemDetail.counter)}</strong>&nbsp; available
                        </p>
                    </div>
                    {/* TERMS */}
                    <div className="row mb-0">
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                                <div className="accordion-header cursor-pointer d-flex" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                    <h3 className='flex-fill'>{t('shop:terms_and_conditions')}</h3>
                                    <div>
                                        <button className="accordion-button filter-effect" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        </button>
                                    </div>
                                </div>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body px-0 pb-3">
                                        {getTranslation(shopItem.shopItemDetail.termsAndConditions)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* SPACER */}
            <div className="my-4" />
            {/* FIXED MENU */}
            <div className="position-fixed d-flex flex-column justify-content-end align-items-center 
            bottom-0 start-0 w-100 " style={{ zIndex: "3" }}>
                <div className={" card w-100 border-bottom-0 rounded-bottom-0"}>
                    <div className="container-lg d-flex flex-row justify-content-center align-items-start card-body p-4">
                        <p className={"mb-3 fw-bold d-none"}></p>
                        {/*Indicator coin*/}
                        <div className='d-flex  px-3 py-2 '>
                            <h5 className='mb-0 fw-bold'>
                                {shopItem.shopItemDetail.gems}
                            </h5>
                            <img
                                src={Config.coinImage}
                                className="img-fluid ms-2 me-1"
                                alt="coin"
                                style={{ height: "1.4rem" }}
                            ></img>
                        </div>

                        {/* {logo && <img src={logo} alt="logo" className="mb-3" /> */}
                        <button data-bs-toggle="modal" data-bs-target="#confirmModal" className={"ms-auto btn btn-primary btn-block rounded-pill px-5 "}>{t('shop:buy')}</button>
                    </div>
                </div>
            </div>
            {/* Confirm modal */}
            <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title" id="confirmModalLabel">{t('shop:confirm_buy')}</h5>
                            <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>{t('shop:are_you_sure')} <strong>{getTranslation(shopItem.shopItemDetail.title)}</strong> {t('common:of')} <strong>{shopItem.shopItemDetail.company}</strong> {t('common:for')} <strong>{shopItem.shopItemDetail.gems}</strong> <img

                                src={Config.coinImage}
                                className="img-fluid me-1"
                                alt="coin"
                                style={{ height: "1.4rem" }}
                            ></img>?</p>
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annulla</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => onShopItemConfirmedBuy(shopItem.shopItemId)}>Conferma</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Error modal */}
            {showErrorModal && <ShopErrorModal error={showErrorModal} dismiss={() => setShowErrorModal(null)} />}
        </section>
    )
}

export default ShopDetail