import React from "react";
import { Flag32 as Flag } from '@weston/react-world-flags';
import Config from "./config";
import { useLocation } from "react-router-dom";
import { useAuthStore } from "stores/authStore";

/**
 * Generates an image URL based on the provided document object.
 * @param {Object} documentObject - The document object containing the storage path. Must have a property called "document" with a property called "storagePath".
 * @returns {string|null} - The generated image URL or null if the document object is invalid.
 */
export function generateImageUrl(documentObject) {
    if (documentObject != null && documentObject.document?.storagePath != null) {
        return Config.fileStorageBaseUrl + documentObject.document.storagePath;
    }
    return null;
}

/**
 * Converts a color string in the format "0xFFRRGGBB" to a hex string in the format "#RRGGBB".
 * @param {string} color - The color string to convert.
 * @returns {string|null} - The converted hex string or null if the input is invalid.
 */
export function convertColorToHex(color) {
    return color?.replace('0xFF', '#');
}

/**
 * Converts a hex string in the format "#RRGGBB" to a color string in the format "0xFFRRGGBB".
 * @param {string} hex - The hex string to convert.
 * @returns {string|null} - The converted color string or null if the input is invalid.
 */
export function convertHexToColor(hex) {
    return hex?.replace('#', '0xFF');
}

/**
 * Converts a color string in the format "0xFFRRGGBB" to an RGB string in the format "R,G,B".
 * @param {string} color - The color string to convert.
 * @returns {string|null} - The converted RGB string or null if the input is invalid.
 */
export function convertColorToRGBString(color) {
    let hex = convertColorToHex(color);
    let bigint = parseInt(hex.replace('#', ''), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    // output a string
    return r + "," + g + "," + b;
}

/**
 * Formats an object containing i18n strings into an object with language codes as keys and trimmed strings as values.
 * @param {string} searchKey - The prefix of the i18n keys to search for.
 * @param {Object} data - The object containing the i18n strings.
 * @returns {Object} - The formatted object with language codes as keys and trimmed strings as values.
 */
export function formatI18nObject(searchKey, data) {
    const result = {};

    for (const prop in data) {
        if (prop.startsWith(`${searchKey}_`)) {
            const lang = prop.split('_')[1];
            result[lang] = data[prop].trim();
        }
    }

    return result;
}

/**
 * Returns a React component that displays the flag emoji for the specified language code.
 * @param {string} languageCode - The language code to display the flag for.
 */
export function getFlagEmoji(languageCode = " ") {
    if (!languageCode) {
        languageCode = " ";
    }
    if (languageCode === "en") {
        languageCode = "gb";
    }
    return <div className="FlagWrapper">
        <Flag
            code={languageCode}
        />
    </div>;
}

/**
 * Returns the contrast color (black or white) for the specified hex color.
 * @param {string} hexColor - The hex color to get the contrast color for.
 * @returns {string} - The contrast color (black or white).
 */
export function getContrastColor(hexColor) {
    hexColor = hexColor.replace("#", "");
    // Convert hex color to RGB
    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);

    // Calculate relative luminance
    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
    // Return black or white depending on brightness
    return luminance > 0.8 ? "var(--bs-dark) !important" : "var(--bs-white)";
}

export const useTitle = title => {
    const documentDefined = typeof document !== 'undefined';
    const originalTitle = React.useRef(documentDefined ? document.title : null);

    React.useEffect(() => {
        if (!documentDefined) return;

        if (document.title !== title) document.title = "Funnifin | " + title;

        return () => {
            document.title = originalTitle.current;
        };
    }, [title, documentDefined]);
};

export const convertDifficultyFromText = (difficulty) => {
    switch (difficulty) {
        case "LOW": return 1;
        case "MEDIUM": return 2;
        case "HARD": return 3;
        default: return 1;
    }
}
export const convertDifficultyFromNumber = (difficulty) => {
    if (typeof difficulty !== 'number') {
        difficulty = parseInt(difficulty);
    }
    switch (difficulty) {
        case 1: return "LOW";
        case 2: return "MEDIUM";
        case 3: return "HARD";
        default: return "LOW";
    }
}

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function getRoleColorVariant() {
    const isAdmin = useAuthStore.getState().isAdmin;
    const isSystem = useAuthStore.getState().isSystem;
    const isHr = useAuthStore.getState().isHR;
    const isUser = useAuthStore.getState().isUser;
    if (isAdmin) return "warning";
    if (isSystem) return "danger";
    if (isHr) return "success";
    if (isUser)
        return "body-secondary";
}

