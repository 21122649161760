import React from 'react'
import { Outlet } from 'react-router-dom'
import MainMenu from 'components/MainMenu'

function MainLayout(props) {
    return (
        <div className="MainLayout main-layout container-fluid container-md h-100">
            <div className="row h-100 pt-3">
                <div className="col-3 h-100">
                    <MainMenu />
                </div>
                <div className="col-9 h-100">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default MainLayout