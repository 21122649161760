import React from 'react'
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthStore } from 'stores/authStore';
import { useSurveyStore } from 'stores/surveyStore';
import { useUserStore } from 'stores/userStore';

function SurveyWrapper(props) {
    const surveyReady = useSurveyStore((state) => state.surveyReady);
    const hasDoneSurvey = useSurveyStore((state) => state.hasDoneMainSurvey);
    const initSurvey = useSurveyStore((state) => state.initSurvey);
    const location = useLocation();
    const user = useUserStore((state) => state.user);
    useEffect(() => {
        if (!surveyReady) {
            initSurvey(user);
        }
    }, [initSurvey, surveyReady, user]);
    if (!surveyReady) return (<></>);
    return (
        <>
            {
                surveyReady && !hasDoneSurvey && !location.pathname.includes('/survey') && useAuthStore.getState().isUser && <Navigate to="/survey"></Navigate>
            }
            {props.children}
        </>
    )
}

export default SurveyWrapper