import CircleButton from 'components/CircleButton';
import { t } from 'i18next';
import Lottie from 'lottie-react';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { Trans } from 'react-i18next';
import { useAuthStore } from 'stores/authStore';
import Config from 'utils/config';
import ReactInputVerificationCode from 'react-input-verification-code';

// @ts-ignore
import checkAnimation from 'assets/lotti/check.json';
import { Tooltip } from 'react-tooltip';
import { useSystemModalStore } from 'stores/systemModalStore';
import ForgotPasswordDialog from '../forgot_password/ForgotPasswordDialog';

const specialCharacters = "^$*.[\\]{}()?/|+=\"!@#%&,><':;_~`";

const RegisterFormStates = {
    Initial: Symbol("initial"),
    Email: Symbol("email"),
    Password: Symbol("password"),
    Terms: Symbol("terms"),
    Confirmation: Symbol("confirmation"),
    Success: Symbol("success"),
}
const RegisterForm = (props) => {
    // state for formData
    const [formData, setFormData] = useState({});
    // state for password visibility
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    // state for formstate
    const [formState, setFormState] = useState(RegisterFormStates.Initial);
    const [code, setCode] = useState('')
    const registerAccount = useAuthStore(state => state.registerAccount);
    const confirmAccount = useAuthStore(state => state.confirmAccount);
    const onInitialSubmit = (e) => {
        e.preventDefault();
        // get data from form
        const _formData = new FormData(e.target);
        const data = Object.fromEntries(_formData.entries());
        // set form data
        setFormData(data);
        setFormState(RegisterFormStates.Email);
    }
    const onEmailSubmit = (e) => {
        e.preventDefault();
        // get data from form
        const _formData = new FormData(e.target);
        const data = Object.fromEntries(_formData.entries());
        // set form data
        setFormData({ ...formData, ...data });
        setFormState(RegisterFormStates.Password);
    }
    const onPasswordSubmit = (e) => {
        e.preventDefault();
        // get data from form
        const _formData = new FormData(e.target);
        const data = Object.fromEntries(_formData.entries());
        // check if password match
        if (data.password !== data.passwordConfirmation) {
            useSystemModalStore.getState().showErrorModal(t('forms:passwords_not_match'));
            return;
        }
        // set form data
        setFormData({ ...formData, ...data });
        setFormState(RegisterFormStates.terms);
    }
    const onTermsSubmit = async (e) => {
        e.preventDefault();
        // get data from form
        const _formData = new FormData(e.target);
        const data = Object.fromEntries(_formData.entries());
        // set form data
        setFormData({ ...formData, ...data });
        // @ts-ignore
        toast.promise(registerAccount(formData.email, formData.password, formData.name, formData.surname), {
            loading: t('home:registering_account'),
            success: t('home:register_success'),
            error: t('home:register_error'),
        }).then((result) => {
            setFormState(RegisterFormStates.Confirmation);
        }).catch((err) => {
            console.log('Error while submitting terms', err);
            toast.error(err.message);
        });
    }

    const onConfirmationSubmit = (e) => {
        e.preventDefault();
        // get data from form
        const _formData = new FormData(e.target);
        const data = Object.fromEntries(_formData.entries());
        // set form data
        setFormData({ ...formData, ...data });
        toast.promise(confirmAccount(formData?.email, code), {
            loading: t('home:confirm_in_progress'),
            success: t('home:confirm_success'),
            error: t('home:confirm_error'),
        }).then((result) => {
            setFormState(RegisterFormStates.Success);
            toast.success(t('home:login_to_continue'));
        }).catch((err) => {
            console.log('Error while submitting confirmation', err);
        });
    }
    let formContent = null;
    let progress = 0;
    switch (formState) {
        default:
        case RegisterFormStates.Initial:
            formContent = (
                <form className='d-flex flex-column text-start' onSubmit={onInitialSubmit} key={'registerInitial'} name="Registrazione - Inizio">

                    <h3 className='mb-4 h1'>{t('home:enter_name')}</h3>

                    <p className='mb-4'>{t('home:register_cta_intro')}</p>

                    <div className="mb-4 form-floating ">
                        <input placeholder={t('forms:name')} name="name" type="text" className="form-control" id="name" required defaultValue={formData['name'] ?? ""} />
                        <label htmlFor="Nome" className="form-label">{t('forms:name')}</label>
                    </div>
                    <div className="mb-4 form-floating">
                        <input placeholder={t('forms:surname')} name="surname" type="text" className="form-control" id="surname" required defaultValue={formData['surname'] ?? ""} />
                        <label htmlFor="surname" className="form-label">{t('forms:surname')}</label>
                    </div>
                    <button type="submit" className=" btn btn-primary btn-block mb-4  rounded-pill" >{t('common:continue')}</button>
                    <div className='text-center d-flex align-items-center justify-content-center'>
                        <span>{t('home:already_have_account')}</span>
                        <span className='text-primary ms-1 text-decoration-underline fw-bold cursor-pointer' onClick={() => {
                            props.setShowLogin(true);
                        }}><u>{t('home:login')}</u></span>
                    </div>
                    <ForgotPasswordDialog />
                </form>
            );
            break;
        case RegisterFormStates.Email:
            formContent = (
                <form className='d-flex flex-column text-start' onSubmit={onEmailSubmit} key={'registerEmail'} name="Registrazione - Email" >
                    <h3 className='mb-4 h1'>{t('home:login')}</h3>
                    <p className='mb-4'>{t('home:email_cta')}</p>
                    <div className="mb-4 form-floating" >

                        <input placeholder="E-mail" name="email" type="email" className="form-control" id="email" value={formData['email'] ?? ""} onChange={(event) => setFormData({ ...formData, email: event.target.value })} required />
                        <label htmlFor="email" className="form-label">{t('forms:email')}</label>
                    </div>
                    <button type="submit" className=" btn btn-primary btn-block mb-4  rounded-pill" disabled={formData['email'] === undefined} >Continue</button>
                    <div className='text-center d-flex align-items-center justify-content-center'>
                        <span>{t('home:already_have_account')} </span>
                        <span className='text-primary ms-1 text-decoration-underline fw-bold cursor-pointer' onClick={() => {
                            props.setShowLogin(true);
                        }
                        }><u>{t('home:login')}</u></span>
                    </div>
                </form>
            );
            progress = 20;

            break;
        case RegisterFormStates.Password:
            formContent = (
                <form className='d-flex flex-column' onSubmit={onPasswordSubmit} key={'registerPassword'} name="Registrazione - Password">
                    <h3 className='mb-4 h1'>{t('home:choose_password')}</h3>
                    <small className='mb-3'>
                        <span>{t('password:must')} </span><br />
                        <div className="d-flex align-items-center">
                            <span>
                                {t('password:uppercase')}</span>
                            {((RegExp(/.*[A-Z].*/).test(formData['password']) && formData['password']) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                <i className="fas fa-times-circle text-danger" />
                            </div>}
                        </div>
                        <div className="d-flex align-items-center">
                            <span>
                                {t('password:lowercase')}</span>
                            {((RegExp(/.*[a-z].*/).test(formData['password']) && formData['password']) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                <i className="fas fa-times-circle text-danger" />
                            </div>}
                        </div>
                        <div className="d-flex align-items-center">

                            <span>
                                {t('password:number')}</span>
                            {((RegExp(/.*\d.*/).test(formData['password']) && formData['password']) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                <i className="fas fa-times-circle text-danger" />
                            </div>}
                        </div>
                        <div className="d-flex align-items-center">
                            <span>
                                {t('password:special')}
                            </span>
                            &nbsp;
                            <span data-tooltip-id="specialCharacters" className='text-decoration-underline' >
                                {t('password:characters')}
                            </span>
                            <Tooltip id="specialCharacters" place="bottom" effect="solid">
                                <span>{specialCharacters}</span>
                            </Tooltip>
                            {((RegExp(/(?=.*[\^$*.[\]{}()?/|+="!@#%&,><':;_~`])/).test(formData['password']) && formData['password']) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                <i className="fas fa-times-circle text-danger" />
                            </div>}
                        </div>
                        <div className="d-flex align-items-center">
                            <span>
                                {t('password:min_length', {
                                    min: 8
                                })}</span>
                            {((formData['password'] && formData['password'].length >= 8) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                <i className="fas fa-times-circle text-danger" />
                            </div>}
                        </div>
                        <div className="d-flex align-items-center">

                            <span>{t('password:match')}</span>
                            {((formData['password'] === formData['passwordConfirmation'] && formData['password'] && formData['passwordConfirmation']) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                <i className="fas fa-times-circle text-danger" />
                            </div>}
                        </div>
                    </small>
                    <div className="mb-4 form-floating position-relative" >
                        <input placeholder="Password" name="password" type={showPassword ? 'text' : 'password'} className="form-control" id="password" required autoComplete='off' value={formData['password'] ?? ""} onChange={(event) => setFormData({ ...formData, password: event.target.value.replace(/"/g, '') })} />

                        <label htmlFor="password" className="form-label">{t('forms:password')}</label>
                        <span onClick={togglePasswordVisibility}>
                            {showPassword ? <i className='fas fa-eye' style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }} /> : <i className='fas fa-eye-slash' style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }} />}
                        </span>
                    </div>
                    <div className="mb-4 form-floating position-relative" >
                        <input placeholder="Confirm password" name="passwordConfirmation" type={showPassword ? 'text' : 'password'} className="form-control" id="passwordConfirmation" value={formData['passwordConfirmation'] ?? ""} required onChange={(event) => setFormData({ ...formData, passwordConfirmation: event.target.value.replace(/"/g, '') })} />

                        <label htmlFor="passwordConfirmation" className="form-label">{t('forms:password_confirmation')}</label>
                        <span onClick={togglePasswordVisibility}>
                            {showPassword ? <i className='fas fa-eye' style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }} /> : <i className='fas fa-eye-slash' style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }} />}
                        </span>
                    </div>

                    <button type="submit" className=" btn btn-primary btn-block mb-4  rounded-pill" disabled={
                        formData['password'] !== formData['passwordConfirmation'] || formData['password'] === undefined || formData['passwordConfirmation'] === undefined || !Config.passwordRegex.test(formData['password'])
                    } >{t('common:continue')}</button>
                    <div className='text-center d-flex align-items-center justify-content-center'>
                        <span>{t('home:already_have_account')} </span>
                        <span className='text-primary ms-1 text-decoration-underline fw-bold cursor-pointer' onClick={() => {
                            props.setShowLogin(true);
                        }
                        }><u>{t('home:login')}</u></span>
                    </div>
                </form >
            );
            progress = 40;
            break;
        case RegisterFormStates.terms:
            formContent = (
                <form className='d-flex flex-column' onSubmit={onTermsSubmit} key={'registerTerms'} name="Registrazione - Termini e condizioni">
                    <h3 className='mb-4 h1'>{t('home:terms_and_conditions')}</h3>
                    <div className="mb-4 form-check">
                        <input name="termsAgreement" type="checkbox" className="form-check-input" id="termsAgreement" required onChange={(e) => {
                            setFormData({ ...formData, termsAgreement: !e.target.checked });
                        }} />
                        <label className="form-check-label" htmlFor="termsAgreement">
                            <Trans
                                i18nKey={'home:terms_agreement'}
                                components={{ terms: <a href="/terms" target="_blank" rel="noopener noreferrer">a</a>, privacy: <a href="/privacyPolicy.pdf" target="_blank" rel="noopener noreferrer">a</a> }}
                            ></Trans>
                        </label>
                    </div>
                    <button type="submit" className=" btn btn-primary btn-block mb-4  rounded-pill">Register</button>
                    <div className='text-center d-flex align-items-center justify-content-center'>
                        <span>{t('home:already_have_account')}{formData['termsAgreement']} </span>
                        <span className='text-primary ms-1 text-decoration-underline fw-bold cursor-pointer' onClick={() => {
                            props.setShowLogin(true);
                        }
                        }><u>Login</u></span>
                    </div>
                </form>
            );
            progress = 60;
            break;
        case RegisterFormStates.Confirmation:
            formContent = (
                <form className='d-flex flex-column' onSubmit={onConfirmationSubmit} key={'registerCode'} name="Registrazione - Conferma codice">

                    <h3 className='mb-4 h1'>{t('home:register')}</h3>
                    <p>
                        <Trans
                            i18nKey={'home:email_sent_message'}
                            values={{ usedEmail: formData.email }}
                            components={{ email: <span className="text-danger fw-bold" /> }}
                        />
                    </p>
                    <div className="mb-4 form-floating" >
                        <ReactInputVerificationCode
                            length={6}
                            onChange={(value) => {
                                setCode(value)
                            }}
                            value={code}
                        />
                    </div>
                    <button type="submit" className=" btn btn-primary btn-block mb-4  rounded-pill" disabled={code.length !== 6}>Confirm</button>
                    <div className='text-center d-flex align-items-center justify-content-center'>
                        <span>{t('home:already_have_account')} </span>
                        <span className='text-primary ms-1 text-decoration-underline fw-bold cursor-pointer' onClick={() => {
                            props.setShowLogin(true);
                        }
                        }><u>{t('home:login')}</u></span>
                    </div>
                </form>
            );
            progress = 80;
            break;
        case RegisterFormStates.Success:
            formContent = (
                <div className='d-flex flex-column'>
                    <img src={Config.companyLogo} className="img-fluid w-75 mx-auto ms-lg-auto d-block" alt="two fin holding hands" />
                    <h3 className='mb-4 h1'>{t('home:registration_completed')}</h3>
                    <p>{t('home:can_login_now')}</p>
                    <button type="submit" className=" btn btn-primary btn-block mb-4 rounded-pill" onClick={() => {
                        props.setShowLogin(true);
                    }
                    }>{t('home:login')}</button>
                </div>
            );
            progress = 100;
            break;
    }

    return <div>
        {/* linear progress bar */}
        <div className="d-flex  mb-4">
            {formState === RegisterFormStates.Initial && <CircleButton className="bg-transparent border-0 me-2" iconClassName="fas fa-arrow-left text-primary fa-2x" data-bs-dismiss="modal" />}
            {formState !== RegisterFormStates.Initial && <CircleButton className="bg-transparent border-0 me-2" iconClassName="fas fa-arrow-left text-primary fa-2x" onClick={() => {
                switch (formState) {
                    default:
                    case RegisterFormStates.Email:
                        setFormState(RegisterFormStates.Initial);
                        break;
                    case RegisterFormStates.Password:
                        setFormState(RegisterFormStates.Email);
                        break;
                    case RegisterFormStates.terms:
                        setFormState(RegisterFormStates.Password);
                        break;
                    case RegisterFormStates.Confirmation:
                        setFormState(RegisterFormStates.terms);
                        break;
                }
            }} />}
            <div className='d-flex justify-content-center w-100 align-items-center'>
                <div className="W-100 col progress rounded-pill" style={{ width: "70%", height: "0.3rem" }}>
                    <div className="progress-bar bg-white opacity-50" role="progressbar" style={{ width: progress + "%" }} ></div>
                </div>
            </div>
        </div>
        {
            formContent
        }


    </div>

}

export default RegisterForm