import HeaderAdmin from 'components/HeaderAdmin';
import Loader from 'components/Loader';
import React, { FormEvent, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useUsersStore } from 'stores/usersStore';
import { useTranslation } from 'react-i18next';
import { UserProfile } from 'models/userModels';
import { useTitle } from 'utils/functions';
import CompanyManageUsers from 'components/createCompanyWizard/CompanyManageUsers';
import { Modal, ModalBody, ModalFooter, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { CompanyUserCreationData, useCompanyManagerStore } from 'stores/companyManagerStore';
import { useUserStore } from 'stores/userStore';
import { useSystemModalStore } from 'stores/systemModalStore';
import { useAuthStore } from 'stores/authStore';
import HeaderWidgetPortal from 'components/HeaderWidgetPortal';

const headersCSV = [
    { label: 'userId', key: 'userProfileId' },
    { label: 'name', key: 'name' },
    { label: 'surname', key: 'surname' },
    { label: 'nickname', key: 'nickname' },
    { label: 'email', key: 'email' },
    { label: 'group', key: 'group' },
    /*         { label: 'accesses', key: 'accesses' }, */
    { label: 'enabled', key: 'enabled' },
    { label: 'hasSocial', key: 'hasSocial' },
    { label: 'registrationDate', key: 'registrationDate' },
    { label: 'dateLastUpdate', key: 'dateLastUpdate' },
];


function UsersPage() {
    const usersList = useUsersStore((state) => state.usersList);
    const initUsers = useUsersStore((state) => state.initUsers);
    //use state for usersListToShow
    const [userListToShow, setUserListToShow] = React.useState(usersList);
    const { t } = useTranslation();
    const [showManageUsers, setShowManageUsers] = React.useState(false);
    const [showManageAdmins, setShowManageAdmins] = React.useState(false);
    const [admins, setAdmins] = useState([] as CompanyUserCreationData[]);
    const [usersToImport, setUsersToImport] = useState([] as CompanyUserCreationData[]);
    const createNewUsers = useUsersStore((state) => state.createUsers);
    const deleteUser = useUsersStore((state) => state.deleteUser);
    const resetUserPassword = useUsersStore((state) => state.resetUserPassword);
    const showModal = useSystemModalStore((state) => state.showConfirmModal);
    const companyList = useCompanyManagerStore((state) => state.companyList);
    const isSystem = useAuthStore((state) => state.isSystem);
    useTitle(t('main_menu:users'));
    // This effect is used to initialize the users list
    useEffect(() => {
        console.log('🧑‍🤝‍🧑 UserPage: useEffect');
        if (usersList === null) {
            console.log('🧑‍🤝‍🧑 UserPage: useEffect: initUsers');
            initUsers();
        } else {
            setUserListToShow(usersList);
            console.log('🧑‍🤝‍🧑 UserPage: useEffect: usersList', usersList);
        }
    }, [usersList, initUsers]);
    // This effect is initialize the company list
    useEffect(() => {
        if (companyList === null) {
            useCompanyManagerStore.getState().init();
        }
    }, [companyList]);


    // If the users list is not initialized yet, we show a loading indicator
    if (usersList === null || usersList === undefined || companyList === null || companyList === undefined) return (<Loader />);
    //create headers file for usersList. eliminate the userDetail field in the user first, and move it up one level before creating the header
    const usersCSV = usersList.map((user: UserProfile) => {
        const { userProfileDetail, ...userWithoutProfileDetail } = user;
        return { ...userWithoutProfileDetail, ...userProfileDetail };
    });



    function searchUsers(event: FormEvent<HTMLFormElement>) {
        // Prevent the form from reloading the page
        event.preventDefault();
        // Get the values from the form
        const name = (event.currentTarget.elements.namedItem('inputName') as HTMLInputElement).value;
        const surname = (event.currentTarget.elements.namedItem('inputSurname') as HTMLInputElement).value;
        const nickname = (event.currentTarget.elements.namedItem('inputNickname') as HTMLInputElement).value;
        const email = (event.currentTarget.elements.namedItem('inputEmail') as HTMLInputElement).value;
        const registrationDate = (event.currentTarget.elements.namedItem('inputRegistrationDate') as HTMLInputElement).value;
        /*         const group = (event.currentTarget.elements.namedItem('inputGroup') as HTMLSelectElement).value;
                const enabled = (event.currentTarget.elements.namedItem('inputEnabled') as HTMLSelectElement).value;
                const hasSocial = (event.currentTarget.elements.namedItem('inputHasSocial') as HTMLSelectElement).value; */
        // Create the filter object
        const filter: {
            [key: string]: string | undefined;
            name?: string,
            surname?: string,
            nickname?: string,
            email?: string,
            registrationDate?: string,
            /*             group?: string,
                        enabled?: boolean,
                        hasSocial?: boolean, */
        } = {};
        if (name !== '') filter.name = name;
        if (surname !== '') filter.surname = surname;
        if (nickname !== '') filter.nickname = nickname;
        if (email !== '') filter.email = email;
        if (registrationDate != '') filter.registrationDate = new Date(registrationDate).toISOString();
        /*         if (group !== 'Choose...') filter.group = group;
        
        
                if (enabled !== 'Choose...') filter.enabled = enabled === 'Yes' ? true : false;
                if (hasSocial !== 'Choose...') filter.hasSocial = hasSocial === 'Yes' ? true : false; */
        // Filter users based on the filter object
        const filteredUsers = (usersList ?? []).filter((user: UserProfile) => {
            for (const key in filter) {
                if (key === 'registrationDate') {
                    // return true if date is after registrationDate. set timezones correctly.
                    if (new Date(user.userProfileDetail[key]).getTime() < new Date((filter[key] as string) ?? "").getTime()) return false;
                } else {
                    //if not contained in the string, return false
                    if (!(user.userProfileDetail[key] ?? "").toLowerCase().includes((filter[key] as string).toLowerCase())) return false;
                }
            }
            return true;
        });
        // Update the users list in the store
        setUserListToShow(filteredUsers);
    }
    const displayCompanyName = (companyId: string) => {
        const company = useCompanyManagerStore.getState().companyList?.find((company) => company.companyId === companyId);
        return company?.companyDetail.company ?? "-";
    }
    return (
        <section className='UsersPage d-flex flex-column'>
            <HeaderWidgetPortal>
                <>
                    {(useUserStore.getState().user?.userProfileDetail.companyId && <Button variant="outline-primary" className='ms-auto me-2' onClick={() => {
                        setShowManageUsers(true);
                    }}>
                        <span className="d-none d-md-inline">{t('company:importUsers')}</span>
                        <i className="fas fa-user-plus ms-md-2"></i>
                    </Button>) || <div className='ms-auto me-2'></div>}

                    <CSVLink data={(userListToShow ?? []).map((user: UserProfile) => {
                        const { userProfileDetail, ...userWithoutProfileDetail } = user;
                        return { ...userWithoutProfileDetail, ...userProfileDetail };
                    }

                    )} headers={headersCSV} filename={'users.csv'} className='btn btn-outline-primary '>
                        <span className="d-none d-md-inline">Download CSV</span>
                        <i className="fas fa-download ms-md-2"></i>
                    </CSVLink>
                </>
            </HeaderWidgetPortal>
            {/* Search form */}
            <div className="card mb-3">
                <div className="card-body">
                    <form className="row g-3" onSubmit={searchUsers}>

                        <div className="col-md-6">
                            <label htmlFor="inputName" className="form-label">{t('forms:name')}</label>
                            <input type="text" className="form-control" id="inputName" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputSurname" className="form-label">{t('forms:surname')}</label>
                            <input type="text" className="form-control" id="inputSurname" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputNickname" className="form-label">{t('forms:nickname')}</label>
                            <input type="text" className="form-control" id="inputNickname" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputEmail" className="form-label">{t('forms:email')}</label>
                            <input type="text" className="form-control" id="inputEmail" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputRegistrationDate" className="form-label">{t('forms:registrationDate')}</label>
                            <input type="date" className="form-control" id="inputRegistrationDate" />
                        </div>
                        {/*                         <div className="col-md-6">
                            <label htmlFor="inputGroup" className="form-label">Group</label>
                            <select id="inputGroup" className="form-select">
                                <option>Choose...</option>
                                <option>Admin</option>
                                <option>User</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputEnabled" className="form-label">Enabled</label>
                            <select id="inputEnabled" className="form-select">
                                <option >Choose...</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputHasSocial" className="form-label">Has Social</label>
                            <select id="inputHasSocial" className="form-select">
                                <option >Choose...</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div> */}
                        <div className="col-md-6">

                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">{t('common:search')}</button>
                        </div>
                    </form>
                </div>
            </div>
            {/* Users list */}

            <div className="card mb-3">
                <div className="card-body">
                    {
                        <table className="table table-hover table-responsive table-fixed">
                            <thead>
                                <tr>
                                    <th scope="col">{t('users:name')}</th>
                                    <th scope="col" className='col-1 text-center'>{t('users:registration_date')}</th>
                                    <th scope="col" className='col-1 text-center'>{t('users:verified')}</th>
                                    <th scope="col" className='col-1 text-center'>{t('users:enabled')}</th>
                                    <th scope="col" className='col-1 text-center'>{t('users:group')}</th>
                                    <th scope="col" className='col-1 text-center'>{t('users:language')}</th>
                                    {
                                        isSystem && <>
                                            <th scope="col" className='col-1 text-center'>{t('common:company')}</th>
                                        </>
                                    }
                                    <th scope="col" className='col-1 text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(userListToShow ?? [])?.map((user: UserProfile, index: number) => {
                                    return (
                                        <tr key={index} className="">
                                            <td>
                                                <div className="d-flex flex-column">
                                                    <h6 className='mb-0'>
                                                        {user.userProfileDetail.name ?? user.userProfileDetail.nickname}
                                                        &nbsp;
                                                        {user.userProfileDetail.surname}
                                                    </h6>
                                                    <small className='text-muted'>
                                                        {user.userProfileDetail.email}
                                                    </small>
                                                </div>
                                            </td>

                                            <td className='text-center align-middle'>
                                                {new Date(user.userProfileDetail.registrationDate).toLocaleDateString()}
                                            </td>

                                            <td className='text-center align-middle'>
                                                <span className={"fas fa-fw fa-" + (user.userProfileDetail.emailVerified ? 'shield-check text-success' : 'shield-xmark text-secondary')}></span>
                                            </td>

                                            <td className='text-center align-middle'>
                                                <span className={"fas fa-fw fa-" + (user.userProfileDetail.enabled ? 'check-circle text-success' : 'times-circle text-secondary')}></span>
                                            </td>


                                            <td className='align-middle'>

                                                {user.userProfileDetail.group === "Admin" && <span className=
                                                    {"badge rounded-pill ms-auto my-auto d-block bg-warning"}>
                                                    ADMIN
                                                </span>}
                                                {user.userProfileDetail.group === "Users" && <span className=
                                                    {"badge rounded-pill ms-auto my-auto d-block bg-primary"}>
                                                    USER
                                                </span>}
                                                {
                                                    user.userProfileDetail.group === "System" && <span className=
                                                        {"badge rounded-pill ms-auto my-auto d-block bg-danger"}>
                                                        SYSTEM
                                                    </span>}
                                                {
                                                    user.userProfileDetail.group === "HR" && <span className=
                                                        {"badge rounded-pill ms-auto my-auto d-block bg-success"}>
                                                        HR  </span>}
                                                {
                                                    !user.userProfileDetail.group && <span className=
                                                        {"badge rounded-pill ms-auto my-auto d-block bg-primary"}>
                                                        USER
                                                    </span>}
                                            </td>
                                            <td className='text-center align-middle'>
                                                {t('languages:' + (user.userProfileDetail.locale ?? "-"))}
                                            </td>
                                            {
                                                isSystem && <>
                                                    <td className='text-center align-middle'>
                                                        {displayCompanyName(user.userProfileDetail.companyId)}
                                                    </td>
                                                </>
                                            }
                                            {/* actions dropdown */}
                                            <td className='text-center align-middle'>
                                                <div className="dropdown">
                                                    <button className="btn btn-link text-dark" type="button" id={"dropdownMenuButton" + index} data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fa-ellipsis-v"></i>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby={"dropdownMenuButton" + index}>
                                                        {/* details */}
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                //TODO
                                                                useSystemModalStore.getState().showInfoModal(
                                                                    'common:details',
                                                                    <div className='d-flex flex-column'>
                                                                        <div className='d-flex flex-column'>
                                                                            <span>ID: {user.userProfileId}</span>
                                                                            <span>{t('users:name')}: {user.userProfileDetail.name} {user.userProfileDetail.surname}</span>
                                                                            <span>{t('users:email')}: {user.userProfileDetail.email}</span>
                                                                            <span>{t('users:registration_date')}: {new Date(user.userProfileDetail.registrationDate).toLocaleDateString()}</span>
                                                                            <span>{t('users:enabled')}: {user.userProfileDetail.enabled ? t('common:yes') : t('common:no')}</span>
                                                                            <span>{t('users:verified')}: {user.userProfileDetail.emailVerified ? t('common:yes') : t('common:no')}</span>
                                                                            <span>{t('users:language')}: {t('languages:' + user.userProfileDetail.locale)}</span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}>
                                                                {t('common:details')}
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                //TODO
                                                                useSystemModalStore.getState().showComingSoonModal();
                                                            }}>
                                                                {t('common:edit')}
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                showModal(
                                                                    t('users:reset_password'),
                                                                    t('users:reset_password_confirm'),
                                                                    'fa-repeat').then((result) => {
                                                                        if (result) {
                                                                            resetUserPassword(user.userProfileId);
                                                                            toast.promise(resetUserPassword(user.userProfileId), {
                                                                                loading: t('common:please_wait'),
                                                                                success: t('common:done'),
                                                                                error: (e) => t('errors:' + e.message ?? 'error')
                                                                            });
                                                                        }
                                                                    });
                                                            }}>
                                                                {t('users:reset_password')}
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                useSystemModalStore.getState().showConfirmModal(
                                                                    t('users:delete_user'),
                                                                    t('users:delete_user_confirm'),
                                                                    'fa-trash text-danger').then((result) => {
                                                                        if (result) {
                                                                            toast.promise(deleteUser(user.userProfileId), {
                                                                                loading: t('common:deleting'),
                                                                                success: t('common:deleted'),
                                                                                error: (e) => t('errors:' + e.message ?? 'error')
                                                                            }).catch((e) => {
                                                                                console.log(e);
                                                                                if (e.message === 'user_not_found_on_provider') {
                                                                                    useSystemModalStore.getState().showConfirmModal(
                                                                                        t('users:delete_user_data'),
                                                                                        t('users:delete_user_data_confirm'),
                                                                                        'fa-question-circle text-danger').then((result) => {
                                                                                            if (result) {
                                                                                                toast.promise(
                                                                                                    useUsersStore.getState().deleteUserData(user.userProfileId), {
                                                                                                    loading: t('common:deleting'),
                                                                                                    success: t('common:deleted'),
                                                                                                    error: (e) => t('errors:' + e.message ?? 'error')
                                                                                                });
                                                                                            }
                                                                                        });
                                                                                }
                                                                            });
                                                                        }
                                                                    });
                                                            }}>
                                                                {t('common:delete')}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                {/* if empty */}
                                {(!userListToShow || userListToShow.length === 0) &&
                                    <tr>
                                        <td colSpan={3} className="text-center">
                                            {t('users:no_users_found')}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>}
                </div>
            </div>
            <Modal show={showManageUsers} onHide={() => setShowManageUsers(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {t('company:importUsers')}</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <CompanyManageUsers onNewUser={(newUsers) => setUsersToImport(newUsers)} />
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={() => setShowManageUsers(false)}>
                        {t('common:cancel')}
                    </Button>
                    <Button variant="primary" disabled={usersToImport.length === 0} onClick={() => {
                        toast.promise(createNewUsers(usersToImport.map(
                            (user) => {
                                return {
                                    ...user,
                                    companyId: useUserStore.getState().user?.userProfileDetail.companyId ?? ""
                                }
                            }
                        )), {
                            loading: t('common:creating'),
                            success: t('common:user') + " " + t('common:created'),
                            error: (e) => t('errors:' + e.message ?? 'error')
                        });
                        setShowManageUsers(false);
                    }}>
                        {t('common:import')}
                    </Button>
                </ModalFooter>
            </Modal>
        </section>
    )
}

export default UsersPage