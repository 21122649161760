import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'stores/authStore';
import Config from 'utils/config';
import FunnifinLogo from './FunnifinLogo';
import LanguageSelector from './LanguageSelector';

/// Displays the main menu
/// Used in: src\components\MainLayout.js
/// Use a MainMenuHeading component to display a heading in the menu
export default function MainMenu() {
    const logout = useAuthStore((state) => state.logout);
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <ul className="MainMenu nav flex-column">
            {/* HEADER */}
            <div className="flex-row d-flex align-items-center mb-3">
                {/*                 <img alt="avatar" className="col-4 img-fluid rounded-circle aspect-ratio-1 cursor-pointer" style={{ height: "3rem", width: "3rem" }} src="https://picsum.photos/100/100" onClick={() => {
                    if (window.confirm("Are you sure you want to log out?")) logout();
                }} /> */}
                <div className="col-4 img-fluid rounded-circle aspect-ratio-1 cursor-pointer bg-white text-dark align-items-center d-flex justify-content-center" style={{ height: "3rem", width: "3rem" }} onClick={() => {
                    if (window.confirm("Are you sure you want to log out?")) logout();
                }}>
                    <i className={`align-items-center d-flex fs-5 justify-content-center fas fa-power-off fa-2x text-dark`} aria-hidden="true" style={{ width: '3.5rem', height: '3.5rem' }} />
                </div>
                {/* LOGO */}
                <div className='rounded ms-3' style={{ height: "min-content" }} >
                    <div >
                        <FunnifinLogo onClick={() => alert('/admin/dashboard')}/> </div>
                </div>
            </div>
            {/* LINKS */}
            <MainMenuHeading>{t('main_menu:marketing')}</MainMenuHeading>
            <MainMenuNavLink icon="home" to='dashboard'>{t('main_menu:dashboard')}</MainMenuNavLink>
            {Config.enabledFeatures.userManagement && <MainMenuNavLink icon="user" to='users'>{t('main_menu:users')}</MainMenuNavLink>}
            {Config.enabledFeatures.quizManagement && <>
                <MainMenuHeading>{t('main_menu:education')}</MainMenuHeading>
                <MainMenuNavLink icon="university" to='eduquizManager'>{t('main_menu:eduquiz_manager')}</MainMenuNavLink>
                <MainMenuNavLink icon="robot" to='eduquiz'>{t('main_menu:eduquiz_simulator')}</MainMenuNavLink>
            </>}
            <MainMenuHeading>{t('main_menu:engagement')}</MainMenuHeading>
            {Config.enabledFeatures.shopManagement && <MainMenuNavLink icon="shop" to='shopmanager'>{t('main_menu:shop_manager')}</MainMenuNavLink>}
            {Config.enabledFeatures.partnerManagement && <MainMenuNavLink icon="ad" to='partnerManager'>{t('main_menu:partner_manager')}</MainMenuNavLink>}
            {Config.enabledFeatures.languageManagement && <MainMenuNavLink icon="flag" to='languageManager'>{t('main_menu:language_manager')}</MainMenuNavLink>}
            {Config.enabledFeatures.companyManagement && <MainMenuNavLink icon="building" to='company_manager'>{t('main_menu:company_manager')}</MainMenuNavLink>}
            <div className="mt-3">
                <LanguageSelector />
            </div>
        </ul >
    )
}
function MainMenuHeading(props) {
    return (
        <span className='text-primary fw-bold'>{props.children}</span>
    )
}

function MainMenuNavLink(props) {
    return (
        <li className={"nav-item MainMenuNavLink"}>
            <NavLink to={props.to} className={({ isActive, isPending }) =>
                isPending ? "nav-link" : isActive ? "active fw-bold MainMenuLink nav-link" : "nav-link"
            }>
                <i className={'fa-duotone me-2 mb-1 text-primary fa-' + props.icon + " "} />
                <span>
                    {props.children}
                </span>
            </NavLink>
        </li>

    )
}