import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function EduQuizGameOverModal() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className='position-fixed w-100 h-100 d-flex align-items-end align-items-md-end align-items-lg-center' style={{
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 9999
        }}>
            <div className='container px-0'>
                <div className='row'>
                    <div className='col-12 col-md-6 mx-auto'>
                        <div className='card'>
                            <div className='card-body p-4'>
                                <div className='d-flex align-items-center mb-3'>
                                    <i className='fa-duotone fa-heart-broken text-danger fa-3x me-3' />
                                    <h3 className='text-center mb-0'>
                                        {t('eduquiz:game_over')}
                                    </h3>
                                </div>
                                <p className='mb-3'>
                                    {t('eduquiz:lifepoints_over')}
                                    <br /> <strong>{t('eduquiz:wait_for_next_life')}</strong>
                                </p>
                                <div className='d-flex align-items-center'>
                                    <button className='ms-auto btn btn-danger rounded-pill px-5' onClick={() => navigate('/welfare/eduquiz')}>
                                        {t('common:exit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
