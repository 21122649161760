import React from "react";
import { Form, FormControl, FormGroup, FormLabel, FormSelect } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useI18nStore } from "stores/i18nStore";
import { getFlagEmoji } from "utils/functions";
import { useState, useMemo } from "react";
import { PlaylistDetail, VideoDetail } from "models/videoModels";

function PlaylistEditor(
    { playlist, thumbnail: videoImage, setPlaylistThumbnail, handlePlaylistChange }
        : {
            playlist: PlaylistDetail,
            thumbnail: File | null,
            setPlaylistThumbnail: (file: File) => void,
            handlePlaylistChange: (playlist: PlaylistDetail) => void,
        }
) {
    const { t } = useTranslation();
    const [activeLanguage, setActiveLanguage] = React.useState("it");
    const languages = useI18nStore((state) => state.quizLanguages);
    const getTranslationWithLanguage = useI18nStore((state) => state.getTranslationWithLanguage);
/*     const description = useMemo(() => getTranslationWithLanguage(playlist.description, activeLanguage), [playlist.description, activeLanguage, getTranslationWithLanguage]);
 */    return (
        <div>
            <Form >
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        {(languages ?? []).map((language: string) =>
                            <button key={language} className={"nav-link text-secondary d-flex flex-column align-items-center " + (language === activeLanguage ? "active" : "")}
                                onClick={() => setActiveLanguage(language)} type="button">
                                <div className="text-body-emphasis fw-bold">{language.toUpperCase()}
                                </div>
                                <div>
                                    {getFlagEmoji(language)}
                                </div>
                            </button>
                        )}
                    </div>
                </nav>


                <div className="tab-content p-3 border-bottom border-start border-end mb-3 rounded-bottom" id="nav-tabContent">
                    {/* <div className="tab-pane fade active show" id="nav-argument-icon" role="tabpanel" aria-labelledby="nav-argument-icon-tab">
        <h1>{fontIcon}</h1>
        {!showEmojiPicker && <button className='btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 fw-bold w-100' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <i className='fas fa-plus' /> {t('forms:emoji')}
        </button>}
        {showEmojiPicker && <EmojiPicker autoFocusSearch={true} onEmojiClick={onEmojiClicked} />}
    </div> */}
                    {(languages ?? []).map((language: string) =>
                        <div key={language} className={"tab-pane " + (language === activeLanguage ? "show active" : "fade")} id={"nav-argument-language-tab-" + language} role="tabpanel" aria-labelledby="nav-argument-image-tab">
                            <div className="d-flex flex-column h-100">
                                <FormGroup className="mb-3">
                                    <FormLabel htmlFor="videoTitle">{t('forms:title')}</FormLabel>
                                    <FormControl type="text" id="videoTitle" placeholder={t('forms:title')} name={"title_" + language}
                                        value={getTranslationWithLanguage(playlist.title, language)}
                                        onChange={(e) => handlePlaylistChange({
                                            ...playlist,
                                            title: {
                                                ...playlist.title,
                                                [language]: e.target.value,
                                            },
                                        })} />
                                </FormGroup>
                                {/* Description */}
                                <FormGroup className="mb-3">
                                    <FormLabel htmlFor="videoDescription">{t('forms:description')}</FormLabel>
                                    <ReactQuill id="videoDescription" placeholder={t('forms:description')} value={getTranslationWithLanguage(playlist.description, language)}
                                        onChange={(value) => handlePlaylistChange({
                                            ...playlist,
                                            description: {
                                                ...playlist.description,
                                                [language]: value,
                                            },
                                        })} />
                                </FormGroup>
                                {/*                                 <FormGroup className="mb-3">
                                    <FormLabel htmlFor="videoDescriptionBrief">{t('forms:description_brief')}</FormLabel>
                                    <FormControl as="textarea" id="videoDescriptionBrief" placeholder={t('forms:description')} name={"description_" + language}
                                        value={getTranslationWithLanguage(playlist.description, language)}
                                        onChange={(e) => handlePlaylistChange({
                                            ...playlist,
                                            description: {
                                                ...playlist.description,
                                                [language]: e.target.value,
                                            },
                                        })} />
                                </FormGroup> */}
                            </div>
                        </div>)}
                </div>
                <Form.Group className="mb-3">
                    <Form.Label className="mb-0">{t('video_manager:category')}</Form.Label>
                    <br></br>
                    <small className="mb-2 d-block text-muted">{t('video_manager:category_description')}</small>
                    <div>
                        {[
                            "Busta paga",
                            "Investimento",
                            "TFR",
                            "Detrazioni fiscali",
                            "Dichiarazione dei redditi",
                            "Criptovalute",
                            "Fondo pensione",
                            "Societario",
                            "Genitorialità",
                            "Pianificazione finanziaria"
                        ].map((category) => (
                            <Form.Check
                                key={category}
                                type="checkbox"
                                id={`category-${category}`}
                                label={category}
                                checked={playlist.category.includes(category)}
                                onChange={() => {
                                    const newCategories = playlist.category.includes(category)
                                        ? playlist.category.filter((c) => c !== category)
                                        : [...playlist.category, category];
                                    handlePlaylistChange({
                                        ...playlist,
                                        category: newCategories,
                                    });
                                }}
                            />
                        ))}
                    </div>
                </Form.Group>
                <Form.Group>
                    <label className="form-label">{t('video_manager:video_picture')}</label>
                    <div id="playlist-banner-image" className="d-flex flex-column mb-3">
                        {!(playlist.thumbnail || videoImage) && <i className="mb-2">{t('forms:messages.no_image_selected')}</i>}
                        {((playlist.thumbnail || videoImage)) && <img src={videoImage != null ? URL.createObjectURL(videoImage) : (playlist.thumbnail ?? "")} className="img-fluid object-fit-contain border rounded mb-3" alt="Slide " />}

                        <input onChange={(event) => {
                            const files = event.target.files;
                            if (files != null && files[0].type.startsWith('image/')) {
                                setPlaylistThumbnail(files[0]);
                            } else {
                                // Handle invalid file type
                            }
                        }} className="form-control" type="file" id="formFile" accept="image/*" />

                        {((playlist.thumbnail || videoImage) /* && !deleteBannerImage */) && <button type="button" className="btn btn-outline-primary btn-outline-big btn-block rounded-pill border-3 mt-3 fw-bold w-100" onClick={() => {

                        }} value={playlist.thumbnail ?? ""}>
                            <i className='fas fa-trash' /> {t('common:delete')} Logo
                        </button>}
                    </div>
                    <div id="form-group" className="d-flex flex-column mb-3">
                        <label className="form-label">{t('video_manager:video_picture_alt')}</label>
                        <input type="text" className="form-control" placeholder={t('video_manager:video_picture_alt')}
                            onChange={(e) => handlePlaylistChange({
                                ...playlist,
                                thumbnail: e.target.value,
                            })} />
                    </div>
                </Form.Group>
                <FormGroup className="mb-3">
                    <FormLabel htmlFor="videoTitle">{t('forms:order')}</FormLabel>
                    <FormControl type="number" id="videoOrder" placeholder={t('forms:order')} name="order"
                        value={playlist.order ?? 0}
                        onChange={(e) => handlePlaylistChange({
                            ...playlist,
                            order: parseInt(e.target.value) ?? 0,
                        })} />
                </FormGroup>
                <Form.Group className="mb-3">
                    <Form.Label>{t('video_manager:author')}</Form.Label>
                    <Form.Control type="text" placeholder={t('video_manager:author')} name="author"
                        value={playlist.metadata.it}
                        onChange={(e) => {
                            handlePlaylistChange({
                                ...playlist,
                                metadata: {
                                    ...playlist.metadata,
                                    'it': e.target.value,
                                },
                            })
                        }} />
                </Form.Group>
            </Form>
        </div>
    )
}
export default PlaylistEditor