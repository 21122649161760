import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect } from 'react'
import sunmo from 'assets/lotti/sunmo.json'
import { AnimationItem } from 'lottie-web';
import { use } from 'i18next';
import { useThemeStore } from 'stores/themeStore';

function DarkModeSelector() {
    const darkMode = useThemeStore(state => state.darkMode);
    const [lottieInstance, setLottieInstance] = React.useState<AnimationItem | null>(null);
    const [initialized, setInitialized] = React.useState(false);
    useEffect(() => {
        if (darkMode) {
            if (!initialized) {
                lottieInstance?.goToAndStop(265, true);
            } else {
                lottieInstance?.playSegments([20, 265
                ], true);
            }
        } else {
            if (!initialized) {
                lottieInstance?.goToAndStop(480, true);
            } else {
                lottieInstance?.playSegments([265, 480
                ], true);
            }
        }
    }, [darkMode, lottieInstance, initialized]);
    return (
        <div
            className='DarkModeSelector'
            onClick={() => {
                setInitialized(true);
                useThemeStore.getState().setDarkMode(!darkMode)}}
            style={{
                width: '66px',
                cursor: 'pointer',
            }}
        >
            <Player
                lottieRef={(instance) => {
                    setLottieInstance(instance);
                }}
                src={sunmo}
                style={{ width: '100%', height: '100%' }}
                loop={false}
                speed={2}
                direction={-1}
                autoplay={false}
                keepLastFrame
            />
        </div>
    )
}

export default DarkModeSelector