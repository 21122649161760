import React, { useEffect } from 'react'
import CircleButton from "components/CircleButton";
import { set } from "immer/dist/internal";
import Lottie from "lottie-react";
import * as ReactDOM from 'react-dom/client';

import { useTranslation } from "react-i18next";
import YouTube, { YouTubeProps } from 'react-youtube';
import coinAnimation from "assets/lotti/coin.json";
import { AmplitudeService } from "services/amplitudeService";
import { Video } from "models/videoModels";
import { useI18nStore } from "stores/i18nStore";
import { Modal } from "react-bootstrap";
import Vimeo from '@u-wave/react-vimeo'; // eslint-disable-line import/no-unresolved
import { useNavigate } from 'react-router-dom';
import { useFeedbackStore } from 'stores/feedbackStore';
import { useIsMobile } from 'stores/appStore';
import Skeleton from 'react-loading-skeleton';
import { use } from 'i18next';

function VideoPlayer({
    video,
    children,
    onVideoEnd,
    showPlayer,
    closePlayer,
    onNextVideoRequested,
    author
}: {
    video: Video, children: React.ReactNode,
    showPlayer: boolean,
    author?: string,
    closePlayer: () => void,
    onNextVideoRequested?: () => void,
    onVideoEnd: (videoId: string) => void
}) {
    const navigate = useNavigate();
    const getTranslation = useI18nStore(state => state.getTranslation);
    const onPlayerReady: YouTubeProps['onReady'] = (event: any) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const isMobile = useIsMobile();
    const [videoReady, setVideoReady] = React.useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        setVideoReady(false);
    }, [video.videoDetail.uri]);

    // Calcalulate the height of the video basded on the window size for desktop and mobile
    const height = !isMobile ? (window.innerHeight - 72 - 192.8 - 40 - 60) : 200;
    const opts: YouTubeProps['opts'] = {
        height: height,
        width: "100%",


        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    const [showPrizeModal, setShowPrizeModal] = React.useState(false);
    const handleVideoEnd = (id: string) => {
        useFeedbackStore.getState().checkVideoFeedback(video.videoDetail.playlist, video.videoId, video.videoDetail.title.it);
        onVideoEnd(id);
    }

    if (showPrizeModal) {
        /*         setTimeout(() => {
                    const gemref = document.getElementById("gemcounter");
                    gemref!.style.background = "red"
                    const gemCounterX = gemref!.getBoundingClientRect().x;
                    const gemCounterY = gemref!.getBoundingClientRect().y;
                    console.log(gemCounterX, gemCounterY);
        
                    const start = document.getElementById("prizicon");
                    console.log(start);
                    const x = start?.getBoundingClientRect().x || 0;
                    const y = start?.getBoundingClientRect().y || 0;
                    console.log(x, y);
                    for (let i = 0; i < 20; i++) {
                        // animate coin
                        const coin = document.createElement("div");
                        coin.className = "coin coingrow";
                        coin.id = "coin" + i;
                        // place coin in a random position, positive or negative, around the clikc event
                        let pos = randomPointNearRect(x - 50, y - 50, 50, 50, 60, 100);
                        coin.style.left = pos[0] + "px";
                        coin.style.top = pos[1] + "px";
                        setTimeout(() => {
                            document.body.appendChild(coin);
                            AmplitudeService.logEvent("Video Prize award", { prize: 200, videoId: video.videoId, videoTitle: video.videoDetail.title });
                            let rootCoin = ReactDOM.createRoot(document.getElementById("coin" + i)!);
                            rootCoin.render(<Lottie animationData={coinAnimation} loop={0} />);
                            setTimeout(() => {
                                coin.classList.add("coinshrink");
                                coin.style.left = (gemCounterX) + "px";
                                coin.style.top = (gemCounterY - 20) + "px";
                            }, 600);
                            setTimeout(() => {
                                coin.remove();
                            }, 2200);
                        }, 60 * i);
                    }
                }, 100); */
    }

    return (
        <>
            {showPlayer && (
                <Modal show={showPlayer} onHide={closePlayer}
                    scrollable
                    fullscreen centered
                >
                    <Modal.Header className='p-4 pb-0 border-0' closeButton>
                    </Modal.Header>
                    <Modal.Body className='p-4' style={{
                    }}>
                        {/*                         {
                            video.videoDetail.type === "youtube" && <YouTube videoId={video.videoDetail.uri} opts={opts} onReady={onPlayerReady} onEnd={() => handleVideoEnd(video.videoId)} />
                        } */}
                        {
                            /* video.videoDetail.type === "vimeo" && */isMobile && <Vimeo
                                video={video.videoDetail.uri}
                                autoplay={true}
                                onEnd={() => handleVideoEnd(video.videoId)}
                                /*  width="100%"
                                 height="100%" */

                                height={height}
                                onReady={() => { setVideoReady(true) }}
                                className={'rounded-3 mx-auto ' + (videoReady ? '' : ' d-none')}
                                onPlay={() => {
                                    AmplitudeService.logEvent("Video Play", { videoId: video.videoId, videoTitle: video.videoDetail.title });
                                }}
                            />
                        }
                        <div className="d-flex">
                            {
                                !isMobile && <Vimeo
                                    video={video.videoDetail.uri}
                                    autoplay={true}
                                    onEnd={() => handleVideoEnd(video.videoId)}
                                    /*  width="100%"
                                     height="100%" */

                                    height={height}
                                    onReady={() => { setVideoReady(true) }}
                                    className={'rounded-3 mx-auto ' + (videoReady ? '' : ' d-none')}
                                    onPlay={() => {
                                        AmplitudeService.logEvent("Video Play", { videoId: video.videoId, videoTitle: video.videoDetail.title });
                                    }}
                                />
                            }
                        </div>
                        {!videoReady &&
                            <Skeleton
                                height={height}
                                width="100%"
                                className="rounded-3 d-block"
                            />
                        }

                        <div>

                            <Modal.Title className='pt-4'>{getTranslation(video.videoDetail.title)}</Modal.Title>
                            <h4 className="font-fancy ">
                                by {author}
                            </h4>
                            <p className='py-4'>
                                {getTranslation(video.videoDetail.description)}
                            </p>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className='border-0'>
                        {/* buttons */}
                        <div className="d-flex justify-content-between align-items-center w-100">
                            {!window.location.pathname.includes('/welfare/playlist') && (
                                <button className="btn btn-secondary rounded-pill" onClick={() => {
                                    closePlayer();
                                    navigate(`/welfare/playlist/${video.videoDetail.playlist}`)
                                }}>
                                    {t("videos:view_all")}
                                </button>
                            )}
                            {onNextVideoRequested && <button className="btn btn-primary rounded-pill ms-auto" onClick={onNextVideoRequested}>{t("videos:next_video")}</button>
                            }                        </div>
                    </Modal.Footer>
                </Modal>
            )
            }
            {children}
        </>
    )
}

export default VideoPlayer