import { ProgressionCard } from 'pages/progression/ProgressionPage';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import pulcino from 'assets/images/pulcino.png';
import Mark from 'components/Mark';
import { useProgressionStore } from 'stores/progressionStore';
import { use } from 'i18next';
import { generateImageUrl } from 'utils/functions';
import { Goal } from 'models/progressionModels';
import { useI18nStore } from 'stores/i18nStore';
import { useAuthStore } from 'stores/authStore';

export default function UserDashboardMainHeader({ user, eduquizStatistics }: { user: any, eduquizStatistics: any }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const goalList = useProgressionStore(state => state.goalList);
    const getTranslation = useI18nStore(state => state.getTranslation);
    const currentExperiencePointData = useProgressionStore(state => state.currentExperiencePointData);
    useEffect(() => {
        if (!goalList) {
            useProgressionStore.getState().getGoalList();
        }
    }, [goalList]);
    useEffect(() => {
        if (!currentExperiencePointData) {
            useProgressionStore.getState().getUserExperiencePoints();
        }
    }, [currentExperiencePointData]);
    let reachedGoals = goalList?.filter((goal: any) => goal.goalDetail.totalXp <= (currentExperiencePointData?.experiencePointDetail?.currentExperiencePoints ?? 0));

    const currentGoal = (reachedGoals ?? [])[(reachedGoals?.length ?? 0) - 1];
    const currentAvatar = generateImageUrl(currentGoal?.goalDetail?.icon ?? '');
    const currentGoalIndex = goalList?.findIndex((goal: Goal) => goal.goalId === currentGoal?.goalId) ?? 0;
    const userName = useAuthStore.getState().isUser ? user?.userProfileDetail.name : 
    "Admin";
    return <>{/* Welcome User! */}
        <div className='d-flex flex-column-reverse mb-3 position-relative' style={{
            /* maxWidth: "3rem" */
            height: "160px",
        }}>
            <div className='d-flex rounded-3 bg-primary position-relative' style={{
                /* maxWidth: "3rem" */
                height: "150px",
            }}>
                {/*Testo benvenuto */}
                <div className='position-absolute'
                    style={{
                        top: "0.4rem",
                        left: "4.1rem",
                        transform: "rotate(-5deg)",
                        paintOrder: "stroke",
                    }}
                >
                    <svg className='w-100'>
                        <text x="0" y="11vw" fill="black" fontFamily='Caveat' stroke="white" strokeWidth="2vw" fontSize="15vw" transform="rotate(-5)" className='d-md-none'>
                            {t('user_dashboard:hello')}
                        </text>
                        <text x="0" y="42" fill="black" fontFamily='Caveat' stroke="white" strokeWidth="0.5rem" fontSize="3.5rem" transform="rotate(-5)" className='d-none d-md-block'>
                            {t('user_dashboard:hello')}
                        </text>
                    </svg>
                </div>
                <div className='position-absolute text-outline-primary'
                    style={{
                        top: "3rem",
                        left: "1.8rem",
                        transform: "rotate(-5deg)",
                        paintOrder: "stroke",
                        zIndex: 1000,
                    }}
                >
                    <svg className='w-100'>
                        <text x="4" y="14vw" fontFamily='Caveat' stroke="white" strokeWidth="2vw" fontSize="15vw" transform="rotate(-5)" className='d-md-none'>
                            {userName}!
                        </text>
                        <text x="8" y="62" fontFamily='Caveat' stroke="white" strokeWidth="0.5rem" fontSize="4rem" transform="rotate(-5)" className='d-none d-md-block'>
                            {userName}!
                        </text>
                    </svg>
                </div>
                {/* GIGAVATAR */}
                {/*             <i className="fa-stack fa-3x ms-auto cursor-pointer hoverable" onClick={() => {
                navigate('../profile');
            }}>
                <i className="fas fa-circle fa-stack-2x text-primary-subtle"></i>
                <i className="fa-duotone fa-user fa-stack-1x text-primary"></i>
            </i> */}
                <Mark background='bg-warning border-warning-subtle' style={{
                    position: "absolute",
                    bottom: "1rem",
                    right: "1rem",
                    zIndex: 1002,
                }}>
                    LIVELLO {currentGoalIndex + 1}
                </Mark>
            </div>
            {currentAvatar && <img src={currentAvatar} className="img-fluid" alt="pulcino" style={{
                position: "absolute",
                bottom: "0",
                right: "-8px",
                height: "180px",
            }} />}
        </div>
        {/* COMPLETED STUFF */}
        <div className='ProgItemsModules mb-3 row gx-2'>
            <ProgressionCard title={t('common:arguments')} value={eduquizStatistics?.argumentsCompleted ?? '0'} />
            <ProgressionCard title={t('common:modules')} value={eduquizStatistics?.modulesCompleted ?? '0'} />
            <ProgressionCard title={t('common:units')} value={eduquizStatistics?.unitsCompleted ?? '0'} />
        </div></>;
}