import React, { useEffect } from 'react'
import { useLifepointsStore } from 'stores/lifepointsStore';
import life from 'assets/images/life.svg';
import { useSystemModalStore } from 'stores/systemModalStore';
import { useTranslation } from 'react-i18next';
function LifepointCounter() {
  const currentLifepointData = useLifepointsStore(state => state.currentLifepointData);
  // init lifepoints if currentLifepointData is null
  useEffect(() => {
    if (currentLifepointData === null) {
      useLifepointsStore.getState().getUserLifepoints();
    }
  }, [currentLifepointData]);
  const t = useTranslation().t;
  return (
    <div className='LifepointCounter mx-2 d-flex align-items-center cursor-pointer' onClick={() => {
      useSystemModalStore.getState().showCustomModal(t('common:lifepoints'), <>
        {t('tutorial:SeeLives')}
        <br />
        {t('tutorial:NewLives')}

      </>, 'fa-heart text-danger');                                
    }}>
      {/*       <i className="fas fa-heart text-danger fa-border ms-auto me-1" style={{ fontSize: "1.5rem" }} /> */}
      <img src={life} alt="lifepoints" className='me-1' style={{
        height: "1.5rem",
      }}></img>
      <h5 className="mb-0">{currentLifepointData?.lifePointDetail?.currentLifePoints ?? "  "}</h5>
    </div>
  )
}

export default LifepointCounter