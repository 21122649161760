import CircleButton from 'components/CircleButton';
import Lottie from 'lottie-react';
import React from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'; import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { AuthStates, useAuthStore } from 'stores/authStore';
import Config from 'utils/config';
import checkAnimation from 'assets/lotti/check.json';
import { Modal } from 'react-bootstrap';
import FunnifinLogo from 'components/FunnifinLogo';

function ResetPasswordDialog() {
    const { t } = useTranslation();

    const newPassword = useAuthStore(state => state.newPassword);
    const authState = useAuthStore(state => state.authenthicationState);
    const currentAuthUser = useAuthStore((state) => state.currentAuthUser);
    const [showPassword, setShowPassword] = React.useState(false);

    const [usedEmail, setUsedEmail] = React.useState('');
    const [code, setCode] = React.useState('');
    const [formData, setFormData] = React.useState({ password: '', confirmPassword: '' });
    const onConfirmationSubmit = (e) => {
        e.preventDefault();
        // get data from form
        const password = e.target.password.value;
        toast.promise(newPassword(currentAuthUser, password), {
            loading: t('common:saving'),
            success: t('forgot:password_reset_success'),
            error: t('errors:login_failed'),
        }).then(() => {
            setFormData({ password: '', confirmPassword: '' });
            toast(t('home:login_to_continue'), { icon: '🔑' });
        });
    }
    return (
        <>
            <Modal show={authState === AuthStates.ResetPasswordRequired} onHide={() => { }}>
                <Modal.Body className='p-5 d-flex flex-column'>
                    {Config.alternateLoginImage != null ? <img src={Config.alternateLoginImage} alt="" className="img-fluid ms-lg-auto d-block w-50 mx-auto mb-3" />
                        : <FunnifinLogo className="img-fluid mx-auto w-50 mb-3" alt="" />}
                    <form className='d-flex flex-column' onSubmit={onConfirmationSubmit} key={'resetPassword'}>
                        <h1 className='mb-4 text-center'>{t('forgot:reset_password')}</h1>
                        <h5 className='mb-4'>{t('forgot:reset_password_description')}</h5>
                        <small className='mb-3'>
                            <span>{t('password:must')} </span><br />
                            <div className="d-flex align-items-center">
                                <span>
                                    {t('password:uppercase')}</span>
                                {((RegExp(/.*[A-Z].*/).test(formData['password']) && formData['password']) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                    <i className="fas fa-times-circle text-danger" />
                                </div>}
                            </div>
                            <div className="d-flex align-items-center">
                                <span>
                                    {t('password:lowercase')}</span>
                                {((RegExp(/.*[a-z].*/).test(formData['password']) && formData['password']) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                    <i className="fas fa-times-circle text-danger" />
                                </div>}
                            </div>
                            <div className="d-flex align-items-center">

                                <span>
                                    {t('password:number')}</span>
                                {((RegExp(/.*\d.*/).test(formData['password']) && formData['password']) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                    <i className="fas fa-times-circle text-danger" />
                                </div>}
                            </div>
                            <div className="d-flex align-items-center">

                                <span>
                                    {t('password:special')}</span>
                                {((RegExp(/(?=.*[\W_])/).test(formData['password']) && formData['password']) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                    <i className="fas fa-times-circle text-danger" />
                                </div>}
                            </div>
                            <div className="d-flex align-items-center">
                                <span>
                                    {t('password:min_length', {
                                        min: 8
                                    })}</span>
                                {((formData['password'] && formData['password'].length >= 8) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                    <i className="fas fa-times-circle text-danger" />
                                </div>}
                            </div>
                            <div className="d-flex align-items-center">

                                <span>{t('password:match')}</span>
                                {((formData['password'] === formData['confirmPassword'] && formData['password'] && formData['confirmPassword']) && <Lottie className="passwordCheckIcon" animationData={checkAnimation} loop={0} />) || <div style={{ height: "24px", marginLeft: "0.5rem", fontSize: "18px" }}>
                                    <i className="fas fa-times-circle text-danger" />
                                </div>}
                            </div>
                        </small>
                        {/* PASSWORD */}
                        <div className="mb-4 form-floating" >
                            <input placeholder="Password" value={formData['password'] ?? ""} onChange={(event) => setFormData({ ...formData, password: event.target.value })}
                                name="password" type={showPassword ? "text" : "password"} className="form-control" id="password" required autoComplete='off' />
                            <div className="input-group-append position-absolute end-0 top-0 pt-2 me-2">
                                <button className="btn p-2 border-0 bg-transparent" type="button" onClick={() => setShowPassword(!showPassword)}>
                                    <i className={`fas fa-fw fa-eye${showPassword ? "-slash" : ""}`}></i>
                                </button>
                            </div>
                            <label htmlFor="password" className="form-label">Password</label>
                        </div>
                        {/* CONFIRM PASSWORD */}
                        <div className="mb-4 form-floating" >
                            <input placeholder="Confirm password" value={formData['confirmPassword'] ?? ""}
                                onChange={(event) => setFormData({ ...formData, confirmPassword: event.target.value })} name="confirmPassword"
                                type={showPassword ? "text" : "password"} className="form-control" id="confirmPassword" /* value={formData['confirmPassword'] ?? ""} required onChange={(event) => setFormData({ ...formData, confirmPassword: event.target.value })} */ />
                            <div className="input-group-append position-absolute end-0 top-0 pt-2 me-2">
                                <button className="btn p-2 border-0 bg-transparent" type="button" onClick={() => setShowPassword(!showPassword)}>
                                    <i className={`fas fa-fw fa-eye${showPassword ? "-slash" : ""}`}></i>
                                </button>
                            </div>
                            <label htmlFor="confirmPassword" className="form-label">{t('common:confirm')}</label>
                        </div>
                        {/* SUBMIT */}
                        <button type="submit" className=" btn btn-primary btn-block mb-4  rounded-pill" data-bs-dismiss="modal" disabled={formData['password'] !== formData['confirmPassword'] || !Config.passwordRegex.test(formData['password'])}>
                            {t('common:confirm')}
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ResetPasswordDialog