import CircleButton from "components/CircleButton";
import CreateCompanyWizard from "components/createCompanyWizard/CreateCompanyWizard";
import HeaderAdmin from "components/HeaderAdmin"
import Loader from "components/Loader";
import { Company } from "models/companyModel";
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCompanyManagerStore } from "stores/companyManagerStore"
import logo from "assets/images/LOGO.png";
import { useUsersStore } from "stores/usersStore";
import { UserProfile } from "models/userModels";
import { CountUp } from 'use-count-up';
import toast from "react-hot-toast";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import CompanyManageConfig from "components/createCompanyWizard/CompanyManageConfig";
import { useDocumentStore } from "stores/documentStore";
import CompanyManageFeatures from "components/createCompanyWizard/CompanyManageFeatures";
import { useSystemModalStore } from "stores/systemModalStore";
import { useTitle } from "utils/functions";
import HeaderWidgetPortal from "components/HeaderWidgetPortal";

function CompaniesManagerPage() {
  const initCompanies = useCompanyManagerStore(state => state.init);
  const companyList = useCompanyManagerStore(state => state.companyList);
  const users = useUsersStore(state => state.usersList);
  const initUsers = useUsersStore(state => state.initUsers);
  const { t } = useTranslation();
  useTitle(t('main_menu:company_manager'));
  (companyList ?? [])?.sort(
    // sort by default company first
    (a, b) => {
      if (a.companyDetail.isDefault === b.companyDetail.isDefault) {
        return 0;
      } else if (a.companyDetail.isDefault) {
        return -1;
      } else {
        return 1;
      }
    }
  )
  const [showCreateCompanyWizard, setShowCreateCompanyWizard] = React.useState(false);

  React.useEffect(() => {
    console.log('Check companies');

    if (companyList === null) {
      initCompanies();
    } else {
      console.log('Companies already fetched');
    }
  }, [companyList, initCompanies]);
  React.useEffect(() => {
    if (users === null) {
      initUsers();
    }
  }, [users, initUsers]);
  const getUserCount = (company: Company) => {
    try {
      return (users ?? []).filter((u: UserProfile) => u.userProfileDetail.companyId === company.companyId).length;
    } catch (e) {
      return 0;
    }
  }
  return (
    <section className='EduQuizPage d-flex flex-column'>
      {
        companyList === null ? <Loader /> :
          <div className=' mb-4 card'>
            <HeaderWidgetPortal>
              <CircleButton className="ms-auto" iconClassName={"fas fa-fw fa-plus"} onClick={
                () => {
                  setShowCreateCompanyWizard(!showCreateCompanyWizard);
                }
              } />
            </HeaderWidgetPortal>
            <div className="card-body d-flex flex-column">
              <ul className="list-group list-group-flush">
                {Array.isArray(companyList) && companyList.map((company) => <CompanyListTile key={company.companyId} company={company} users={getUserCount(company)} />)}
              </ul>
            </div>
          </div>
      }

      {/*       <button className="btn btn-primary" onClick={() => {
        toast.promise(ApiService.education.fixEverything(), {
          loading: t('common:loading'),
          success: t('common:success'),
          error: t('common:error')
        });
      }
      }>{t('company:fixEverything')}</button> */}
      <CreateCompanyWizard
        showModal={showCreateCompanyWizard}
        onClose={() => {
          setShowCreateCompanyWizard(false);
        }} />
    </section>
  )
}

/* -------------------------------------------------------------------------- */
/*                            CompanyListTile                                 */
/* -------------------------------------------------------------------------- */

function CompanyListTile(props: { company: Company, users: number }) {
  const company = props.company;
  const users = props.users;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = React.useState(false);
  const deleteCompany = useCompanyManagerStore(state => state.deleteCompany);
  const [partnerLogoImage, setBannerImage] = useState<File | null>(null);
  const editCompany = useCompanyManagerStore(state => state.editCompany);
  const uploadDocument = useDocumentStore(state => state.uploadDocument);
  const [showFeaturesModal, setShowFeaturesModal] = React.useState(false);
  useEffect(() => {
    setCompanyEdit(company.companyDetail);
  }, [company]);
  const handleDelete = (e: any) => {
    e.stopPropagation();
    const confirmMsg = t('company:delete_company_confirm', { company: company.companyDetail.company });
    useSystemModalStore.getState().showConfirmModal('company:delete_company', confirmMsg, 'fa-trash text-danger').then((result) => {
      if (result) {
        toast.promise(deleteCompany(company), {
          loading: t('common:deleting'),
          success: t('common:deleted'),
          error: t('common:error')
        });
      }
    });
  }
  const [companyEdit, setCompanyEdit] = useState(company.companyDetail);
  const handleConfigChange = (e: any) => {
    // get the form element
    const form = e.target.form;
    // get form data
    const formData = new FormData(form);
    // convert form data to object
    let object: any = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    // merge with company object
    setCompanyEdit({
      ...companyEdit,
      ...object
    });
  }
  const handleLanguageChange = (selectedOption: any) => {
    if (selectedOption.map((option: any) => option.value).length === 0) {
      alert(t('company:atLeastOneLanguage'));
      return;
    }
    setCompanyEdit({
      ...companyEdit,
      languages: selectedOption.map((option: any) => option.value)
    });
  };
  const handleUrlChange = (language: string, value: string) => {
    setCompanyEdit({
      ...companyEdit,
      multiLanguageUrl: {
        ...companyEdit.multiLanguageUrl,
        [language]: value
      }
    });
  }
  const handleDashboardPreferencesChange = (items: string[]) => {
    setCompanyEdit({
      ...companyEdit,
      defaultDashboardPreferences: items
    });
  }

  return (
    <>
      <li className="CompanyListTile list-group-item d-flex flex-row align-items-center px-0 jsutify-content-start" >
        {company.companyDetail.isDefault ?
          <img src={logo} alt="logo" className="me-3" style={{ width: "50px" }} />
          : <img src={company.companyDetail.companyLogo} alt="logo" className="me-3" style={{ width: "50px", height: "36px", objectFit: "contain" }} />}
        <h6 className='mb-0 d-flex align-items-center flex-grow-1'>
          {/* {getTranslation(company.companyDetail.title)} */}
          {company.companyDetail.company}
          {company.companyDetail.isDefault && <i className="fa fa-star text-warning ms-2 mb-1 fs-5"></i>}
        </h6>
        <div className='align-items-center text-muted mx-auto d-none d-lg-flex '>
          <div className='d-flex align-items-center' style={{
            width: '100px'
          }}>
            <h6 className='mb-0 me-2 '>
              {t('common:users')}:
            </h6>
            <span className='badge bg-primary me-4 cursor-pointer' onClick={() => navigate(`/system/company_manager/${company.companyId}/manage_users`)} >
              <CountUp isCounting={users != null} end={users} />
            </span>
          </div>
          <div className='d-flex align-items-center'>
            <h6 className='mb-0 me-2 '>
              {/* {t('common:users')} */}Scadenza:
            </h6>
            <span className={`badge ${company.companyDetail.expirationDate && new Date(company.companyDetail.expirationDate) <= new Date() ? 'bg-danger' : 'bg-primary'} me-4`}
              style={{ width: '100px' }}
            >
              {company.companyDetail.expirationDate && new Date(company.companyDetail.expirationDate).toLocaleDateString()}
              {
                !company.companyDetail.expirationDate && "--/--/----"
              }
            </span>
          </div>
          {
            company.companyDetail.isActive ?
              <i className='fas fa-check-circle text-primary'></i>
              :
              <i className='fas fa-times-circle text-danger'></i>
          }
        </div>
        <div className='ms-auto d-flex align-items-center'>
          <div className="dropdown">

            <button className="btn btn-outline border-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fas fa-fw fa-ellipsis-v"></i>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {/* Config company */}
              <li data-bs-toggle="dismiss">
                <button className="dropdown-item d-flex align-items-center rounded rounded-1" onClick={() => {
                  setShowEditModal(true);
                }}>
                  <i className="fas fa-fw fa-cogs me-2"></i>
                  {t('company:configureCompany')}
                </button>
              </li>
              {/* manage users */}
              <li data-bs-toggle="dismiss">
                <button className="dropdown-item d-flex align-items-center rounded rounded-1" onClick={() => {
                  navigate(`/system/company_manager/${company.companyId}/manage_users`);
                }}>
                  <i className="fas fa-fw fa-users me-2"></i>
                  {t('company:manage_users')}
                </button>
              </li>
              {/* MANAGE FEATURES */}
              {!company.companyDetail.isDefault && <li data-bs-toggle="dismiss">
                <button className="dropdown-item d-flex align-items-center rounded rounded-1" onClick={() => {
                  setShowFeaturesModal(true);
                }}>
                  <i className="fas fa-fw fa-sliders me-2"></i>
                  {t('company:manage_features')}
                </button>
              </li>}
              {/* manage quiz */}
              <li data-bs-toggle="dismiss">
                <button className="dropdown-item d-flex align-items-center rounded rounded-1" onClick={() => {
                  navigate(`/system/company_manager/${company.companyId}/manage_quiz`);
                }}>
                  <i className="fas fa-fw fa-question-circle me-2"></i>
                  {t('company:manage_quiz')}
                </button>
              </li>
              {/* Deactivate */}
              {company.companyDetail.isActive && !company.companyDetail.isDefault && <li data-bs-toggle="dismiss">
                <button className="dropdown-item d-flex align-items-center rounded rounded-1" onClick={() => {
                  toast.promise(useCompanyManagerStore.getState().deactivateCompany(company), {
                    loading: t('common:saving'),
                    success: t('common:saved'),
                    error: t('common:error')
                  });
                }}>
                  <i className="fas fa-fw fa-power-off me-2"></i>
                  {t('company:deactivateCompany')}
                </button>
              </li>}
              {/* ACTIVATE */}
              {!company.companyDetail.isActive && <li>
                <button className="dropdown-item d-flex align-items-center rounded rounded-1" onClick={() => {
                  toast.promise(useCompanyManagerStore.getState().activateCompany(company), {
                    loading: t('common:saving'),
                    success: t('common:saved'),
                    error: t('common:error')
                  });
                }
                }>
                  <i className="fas fa-fw fa-power-off me-2"></i>
                  {t('company:activateCompany')}
                </button>
              </li>}
              {/* DELETE */}
              {!company.companyDetail.isDefault && <li data-bs-toggle="dismiss">
                <button className="dropdown-item d-flex align-items-center rounded rounded-1" onClick={handleDelete} data-bs-toggle="dismiss"  >
                  <i className="fas fa-fw fa-trash me-2"></i>
                  {t('common:delete')}
                </button>
              </li>}
            </ul>
          </div>
        </div>
      </li>
      {/* EDIT MODAL */}
      <Modal id="editCompanyModal" onHide={() => setShowEditModal(false)} show={showEditModal} scrollable>
        <ModalHeader closeButton>
          <ModalTitle>{t('company:configureCompany')}<br />
            <small className='text-body-tertiary d-flex fs-6'>
              <span>
                ID:
              </span>&nbsp;<div className='text-body-tertiary' style={{ cursor: 'copy' }}
                onClick={() => {
                  toast(t('common:copied_to_clipboard'), {
                    icon: '📋',
                  });
                  navigator.clipboard.writeText(company?.companyId);
                }}>
                {company?.companyId}
              </div>
            </small>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <CompanyManageConfig
            company={companyEdit}
            handleConfigChange={handleConfigChange}
            handleUrlChange={handleUrlChange}
            setBannerImage={setBannerImage}
            partnerLogoImage={partnerLogoImage}
            handleLanguageChange={handleLanguageChange}
            handleDashboardPreferencesChange={handleDashboardPreferencesChange}
          />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={async () => {
            setShowEditModal(false);
            toast.loading(t('company:companyEditProgress'));
            try {
              if (partnerLogoImage != null) {
                const logoDocument = await uploadDocument({
                  format: "image",
                  title: "Company logo",
                  ownerId: "",
                  type: "companyLogo",
                  file: partnerLogoImage as File
                });
                if (logoDocument == null) {
                  toast.error(t('company:companyEditError'));
                  return;
                }
                companyEdit.companyLogo = company.companyDetail.fileStorageBaseUrl + logoDocument.document.storagePath;
              }
              editCompany(company, companyEdit)

              toast.dismiss();
              toast.success(t('company:companyModified'));
            }
            catch (e) {
              toast.dismiss();
              toast.error(t('company:companyEditError'));
            }
          }
          }>{t('common:save')}</button>
        </ModalFooter>
      </Modal>
      {/*  FEATURES MODAL */}
      <Modal
        id="featuresModal"
        onHide={() => setShowFeaturesModal(false)}
        show={showFeaturesModal}
        scrollable>
        <ModalHeader closeButton>
          <ModalTitle>{t('company:manage_features')}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <CompanyManageFeatures
            company={companyEdit}
            handleFeaturesChange={(feature) => {
              setCompanyEdit(currentCompany => ({
                ...currentCompany,
                enabledFeatures: {
                  ...currentCompany.enabledFeatures,
                  [feature]: !currentCompany.enabledFeatures[feature]
                }
              }));
            }}
          />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={async () => {
            setShowFeaturesModal(false);
            toast.loading(t('company:companyEditProgress'));
            await editCompany(company, companyEdit);
            toast.dismiss();
          }
          }>{t('common:save')}</button>

        </ModalFooter>
      </Modal>
    </>
  )
}
export default CompaniesManagerPage