import { ChatMessage } from "models/chatModels";
import React, { } from "react"
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useChatStore } from "stores/chatStore";
import { toast } from "react-hot-toast";
import { useChatMessengerStore } from "stores/chatMessengerStore";
const messageChildrenVariants = {
    hidden: {
        opacity: 0,
        x: -50
    },
    visible: {
        opacity: 1,
        x: 0
    },
    exit: { opacity: 0, x: -50 },
};
function ChatSwitchMessageElement({ message }: { message: ChatMessage }) {
    const { t } = useTranslation();
    const [isSwitiching, setIsSwitching] = React.useState(false);
    return <motion.div
        variants={messageChildrenVariants}
        className={"ChatSwitchMessageElement w-100 d-flex py-3 "}
    >
        <div className="mx-auto p-3 rounded d-flex flex-column align-items-center p-3 text-center bg-body-tertiary text-primary">
            <div>
                {t('chat:' + message.message)}
            </div>
            {
                useChatMessengerStore.getState().activeChat?.asyncChatDetail.useAI === false && message.message !== "change_user_ai" &&
                <div
                    className="text-body-secondary text-hoverable cursor-pointer mt-2"
                    style={{
                        fontSize: "0.7rem",
                    }}
                    onClick={async () => {
                        if (isSwitiching) return;
                        setIsSwitching(true);
                        toast.loading(t("common:loading"));
                        console.log("Switching back to AI", useChatMessengerStore.getState().activeChat);
                        await useChatStore.getState().restoreAI(useChatMessengerStore.getState().activeChat!);
                        toast.dismiss();
                        setIsSwitching(false);
                    }}
                >
                    {t('chat:switch_back_ai')}
                </div>}
        </div>
    </motion.div >;
}
export default ChatSwitchMessageElement;