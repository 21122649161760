import BeeWiseLandingPage from "./BeeWiseLandingPage";
import DefaultLandingPage from "./DefaultLandingPage";
import WelfareLandingPage from "./WelfareLandingPage";
import SplitLandingPage from "./SplitLandingPage";

export const landingPagesLibrary = {
        "welfare": WelfareLandingPage,
        "default": DefaultLandingPage,
        "beewise": BeeWiseLandingPage,
        "split": SplitLandingPage
};