import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { useUserStore } from "./userStore";
import cloneDeep from "clone-deep";
export interface LifepointsStore {
    currentLifepointData: any;
    getUserLifepoints: () => Promise<any>;
    refreshLifePoints: (expectedLifepoints?: number) => void;
}
//* This is the lifepointsStore.js file
//* It is used to store the lifepoints of the user
//* Call the refreshLifePoints() function to update the lifepoints of the user. 
//* Provide a expectedLifepoints parameter to set the lifepoints to a specific value before the API call is made.
//  This is useful when you want to update the lifepoints of the user before waiting for the API call to complete and the expectedLifepoints are known.
export const useLifepointsStore = create<LifepointsStore>()((set, get, __) => {
    return {
        currentLifepointData: null,
        getUserLifepoints: async () => {
            if (get().currentLifepointData === null) {
                await get().refreshLifePoints();
            } else {
                return get().currentLifepointData;
            }
        },
        refreshLifePoints: function (expectedLifepoints) {
            if (expectedLifepoints !== undefined) {
                let tmpLifepoints = get().currentLifepointData;
                tmpLifepoints.lifePointDetail.currentLifePoints = expectedLifepoints;
                set({ currentLifepointData: cloneDeep(tmpLifepoints) });
                return;
            }
            ApiService.lifePoints.getLifePointsByUserProfileId(useUserStore.getState().user?.userProfileId)
                .then(function (lifepointResponse) {
                    set({ currentLifepointData: lifepointResponse });
                });
        },
    };
});