import { create } from "./createStore";
import { ApiService } from "../services/apiService";
import { convertHexToColor, generateImageUrl } from "utils/functions";
import { useDocumentStore } from "./documentStore";
import { useUserStore } from "./userStore";
import { Partner } from "models/partnerModel";
export interface PartnerStore {

    partnerList: Partner[] | null;
    initPartnerStore: () => Promise<any>;
    isPartnerActive: (partner: any) => boolean;
    createPartner: (partnerDetail: any) => Promise<any>;
    editPartner: (partner: Partner, data: any) => Promise<any>;
}
export const usePartnerStore = create<PartnerStore>()((set, get, __) => ({
    partnerList: null,
    initPartnerStore: async () => {
        try {
            let partners = await ApiService.partners.searchPartners();
            if (partners) {
                set({ partnerList: partners });
                return partners;
            } else {
                set({ partnerList: [] });
                return [];
            }
        } catch (e) {
            console.log('Error initializing partner store', e);
            return null;
        }
    },
    isPartnerActive: (partner) => {
        if (partner.partnerDetail.startDate == null || partner.partnerDetail.endDate == null) {
            return true;
        }
        const today = new Date();
        const startDate = new Date(partner.partnerDetail.startDate);
        const endDate = new Date(partner.partnerDetail.endDate);
        if (today >= startDate && today <= endDate) {
            return true;
        }
        return false;
    },
    createPartner: async (partnerDetail) => {
        try {
            if (partnerDetail.bannerImage) {
                let imageData = await useDocumentStore.getState().uploadDocument({
                    file: partnerDetail.bannerImage,
                    type: 'image',
                    title: partnerDetail.bannerImage.name,
                    format: partnerDetail.bannerImage.type,
                    ownerId: useUserStore.getState().user?.userProfileId ?? ""
                });
                /* partnerDetail.banner = imageData; */
                partnerDetail.bannerId = generateImageUrl(imageData as any);
            }
            if (partnerDetail.startDate) {
                partnerDetail.startDate = new Date(partnerDetail.startDate).toISOString();
            }
            if (partnerDetail.endDate) {
                partnerDetail.endDate = new Date(partnerDetail.endDate).toISOString();
            }
            partnerDetail.backGroundColor1 = convertHexToColor(partnerDetail.backGroundColor1 ?? "");
            partnerDetail.backGroundColor2 = convertHexToColor(partnerDetail.backGroundColor2);
            partnerDetail.button = convertHexToColor(partnerDetail.textColor1);
            partnerDetail.callToActionColor = convertHexToColor(partnerDetail.callToActionColor);
            partnerDetail.textColor1 = convertHexToColor(partnerDetail.textColor1);
            partnerDetail.textColor2 = convertHexToColor(partnerDetail.textColor2);


            let newPartner = await ApiService.partners.postPartner({
                partnerDetail: {
                    ...partnerDetail,
                }
            });
            if (newPartner !== null) {
                let partners = await ApiService.partners.searchPartners();
                if (partners !== null) {
                    set({ partnerList: [newPartner, ...[get().partnerList ?? []]] });
                    return partners;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (e) {
            console.log('Error while creating partner', e);
            throw e;
        }
    },
    editPartner: async (partner, data) => {
        try {
            if (data.bannerImage) {
                let imageData = await useDocumentStore.getState().uploadDocument({
                    file: data.bannerImage,
                    type: 'image',
                    title: data.bannerImage.name,
                    format: data.bannerImage.type,
                    ownerId: useUserStore.getState().user?.userProfileId ?? ""
                });
                /* data.bannerImage = imageData; */
                data.bannerId = generateImageUrl(imageData as any);
            }
            if (data.startDate) {
                data.startDate = new Date(data.startDate).toISOString();
            }
            if (data.endDate) {
                data.endDate = new Date(data.endDate).toISOString();
            }
            data.backGroundColor1 = convertHexToColor(data.backGroundColor1);
            data.backGroundColor2 = convertHexToColor(data.backGroundColor2);
            data.button = convertHexToColor(data.textColor1);
            data.callToActionColor = convertHexToColor(data.callToActionColor);
            data.textColor1 = convertHexToColor(data.textColor1);
            data.textColor2 = convertHexToColor(data.textColor2);
            let newPartner = await ApiService.partners.putPartner({
                partnerId: partner.partnerId,
                partnerDetail: {
                    ...partner.partnerDetail,
                    ...data
                }
            });
            if (newPartner !== null) {
                let partners = await ApiService.partners.searchPartners();
                if (partners !== null) {
                    set({ partnerList: partners });
                    return partners;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (e) {
            console.log('Error while editing partner', e);
            throw e;
        }
    }
}));
