import Loader from "components/Loader";
import React, { useEffect } from "react";
import { EduQuizGameStates, useEduquizStore } from "stores/eduquizStore";
import { useParams } from 'react-router-dom';
import EduQuizLearnView from "./EduQuizLearnView";
import EduQuizGameView from "./EduQuizGameView";
import EduQuizEndView from "./EduQuizEndView";
import EduQuizGameOverModal from "./EduQuizGameOverModal";
export default function EduQuizGamePage() {
    let { unitId } = useParams();
    const selectedUnit = useEduquizStore(state => state.selectedUnit);
    const getUnit = useEduquizStore(state => state.getUnit);
    const quizState = useEduquizStore(state => state.quizState);
    //setstate for gameover modal
/*     const [gameOverModal, setGameOverModal] = React.useState(false); */
    //If the selected unit is not the same as the unitId in the url, or the quizState is initial, get the unit
    useEffect(() => {
        if (!selectedUnit || selectedUnit?.unitId !== unitId /* || quizState === EduQuizGameStates.initial */) {
            console.log("EduQuizGamePage: Unit not loaded yet, getting unit");
            getUnit(unitId!);
        }
    }, [getUnit, selectedUnit, unitId, quizState]);

    // If the selected unit is not loaded yet, or the quizState is initial, show the loader
    if (!selectedUnit || quizState === EduQuizGameStates.initial || selectedUnit?.unitId !== unitId) { return (<Loader />); }
    /*     const color = selectedUnit.moduleDetail.argument.argumentDetail.color.replace("0xFF", "#"); */
    // If the quizState is learning, show the EduQuizLearnView
    // If the quizState is gaming, show the EduQuizGameView
    // If the quizState is ending, show the EduQuizEndView
    return (
        <section className="container-lg EduQuizModulePage d-flex flex-column pt-3 h-100">
            {quizState === EduQuizGameStates.gameOver && <EduQuizGameOverModal />}
            {quizState === EduQuizGameStates.learning && <EduQuizLearnView />}
            {(quizState === EduQuizGameStates.gaming || quizState === EduQuizGameStates.gameOver) && <EduQuizGameView />}
            {quizState === EduQuizGameStates.ending && <EduQuizEndView />}
        </section>
    )
}
