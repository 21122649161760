import React from 'react'
import { useTranslation } from 'react-i18next';

function TitleViewAll({ title, onClick, className }: { title: string, onClick: () => void, className?: string }) {
    const { t } = useTranslation();
    return (
        <div className={"d-flex justify-content-between align-items-center " + className ?? ""} >
            <p className="mb-0 fw-bold">{title}</p>
            <div className="btn btn-outline d-flex align-items-center pe-0" >
                <div className="small text-primary text-uppercase" onClick={onClick}>{t('common:view_all')}</div>
                <i className='fa-duotone fa-angle-right text-primary ms-2' />
            </div>
        </div>
    )
}

export default TitleViewAll