import { CompanyDetail } from 'models/companyModel'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

function CompanyManageStyle(
    { company, handleStyleChange }
        : {
            company: CompanyDetail,
            handleStyleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
        }): JSX.Element {
    const { t } = useTranslation();
    return (
        <Form>
            <h5>{t('company:configureStyle')}</h5>
            <p>{t('company:configureStyleDescription')}</p>
            <Form.Group className="mb-3">
                <Form.Label>{t('company:primaryColor')}</Form.Label>
                <Form.Control type="color" defaultValue={company.backGroundColor2 || '#1cafb9'} name="primaryColor" onChange={handleStyleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>{t('company:secondaryColor')}</Form.Label>
                <Form.Control type="color" defaultValue={company.textColor1 || '#343a40'} name="secondaryColor" onChange={handleStyleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>{t('company:backgroundColor')}</Form.Label>
                <Form.Control type="color" defaultValue={company.backGroundColor1 || '#F4F4F4'} name="backgroundColor" onChange={handleStyleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>{t('company:customCSS')}</Form.Label>
                <Form.Control as="textarea" rows={6} defaultValue={company.callToActionColor || ''} name="customCSS" onChange={handleStyleChange} />
            </Form.Group>
        </Form>
    )
}

export default CompanyManageStyle