import { UserProfile } from "models/userModels";
import Config from "utils/config";
import { useAuthStore } from "./authStore";
import { useCompanyStore } from "./companyStore";
import { create } from "./createStore";
import { useEduquizStore } from "./eduquizStore";
import { useExpertsStore } from "./expertsStore";
import { useSystemModalStore } from "./systemModalStore";
import { useVideoStore } from "./videoStore";

export interface SurveyStore {
    surveyReady: boolean;
    hasDoneMainSurvey: boolean;
    initSurvey: (user: UserProfile) => Promise<void>;
    onSurveyCompleted: (survey: string) => Promise<void>;
    forceSurvey: () => void;
}

export const useSurveyStore = create<SurveyStore>()((set, get) => ({
    surveyReady: false,
    hasDoneMainSurvey: false,
    initSurvey: async (user: UserProfile) => {
        if (!Config.production || !Config.enabledFeatures.initialSurvey) {
            set({ hasDoneMainSurvey: true, surveyReady: true });
            return;
        }
        if (!get().surveyReady) {
            const storageSurveyState = user.userProfileDetail.formsCompleted['surveyCompleted'] === true;
            if (storageSurveyState) {
                set({ hasDoneMainSurvey: true, surveyReady: true });
            } else {
                set({ hasDoneMainSurvey: false, surveyReady: true });
            }
        }
    },
    onSurveyCompleted: async (survey) => {
        if (survey === 'main') {
            /*             if (useAuthStore.getState().isUser) { */
            if (!useCompanyStore.getState().currentCompany?.companyId) {
                useSystemModalStore.getState().showErrorModal('Non risulti associato ad alcuna azienda. Contatta il supporto.');
                return;
            }
            await useEduquizStore.getState().initEduquiz(useCompanyStore.getState().currentCompany?.companyId!);
            await useVideoStore.getState().initVideoStore();
            await useExpertsStore.getState().initExpertsStore();
            /*           } */
            set({ hasDoneMainSurvey: true });
        }
    },
    forceSurvey: () => {
        set({ hasDoneMainSurvey: false });
    }
}));

